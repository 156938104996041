import { TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useDocumentNaming } from "@/hooks"
import { cn } from "@/lib/utils"
import { Tooltip } from "@radix-ui/react-tooltip"
import { useState } from "react"
import { DocumentViewer } from ".."

interface DocumentNamePillProps {
	id: string
	size?: "sm" | "lg"
	className?: string
}

export const DocumentNamePill = ({
	id,
	size = "sm",
	className,
}: DocumentNamePillProps) => {
	const { getDocumentName } = useDocumentNaming()
	const name = getDocumentName(id)
	const [showDocumentViewer, setShowDocumentViewer] = useState(false)
	return (
		<>
			<Tooltip>
				<TooltipTrigger>
					<div
						className={cn(
							// Base styles
							"inline-block rounded-full border",
							// Light mode styles
							"border-blue-300 bg-blue-50 text-black",
							// Dark mode styles
							"dark:border-blue-700 dark:bg-blue-950 dark:text-white",
							// Size variants
							size === "sm" ? "px-2 py-0.5 text-xs" : "px-3 py-1 text-sm",
							className,
						)}
						onClick={() => setShowDocumentViewer(true)}
					>
						{name}
					</div>
				</TooltipTrigger>
				<TooltipContent>View</TooltipContent>
			</Tooltip>
			<DocumentViewer
				open={showDocumentViewer}
				handleClose={() => setShowDocumentViewer(false)}
				documentId={id}
			/>
		</>
	)
}

export default DocumentNamePill
