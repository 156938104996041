import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "@/components/ui/context-menu"
import { Label } from "@/components/ui/label"
import { CondensedParagraph } from "@/components/ui/typography"
import type { Patent } from "@/types"
import { cleanDocumentChunkText } from "@/utils/formatStrings"
import type React from "react"
import { useState } from "react"

/**
 * @description Subject abstract and images component
 * @param {object} details - The details to display
 */
interface PatentAbstractImagesProps {
	patent: Patent
	citationText?: string
}

const PatentAbstract: React.FC<PatentAbstractImagesProps> = ({
	patent,
	citationText,
}) => {
	const [hasSelection, setHasSelection] = useState(false)

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text)
	}

	const handleCopyWithCitation = () => {
		const selection = window.getSelection()?.toString().trim()
		if (hasSelection && selection) {
			const textWithCitation = `${selection}\n\n${"Abstract"}`
			navigator.clipboard.writeText(textWithCitation)
		} else {
			const textWithCitation = `${cleanDocumentChunkText(patent.abstract)}\n\n${"Abstract"}`
			navigator.clipboard.writeText(textWithCitation)
		}
	}

	const handleContextMenuOpen = (_e: React.MouseEvent) => {
		const selection = window.getSelection()?.toString().trim()
		setHasSelection(!!selection)
	}

	return (
		<>
			{patent.abstract && (
				<div className="mb-4">
					<Label className="mb-2">Abstract</Label>
					<ContextMenu>
						<ContextMenuTrigger
							onContextMenu={handleContextMenuOpen}
							className="cursor-text select-text"
						>
							<CondensedParagraph>
								{citationText && patent?.abstract ? (
									<>
										{patent?.abstract
											.split(new RegExp(citationText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i"))
											.map((part, i) =>
												part.toLowerCase() === citationText?.toLowerCase() ? (
													<span key={i} className="bg-blue-100">
														{part}
													</span>
												) : (
													part
												),
											)}
									</>
								) : (
									patent?.abstract
								)}
							</CondensedParagraph>
						</ContextMenuTrigger>
						<ContextMenuContent>
							<ContextMenuItem
								onClick={() => {
									const selection = window.getSelection()?.toString().trim()
									copyToClipboard(selection || patent.abstract)
								}}
							>
								Copy
							</ContextMenuItem>
							<ContextMenuItem onClick={handleCopyWithCitation}>
								Copy with Citation
							</ContextMenuItem>
						</ContextMenuContent>
					</ContextMenu>
				</div>
			)}
		</>
	)
}

export default PatentAbstract
