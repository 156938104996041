import { DateTime } from "luxon"

type DateInput = Date | string | DateTime

export const getUtcDateNow = () => {
	return DateTime.now().setZone("utc").startOf("day")
}

// Helper function to extract the day, month, and year from a Date object
const extractDayMonthYearFromDate = (date: DateInput) => {
	let jsDate: Date

	if (date instanceof DateTime) {
		jsDate = date.toJSDate()
	} else if (typeof date === "string") {
		jsDate = new Date(date)
	} else {
		jsDate = date
	}

	const day = jsDate.getDate()
	const month = jsDate.getMonth() + 1 // months are 0-indexed
	const year = jsDate.getFullYear()
	return { day, month, year }
}

export const getUtcDateString = (date: DateInput) => {
	if (date instanceof DateTime) {
		return date.toFormat("MM/dd/yyyy")
	}
	if (typeof date === "string") {
		return DateTime.fromISO(date, { zone: "utc" }).toFormat("MM/dd/yyyy")
	}
	return DateTime.fromJSDate(date, { zone: "utc" }).toFormat("MM/dd/yyyy")
}

export const convertToUtcDateString = (date: DateInput) => {
	if (!date) return ""
	const { day, month, year } = extractDayMonthYearFromDate(date)
	// Set hour=12
	return DateTime.utc(year, month, day, 12).toFormat("MM/dd/yyyy")
}

export const convertToUtcDateTimeString = (date: DateInput) => {
	const { day, month, year } = extractDayMonthYearFromDate(date)
	// Set hour=12
	return DateTime.utc(year, month, day, 12).toISO()
}

export const convertToUtcDate = (date: DateInput) => {
	const { day, month, year } = extractDayMonthYearFromDate(date)
	// Set hour=12
	return DateTime.utc(year, month, day, 12)
}

/**
 * Converts a UTC date input into a Luxon DateTime object in the user's local time zone.
 * You can also pass an optional `format` string (using Luxon tokens) to return a formatted string.
 *
 * @param date - The UTC date input (Date, ISO string, or DateTime).
 * @param format - (Optional) A Luxon format string (e.g., "MM/dd/yyyy HH:mm").
 * @returns The converted DateTime adjusted to the local time zone, or a formatted string if `format` is provided.
 *
 * Example:
 *   convertUtcToLocal("2025-02-02T22:45:46.446000Z", "MM/dd/yyyy hh:mm a")
 */
export const convertUtcToLocal = (
	date: DateInput,
	format?: string,
): DateTime | string => {
	let dt: DateTime

	if (date instanceof DateTime) {
		dt = date
	} else if (typeof date === "string") {
		dt = DateTime.fromISO(date, { zone: "utc" })
	} else {
		dt = DateTime.fromJSDate(date, { zone: "utc" })
	}

	// Convert to local time zone
	dt = dt.setZone("local")

	return format ? dt.toFormat(format) : dt
}
