import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import { useAppStateStore } from "@/store"
import type { InvaliditySettings } from "@/types"
import { invalidateSettingsRelatedQueries } from "@/utils/query/invalidation"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useQueryClient } from "@tanstack/react-query"
/**
 * Hook to get and update invalidity settings for a project or portfolio
 * @returns {Object} - An object containing the settings, loading state, error, and a function to update the settings
 */
export function useInvaliditySettings(budget?: number) {
	const queryClient = useQueryClient()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()
	const { projectId, portfolioId } = useParentContext()

	const invalidateSettings = () => {
		queryClient.invalidateQueries({
			queryKey: QUERY_KEYS.invaliditySettings(projectId, portfolioId),
		})
	}

	// Fetch settings for project if id exists
	const {
		data: settings,
		isLoading,
		error,
	} = useQuery<InvaliditySettings>({
		queryKey: QUERY_KEYS.invaliditySettings(projectId, portfolioId),
		queryFn: () => api.getInvaliditySettings(projectId, portfolioId),
		enabled: !!projectId || !!portfolioId,
		...DEFAULT_QUERY_OPTIONS,
	})

	// Mutations
	const updateProjectSettingsMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.settings.update(),
		mutationFn: (newSettings: InvaliditySettings) => {
			return api.updateProjectSettings(projectId, portfolioId, budget, newSettings)
		},
		onMutate: async (newSettings) => {
			return handleOptimisticUpdate({
				queryClient,
				queryKeys: [QUERY_KEYS.invaliditySettings(projectId, portfolioId)],
				updateFn: () => newSettings,
			})
		},
		onError: (_err, _variables, context) => {
			// If the mutation fails, use the context we saved to roll back
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			addErrorMessage("Error updating project settings")
		},
		onSuccess: () => {
			if (projectId) {
				invalidateSettingsRelatedQueries(queryClient, projectId, portfolioId || null)
			}
			addSuccessMessage("Project settings updated successfully")
		},
	})

	return {
		settings: settings as InvaliditySettings,
		isLoading,
		error: error || undefined,
		updateSettings: updateProjectSettingsMutation.mutateAsync as (
			settings: Partial<InvaliditySettings>,
		) => Promise<void>,
		invalidateSettings,
	}
}

export default useInvaliditySettings
