/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import { usePatentDetails } from "@/hooks"
import type { PatentNumberOption } from "@/types"
import { isLikelyPatentNumber, parseCommaSeparatedString } from "@/utils"
import type React from "react"
import { useState } from "react"

/**
 * A card component that renders full patent details.
 */
const PatentDetailCard: React.FC<{ patent: PatentNumberOption }> = ({ patent }) => {
	return (
		<div key={patent.number} className="text-sm space-y-1">
			<div className="font-medium">{patent.number}</div>
			{patent.details && (
				<>
					<div className="text-gray-600">
						{typeof patent.details.title === "string" ? patent.details.title : ""}
					</div>
					<div className="text-gray-500 text-xs">
						{typeof patent.details.assignee === "string" ? patent.details.assignee : ""}
					</div>
					<div className="text-gray-500 text-xs">
						{typeof patent.details.publicationDate === "string"
							? patent.details.publicationDate
							: ""}
					</div>
					{typeof patent.details.abstract === "string" && (
						<div className="text-gray-600 text-xs mt-1">{patent.details.abstract}</div>
					)}
				</>
			)}
		</div>
	)
}

interface PatentNumberInputProps {
	/**
	 * Maximum number of patents allowed to input.
	 */
	maxPatents?: number
	/**
	 * Placeholder text for the textarea.
	 */
	placeholder?: string
	/**
	 * Text for the submit button.
	 */
	submitButtonText?: string
	/**
	 * Optional additional validation function.
	 */
	validatePatentNumber?: (number: string) => boolean
	/**
	 * Callback function to send the fetched patent details.
	 */
	onDetails: (patentDetails: PatentNumberOption[]) => void
	/**
	 * Callback function to handle the cancel button.
	 */
	onCancel?: () => void
	/**
	 * Callback function to handle the error.
	 */
	onError?: (error: string) => void
	/**
	 * Whether to only show the input error.
	 */
	onlyShowInputError?: boolean
	/**
	 * The project ID.
	 */
	projectId: string
	/**
	 * The project multiplier.
	 */
	projectMultiplier?: number | null
	/**
	 * Callback function when user confirms proceeding despite budget exceeded.
	 */
	onBudgetExceededConfirm?: () => void
}

/**
 * A generalized component for patent number input, fetching details, displaying results, and confirming selection.
 */
const PatentNumberInput: React.FC<PatentNumberInputProps> = ({
	maxPatents = 100,
	placeholder = "e.g., US10952930, EP1095293A1",
	submitButtonText = "Find Patents",
	validatePatentNumber,
	onDetails,
	onCancel,
	onlyShowInputError = false,
	projectId,
	projectMultiplier,
	onBudgetExceededConfirm,
}) => {
	const [inputText, setInputText] = useState<string>("")
	const [inputError, setInputError] = useState<string>("")
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const { getPatentDetails } = usePatentDetails()
	const [budgetExceededAmount, setBudgetExceededAmount] = useState<number | null>(null)
	const [pendingDetails, setPendingDetails] = useState<PatentNumberOption[]>([])

	const handleSubmit = async () => {
		const patentNumbers = parseCommaSeparatedString(inputText).filter(
			(num) => num.trim() !== "",
		)

		// Check for maximum limit
		if (patentNumbers.length > maxPatents) {
			setInputError(`You can input up to ${maxPatents} patent numbers.`)
			return
		}

		// Validate patent numbers
		const validPatentNumbers: string[] = []
		const invalidPatentNumbers: string[] = []
		for (const patentNumber of patentNumbers) {
			const isValid =
				isLikelyPatentNumber(patentNumber) &&
				(validatePatentNumber ? validatePatentNumber(patentNumber) : true)
			if (isValid) {
				validPatentNumbers.push(patentNumber)
			} else {
				invalidPatentNumbers.push(patentNumber)
			}
		}

		if (invalidPatentNumbers.length > 0) {
			setInputError(
				`The following patent numbers are invalid: ${invalidPatentNumbers.join(", ")}.`,
			)
			return
		}

		if (validPatentNumbers.length === 0) {
			setInputError("No valid patent numbers were input.")
			return
		}

		// Fetch patent details
		setIsLoading(true)
		try {
			const response = await getPatentDetails(
				validPatentNumbers,
				projectId,
				false,
				projectMultiplier,
			)

			// Check for budget exceeded
			const budgetExceededItem = response.find(
				(item: PatentNumberOption) => item.isBudgetExceeded,
			)
			if (budgetExceededItem) {
				setBudgetExceededAmount(-budgetExceededItem.remainingBudget)
				// Store the items to show under the warning
				const successfulItems = response.filter(
					(item: PatentNumberOption) => item.error === null,
				)
				setPendingDetails(successfulItems)
				return
			}

			const successfulItems = response.filter(
				(item: PatentNumberOption) => item.error === null,
			)
			const failedItems = response.filter(
				(item: PatentNumberOption) => item.error !== null,
			)

			if (failedItems.length > 0) {
				const failedNumbers = failedItems.map((item) => item.number)
				setInputError(
					`The following patent numbers were not found: ${failedNumbers.join(", ")}.`,
				)
			} else {
				setInputError("")
			}

			if (successfulItems.length > 0) {
				onDetails(successfulItems)
				setInputText("")
				setPendingDetails([]) // Clear pending details
			} else {
				setInputError("No valid patent numbers were found.")
			}
		} catch (_error) {
			setInputError("An error occurred while fetching patent details.")
		} finally {
			setIsLoading(false)
		}
	}

	/**
	 * When the budget is exceeded and the user clicks "Continue Anyway",
	 * this function directly passes the existing pending details to the onDetails callback,
	 * ensuring the full details are shown as in the normal case.
	 */
	const handleBudgetExceededConfirm = () => {
		if (pendingDetails.length > 0) {
			onDetails(pendingDetails)
			setInputText("")
		}
		setBudgetExceededAmount(null)
		setPendingDetails([])
		if (onBudgetExceededConfirm) {
			onBudgetExceededConfirm()
		}
	}

	return (
		<div className="space-y-4">
			{isLoading ? (
				<Loader message="Fetching patent details..." />
			) : budgetExceededAmount ? (
				<div className="space-y-4">
					<Alert variant="destructive">
						<AlertDescription>
							This action will exceed this project's budget by $
							{budgetExceededAmount.toLocaleString()}. Are you sure you want to continue?
						</AlertDescription>
					</Alert>
					{pendingDetails.length > 0 && (
						<div className="mt-4 border rounded p-4">
							<h3 className="text-sm font-medium mb-2">Patents to be added:</h3>
							<div className="space-y-3">
								{pendingDetails.map((item) => (
									<PatentDetailCard key={item.number} patent={item} />
								))}
							</div>
						</div>
					)}
					<div className="flex justify-end gap-2">
						<Button
							variant="outline"
							onClick={() => {
								setBudgetExceededAmount(null)
								setPendingDetails([])
							}}
						>
							Cancel
						</Button>
						<Button variant="destructive" onClick={handleBudgetExceededConfirm}>
							Continue Anyway
						</Button>
					</div>
				</div>
			) : onlyShowInputError ? (
				<>
					{inputError && (
						<Alert variant="destructive">
							<AlertDescription>{inputError}</AlertDescription>
						</Alert>
					)}
				</>
			) : (
				<>
					<p className="text-sm text-gray-500">
						Enter a comma-separated list of published patents and applications numbers. A
						country prefix is required.
					</p>
					<AutosizeTextarea
						value={inputText}
						onChange={(e) => setInputText(e.target.value)}
						placeholder={placeholder}
					/>
					{inputError && (
						<Alert variant="destructive">
							<AlertDescription>{inputError}</AlertDescription>
						</Alert>
					)}
					<div className="flex justify-end gap-2">
						{onCancel && (
							<Button variant="outline" onClick={onCancel}>
								Cancel
							</Button>
						)}
						<Button onClick={handleSubmit} disabled={inputText.trim() === ""}>
							{submitButtonText}
						</Button>
					</div>
				</>
			)}
		</div>
	)
}

export default PatentNumberInput
