import useClaims from "@/hooks/project/useClaims"
import {
	ChartMode,
	type CollapsedLimitation,
	type InvalidityChart,
	type InvalidityChartData,
} from "@/types"
import { DocumentSection } from "@/types/documents"
import type React from "react"
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"
import { useParams } from "react-router-dom"
import { scrollToCenter, scrollToElementTop } from "../components/scrollHelpers"
import useClaimInvalidityData from "../hooks/queries/useClaimInvalidityData"
import useInvalidityChartData from "../hooks/queries/useInvalidityChartData"

// Updated interface to include the new state names and setters.
export interface ChartsContextType {
	invalidityChartId: string | null
	filterLimitations: string[] // limitation ids filter
	filterColors: string[] // HEX color codes filter
	selectedChartMode: ChartMode // table or document
	lastSeenLimitationId: string | null // limitation id of the last seen limitation
	selectedDocumentId: string | null // document id of the currently selected document
	selectedDocumentSection: DocumentSection // section of the currently selected document

	setFilterLimitations: (limitations: string[]) => void
	setFilterColors: (colors: string[]) => void
	setSelectedChartMode: (chartMode: ChartMode) => void
	setSelectedDocumentId: (documentId: string | null) => void
	setSelectedDocumentSection: (section: DocumentSection) => void
	activeLimitationId: string | null // limitation id of the currently selected limitation (used for navigation)
	activeChunkId: string | null // chunk id of the currently selected chunk (used for navigation)

	isCombination: boolean

	setActiveLimitationId: (limitationId: string | null) => void
	setActiveChunkId: (chunkId: string | null) => void
	setLastSeenLimitationId: (limitationId: string | null) => void

	selectedDocumentChunkRef: React.MutableRefObject<HTMLDivElement | null>
	selectedClaimRef: React.MutableRefObject<HTMLDivElement | null>
	selectedTableRowRef: React.MutableRefObject<HTMLDivElement | null>
	scrollToParagraph: (documentChunkId: string) => void
	scrollToLimitation: (limitationId: string) => void

	// Expose the computed filtered limitations
	filteredLimitations: CollapsedLimitation[]

	activeLimitationInvalidityData: InvalidityChartData | null
	isActiveLimitationInvalidityDataLoading: boolean
	isActiveLimitationInvalidityDataError: boolean

	chartData: InvalidityChart | null
}

// Provide a default (empty) context; will be overridden by the real provider.
const ChartsContext = createContext<ChartsContextType | undefined>(undefined)

interface ChartsContextProviderProps {
	children: React.ReactNode
}

export const ChartsContextProvider: React.FC<ChartsContextProviderProps> = ({
	children,
}) => {
	const { projectId, invalidityChartId } = useParams()
	// New state variables per the updated interface
	const [filterLimitations, setFilterLimitations] = useState<string[]>([])
	const [filterColors, setFilterColors] = useState<string[]>([])
	const [selectedChartMode, setSelectedChartMode] = useState<ChartMode>(ChartMode.TABLE)
	const [selectedDocumentSection, setSelectedDocumentSection] =
		useState<DocumentSection>(DocumentSection.ALL)
	const [activeLimitationId, setActiveLimitationId] = useState<string | null>(null)
	const [activeChunkId, setActiveChunkId] = useState<string | null>(null)
	const [lastSeenLimitationId, setLastSeenLimitationId] = useState<string | null>(null)
	const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null)
	// Retrieve collapsedLimitations from useClaims
	const { collapsedLimitations = [] } = useClaims()

	// Refs for scrolling
	const selectedDocumentChunkRef = useRef<HTMLDivElement | null>(null)
	const selectedClaimRef = useRef<HTMLDivElement | null>(null)
	const selectedTableRowRef = useRef<HTMLDivElement | null>(null)

	// Scroll to a given paragraph/element and update activeChunkId
	const scrollToParagraph = useCallback((documentChunkId: string) => {
		setActiveChunkId(documentChunkId)
		// Use a delay so that DocumentPanel has re-rendered and attached the ref to the target element.
		setTimeout(() => {
			if (selectedDocumentChunkRef.current) {
				scrollToCenter(selectedDocumentChunkRef.current)
			}
		}, 100)
	}, [])

	// Suppose you keep track of the current chartId & limitationIds in your context
	const {
		limitationInvalidityData: activeLimitationInvalidityData,
		isLoading: isActiveLimitationInvalidityDataLoading,
		isError: isActiveLimitationInvalidityDataError,
	} = useClaimInvalidityData(
		invalidityChartId,
		activeLimitationId ? [activeLimitationId] : [],
		{
			projectId,
			documents: [],
			colors: filterColors,
			enabled: selectedChartMode === ChartMode.DOCUMENT,
		},
	)

	useEffect(() => {
		console.log("filterColors", filterColors)
	}, [filterColors])

	const { data: chartData } = useInvalidityChartData(projectId, invalidityChartId)

	// Scroll to a given limitation in the claim panel or table view and update activeLimitationId
	const scrollToLimitation = useCallback(
		(limitationId: string) => {
			setActiveLimitationId(limitationId)
			// Delay scrolling slightly so that the ref has been (re)assigned
			setTimeout(() => {
				if (selectedChartMode === ChartMode.TABLE) {
					if (selectedTableRowRef.current) {
						scrollToElementTop(selectedTableRowRef.current)
					}
				} else {
					if (selectedClaimRef.current) {
						scrollToCenter(selectedClaimRef.current)
					}
				}
			}, 0)
		},
		[selectedChartMode],
	)

	/**
	 * Instead of a generic filtering function, we simply compute
	 * the filtered limitations here. This uses the limitations
	 * from useClaims and applies the active filters.
	 */
	const filteredLimitations = useMemo(() => {
		return collapsedLimitations.filter(
			(limitation) =>
				filterLimitations.length === 0 || filterLimitations.includes(limitation.id),
		)
	}, [collapsedLimitations, filterLimitations])

	const isCombination = useMemo(() => {
		return chartData?.documentIds?.length && chartData.documentIds.length > 1
	}, [chartData])

	const value: ChartsContextType = {
		invalidityChartId,
		filterLimitations,
		filterColors,
		selectedChartMode,
		setFilterLimitations,
		setFilterColors,
		setSelectedChartMode,
		activeLimitationId,
		activeChunkId,
		setActiveLimitationId,
		setActiveChunkId,
		selectedDocumentChunkRef,
		selectedClaimRef,
		selectedTableRowRef,
		scrollToParagraph,
		scrollToLimitation,
		lastSeenLimitationId,
		setLastSeenLimitationId,
		selectedDocumentId,
		setSelectedDocumentId,
		selectedDocumentSection,
		setSelectedDocumentSection,

		isCombination,
		// Expose the computed filtered limitations
		filteredLimitations,
		activeLimitationInvalidityData,
		isActiveLimitationInvalidityDataLoading,
		isActiveLimitationInvalidityDataError,

		chartData,
	}

	return <ChartsContext.Provider value={value}>{children}</ChartsContext.Provider>
}

export const useChartsContext = (): ChartsContextType => {
	const context = useContext(ChartsContext)
	if (!context) {
		throw new Error("useChartsContext must be used within a ChartsContextProvider")
	}
	return context
}
