import * as React from "react"
import { Button } from "@/components/ui/button"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"

export interface TooltipButtonProps {
	icon: React.ReactNode
	tooltipText: string
	onClick: () => void
	disabled?: boolean
	srText?: string
	size?: "icon" | "xsIcon" | "smIcon"
	variant?: "outline" | "default" | "secondary" | "ghost" | "link" | "destructive"
	iconClassName?: string
	isActive?: boolean
	activeColor?: string
}

const TooltipButton = React.forwardRef<HTMLButtonElement, TooltipButtonProps>(
	(
		{
			icon,
			tooltipText,
			onClick,
			disabled = false,
			srText,
			size = "icon",
			variant = "default",
			iconClassName,
			isActive = false,
			activeColor,
			...rest
		},
		ref
	) => {
		const iconSizeMapping: Record<"icon" | "xsIcon", string> = {
			icon: "h-4 w-4",
			xsIcon: "h-4 w-4",
		}

		const computedIconClass = iconClassName ?? iconSizeMapping[size]

		return (
			<Tooltip>
				<TooltipTrigger asChild>
					<Button
						{...rest}
						ref={ref}
						variant={variant}
						size={size}
						disabled={disabled}
						onClick={onClick}
					>
						<span
							className={cn(
								"inline-flex items-center justify-center",
								computedIconClass,
								isActive && activeColor,
							)}
						>
							{icon}
						</span>
						{srText && <span className="sr-only">{srText}</span>}
					</Button>
				</TooltipTrigger>
				<TooltipContent>
					<p>{tooltipText}</p>
				</TooltipContent>
			</Tooltip>
		)
	}
)

TooltipButton.displayName = "TooltipButton"

export default TooltipButton
