/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import ImageViewer from "@/components/images/ImageViewer"
import type { DocumentChunk } from "@/types/invalidity"
import { MinusCircle } from "lucide-react"
import { useState } from "react"
import type React from "react"
import { Button } from "../ui/button"
import { Card } from "../ui/card"

interface FigureChunkProps {
	figureChunk?: DocumentChunk
	/**
	 * Optional click handler.
	 *
	 * If provided, then clicking on the figure will call this handler.
	 * Otherwise, clicking on the figure will open the image viewer.
	 */
	onClick?: () => void
	editMode?: boolean
	onDeleteClick?: (chunk: DocumentChunk, e: React.MouseEvent) => void
	height?: number
	showBorder?: boolean
}

function FigureChunk({
	figureChunk,
	onClick,
	editMode,
	onDeleteClick,
	height = 100,
	showBorder = true,
}: FigureChunkProps) {
	const [imageOpen, setImageOpen] = useState<boolean>(false)
	const [containerWidth, setContainerWidth] = useState<number>(100)

	// Multiply figureRotation by 90 to match the logic in FiguresModal
	const rotationDegrees = (figureChunk?.figureRotation ?? 0) * 90

	const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
		const img = event.currentTarget
		const width = img.naturalWidth
		const heightValue = img.naturalHeight

		// Calculate the aspect ratio based on rotation.
		// For 90° or 270° rotation swap naturalWidth and naturalHeight.
		if (rotationDegrees % 180 !== 0) {
			const rotatedAspectRatio = heightValue / width
			setContainerWidth(height * rotatedAspectRatio)
		} else {
			const aspectRatio = width / heightValue
			setContainerWidth(height * aspectRatio)
		}
	}

	// New click handler:
	// • If an onClick prop is passed => call the parent's logic.
	// • Otherwise, open the image viewer.
	const handleClick = (_e: React.MouseEvent) => {
		if (onClick) {
			onClick()
		} else {
			setImageOpen(true)
		}
	}

	// Gather the URL and figure name/rotation from the chunk
	const url = figureChunk?.figureUrl
	const name = figureChunk?.figureNumber

	return (
		<>
			<Card
				className={`shrink-0 hover:border-gray-400 inline-block relative cursor-pointer ${
					showBorder ? "" : "border-0"
				}`}
				onClick={handleClick}
			>
				{editMode && onDeleteClick && (
					<Button
						variant="ghost"
						size="icon"
						className="absolute -top-4 -right-4 z-10 rounded-full"
						onClick={(e) => {
							e.stopPropagation()
							onDeleteClick(figureChunk, e)
						}}
					>
						<MinusCircle size={20} />
					</Button>
				)}

				<div
					className="m-2 overflow-hidden"
					style={{
						height: `${height}px`,
						width: `${containerWidth}px`,
					}}
				>
					<img
						src={url}
						alt={`Figure: ${name}`}
						onLoad={handleImageLoad}
						className="object-contain w-full h-full"
						style={{
							transform: `rotate(${rotationDegrees}deg)`,
							transformOrigin: "center center",
						}}
					/>
				</div>
			</Card>

			<ImageViewer
				imageUrl={url}
				imageName={name}
				open={imageOpen}
				handleClose={() => setImageOpen(false)}
			/>
		</>
	)
}

export default FigureChunk
