import { api } from "@/api"
import type { ParentDocumentMetadata } from "@/types"
import { invalidatePriorArtRelatedQueries } from "@/utils/query/invalidation"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import {
	handleOptimisticUpdate,
	revertOptimisticUpdates,
} from "@/utils/query/optimisticUpdates"
import { useMutation, useQueryClient } from "@tanstack/react-query"

function filterReferences(oldData: unknown, referenceIds: string[]): unknown {
	if (!oldData || !Array.isArray(oldData)) return oldData
	const typedData = oldData as ParentDocumentMetadata[]
	return typedData.filter((ref) => !referenceIds.includes(ref.documentId!))
}

interface DeletePriorArtVariables {
	projectId?: string
	portfolioId?: string
	documentIds: string[]
}

export function useDeletePriorArt() {
	const queryClient = useQueryClient()

	const deletePriorArtMutation = useMutation({
		mutationKey: MUTATION_KEYS.document.delete(),
		mutationFn: async ({
			projectId,
			portfolioId,
			documentIds,
		}: DeletePriorArtVariables) => {
			return api.deleteReferences(projectId, portfolioId, documentIds)
		},
		onMutate: async (variables) => {
			const queryKeys = [
				QUERY_KEYS.project.priorArt(variables.projectId),
				QUERY_KEYS.portfolio.priorArt(variables.portfolioId),
			]

			const context = await handleOptimisticUpdate({
				queryClient,
				queryKeys,
				updateFn: (oldData) => filterReferences(oldData, variables.documentIds),
			})

			return context
		},
		onError: (_error, _variables, context) => {
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
		},
		onSuccess: (_data, variables) => {
			invalidatePriorArtRelatedQueries(
				queryClient,
				variables.projectId,
				variables.portfolioId,
			)
		},
	})

	return { deletePriorArt: deletePriorArtMutation }
}
