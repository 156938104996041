import type {
	CreateProjectRequest,
	GetDocumentStatusesRequest,
	GetOfficeActionApplicationNumberRequest,
	ProcessDocumentsRequest,
	ReprocessDocumentsRequest,
} from "@/types"
import type { ProjectType } from "@/types"
import type {
	DocumentToProcess,
	ProcessSettings,
	ProcessingDocumentStatus,
} from "@/types/documents"
import { apiClient } from "./client"

export const processApi = {
	async processDocuments(
		portfolioId: string | null,
		projectIds: string[] | null,
		documents: DocumentToProcess[],
		settings: ProcessSettings,
	): Promise<ProcessingDocumentStatus[]> {
		const payload: ProcessDocumentsRequest = {
			portfolio_id: portfolioId,
			project_ids: projectIds,
			documents,
			settings,
		}
		return await apiClient.post("process", payload)
	},

	async reprocessDocuments(
		portfolioId: string | null,
		projectIds: string[] | null,
		documentIds: string[],
		settings: ProcessSettings,
	): Promise<ProcessingDocumentStatus[]> {
		const payload: ReprocessDocumentsRequest = {
			portfolio_id: portfolioId,
			project_ids: projectIds,
			document_ids: documentIds,
			settings: {
				chart: settings.chart ?? true,
				reparse: settings.reparse ?? false,
				citation_policy: settings.citation_policy,
				claims_in_charting: settings.claims_in_charting ?? false,
				overwrite: settings.overwrite ?? false,
			},
		}
		return await apiClient.post("reprocess", payload)
	},

	async createProject(
		name: string,
		type: ProjectType,
		client_number: string,
		documents: DocumentToProcess[],
		evaluation_project: boolean,
		default_budget: number | null,
	) {
		const payload: CreateProjectRequest = {
			name: name,
			type: type,
			client_number: client_number,
			documents: documents,
			evaluation_project: evaluation_project,
			default_budget: default_budget,
		}
		return await apiClient.post("post_create_project", payload)
	},

	async getOfficeActionApplicationNumber(s3_key: string): Promise<string> {
		const params: GetOfficeActionApplicationNumberRequest = {
			s3_key: s3_key,
		}
		return await apiClient.get("get_office_action_application_number", params)
	},

	async getDocumentStatuses(
		portfolioId: string | null,
		projectIds: string[] | null,
		documentRole: string | null,
	): Promise<[string, ProcessingDocumentStatus][]> {
		const params: GetDocumentStatusesRequest = {
			...(portfolioId !== null && { portfolio_id: portfolioId }),
			...(projectIds !== null && { project_ids: projectIds }),
			...(documentRole !== null && { document_role: documentRole }),
		}
		return await apiClient.get("get_document_statuses", params)
	},
}
