/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { H4 } from "@/components/ui/typography"
import { usePortfolioMatrix, usePortfolioMetadata } from "@/hooks"
import { usePriorArt } from "@/hooks/prior-art/usePriorArt"
import { PageName } from "@/types/pages"
import type React from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import { PortfolioMatrixTable } from "./PortfolioMatrixTable"

const PortfolioOverviewPage: React.FC = () => {
	const { isLoading: isLoadingPortfolioMatrix } = usePortfolioMatrix()
	const { portfolioProjects } = usePortfolioMetadata()
	const { priorArtDocuments: portfolioPriorArtDocuments } = usePriorArt()

	return (
		<ProjectPage pageName={PageName.PortfolioOverview}>
			<div className="bg-background">
				{isLoadingPortfolioMatrix ? (
					<div className="mt-10">
						<Loader message="Loading..." />
					</div>
				) : (
					<>
						{!portfolioProjects?.length ? (
							<div className="flex justify-center items-center flex-col mt-12">
								<H4>This portfolio has no subjects.</H4>
							</div>
						) : portfolioPriorArtDocuments?.length > 0 ? (
							<div className="h-[calc(100vh-55px)] overflow-auto">
								<PortfolioMatrixTable />
							</div>
						) : (
							<div className="flex justify-center items-center flex-col mt-12">
								<H4>This portfolio has no references.</H4>
							</div>
						)}
					</>
				)}
			</div>
		</ProjectPage>
	)
}

export default PortfolioOverviewPage
