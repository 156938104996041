import { DocumentNamePill } from "@/components/documents/DocumentNamePill"
import { TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { usePortfolioMatrix } from "@/hooks"
import type React from "react"

const PortfolioMatrixHeader: React.FC = () => {
	const { portfolioMatrix } = usePortfolioMatrix()

	const singleRow = portfolioMatrix[0].cells

	return (
		<TableHeader className="sticky top-0 z-10 bg-background">
			<TableRow>
				<TableHead className="sticky left-0 z-20 bg-background w-[100px]" />
				{singleRow.map((cell) => {
					return (
						<TableHead
							key={cell.documentIds[0]}
							data-patent-id={cell.documentIds[0]}
							className="relative h-auto min-w-[45px] pr-4"
						>
							<div
							// className="absolute"
							// style={{
							// 	transformOrigin: "center left",
							// 	transform: needsRotation ? "rotate(-25deg)" : "none",
							// 	width: needsRotation ? "120px" : "100%",
							// 	whiteSpace: "nowrap",
							// 	top: needsRotation ? "65%" : "auto",
							// 	left: needsRotation ? "50%" : "0",
							// 	textAlign: needsRotation ? "left" : "center",
							// 	padding: needsRotation ? "0" : "8px",
							// }}
							>
								<div className="flex flex-wrap gap-2">
									{cell.documentIds.map((documentId) => (
										<DocumentNamePill key={documentId} id={documentId} size="sm" />
									))}
								</div>
							</div>
						</TableHead>
					)
				})}
			</TableRow>
		</TableHeader>
	)
}

export default PortfolioMatrixHeader
