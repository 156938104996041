import { useParentContext } from "@/context/ParentContext"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { formatPatentNumber } from "@/utils"
import { useProjectSubject } from "."
import usePortfolioDocuments from "./prior-art/usePortfolioPriorArtDocuments"
import useProjectDocuments from "./prior-art/useProjectPriorArtDocuments"

const useDocumentNaming = () => {
	const { projectId, portfolioId } = useParentContext()
	const { priorArtDocuments: projectPriorArtDocuments } = useProjectDocuments(
		projectId || "",
	)
	const { projectSubject } = useProjectSubject()
	const { priorArtDocuments: portfolioDocuments } = usePortfolioDocuments(
		portfolioId || "",
	)

	const getDocumentName = (id: string): string => {
		if (portfolioId) {
			return getDocumentPortfolioName(id)
		}

		if (projectId) {
			const doc =
				projectPriorArtDocuments?.find((ref) => ref.documentId === id) ||
				(projectSubject?.documentId === id ? projectSubject : null)

			return (
				doc?.nickname ||
				formatPatentNumber(doc?.document?.patent?.number ?? "") ||
				doc?.document?.title ||
				""
			)
		}

		return ""
	}

	const getDocumentPortfolioName = (id: string): string => {
		const doc = portfolioDocuments?.find((ref) => ref.documentId === id)
		return (
			doc?.nickname ||
			formatPatentNumber(doc?.document?.patent?.number ?? "") ||
			doc?.document?.title ||
			""
		)
	}

	return { getDocumentName, getDocumentPortfolioName }
}

export default useDocumentNaming
