/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import ClaimLimitationLabelPill from "@/components/claims/ClaimLimitationLabelPill"
import {} from "@/components/ui/tooltip"
import { useParentContext } from "@/context/ParentContext"
import { useFeedbackData } from "@/features/charts/hooks/useFeedbackData"
import { useInvaliditySettings, useProjectSubject } from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { cn } from "@/lib/utils"
import { ChartMode, type CollapsedLimitation } from "@/types"
import { convertToUtcDateString } from "@/utils/dateUtils"
import { cleanDocumentChunkText } from "@/utils/formatStrings"
import { formatPatentNumber } from "@/utils/projectUtils"
import { useMemo, useState } from "react"
import type React from "react"
import { useInView } from "react-intersection-observer"
import { useChartsContext } from "../../context/ChartsContext"
import { useChunkToLimitationUpdates, useClaimInvalidityData } from "../../hooks"
import CopyCitationsModal from "../modals/CopyCitationsModal"
import EditCellBoilerplateModal from "../modals/EditCellBoilerplateModal"
import InvalidityTableCellCollapsibleCitations from "./InvalidityTableCellCollapsibleCitations"
import InvalidityTableCitationCellTooltips from "./InvalidityTableCitationCellTooltips"
import InvalidityTableDocumentCitationGroup from "./InvalidityTableDocumentCitationGroup"

interface InvalidityTableLimitationCellProps {
	collapsedLimitation: CollapsedLimitation
}

/**
 * @description Div-based citation cell
 * contain language, header, footer, middle language,
 * +, copy
 * document blocks
 */
const InvalidityTableLimitationCell: React.FC<InvalidityTableLimitationCellProps> = ({
	collapsedLimitation,
}) => {
	const { projectId } = useParentContext()
	const { projectSubject } = useProjectSubject()
	const { settings } = useInvaliditySettings()
	const { getDocumentName } = useDocumentNaming()
	const { findMoreCitationsForLimitation } = useChunkToLimitationUpdates()

	const {
		filterColors,
		activeLimitationId,
		selectedTableRowRef,
		chartData,
		setSelectedChartMode,
		invalidityChartId,
		isCombination,
	} = useChartsContext()

	// Determine whether chartData is available.
	const hasChartData = chartData?.documentIds.length > 0

	// Call useFeedbackData unconditionally.
	const { positiveFeedbackData, negativeFeedbackData } = useFeedbackData(
		projectId,
		invalidityChartId,
		collapsedLimitation.id,
	)

	// Use Intersection Observer with the viewport as the root.
	const { ref: inViewRef, inView } = useInView({
		root: null, // use the browser viewport
		rootMargin: "200px",
		triggerOnce: false,
	})

	const { limitationInvalidityData } = useClaimInvalidityData(
		invalidityChartId,
		[collapsedLimitation.id],
		{
			projectId: projectId ?? "",
			documents: [],
			colors: filterColors,
			enabled: Boolean(inView && projectId), // chartData is checked above
		},
	)

	// Use a safe default (empty object) in case limitationInvalidityData doesn't have the key.
	const dataForLimitation = useMemo(
		() => limitationInvalidityData?.[collapsedLimitation.id],
		[limitationInvalidityData, collapsedLimitation.id],
	)

	const docCitations = useMemo(() => dataForLimitation?.citations, [dataForLimitation])

	const rowMetadata = useMemo(() => dataForLimitation?.metadata, [dataForLimitation])

	const isSelected = useMemo(() => {
		return activeLimitationId === collapsedLimitation.id
	}, [activeLimitationId, collapsedLimitation.id])

	const [isInvalidityLoading, setIsInvalidityLoading] = useState(false)
	const [showCopyCitationsModal, setShowCopyCitationsModal] = useState(false)
	const [isHeaderFooterModalOpen, setIsHeaderFooterModalOpen] = useState(false)

	const removedCitations = useMemo(
		() =>
			Object.values(docCitations || {}).flatMap((citations) =>
				citations.filter((citation) => citation.removed === true),
			),
		[docCitations],
	)
	const verifiedCitations = useMemo(
		() =>
			Object.values(docCitations || {}).flatMap((citations) =>
				citations.filter((citation) => citation.verified === true),
			),
		[docCitations],
	)

	const concatenatedText = cleanDocumentChunkText(
		(collapsedLimitation.limitations || [])
			.map((limitation) => limitation.text)
			.join(" "),
	)

	const cellHeader = useMemo(() => {
		const headerFromCitation = rowMetadata?.header ?? ""
		return headerFromCitation && headerFromCitation !== ""
			? headerFromCitation
			: (settings?.headerFormat ?? "")
	}, [rowMetadata, settings])

	const cellFooter = useMemo(() => {
		const footerFromCitation = rowMetadata?.footer ?? ""
		return footerFromCitation && footerFromCitation !== ""
			? footerFromCitation
			: (settings?.footerFormat ?? "")
	}, [rowMetadata, settings])

	const cellMiddle = useMemo(() => {
		const middleFromCitation = rowMetadata?.middle ?? ""
		return middleFromCitation
	}, [rowMetadata])

	const formatHeaderFooter = (text: string) => {
		return text
			.replaceAll("{{element_number}}", collapsedLimitation.label)
			.replaceAll(
				"{{reference_name}}",
				`${getDocumentName(chartData?.documentIds[0])}, ${getDocumentName(chartData?.documentIds[1])}`,
			)
			.replaceAll("{{element_language}}", concatenatedText)
			.replaceAll("{{subject_nickname}}", projectSubject?.nickname || "")
			.replaceAll(
				"{{subject_number}}",
				formatPatentNumber(projectSubject?.document?.patent?.number ?? ""),
			)
			.replaceAll(
				"{{subject_priority_date}}",
				convertToUtcDateString(projectSubject?.document?.patent?.priorityDate),
			)
	}

	// // Handler that sets the chart mode and scrolls to the selected limitation using the context.
	// const handleCellClick = () => {
	// 	scrollToLimitation(collapsedLimitation.id)
	// }

	const findMoreCitations = async () => {
		setIsInvalidityLoading(true)
		try {
			const positiveExamples = positiveFeedbackData
				.map((citation) => citation.documentChunk?.text)
				.filter((text): text is string => Boolean(text))
			const negativeExamples = negativeFeedbackData
				.map((citation) => citation.documentChunk?.text)
				.filter((text): text is string => Boolean(text))
			await findMoreCitationsForLimitation({
				claimLimitationId: collapsedLimitation.id,
				documentId: chartData?.documentIds[0],
				positiveExamples,
				negativeExamples,
			})
		} finally {
			setIsInvalidityLoading(false)
		}
	}

	// Early return is now done after calling all hooks.
	if (!hasChartData) {
		return <Loader message="Loading chart data..." />
	}

	return (
		<div
			className={cn(
				"w-full p-2 px-3 border rounded-lg cursor-pointer hover:bg-accent",
				isSelected ? "border-blue-500 bg-accent" : "",
			)}
			ref={selectedTableRowRef}
		>
			<div ref={inViewRef}>
				<div className="mb-2">
					{isInvalidityLoading ? (
						<div className="mb-2">
							<Loader message="Retrieving invalidity..." />
						</div>
					) : (
						<div className="flex items-center justify-between">
							<ClaimLimitationLabelPill
								id={collapsedLimitation.id}
								label={collapsedLimitation.label}
							/>
							<InvalidityTableCitationCellTooltips
								isInvalidityLoading={isInvalidityLoading}
								// showNoMoreCitations={showNoMoreCitations}
								onFindMoreCitations={!isCombination && (() => findMoreCitations())}
								onAddFromDocument={() => setSelectedChartMode(ChartMode.DOCUMENT)}
								onCopyEvidence={!isCombination && (() => setShowCopyCitationsModal(true))}
								onEditHeaderFooter={() => setIsHeaderFooterModalOpen(true)}
							/>
						</div>
					)}
				</div>

				{cellHeader && (
					<p className="text-sm text-muted-foreground mb-4">
						{formatHeaderFooter(cellHeader)}
					</p>
				)}

				<div className="flex flex-col gap-2 mb-2">
					{Object.entries(docCitations || {}).map(
						([documentIdEntry, citations], citationIndex) => (
							<>
								<InvalidityTableDocumentCitationGroup
									key={`document-${documentIdEntry}-${collapsedLimitation.id}-${citationIndex}`}
									documentId={documentIdEntry}
									claimLimitationId={collapsedLimitation.id}
									claimLimitationLabel={collapsedLimitation.label}
									citations={citations}
								/>
								{citationIndex === 0 && cellMiddle && (
									<p className="text-sm text-muted-foreground mt-4 mb-3">
										{formatHeaderFooter(cellMiddle)}
									</p>
								)}
							</>
						),
					)}
				</div>

				{cellFooter && (
					<p className="text-sm text-muted-foreground mt-4 mb-3">
						{formatHeaderFooter(cellFooter)}
					</p>
				)}

				<InvalidityTableCellCollapsibleCitations
					positiveFeedbackData={positiveFeedbackData}
					negativeFeedbackData={negativeFeedbackData}
					removedCitations={removedCitations}
					verifiedCitations={verifiedCitations}
				/>

				{isHeaderFooterModalOpen && (
					<EditCellBoilerplateModal
						isOpen={true}
						onClose={() => setIsHeaderFooterModalOpen(false)}
						claimLimitationLabel={collapsedLimitation.label}
						claimLimitationText={concatenatedText}
						claimLimitationId={collapsedLimitation.id}
						cellHeader={cellHeader ?? ""}
						cellFooter={cellFooter ?? ""}
						cellMiddle={cellMiddle ?? ""}
					/>
				)}

				<CopyCitationsModal
					open={showCopyCitationsModal}
					handleClose={() => setShowCopyCitationsModal(false)}
					documentId={chartData?.documentIds[0]}
					claimLimitationId={collapsedLimitation.id}
					claimLimitationLabel={collapsedLimitation.label}
				/>
			</div>
		</div>
	)
}

export default InvalidityTableLimitationCell
