import { useAuthInfo } from "@propelauth/react"
import { useMemo } from "react"

export function useIsProjectOwner(userId: string) {
	const { user } = useAuthInfo()

	const isOwner = useMemo(() => userId === user?.userId, [userId, user?.userId])

	return {
		isOwner,
	}
}

export default useIsProjectOwner
