export const QUERY_KEYS = {
	// Admin related keys
	admin: {
		users: (orgId?: string) => ["users", orgId] as const,
		overview: (orgId?: string, date?: string) => ["adminOverview", orgId, date] as const,
		projects: (orgId?: string) => ["adminProjects", orgId] as const,
		billing: (orgId?: string) => ["adminBilling", orgId] as const,
	},

	// Super admin related keys
	superAdmin: {
		overview: ["superAdminOverview"] as const,
		organizations: ["superAdminOrganizations"] as const,
		billing: ["superAdminBilling"] as const,
	},

	// document: {
	// 	all: ["document"] as const,
	// 	full: (documentId: string) => ["document", documentId] as const,
	// 	figures: (documentId: string) => ["document", "figures", documentId] as const,
	// },

	portfolio: {
		all: ["portfolio"] as const,
		metadata: (portfolioId: string) => ["portfolio", portfolioId, "metadata"],
		priorArt: (portfolioId: string) => ["portfolio", portfolioId, "priorArt"],
		settings: (portfolioId: string) => ["portfolio", portfolioId, "settings"],
		context: (portfolioId: string) => ["portfolio", portfolioId, "context"],
		matrix: {
			overview: (portfolioId: string) => ["portfolio", portfolioId, "overview"],
			projectSummary: (portfolioId: string, projectId: string) =>
				["portfolio", portfolioId, "projectSummary", projectId] as const,
		},
	},

	project: {
		all: ["project"] as const,
		metadata: (projectId: string) => ["project", projectId, "metadata"],
		subject: (projectId: string) => ["project", projectId, "subject"],
		priorArt: (projectId: string) => ["project", projectId, "priorArt"],
		officeActions: (projectId: string) => ["project", projectId, "officeActions"],
		claims: (projectId: string) => ["project", projectId, "claims"],
		claimStrengths: (projectId: string | null) => [
			"project",
			projectId,
			"claimStrengths",
		],
		settings: (projectId: string) => ["project", projectId, "settings"],
		context: (projectId: string) => ["project", projectId, "context"],
		charts: {
			all: (projectId?: string) => ["project", projectId, "charts"],
			active: (projectId?: string) => ["project", projectId, "charts", "active"],
			inactive: (projectId?: string) => ["project", projectId, "charts", "archive"],
		},
		invalidity: {
			chartMetadata: (projectId: string, chartId: string) =>
				["project", projectId, "invalidityChart", chartId] as const,
			limitationChartData: (
				projectId: string,
				chartId: string,
				limitationIds: string[],
				colors?: string[],
			) =>
				[
					"project",
					projectId,
					"invalidityLimitationChartData",
					chartId,
					limitationIds,
					colors,
				] as const,
			chartSummaryData: (projectId: string, chartId: string | null) =>
				["project", projectId, "invalidityChartSummaryData", chartId] as const,
		},
	},

	cover: {
		documentChunks: (documentId: string) =>
			["cover", "documentChunks", documentId] as const,
	},

	document: {
		full: (documentId: string) => ["document", documentId, "full"] as const,
		figures: (documentId: string) => ["document", documentId, "figures"] as const,
		pdf: (documentId: string) => ["document", documentId, "pdf"] as const,
	},

	sharing: {
		sharedUsers: (id: string) => ["sharing", "sharedUsers", id],
		shareableUsers: (id: string) => ["sharing", "shareableUsers", id],
	},

	projects: {
		active: () => ["projects", "active"],
		archived: () => ["projects", "archived"],
	},

	officeActionApplicationNumber: (projectId?: string) => [
		"officeActionApplicationNumber",
		projectId,
	],

	invaliditySettings: (projectId: string | null, portfolioId: string | null) => [
		"invaliditySettings",
		projectId,
		portfolioId,
	],

	documentStatuses: (portfolioId: string | null, projectIds: string[]) => [
		"documentStatuses",
		portfolioId,
		projectIds,
	],
} as const

// TODO set up mutation keys later, not necessaery in short term
export const MUTATION_KEYS = {
	admin: {
		user: {
			create: () => ["admin", "user", "create"] as const,
			update: () => ["admin", "user", "update"] as const,
			delete: () => ["admin", "user", "delete"] as const,
			toggleStatus: () => ["admin", "user", "toggleStatus"] as const,
		},
	},

	document: {
		update: () => ["document", "update"] as const,
		chunk: {
			update: () => ["document", "chunk", "update"] as const,
			add: () => ["document", "chunk", "add"] as const,
			delete: () => ["document", "chunk", "delete"] as const,
		},
		metadata: {
			update: () => ["document", "metadata", "update"] as const,
		},
		delete: () => ["document", "delete"] as const,
		prune: () => ["document", "prune"] as const,
	},

	// invalidity: { // probably also restructure
	// 	chart: {
	// 		updateChunkToLimitation: () =>
	// 			["invalidity", "chart", "updateChunkToLimitation"] as const,
	// 		toggleVerifyCitation: () => ["invalidity", "chart", "toggleVerifyCitation"] as const,
	// 		pruneByColor: () => ["invalidity", "chart", "pruneByColor"] as const,
	// 		pruneByScore: () => ["invalidity", "chart", "pruneByScore"] as const,
	// 	},
	// },

	project: {
		create: () => ["project", "create"] as const,
		update: (projectId?: string) => ["project", "update", projectId] as const,
		updateDetails: (projectId?: string) =>
			["project", "updateDetails", projectId] as const,
		updateStatus: (projectId?: string) => ["project", "updateStatus", projectId] as const,
		rename: (projectId?: string) => ["project", "rename", projectId] as const,
		context: {
			update: () => ["project", "context", "update"] as const,
			generateFromId: () => ["project", "context", "generateFromId"] as const,
			generateFromText: () => ["project", "context", "generateFromText"] as const,
		},
		claims: {
			update: () => ["project", "claims", "update"] as const,
		},
		settings: {
			update: () => ["project", "settings", "update"] as const,
		},
		users: {
			add: () => ["project", "users", "add"] as const,
			remove: () => ["project", "users", "remove"] as const,
		},
		delete: () => ["project", "delete"] as const,
	},

	portfolio: {
		update: (portfolioId?: string) => ["portfolio", "update", portfolioId] as const,
		removeProjects: (portfolioId?: string) =>
			["portfolio", "removeProjects", portfolioId] as const,
		rename: (portfolioId?: string) => ["portfolio", "rename", portfolioId] as const,
		delete: (portfolioId?: string) => ["portfolio", "delete", portfolioId] as const,
		updateStatus: (portfolioId?: string) =>
			["portfolio", "updateStatus", portfolioId] as const,
	},

	organization: {
		create: () => ["organization", "create"] as const,
		update: () => ["organization", "update"] as const,
		delete: () => ["organization", "delete"] as const,
	},

	cover: {
		generate: () => ["cover", "generate"] as const,
		update: () => ["cover", "update"] as const,
	},
} as const

export const PARTIAL_INVALIDITY_KEYS = {
	limitationData: (projectId: string | null) =>
		["project", projectId, "invalidityLimitationData"] as const,
	limitationDataForLimitation: (projectId: string, limitationId: string) =>
		["project", projectId, "invalidityLimitationData", limitationId] as const,
	limitationChartData: (projectId: string, chartId: string, limitationIds: string[]) =>
		[
			"project",
			projectId,
			"invalidityLimitationChartData",
			chartId,
			limitationIds,
		] as const,
	documentSummaryData: (projectId: string | null) =>
		["project", projectId, "invalidityDocumentSummaryData"] as const,
	project: (projectId: string) => ["project", projectId] as const,
	portfolio: (portfolioId: string) => ["portfolio", portfolioId] as const,
}
