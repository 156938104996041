/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import SearchDocumentsComponent from "@/features/search/SearchDocumentsComponent"
import { PageName } from "@/types/pages"
import ProjectPage from "../../components/projects/ProjectPage"

/**
 * @description Renders the project search page
 */
const ProjectSearchPage = () => {
	return (
		<ProjectPage pageName={PageName.Search}>
			<SearchDocumentsComponent />
		</ProjectPage>
	)
}
export default ProjectSearchPage
