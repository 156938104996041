import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { cn } from "@/lib/utils"
import type { ChunkToLimitation } from "@/types"
import { pluralString } from "@/utils"
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@radix-ui/react-collapsible"
import type React from "react"
import { useState } from "react"
import InvalidityTableCitation from "./InvalidityTableCitation"
interface InvalidityTableCellCollapsibleCitationsProps {
	positiveFeedbackData: ChunkToLimitation[]
	negativeFeedbackData: ChunkToLimitation[]
	removedCitations: ChunkToLimitation[]
	verifiedCitations: ChunkToLimitation[]
}

// New subcomponents
const CollapsibleButton: React.FC<{
	count: number
	label: string
	isOpen: boolean
	onClick: () => void
}> = ({ count, label, isOpen, onClick }) => (
	<CollapsibleTrigger asChild>
		<Button
			variant="ghost"
			size="sm"
			className={cn(
				"justify-between",
				"border border-gray-200 hover:border-gray-300",
				"transition-all duration-10",
				isOpen && "bg-accent",
			)}
			onClick={onClick}
		>
			<h4 className="text-sm font-semibold">
				{label} ({count})
			</h4>
		</Button>
	</CollapsibleTrigger>
)

const InvalidityTableCellCollapsibleCitations: React.FC<
	InvalidityTableCellCollapsibleCitationsProps
> = ({
	positiveFeedbackData,
	negativeFeedbackData,
	removedCitations,
	verifiedCitations,
}) => {
	const [showPositiveCitations, setShowPositiveCitations] = useState(false)
	const [showNegativeCitations, setShowNegativeCitations] = useState(false)
	const [showRemovedCitations, setShowRemovedCitations] = useState(false)
	const [showVerifiedCitations, setShowVerifiedCitations] = useState(false)

	return (
		<div className="flex pb-2">
			<Collapsible
				open={
					showNegativeCitations ||
					showRemovedCitations ||
					showPositiveCitations ||
					showVerifiedCitations
				}
				onOpenChange={(open) => {
					if (!open) {
						setShowPositiveCitations(false)
						setShowNegativeCitations(false)
						setShowRemovedCitations(false)
						setShowVerifiedCitations(false)
					}
				}}
				className="space-y-2"
			>
				<div className="flex w-[250px] space-x-2">
					{positiveFeedbackData.length > 0 && (
						<CollapsibleButton
							count={positiveFeedbackData.length}
							label={pluralString(positiveFeedbackData.length, "Positive citation")}
							isOpen={showPositiveCitations}
							onClick={() => setShowPositiveCitations(!showPositiveCitations)}
						/>
					)}
					{negativeFeedbackData.length > 0 && (
						<CollapsibleButton
							count={negativeFeedbackData.length}
							label={pluralString(negativeFeedbackData.length, "Negative citation")}
							isOpen={showNegativeCitations}
							onClick={() => setShowNegativeCitations(!showNegativeCitations)}
						/>
					)}
					{removedCitations.length > 0 && (
						<CollapsibleButton
							count={removedCitations.length}
							label="Removed citations"
							isOpen={showRemovedCitations}
							onClick={() => setShowRemovedCitations(!showRemovedCitations)}
						/>
					)}
					{/* {verifiedCitations.length > 0 && (
						<CollapsibleButton
							count={verifiedCitations.length}
							label="Verified citations"
							isOpen={showVerifiedCitations}
							onClick={() => setShowVerifiedCitations(!showVerifiedCitations)}
						/>
					)} */}
				</div>
				<CollapsibleContent className="space-y-2 pb-2">
					<Card className="p-4">
						{showPositiveCitations &&
							positiveFeedbackData.map((item) => (
								<InvalidityTableCitation
									key={`positive-${item.id}`}
									citation={item}
									showVerify={false}
									showRemove={false}
									showFeedback={true}
									showColor={false}
								/>
							))}
						{showNegativeCitations &&
							negativeFeedbackData.map((item) => (
								<InvalidityTableCitation
									key={`negative-${item.id}`}
									citation={item}
									showVerify={false}
									showRemove={false}
									showFeedback={true}
									showColor={false}
								/>
							))}
						{showRemovedCitations &&
							removedCitations.map((item) => (
								<InvalidityTableCitation
									key={`removed-${item.id}`}
									citation={item}
									showVerify={false}
									showRemove={true}
									showFeedback={false}
									showColor={false}
								/>
							))}
						{showVerifiedCitations &&
							verifiedCitations.map((item) => (
								<InvalidityTableCitation
									key={`verified-${item.id}`}
									citation={item}
									showVerify={false}
									showRemove={false}
									showFeedback={false}
									showColor={false}
								/>
							))}
					</Card>
				</CollapsibleContent>
			</Collapsible>
		</div>
	)
}

export default InvalidityTableCellCollapsibleCitations
