import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import type { ClaimLimitationEdit } from "@/types"
import { Cross1Icon } from "@radix-ui/react-icons"
import { useEffect, useState } from "react"
import { FloatingLabelTextarea } from "../ui/floating-textarea"

interface LimitationEditorProps {
	limitation: ClaimLimitationEdit
	onUpdate: (limitation: ClaimLimitationEdit) => void
	onDelete: () => void
	isLoading: boolean
	isSubject: boolean
}

function LimitationEditor({
	limitation,
	onUpdate,
	onDelete,
	isLoading,
	isSubject,
}: LimitationEditorProps) {
	// Local state for editing
	const [localLabel, setLocalLabel] = useState(limitation.label)
	const [localText, setLocalText] = useState(limitation.text)
	// commentary might be null so we default to an empty string
	const [localCommentary, setLocalCommentary] = useState(limitation.commentary || "")

	// Update local state when prop changes
	useEffect(() => {
		setLocalLabel(limitation.label)
		setLocalText(limitation.text)
		setLocalCommentary(limitation.commentary || "")
	}, [limitation.label, limitation.text, limitation.commentary])

	const handleLabelChange = (newLabel: string) => {
		if (newLabel === "" || /^[0-9]+\.?([0-9]+\.?)*$/.test(newLabel)) {
			setLocalLabel(newLabel)
		}
	}

	const handleLabelBlur = () => {
		if (localLabel !== limitation.label) {
			onUpdate({ ...limitation, label: localLabel })
		}
	}

	const handleTextChange = (newText: string) => {
		setLocalText(newText)
	}

	const handleTextBlur = () => {
		if (localText !== limitation.text) {
			onUpdate({ ...limitation, text: localText })
		}
	}

	// Commentary change and blur handlers
	const handleCommentaryChange = (newCommentary: string) => {
		setLocalCommentary(newCommentary)
	}

	const handleCommentaryBlur = () => {
		if (localCommentary !== (limitation.commentary || "")) {
			onUpdate({ ...limitation, commentary: localCommentary })
		}
	}

	return (
		<div className="p-2 border rounded-md">
			<div className="flex gap-2 items-start">
				<Input
					className="w-24 flex-shrink-0 h-9"
					value={localLabel}
					onChange={(e) => handleLabelChange(e.target.value)}
					onBlur={handleLabelBlur}
					placeholder="1.1"
					disabled={isLoading}
					pattern="[0-9.]+"
				/>
				<FloatingLabelTextarea
					value={localText}
					onChange={(e) => handleTextChange(e.target.value)}
					onBlur={handleTextBlur}
					disabled={isLoading}
					label="Limitation Text"
					className="flex-1"
				/>
				{isSubject && (
					<FloatingLabelTextarea
						value={localCommentary}
						onChange={(e) => handleCommentaryChange(e.target.value)}
						onBlur={handleCommentaryBlur}
						disabled={isLoading}
						label="Commentary"
						className="flex-1"
					/>
				)}
				<Button variant="ghost" size="icon" onClick={onDelete} disabled={isLoading}>
					<Cross1Icon className="h-4 w-4" />
				</Button>
			</div>
		</div>
	)
}

export default LimitationEditor
