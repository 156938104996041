import { api } from "@/api"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

interface UsePdfOptions {
	staleTime?: number
	cacheTime?: number
	retries?: number
	enabled?: boolean
}

interface UsePdfResult {
	pdfData: Blob | null
	isLoading: boolean
	error: Error | null
	refetch: () => Promise<void>
	isFetching: boolean
}

export const usePdf = (
	url: string,
	documentId: string,
	options: UsePdfOptions = {},
): UsePdfResult => {
	const { retries = 3, enabled = true } = options

	const { data, isLoading, error, refetch, isFetching } = useQuery({
		queryKey: QUERY_KEYS.document.pdf(documentId),
		queryFn: async () => {
			if (!url) {
				return null
			}

			try {
				// First attempt: Try loading directly from URL
				const directResponse = await fetch(url)
				if (directResponse.ok) {
					const blob = await directResponse.blob()
					if (blob.size === 0) {
						throw new Error("Empty PDF received")
					}
					return blob
				}
			} catch (err) {
				console.warn("Direct URL fetch failed:", err)
			}

			// Fallback: Stream from backend
			const response = await api.getPdfFile(url)
			if (!response.data || response.data.size === 0) {
				throw new Error("Failed to fetch PDF or received empty file")
			}
			return response.data
		},
		retry: retries,
		enabled: enabled && Boolean(url),
		refetchOnWindowFocus: false,
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		pdfData: data ?? null,
		isLoading,
		error: error as Error | null,
		refetch: async () => {
			await refetch()
		},
		isFetching,
	}
}
