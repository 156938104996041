import type { ClaimLimitation } from "@/types"
import type React from "react"

// Props for one document column.
export interface ChartSummaryRowLabelColumnProps {
	claimLimitations: ClaimLimitation[]
	style: React.CSSProperties
}

const ChartSummaryRowLabelColumn: React.FC<ChartSummaryRowLabelColumnProps> = ({
	claimLimitations,
	style,
}) => {
	return (
		<div className="flex flex-col sticky left-0 z-20" style={style}>
			{/* Empty header cell */}
			<div
				className="sticky top-0 z-20 bg-background pb-1"
				style={{ minWidth: "75px", flex: "1", padding: "0 2px" }}
			/>

			{/* Render cells for each row */}
			{claimLimitations?.map((row) => {
				return (
					<div
						key={row.id}
						className="px-0.75 bg-background"
						style={{
							minWidth: "75px",
							flex: "1",
							margin: "0",
							padding: "0 2px",
						}}
					>
						<div
							className={
								"w-full h-full flex items-center justify-center cursor-pointer transition-colors duration-200 rounded m-1"
							}
							style={{
								height: "25px",
							}}
						>
							{row.label}
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default ChartSummaryRowLabelColumn
