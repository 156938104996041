import { api } from "@/api"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type { ClaimStrength } from "@/types"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

/**
 * @description Hook for handling references
 */
const useClaimStrength = (projectId: string) => {
	const { data, isLoading, error } = useQuery({
		queryKey: QUERY_KEYS.project.claimStrengths(projectId),
		queryFn: async (): Promise<ClaimStrength[]> => {
			return await api.getClaimStrengths(projectId)
		},
		...DEFAULT_QUERY_OPTIONS,
	})

	return { claimStrengths: data, isLoading, error }
}

export default useClaimStrength
