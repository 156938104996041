import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

const useFullDocument = (documentId: string) => {
	const { projectId, portfolioId } = useParentContext()

	const documentQuery = useQuery({
		queryKey: QUERY_KEYS.document.full(documentId),
		queryFn: async () => await api.getFullDocument(documentId, projectId, portfolioId),
		enabled: Boolean(documentId && (projectId || portfolioId)),
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		document: documentQuery.data,
		isLoading: documentQuery.isLoading,
		isError: documentQuery.isError,
		refetch: documentQuery.refetch,
	}
}

export default useFullDocument
