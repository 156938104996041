import { Loader } from "@/components"
import {
	AdminPageLayout,
	NoAdminAccessMessage,
	SuperAdminOverviewCard,
} from "@/features/admin/components"
import { useUserStore } from "@/store"
import { type AdminOverview, RoleType } from "@/types"
import { PageName } from "@/types/pages"
import { useMemo } from "react"
import { useSuperAdminOverview } from "../hooks/useSuperAdminOverview"

/**
 * @description Renders the main overview content if data is loading or ready
 */
function OverviewContent({
	isLoading,
	overview,
}: {
	isLoading: boolean
	overview: AdminOverview
}) {
	if (isLoading) {
		return <Loader />
	}

	return <SuperAdminOverviewCard overview={overview} />
}

/**
 * @description Super admin page component
 */
function SuperAdminPage() {
	const { isLoading, overview } = useSuperAdminOverview()
	const { role } = useUserStore()

	// Determine if user is a super admin (owner)
	const isSuperAdmin = useMemo(() => role === RoleType.OWNER, [role])

	return (
		<AdminPageLayout isSuperAdmin={true} page={PageName.SuperAdminOverview}>
			{isSuperAdmin ? (
				<div className="space-y-8 mt-2">
					<OverviewContent isLoading={isLoading} overview={overview} />
				</div>
			) : (
				<NoAdminAccessMessage />
			)}
		</AdminPageLayout>
	)
}

export default SuperAdminPage
