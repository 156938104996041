import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { BlockType, type DocumentChunk } from "@/types"
import { normalizeTextToMatch } from "@/utils/normalizeTextToMatch"
import type React from "react"
import { parseFigureReferences } from "./parseFigureReferences"

interface ParseHtmlOptions {
	TableComponent?: React.ComponentType<any>
	TableHeaderComponent?: React.ComponentType<any>
	TableBodyComponent?: React.ComponentType<any>
	TableRowComponent?: React.ComponentType<any>
	TableHeadComponent?: React.ComponentType<any>
	TableCellComponent?: React.ComponentType<any>
}

/**
 * Converts HTML string content into React elements
 * @param {string} html - The HTML string to parse
 * @param {ParseHtmlOptions} options - Optional component overrides for table elements
 * @returns {React.ReactNode} - The parsed content as React elements
 */
export const parseHtml = (
	html: string,
	options: ParseHtmlOptions = {},
	citationText?: string,
	figureChunks?: DocumentChunk[],
	handleFigureReferenceClick?: (figureChunk: DocumentChunk) => void,
	chunkType?: BlockType,
	isDarkMode?: boolean,
): React.ReactNode => {
	const {
		TableComponent = Table,
		TableHeaderComponent = TableHeader,
		TableBodyComponent = TableBody,
		TableRowComponent = TableRow,
		TableHeadComponent = TableHead,
		TableCellComponent = TableCellCondensed,
	} = options

	const parser = new DOMParser()
	const content = parser.parseFromString(html, "text/html")

	/**
	 * 1) We parse out figure references (like "FIGS. 16(a) and 16(b)").
	 * 2) We optionally highlight 'citationText'.
	 */
	const highlightText = (text: string): React.ReactNode => {
		if (!text.trim()) return text

		let currentSegments: React.ReactNode[] = [text]

		// Parse out figure references if figureChunks is provided
		if (figureChunks?.length) {
			currentSegments = currentSegments.flatMap((segment) => {
				if (typeof segment === "string") {
					return parseFigureReferences(
						segment,
						figureChunks,
						handleFigureReferenceClick,
						isDarkMode,
					)
				}
				return segment
			})
		}

		// If citationText is provided, highlight the first occurrence
		if (citationText) {
			const normalizedSearch = normalizeTextToMatch(citationText)

			const splitOnCitation = (node: React.ReactNode): React.ReactNode[] => {
				if (typeof node !== "string") return [node]

				const textValue = node
				const normalizedText = normalizeTextToMatch(textValue)
				const index = normalizedText.indexOf(normalizedSearch)
				if (index === -1) {
					return [textValue]
				}
				const searchLength = citationText.length

				let originalIndex = 0
				let normalizedIndex = 0
				let highlightStart = -1
				while (originalIndex < textValue.length) {
					const char = textValue[originalIndex]
					const normalizedChar = normalizeTextToMatch(char)
					if (normalizedChar) {
						if (normalizedIndex === index) {
							highlightStart = originalIndex
							break
						}
						normalizedIndex++
					}
					originalIndex++
				}

				const highlightEnd = highlightStart + searchLength
				return [
					textValue.slice(0, highlightStart),
					<span key={crypto.randomUUID()} className="bg-blue-100">
						{textValue.slice(highlightStart, highlightEnd)}
					</span>,
					textValue.slice(highlightEnd),
				]
			}

			currentSegments = currentSegments.flatMap((node) => splitOnCitation(node))
		}

		// If there's only one segment and it's a plain string, just return it
		if (currentSegments.length === 1 && typeof currentSegments[0] === "string") {
			return currentSegments[0]
		}

		// Otherwise wrap in a Fragment
		return <>{currentSegments}</>
	}

	const processNode = (node: Node): React.ReactNode => {
		// TEXT_NODE
		if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
			return (
				<span
					key={crypto.randomUUID()}
					className={
						chunkType === BlockType.HEADER || chunkType === BlockType.SECTION_HEADER
							? "font-semibold"
							: ""
					}
				>
					{highlightText(node.textContent || "")}
				</span>
			)
		}

		// TABLE ELEMENT
		if (node instanceof HTMLTableElement) {
			const rows = Array.from(node.querySelectorAll("tr"))
			const hasHeader = rows[0]?.querySelectorAll("th").length > 0

			return (
				<TableComponent key={crypto.randomUUID()} className="my-4 border">
					{hasHeader && (
						<TableHeaderComponent>
							<TableRowComponent>
								{Array.from(rows[0].querySelectorAll("th")).map((cell, i) => (
									<TableHeadComponent key={i}>{cell.textContent}</TableHeadComponent>
								))}
							</TableRowComponent>
						</TableHeaderComponent>
					)}
					<TableBodyComponent>
						{rows.slice(hasHeader ? 1 : 0).map((row, i) => (
							<TableRowComponent key={i}>
								{Array.from(row.querySelectorAll("td")).map((cell, j) => (
									<TableCellComponent key={j}>{cell.textContent}</TableCellComponent>
								))}
							</TableRowComponent>
						))}
					</TableBodyComponent>
				</TableComponent>
			)
		}

		// ANY OTHER HTML ELEMENT
		if (node instanceof HTMLElement) {
			const children = Array.from(node.childNodes).map(processNode)
			return (
				<div key={crypto.randomUUID()} className="inline">
					{children}
				</div>
			)
		}

		return null
	}

	return processNode(content.body)
}

export const showBoldText = (text: string) => {
	// If text is in array format, take the first element
	const cleanText = text.startsWith("[") ? JSON.parse(text)[0] : text

	// Split with a more forgiving regex that handles newlines
	const parts = cleanText.split(/(<bold>[\s\S]*?<\/bold>)/g)

	return parts.map((part, index) => {
		const isBold = part.match(/<bold>([\s\S]*?)<\/bold>/)
		if (isBold) {
			// Extract just the text between the tags
			const boldText = isBold[1]
			return <strong key={index}>{boldText}</strong>
		}
		return part
	})
}
