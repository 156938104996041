import { Button } from "@/components/ui/button"
import { CheckboxWithText } from "@/components/ui/checkbox"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { ChevronDown, ListFilterIcon } from "lucide-react"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react"

interface FilterOption {
	value: string
	label: string
}

interface FilterData {
	label: string
	values: FilterOption[]
}

interface FilterDropdownProps {
	table: any // Replace 'any' with the correct table type if available
	filters: Record<string, FilterData>
}

export function TableFilter({ table, filters }: FilterDropdownProps) {
	const [openFilters, setOpenFilters] = useState<string[]>([])
	const [open, setOpen] = useState(false)

	const activeFiltersCount = Object.keys(filters).filter((columnId) => {
		const column = table.getColumn(columnId)
		return column && (column.getFilterValue() as string[] | undefined)?.length > 0
	}).length

	const toggleFilter = (columnId: string) => {
		setOpenFilters((prev) =>
			prev.includes(columnId) ? prev.filter((id) => id !== columnId) : [...prev, columnId],
		)
	}

	const handleClearAll = () => {
		for (const column of table.getAllColumns()) {
			if (column.getCanFilter()) {
				column.setFilterValue(undefined)
			}
		}
		setOpenFilters([])
		setOpen(false)
	}

	return (
		<DropdownMenu open={open} onOpenChange={setOpen}>
			<DropdownMenuTrigger asChild>
				<Button variant="outline" className="px-3" size="sm">
					<ListFilterIcon className="h-4 w-4 md:mr-2" />
					<span className="hidden md:inline mr-2">Filter</span>
					{activeFiltersCount > 0 && (
						<div className="ml-1 bg-primary text-background w-5 h-5 flex items-center justify-center rounded-full text-xs font-medium leading-none dark:text-white">
							{activeFiltersCount}
						</div>
					)}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-64">
				<div className="p-0">
					{Object.entries(filters).map(([columnId, filterData]) => {
						const column = table.getColumn(columnId)
						if (!column) {
							return null
						}

						const currentFilters = column.getFilterValue() as string[] | undefined
						const isOpen = openFilters.includes(columnId)

						return (
							<div key={columnId} className="mb-1">
								<Button
									variant="ghost"
									className="w-full justify-between"
									onClick={() => toggleFilter(columnId)}
								>
									<span>{filterData.label}</span>
									<ChevronDown
										className={`h-4 w-4 transition-transform ${isOpen ? "transform rotate-180" : ""}`}
									/>
								</Button>
								{isOpen && (
									<div className="mt-1 ml-4">
										{filterData.values.map((option) => (
											<CheckboxWithText
												key={option.value}
												id={`${columnId}-${option.value}`}
												checked={currentFilters?.includes(option.value) ?? false}
												onCheckedChange={(checked) => {
													const column = table.getColumn(columnId)
													const newFilters = checked
														? [...(currentFilters || []), option.value]
														: currentFilters?.filter((v) => v !== option.value)
													column?.setFilterValue(newFilters?.length ? newFilters : undefined)
												}}
												label={option.label}
											/>
										))}
									</div>
								)}
							</div>
						)
					})}
					<Button variant="ghost" className="w-full mt-2" onClick={handleClearAll}>
						Clear All
					</Button>
				</div>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
