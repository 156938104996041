/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type React from "react"
import styled from "styled-components"

const PillBox = styled.div<{ bgcolor: string }>`
  background-color: ${(props) => props.bgcolor};
  border-radius: 8px;
  padding: 5px 8px;
  display: inline-block;
  color: #fff;
  margin: 1px;
  min-width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  p {
    margin: 0;
    line-height: 1;
  }
`

/**
 * @description Element pill component
 * @param {string} color
 * @param {string} label
 * @param {function} handleClick
 */
interface ElementPillProps {
	color: string
	label: string
	handleClick: () => void
	isSelected?: boolean
}

const ElementPill: React.FC<ElementPillProps> = ({ color, label, handleClick }) => {
	return (
		<PillBox bgcolor={color} onClick={handleClick}>
			<p className="text-sm text-black">{label}</p>
		</PillBox>
	)
}

export default ElementPill
