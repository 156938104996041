import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import { useAppStateStore } from "@/store"
import type { ClaimStatusReason, OfficeAction } from "@/types"
import { toSnakeCase } from "@/utils/dataUtils"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useApi, useProjectMetadata } from "./"
/**
 * @description Hook for handling Office Action-related operations
 * @returns {object} - Actions for office actions
 */
const useOfficeAction = () => {
	const { handleError } = useApi()
	const { projectId } = useParentContext()
	const { subjectId } = useProjectMetadata()

	const { addErrorMessage } = useAppStateStore()
	const queryClient = useQueryClient()

	// Queries
	const officeActions = useQuery({
		queryKey: QUERY_KEYS.project.officeActions(projectId),
		queryFn: () => api.getOfficeActions(projectId),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const getOfficeActionApplicationNumber = async (s3Key: string) => {
		const response = await api.getOfficeActionApplicationNumber(s3Key)
		return response
	}
	// const officeActionApplicationNumber = useQuery({
	//   queryKey: QUERY_KEYS.officeActionApplicationNumber(currentProject?.id),
	//   queryFn: ({ queryKey }) => api.getOfficeActionApplicationNumber(queryKey[1]),
	//   enabled: false, // This query is manually triggered
	//   ...DEFAULT_QUERY_OPTIONS,
	// });

	// Mutations
	const deleteOfficeActionMutation = useMutation({
		mutationFn: (officeActionIds: string[]) =>
			api.deleteReferences(projectId, null, officeActionIds),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.officeActions(projectId),
			})
		},
		onError: (error) => {
			addErrorMessage("Error deleting office action")
			handleError(error, "Error deleting office action")
		},
	})

	const generateClaimAmendmentsMutation = useMutation({
		mutationFn: ({
			claimText,
			reason,
		}: {
			claimText: string
			reason: ClaimStatusReason
		}) => {
			const reasonWithCitations: ClaimStatusReason = {
				...reason,
				arguments: reason.arguments || [],
				amendments: reason.amendments || [],
				referenceCitations: toSnakeCase(reason.referenceCitations),
			}
			return api.generateClaimAmendment(claimText, reasonWithCitations, subjectId)
		},
		onError: (error) => {
			addErrorMessage("Failed to generate claim amendments")
			handleError(error, "Failed to generate claim amendments")
		},
	})

	const generateClaimArgumentMutation = useMutation({
		mutationFn: ({
			claimText,
			reason,
		}: {
			claimText: string
			reason: ClaimStatusReason
		}) => {
			const reasonWithCitations = {
				...reason,
				arguments: reason.arguments || [],
				referenceCitations: toSnakeCase(reason.referenceCitations),
			}
			return api.generateClaimArgument(claimText, reasonWithCitations, subjectId)
		},
		onError: (error) => {
			addErrorMessage("Failed to generate claim arguments")
			handleError(error, "Failed to generate claim arguments")
		},
	})

	return {
		getOfficeActionApplicationNumber,
		// Query data
		officeActions: officeActions.data as OfficeAction[],

		// Loading states
		isLoading: {
			officeActions: officeActions.isLoading,
		},

		// Fetching states
		isFetching: {
			officeActions: officeActions.isFetching,
		},

		// Mutation states
		isPending: {
			deleteOfficeAction: deleteOfficeActionMutation.isPending,
			generateClaimAmendments: generateClaimAmendmentsMutation.isPending,
			generateClaimArgument: generateClaimArgumentMutation.isPending,
		},

		// Actions
		// getOfficeActions: () =>
		//   queryClient.invalidateQueries({
		//     queryKey: QUERY_KEYS.officeActions(currentProject?.id),
		//   }),
		// getOfficeActionApplicationNumber: (s3Key: string) =>
		//   getApplicationNumber.refetch({
		//     queryKey: [
		//       QUERY_KEYS.officeActionApplicationNumber(currentProject?.id),
		//       s3Key,
		//     ],
		//   }),
		deleteOfficeAction: deleteOfficeActionMutation.mutate,
		generateClaimAmendments: generateClaimAmendmentsMutation.mutateAsync,
		generateClaimArgument: generateClaimArgumentMutation.mutateAsync,
	}
}

export default useOfficeAction
