/*
 * Copyright AndAI, Inc. 2025.
 * All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import { InfoPopover } from "@/components"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import type React from "react"
import { useCreateProjectContext } from "../CreateProjectContext"

/**
 * @description Component for displaying the project details form (includes project name, client matter ID, and project default budget)
 */
const ProjectDetailsForm: React.FC = () => {
	const {
		projectName,
		updateProjectName,
		clientNumber,
		updateClientNumber,
		defaultBudget,
		updateDefaultBudget,
		isProjectCreationInProgress,
	} = useCreateProjectContext()

	const clientNumberContent =
		"The project will be added to a client-specific invoice provided for ease of billing."

	return (
		<div className="flex flex-row gap-4">
			<div className="flex-1">
				<Label htmlFor="project-name">Project Name</Label>
				<Input
					id="project-name"
					className="mt-1"
					placeholder="Required"
					disabled={isProjectCreationInProgress}
					value={projectName || ""}
					onChange={(e) => updateProjectName(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault()
						}
					}}
				/>
			</div>

			<div className="flex-1">
				<div className="flex items-center justify-start">
					<Label htmlFor="client-number" className="mr-1 flex items-center">
						Client Matter ID
						<InfoPopover content={clientNumberContent} className="ml-1" />
					</Label>
				</div>
				<Input
					id="client-number"
					className="mt-1"
					placeholder="Optional"
					disabled={isProjectCreationInProgress}
					value={clientNumber}
					onChange={(e) => updateClientNumber(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault()
						}
					}}
				/>
			</div>

			<div className="flex-1">
				<div className="flex items-center justify-start">
					<Label htmlFor="default-budget" className="mr-1 flex items-center">
						Budget
						<InfoPopover
							content="Users will be warned before performing billable actions that would surpass the budget, such as processing a document. Actions will not be blocked."
							className="ml-1"
						/>
					</Label>
				</div>
				<Input
					id="default-budget"
					type="text"
					className="mt-1"
					placeholder="Default Budget"
					disabled={isProjectCreationInProgress}
					value={defaultBudget ? `$${defaultBudget.toLocaleString()}` : ""}
					onChange={(e) => {
						const value = e.target.value.replace(/[^0-9]/g, "")
						updateDefaultBudget(value ? Number(value) : 0)
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.preventDefault()
						}
					}}
				/>
			</div>
		</div>
	)
}

export default ProjectDetailsForm
