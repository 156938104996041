/**
 * @param {string} string - The string to capitalize
 * @returns {string} - The capitalized string
 */
export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * @param {string | number | null} value - The value to format
 * @param {boolean} inCents - Whether the value is in cents
 * @returns {string} - The formatted value
 */
export const formatCurrency = (
	value: string | number | null,
	inCents = false,
): string => {
	if (value === null) return
	const numValue = typeof value === "string" ? Number.parseFloat(value) : value
	if (Number.isNaN(numValue)) return "N/A"

	const dollars = inCents ? numValue / 100 : numValue
	return dollars.toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
	})
}

/**
 * @param {number} count - The count of items
 * @param {string} text - The text to pluralize
 * @returns {string} - The pluralized string
 */
export const pluralString = (count: number, text: string): string => {
	return count > 1 ? `${text}s` : text
}

export const cleanDocumentChunkText = (text: string): string => {
	text = text.replace(/-\s+/g, "") // Merge words separated by '- ' over multiple lines
	return text.replace(/[\r\n]+/g, " ").trim()
}
