/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import type { PatentNumberOption, PatentResult, UnprocessedDocument } from "@/types"
import { formatPatentNumber } from "@/utils"
import { formatInventorName } from "@/utils/projectUtils"
import { ChevronDownIcon, FileIcon } from "@radix-ui/react-icons"
import type React from "react"
import OpenInGooglePatentButton from "../patents/OpenInGooglePatentButton"
import { RankPill } from "../patents/RankPill"

interface UnprocessedPatentProps {
	document?: UnprocessedDocument
	updateSelectedKindCode: (documentId: string, kindCode: string) => void
	inputedPatentDetail?: PatentNumberOption
}

/**
 * A component that displays detailed information of an unprocessed patent.
 *
 * @param {UnprocessedPatentProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered component.
 */
const UnprocessedPatentCard: React.FC<UnprocessedPatentProps> = ({
	document,
	updateSelectedKindCode,
	inputedPatentDetail,
}) => {
	if (!document && !inputedPatentDetail) {
		return <div>Both document and inputedPatentDetail cannot be undefined</div>
	}
	let patentDetail: PatentResult | undefined
	let selectedKindCode: string | undefined
	if (!inputedPatentDetail) {
		selectedKindCode =
			document.selectedKindCode ||
			document.patentDetails?.selectedKindCode ||
			Object.keys(document.patentDetails?.details || {})[0]
		patentDetail = document.patentDetails?.details?.[selectedKindCode]
	} else {
		selectedKindCode = inputedPatentDetail.selectedKindCode
		patentDetail = inputedPatentDetail.details[selectedKindCode]
	}
	if (!patentDetail) {
		return <div>There was an error fetching the patent details</div>
	}

	const MetadataItem = ({
		label,
		value,
	}: { label?: string; value: React.ReactNode }) => (
		<>
			<span className="text-gray-400">•</span>
			<div className="flex items-center gap-2">
				{label && <span className="text-muted-foreground">{label}</span>}
				<span>{value}</span>
			</div>
		</>
	)

	return (
		<div className="flex items-center gap-4">
			{patentDetail.rank ? (
				<RankPill rank={patentDetail.rank} />
			) : (
				<FileIcon className="text-primary h-5 w-5" />
			)}
			<div className="space-y-1">
				<div className="flex items-center gap-2">
					<p className="text-sm font-semibold">{patentDetail.title}</p>
					<OpenInGooglePatentButton
						sourceNumber={patentDetail.number}
						prefix={patentDetail.prefix || ""}
					/>
				</div>

				<div className="flex flex-col gap-2 text-sm">
					<div className="flex items-center gap-2 flex-wrap">
						<p>{formatPatentNumber(patentDetail.number)}</p>

						{document?.patentDetails?.details &&
							Object.keys(document?.patentDetails.details).length > 0 && (
								<div>
									{Object.keys(document?.patentDetails.details).length === 1 ? (
										<span>{selectedKindCode}</span>
									) : (
										<DropdownMenu>
											<DropdownMenuTrigger asChild>
												<Button
													variant="ghost"
													size="sm"
													className="h-auto p-0 font-normal text-gray-500 hover:text-gray-900"
												>
													{selectedKindCode}
													<ChevronDownIcon className="ml-1 h-4 w-4" />
												</Button>
											</DropdownMenuTrigger>
											<DropdownMenuContent>
												{Object.keys(document?.patentDetails.details).map((kindCode) => (
													<DropdownMenuItem
														key={kindCode}
														onClick={() => updateSelectedKindCode(document?.id, kindCode)}
													>
														{kindCode}
													</DropdownMenuItem>
												))}
											</DropdownMenuContent>
										</DropdownMenu>
									)}
								</div>
							)}

						{patentDetail.priorityDate && (
							<MetadataItem
								label="Priority Date"
								value={new Date(patentDetail.priorityDate).toLocaleDateString()}
							/>
						)}

						{patentDetail.publicationDate && (
							<MetadataItem
								label="Publication Date"
								value={new Date(patentDetail.publicationDate).toLocaleDateString()}
							/>
						)}

						{patentDetail.inventors && patentDetail.inventors.length > 0 && (
							<MetadataItem
								label="Inventor"
								value={formatInventorName(patentDetail.inventors)}
							/>
						)}

						{patentDetail.assignee && (
							<MetadataItem label="Assignee" value={patentDetail.assignee} />
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default UnprocessedPatentCard
