/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { SidebarPage } from "@/components"
import { Loader } from "@/components"
import { useParentContext } from "@/context/ParentContext"
import ChatComponent from "@/features/chat/components/ChatComponent"
import useFullDocument from "@/hooks/document/useFullDocument"
import { usePriorArt } from "@/hooks/prior-art/usePriorArt"
import type React from "react"
import { useParams } from "react-router-dom"
import UserDocumentPageHeader from "./components/UserDocumentPageHeader"

/**
 * @description User documents chat page
 */
const UserDocumentsChatPage: React.FC = () => {
	const { documentId } = useParams()
	const { projectId } = useParentContext()
	const { priorArtDocuments } = usePriorArt()
	const { document, isLoading, isError } = useFullDocument(documentId)
	const pageName = document?.document.title
		? `${document.document.title} - Chat`
		: "Chat"

	const selectedDocument = priorArtDocuments.find((doc) => doc.documentId === documentId)
	return (
		<SidebarPage page={pageName}>
			<UserDocumentPageHeader name={document?.document.title || "Untitled"} />
			{isLoading ? (
				<Loader />
			) : isError ? (
				<div>Error loading document</div>
			) : (
				<ChatComponent
					projectId={projectId}
					disabledBool={false}
					documents={[selectedDocument]}
				/>
			)}
		</SidebarPage>
	)
}

export default UserDocumentsChatPage
