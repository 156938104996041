/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import DocumentDetailLayout from "@/components/documents/DocumentDetailLayout"
import { useClaims } from "@/hooks"
import { useProjectSubject } from "@/hooks/project/useProjectSubject"
import { PageName } from "@/types/pages"
import type React from "react"
import ProjectPage from "../../components/projects/ProjectPage"

const SubjectPage: React.FC = () => {
	const {
		projectSubject,
		isLoading: isLoadingSubject,
		isError: isErrorSubject,
	} = useProjectSubject()
	const { allClaims, isLoadingClaims, isErrorClaims } = useClaims()

	if (isLoadingClaims || isLoadingSubject) {
		return (
			<ProjectPage pageName={PageName.ProjectSubject}>
				<Loader />
			</ProjectPage>
		)
	}

	if (isErrorClaims || isErrorSubject) {
		return (
			<ProjectPage pageName={PageName.ProjectSubject}>
				<div>Error loading project metadata</div>
			</ProjectPage>
		)
	}

	return (
		<ProjectPage pageName={PageName.ProjectSubject}>
			{projectSubject?.document && allClaims ? (
				<DocumentDetailLayout document={projectSubject} claims={allClaims} />
			) : (
				<Loader />
			)}
		</ProjectPage>
	)
}

export default SubjectPage
