import { Dialog, DialogContent } from "@/components/ui/dialog"
import useDocumentFigures from "@/hooks/document/useDocumentFigures"
import type { DocumentChunk } from "@/types/invalidity"
import { useMemo } from "react"
import { FigureViewer } from "./FigureViewer"

interface FiguresModalProps {
	documentId: string
	open: boolean
	onOpenChange: (open: boolean) => void
	selectedFigure?: DocumentChunk
	selectedFigureUrl?: string
}

function FiguresModal({
	documentId,
	open,
	onOpenChange,
	selectedFigure,
	selectedFigureUrl,
}: FiguresModalProps) {
	const { figureChunks, updateFigure } = useDocumentFigures(documentId)

	const matchedFigure = useMemo<DocumentChunk | undefined>(() => {
		if (!figureChunks?.length) return undefined

		if (selectedFigure) {
			return figureChunks.find((fc) => fc.id === selectedFigure.id)
		}
		if (selectedFigureUrl) {
			return figureChunks.find((fc) => fc.figureUrl === selectedFigureUrl)
		}

		return undefined
	}, [figureChunks, selectedFigure, selectedFigureUrl])

	if (!figureChunks) {
		return null
	}

	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent className="max-w-[60vw] h-[90vh] p-0" hideCloseButton>
				<FigureViewer
					documentId={documentId}
					selectedFigure={matchedFigure}
					onHide={() => onOpenChange(false)}
					figureChunks={figureChunks}
					updateFigureChunk={updateFigure}
				/>
			</DialogContent>
		</Dialog>
	)
}

export default FiguresModal
