/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
	InvaliditySettingsModal,
	PortfolioNavigationTabs,
	ProjectNavigation,
	ShareProjectModal,
} from "@/components"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { useParentContext } from "@/context/ParentContext"
import { useNavigationState, useProjectMetadata } from "@/hooks"
import usePortfolioMetadata from "@/hooks/portfolio/usePortfolioMetadata"
import useRenamePortfolio from "@/hooks/portfolio/useRenamePortfolio"
import useIsProjectOwner from "@/hooks/project/useIsProjectOwner"
import useRenameProject from "@/hooks/project/useRenameProject"
import { ChevronRight, Settings, UserRound } from "lucide-react"
import { type ChangeEvent, type KeyboardEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
/**
 * @description Project header containing title, navigation tabs, and sharing
 */
function ProjectHeader() {
	const navigate = useNavigate()
	const { portfolioId, inPortfolio } = useParentContext()
	const { projectName: currentProjectName, projectCreatedBy } = useProjectMetadata()
	const { portfolioName: currentPortfolioName, portfolioCreatedBy } =
		usePortfolioMetadata()
	const { isPortfolioPage, isProjectPage } = useNavigationState()

	const { isOwner: isProjectOwner } = useIsProjectOwner(projectCreatedBy)
	const { isOwner: isPortfolioOwner } = useIsProjectOwner(portfolioCreatedBy)
	const { renameProject } = useRenameProject()
	const { renamePortfolio } = useRenamePortfolio()

	// Local state set up
	const [showShareModal, setShowShareModal] = useState<boolean>(false)
	const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false)
	const [projectName, setProjectName] = useState<string>(
		currentProjectName || "Untitled",
	)
	const [portfolioName, setPortfolioName] = useState<string>(
		currentPortfolioName || "Untitled",
	)
	const [isEditingPortfolioName, setIsEditingPortfolioName] = useState<boolean>(false)
	const [isEditingProjectName, setIsEditingProjectName] = useState<boolean>(false)

	// Update projectName when currentProjectName changes
	useEffect(() => {
		if (currentProjectName) {
			setProjectName(currentProjectName)
		}
	}, [currentProjectName, isPortfolioPage])

	useEffect(() => {
		if (currentPortfolioName) {
			setPortfolioName(currentPortfolioName)
		}
	}, [currentPortfolioName, isPortfolioPage])

	// Handle project name change
	const handleRename = (e: ChangeEvent<HTMLInputElement>) => {
		setProjectName(e.target.value)
	}

	const handlePortfolioClick = () => {
		if (isProjectPage && isPortfolioPage) {
			navigate(`/portfolio/${portfolioId}/subjects`)
		} else {
			setIsEditingProjectName(true)
		}
	}

	// Save project name
	const handleSaveProjectName = async () => {
		setIsEditingProjectName(false)
		const finalName = projectName.trim() === "" ? "Untitled" : projectName
		if (currentProjectName !== finalName) {
			await renameProject({
				name: finalName,
			})
		}
	}

	// const handleSubProjectRename = (e: ChangeEvent<HTMLInputElement>) => {
	//   setSubProjectName(e.target.value)
	// }

	const handleSavePortfolioName = async () => {
		setIsEditingPortfolioName(false)
		const finalName = portfolioName.trim() === "" ? "Untitled" : portfolioName
		if (currentPortfolioName !== finalName) {
			await renamePortfolio({
				name: finalName,
			})
		}
	}

	const isOwner = !isPortfolioPage ? isProjectOwner : isPortfolioOwner

	return (
		<div className="flex items-center justify-between px-4 py-2 border-b">
			<div className="flex items-center space-x-2">
				<div className="flex items-center space-x-2 mr-4">
					{inPortfolio && (
						<>
							{!isEditingPortfolioName ? (
								<Tooltip>
									<TooltipTrigger asChild>
										<div
											className="text-base font-medium p-1 h-auto whitespace-nowrap"
											onClick={() => setIsEditingPortfolioName(true)}
										>
											{portfolioName}
										</div>
									</TooltipTrigger>
									<TooltipContent>Rename portfolio</TooltipContent>
								</Tooltip>
							) : (
								<Input
									autoFocus
									value={portfolioName}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										setPortfolioName(e.target.value)
									}
									onBlur={handleSavePortfolioName}
									onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
										if (e.key === "Enter") {
											handleSavePortfolioName()
										}
									}}
								/>
							)}
							{isProjectPage && <ChevronRight className="w-4 h-4" />}
						</>
					)}
					{isProjectPage &&
						(!isEditingProjectName ? (
							<Tooltip>
								<TooltipTrigger asChild>
									<div
										className={`text-base font-medium p-1 h-auto whitespace-nowrap ${
											isPortfolioPage ? "cursor-pointer" : ""
										}`}
										onClick={handlePortfolioClick}
									>
										{projectName}
									</div>
								</TooltipTrigger>

								{!isProjectPage && (
									<TooltipContent>
										Rename {!inPortfolio ? "project" : "portfolio"}
									</TooltipContent>
								)}
							</Tooltip>
						) : (
							<Input
								className="h-8 text-sm"
								autoFocus
								value={projectName}
								onChange={handleRename}
								onBlur={handleSaveProjectName}
								onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
									if (e.key === "Enter") {
										handleSaveProjectName()
									}
								}}
							/>
						))}
				</div>
				{isProjectPage && <ProjectNavigation />}
				{isPortfolioPage && <PortfolioNavigationTabs />}
			</div>
			<div className="flex items-center space-x-2">
				<Tooltip>
					<TooltipTrigger asChild>
						<Button
							variant="ghost"
							size="icon"
							onClick={() => setShowSettingsModal(true)}
							aria-label="Invalidity settings"
						>
							<Settings className="w-5 h-5" />
						</Button>
					</TooltipTrigger>
					<TooltipContent>Settings</TooltipContent>
				</Tooltip>
				{isOwner && (
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								variant="ghost"
								size="icon"
								onClick={() => setShowShareModal(true)}
								aria-label="Share project"
							>
								<UserRound className="w-5 h-5" />
							</Button>
						</TooltipTrigger>
						<TooltipContent>Share</TooltipContent>
					</Tooltip>
				)}
			</div>

			<InvaliditySettingsModal
				open={showSettingsModal}
				handleClose={() => setShowSettingsModal(false)}
			/>

			<ShareProjectModal
				open={showShareModal}
				handleClose={() => setShowShareModal(false)}
				projectTitle={
					!isPortfolioPage && projectName ? projectName : portfolioName || "Untitled"
				}
			/>
		</div>
	)
}

export default ProjectHeader
