/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */

import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { cn } from "@/lib/utils"
import type {
	PortfolioDocumentMetadata,
	ProjectDocumentMetadata,
} from "@/types/documents"
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import type React from "react"
import { useCallback, useState } from "react"

interface DualDocumentSelectProps {
	/**
	 * Callback with an array of selected document IDs (max of 2).
	 */
	handleDocumentSelect: (selectedIds: string[]) => void
	/**
	 * The full list of project (or portfolio) document metadata.
	 */
	documents: (ProjectDocumentMetadata | PortfolioDocumentMetadata)[]
	className?: string
}

/**
 * DocumentSelect renders two selectors (“Document 1” and “Document 2”)
 * that together control the selected documents (max 2) used for chart display.
 */
const DualDocumentSelect: React.FC<DualDocumentSelectProps> = ({
	handleDocumentSelect,
	documents = [],
	className,
}) => {
	const { getDocumentName } = useDocumentNaming()
	// Maintain state for up to 2 selections.
	const [selectedDocIds, setSelectedDocIds] = useState<(string | undefined)[]>([])

	/**
	 * Update the selection at a specified slot (0 or 1).
	 * If the new document is already selected in the other slot, remove it there.
	 */
	const handleSlotChange = useCallback(
		(index: number, newDoc?: string) => {
			setSelectedDocIds((prev) => {
				const newSelected = [...prev] as (string | undefined)[]
				newSelected[index] = newDoc
				// Remove duplicate: if the new selection exists in the other slot, clear that slot.
				const otherIndex = index === 0 ? 1 : 0
				if (newDoc && newSelected[otherIndex] === newDoc) {
					newSelected[otherIndex] = undefined
				}
				// Fire the parent callback with non-empty selections.
				const finalized = newSelected.filter(Boolean) as string[]
				handleDocumentSelect(finalized)
				return newSelected
			})
		},
		[handleDocumentSelect],
	)

	// For each slot, we filter out the document that is selected in the other slot.
	const availableForSlot1 = documents.filter(
		(doc) => doc.documentId !== selectedDocIds[1],
	)
	const availableForSlot2 = documents.filter(
		(doc) => doc.documentId !== selectedDocIds[0],
	)

	return (
		<div className={cn("flex space-x-2 flex-grow", className)}>
			<SingleDocumentSelect
				label="Select a document *"
				currentValue={selectedDocIds[0]}
				availableDocuments={availableForSlot1}
				onSelect={(docId) => handleSlotChange(0, docId)}
				getDocumentName={getDocumentName}
				className="flex-grow h-9"
			/>
			{documents.length > 1 && (
				<SingleDocumentSelect
					label="Select a document"
					currentValue={selectedDocIds[1]}
					availableDocuments={availableForSlot2}
					onSelect={(docId) => handleSlotChange(1, docId)}
					getDocumentName={getDocumentName}
					className="flex-grow h-9"
				/>
			)}
		</div>
	)
}

/**
 * SingleDocumentSelect handles the UI for a single document selector.
 * It shows a button (with a label or current document name) that opens a popover.
 */
interface SingleDocumentSelectProps {
	/**
	 * Label to display when no document is selected.
	 */
	label: string
	/**
	 * The currently selected document ID, if any.
	 */
	currentValue?: string
	/**
	 * The list of available documents (filtered to not include the document
	 * selected in the other slot).
	 */
	availableDocuments: (ProjectDocumentMetadata | PortfolioDocumentMetadata)[]
	/**
	 * Callback when a document is selected or cleared (passing undefined clears the slot).
	 */
	onSelect: (selectedId?: string) => void
	/**
	 * Helper to extract the document name from its ID.
	 */
	getDocumentName: (id: string) => string
	className?: string
}

const SingleDocumentSelect: React.FC<SingleDocumentSelectProps> = ({
	label,
	currentValue,
	availableDocuments,
	onSelect,
	getDocumentName,
	className,
}) => {
	const [open, setOpen] = useState(false)

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button variant="outline" className={cn("justify-between flex-grow", className)}>
					<span className="truncate">
						{currentValue ? (
							getDocumentName(currentValue)
						) : (
							<span className="text-muted-foreground">{label}</span>
						)}
					</span>
					<ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent forceInline className="p-0 w-auto max-w-[300px]" align="start">
				<ScrollArea className="h-[300px]">
					<div className="p-1">
						<Button
							variant="ghost"
							className="w-full justify-start text-left"
							onClick={() => {
								onSelect(undefined)
								setOpen(false)
							}}
						>
							Clear selection
						</Button>
						{availableDocuments.map((doc) => (
							<Button
								key={doc.documentId}
								variant="ghost"
								className="w-full justify-start text-left"
								onClick={() => {
									onSelect(doc.documentId)
									setOpen(false)
								}}
							>
								<div className="flex items-center">
									{currentValue === doc.documentId && (
										<CheckIcon className="mr-2 h-4 w-4 flex-shrink-0" />
									)}
									<span>{getDocumentName(doc.documentId)}</span>
								</div>
							</Button>
						))}
					</div>
				</ScrollArea>
			</PopoverContent>
		</Popover>
	)
}

export default DualDocumentSelect
