import { api } from "@/api"
import { useAppStateStore } from "@/store"
import { MUTATION_KEYS } from "@/utils"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export function useRemoveProjectFromPortfolio() {
	const queryClient = useQueryClient()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()

	const removeProjectsMutation = useMutation({
		mutationKey: MUTATION_KEYS.portfolio.removeProjects(),
		mutationFn: async ({
			portfolioId,
			projectIds,
		}: {
			portfolioId: string
			projectIds: string[]
		}) => {
			return api.removeProjectsFromPortfolio(portfolioId, projectIds)
		},
		onError: (_error) => addErrorMessage("Error removing patents from portfolio"),
		onSuccess: () => {
			addSuccessMessage("Projects removed successfully")
			queryClient.invalidateQueries({ queryKey: ["portfolio"] })
		},
	})

	return {
		removeProjects: removeProjectsMutation.mutateAsync,
		isPending: removeProjectsMutation.isPending,
	}
}

export default useRemoveProjectFromPortfolio
