/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { FloatingLabelInput } from "@/components/ui/floating-input"
import { LoadingButton } from "@/components/ui/loading-button"
import { ScrollArea } from "@/components/ui/scroll-area"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import type { Organization } from "@/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { VisuallyHidden } from "@radix-ui/react-visually-hidden"
import { useEffect } from "react"
import { type Control, Controller, type FieldErrors, useForm } from "react-hook-form"
import { z } from "zod"
import { useSuperAdminOrganizations } from "../../hooks/useSuperAdminOrganizations"

/**
 * The updated OrganizationModalProps no longer includes extra pricing fields.
 */
interface OrganizationModalProps {
	open: boolean
	onClose: () => void
	org?: Organization
	isEdit?: boolean
}

/**
 * Updated organizationSchema to match prisma schema.
 */
const organizationSchema = z.object({
	name: z.string().min(1, "Organization name is required"),
	billing_frequency: z.enum(["NONE", "MONTHLY", "YEARLY"]).optional(),
	document_process_fee: z.number().nullable().optional(),
	document_export_fee: z.number().nullable().optional(),
	platform_fee: z.number().nullable().optional(),
	seat_fee: z.number().nullable().optional(),
	default_budget: z.number().nullable().optional(),
})

type OrganizationFormData = z.infer<typeof organizationSchema>

const initialState: OrganizationFormData = {
	name: "",
	billing_frequency: "NONE",
	document_process_fee: null,
	document_export_fee: null,
	platform_fee: null,
	seat_fee: null,
	default_budget: null,
}

function OrganizationModal({ open, onClose, org, isEdit }: OrganizationModalProps) {
	const {
		updateOrganization,
		createOrganization,
		isLoading,
		isCreatePending,
		isUpdatePending,
	} = useSuperAdminOrganizations()

	const {
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<OrganizationFormData>({
		resolver: zodResolver(organizationSchema),
		defaultValues: isEdit && org ? org : initialState,
	})

	const isLoadingState = isLoading || isUpdatePending || isCreatePending

	useEffect(() => {
		if (open) {
			if (isEdit && org) {
				reset(org)
			} else {
				reset(initialState)
			}
		}
	}, [isEdit, org, reset, open])

	const onSubmit = async (data: OrganizationFormData) => {
		try {
			if (isEdit && org) {
				await updateOrganization({
					organizationId: org.id ?? null,
					newOrg: {
						id: org.id ?? null,
						name: data.name,
						createdAt: org.createdAt ?? null,
						documentProcessFee: data.document_process_fee,
						documentExportFee: data.document_export_fee,
						platformFee: data.platform_fee,
						seatFee: data.seat_fee,
						billingFrequency: data.billing_frequency,
						defaultBudget: data.default_budget,
					},
				})
			} else {
				await createOrganization({
					org: {
						id: "",
						name: data.name,
						createdAt: new Date().toISOString(),
						documentProcessFee: data.document_process_fee,
						documentExportFee: data.document_export_fee,
						platformFee: data.platform_fee,
						seatFee: data.seat_fee,
						billingFrequency: data.billing_frequency,
						defaultBudget: data.default_budget,
					},
				})
			}
			onClose()
		} catch (error) {
			console.error("Error saving organization:", error)
		}
	}

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[900px]">
				<DialogHeader>
					<DialogTitle>{isEdit ? "Edit" : "Add"} organization</DialogTitle>
					<VisuallyHidden asChild>{org?.name}</VisuallyHidden>
				</DialogHeader>
				<form onSubmit={handleSubmit(onSubmit)} className="pb-4">
					<ScrollArea className="max-h-[calc(100vh-200px)] mx-2">
						<Controller
							name="name"
							control={control}
							render={({ field }) => (
								<FloatingLabelInput
									id="name"
									label="Organization Name"
									{...field}
									className="mt-2 mb-2"
									error={errors.name?.message}
									disabled={isLoadingState}
								/>
							)}
						/>

						<Controller
							name="billing_frequency"
							control={control}
							render={({ field }) => (
								<Select
									onValueChange={field.onChange}
									value={field.value}
									disabled={isLoadingState}
								>
									<SelectTrigger className="mb-2">
										<SelectValue placeholder="Select Billing Frequency" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value="NONE">None</SelectItem>
										<SelectItem value="MONTHLY">Monthly</SelectItem>
										<SelectItem value="YEARLY">Yearly</SelectItem>
									</SelectContent>
								</Select>
							)}
						/>

						<PricingInputs
							fields={[
								{ name: "document_process_fee", label: "Document Process" },
								{ name: "document_export_fee", label: "Document Export" },
								{ name: "platform_fee", label: "Platform" },
								{ name: "seat_fee", label: "Seat" },
								{ name: "default_budget", label: "Default Budget" },
							]}
							control={control}
							errors={errors}
							disabled={isLoadingState}
						/>
					</ScrollArea>
					<DialogFooter>
						<Button
							variant="outline"
							disabled={isLoadingState}
							onClick={onClose}
							type="button"
						>
							Cancel
						</Button>
						<LoadingButton type="submit" loading={isLoadingState}>
							{isLoadingState ? "Saving..." : "Save Changes"}
						</LoadingButton>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	)
}

interface PricingInputProps {
	fields: Array<{ name: keyof OrganizationFormData; label: string }>
	control: Control<OrganizationFormData>
	errors: FieldErrors<OrganizationFormData>
	disabled: boolean
}

const PricingInputs = ({ fields, control, errors, disabled }: PricingInputProps) => (
	<div className="flex gap-2 mb-2 w-full">
		{fields.map(({ name, label }) => (
			<Controller
				key={name}
				name={name}
				control={control}
				render={({ field }) => (
					<FloatingLabelInput
						id={name}
						label={label}
						{...field}
						value={field.value === null ? "" : field.value.toString()}
						onChange={(e) => {
							const value = e.target.value === "" ? null : Number(e.target.value)
							field.onChange(value)
						}}
						className="w-full"
						error={errors[name]?.message}
						disabled={disabled}
					/>
				)}
			/>
		))}
	</div>
)

export default OrganizationModal
