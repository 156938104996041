import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import type { DocumentCoverSentence } from "@/types"
import { PlusIcon, TrashIcon } from "lucide-react"
import type React from "react"

interface SentencePartProps {
	text: string
	onChange: (newText: string) => void
	onRemove: () => void
	error?: boolean
	className?: string
}

const SentencePart: React.FC<SentencePartProps> = ({
	text,
	onChange,
	onRemove,
	className,
}) => (
	<div className="flex items-start gap-2">
		<AutosizeTextarea
			value={text}
			onChange={(e) => onChange(e.target.value)}
			className={`border rounded p-1 focus:outline-none flex-1 custom-scrollbar ${className}`}
		/>
		<Button
			variant="ghost"
			className="h-6 w-6 p-0 flex items-center justify-center"
			onClick={onRemove}
		>
			<TrashIcon className="h-4 w-4 text-red-500" />
		</Button>
	</div>
)

const AddPartButton: React.FC<{ onClick: () => void; position: "top" | "bottom" }> = ({
	onClick,
	position,
}) => (
	<div
		className={`absolute left-1/2 -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity ${
			position === "top" ? "-top-4" : "-bottom-4"
		}`}
	>
		<Button
			variant="ghost"
			className="h-6 w-6 p-0 flex items-center justify-center"
			onClick={onClick}
		>
			<PlusIcon className="h-4 w-4" />
		</Button>
	</div>
)

interface EditableSentenceDisplayProps {
	sentence: DocumentCoverSentence
	onChange: (updatedSentence: DocumentCoverSentence) => void
	validationErrors: (string | null)[]
}

const EditableSentenceDisplay: React.FC<EditableSentenceDisplayProps> = ({
	sentence,
	onChange,
	validationErrors,
}) => {
	const handlePartChange = (index: number, newText: string) => {
		const newParts = sentence.sentence.map((part, i) => {
			if (i === index) {
				return { ...part, text: newText }
			}
			return part
		})

		// Rebuild a new "DocumentCoverSentence" for the parent
		// but do NOT re-inject quotes into the child's displayed text.
		// We only add them in the parent's version so that the parent's
		// summary preview or final data has them.
		const rebuiltSentence = newParts.map((part, i) => {
			return {
				...part,
				text: part.text,
			}
		})

		const oldFullText = sentence.sentence.map((item) => item.text).join(" ")
		const newFullText = rebuiltSentence.map((item) => item.text).join(" ")
		if (newFullText !== oldFullText) {
			onChange({ ...sentence, sentence: rebuiltSentence })
		}
	}

	const handleInsertFiller = (index: number) => {
		const newParts = [...sentence.sentence]
		// Create a new filler part (no chunkId => filler)
		const fillerPart = { text: "" }

		// Insert AFTER the current part => position = index + 1
		newParts.splice(index + 1, 0, fillerPart)
		onChange({ ...sentence, sentence: newParts })
	}

	const handleRemovePart = (index: number) => {
		const newParts = [...sentence.sentence]
		newParts.splice(index, 1)
		onChange({ ...sentence, sentence: newParts })
	}

	return (
		<div className="mb-4 p-4 rounded-md border shadow-sm">
			{sentence.sentence.map((part, index) => {
				const isQuote = "chunkId" in part
				const error = validationErrors[index]

				return (
					<div
						key={`part-${isQuote ? part.chunkId : `filler-${index}`}`}
						className={`relative mb-4 group ${isQuote ? "" : ""}`}
					>
						{isQuote && (
							<AddPartButton onClick={() => handleInsertFiller(index - 1)} position="top" />
						)}

						<SentencePart
							text={part.text}
							onChange={(newText) => handlePartChange(index, newText)}
							onRemove={() => handleRemovePart(index)}
							error={isQuote ? !!error : undefined}
							className={isQuote ? (error ? "bg-red-100" : "bg-blue-100") : "bg-white-100"}
						/>

						{isQuote && (
							<AddPartButton onClick={() => handleInsertFiller(index)} position="bottom" />
						)}
					</div>
				)
			})}
		</div>
	)
}

export default EditableSentenceDisplay
