import { AddPatentsModal } from "@/components"
import { DataTable } from "@/components/table/DataTable"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import { useParentContext } from "@/context/ParentContext"
import { usePortfolioMetadata, useRemoveProjectFromPortfolio } from "@/hooks"
import { useAreProcessesPending } from "@/store"
import { DocumentRole, ProcessType, type ProjectMetadata } from "@/types"
import { formatPatentNumber } from "@/utils/projectUtils"
import { PlusIcon } from "@radix-ui/react-icons"
import type { ColumnDef } from "@tanstack/react-table"
import { DateTime } from "luxon"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import DeletePortfolioProjectModal from "./DeletePortfolioProjectModal"

export function PortfolioProjectTable() {
	const navigate = useNavigate()
	const { portfolioId } = useParentContext()
	const { portfolioProjects } = usePortfolioMetadata()
	const { removeProjects } = useRemoveProjectFromPortfolio()
	const [showAddNewModal, setShowAddNewModal] = useState(false)
	const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false)
	const [selectedProjectsToDelete, setSelectedProjectsToDelete] = useState([])

	// Restore the disabled state for Add Subjects button
	const isAddSubjectDisabled = useAreProcessesPending(
		[ProcessType.GENERATE_CONTEXT, ProcessType.ADD_PRIOR_ART],
		null,
		portfolioId,
	)

	// Restore delete functionality
	const handleDeleteSelected = async (selectedProjects: ProjectMetadata[]) => {
		const projectIds = selectedProjects?.map((project) => project.id)
		await removeProjects({
			portfolioId,
			projectIds,
		})
	}

	const handleDeleteProjects = (selectedProjects: ProjectMetadata[]) => {
		setSelectedProjectsToDelete(selectedProjects)
		setShowDeleteProjectModal(true)
	}

	const handleSelectProject = async (project: ProjectMetadata) => {
		navigate(`/portfolio/${portfolioId}/project/${project.id}/subject`)
	}

	const checkboxColumn: ColumnDef<ProjectMetadata> = {
		id: "select",
		header: ({ table }) => (
			<Checkbox
				checked={table.getIsAllPageRowsSelected()}
				onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
				aria-label="Select all"
			/>
		),
		cell: ({ row }) => (
			<Checkbox
				checked={row.getIsSelected()}
				onCheckedChange={(value) => row.toggleSelected(!!value)}
				aria-label="Select row"
			/>
		),
		enableSorting: false,
		enableHiding: false,
	}

	const columns: ColumnDef<ProjectMetadata>[] = [
		checkboxColumn,
		{
			id: "name",
			accessorKey: "name",
			header: "Name",
			cell: ({ row }) => row.original.name,
		},
		{
			id: "title",
			header: "Title",
			cell: ({ row }) => {
				return row.original.subject?.title
			},
		},
		{
			id: "number",
			header: "Number",
			cell: ({ row }) => {
				const patentNumber = row.original.subject?.patent?.number
				return patentNumber ? (
					<div>
						{formatPatentNumber(patentNumber)}
						{/* <OpenInGooglePatentButton sourceNumber={patentNumber} /> */}
					</div>
				) : (
					"—"
				)
			},
		},
		{
			id: "priorityDate",
			header: "Priority",
			cell: ({ row }) => {
				const projectPriorityDate = row.original.priorityDate
				const subjectPriorityDate = row.original.subject?.patent?.priorityDate
				return projectPriorityDate || subjectPriorityDate
					? DateTime.fromISO(projectPriorityDate || subjectPriorityDate, {
							zone: "utc",
						}).toFormat("MM/dd/yyyy")
					: "—"
			},
		},
	]

	return (
		<div>
			<DataTable
				columns={columns}
				data={portfolioProjects || []}
				onRowClick={handleSelectProject}
				showActions={true}
				enableRowDelete={true}
				onDeleteSelected={handleDeleteProjects}
				height="auto"
			>
				<div className="flex gap-2 justify-between">
					<div className="flex gap-2">
						{(!portfolioProjects || portfolioProjects.length < 100) && (
							<Button
								onClick={() => setShowAddNewModal(true)}
								disabled={isAddSubjectDisabled}
								size="sm"
							>
								Add Subjects
								<PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
							</Button>
						)}
					</div>
				</div>
			</DataTable>
			<AddPatentsModal
				open={showAddNewModal}
				handleClose={() => {
					setShowAddNewModal(false)
				}}
				documentRole={DocumentRole.SUBJECT}
				showProcessSettings={false}
			/>
			<DeletePortfolioProjectModal
				open={showDeleteProjectModal}
				onClose={() => setShowDeleteProjectModal(false)}
				removeProjectsFromPortfolio={handleDeleteSelected}
				projects={selectedProjectsToDelete}
			/>
		</div>
	)
}
