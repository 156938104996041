import type { Claim } from "@/types"
import DOMPurify from "dompurify"
import type React from "react"
import { CondensedParagraph } from "../../../components/ui/typography"

interface ClaimComponentProps {
	claim: Claim
}

export const ClaimComponent: React.FC<ClaimComponentProps> = ({ claim }) => {
	if (!claim) {
		return null
	}

	const renderCitationLocation = () => {
		if (!claim.number) return null

		return (
			<div className="w-[75px] shrink-0 text-sm text-gray-500 whitespace-pre-line">
				Claim {claim.number}
			</div>
		)
	}

	const renderLimitations = () => {
		return claim.limitations.map((limitation, index) => {
			const indentLevel = limitation.label?.split(".").length - 1 || 0
			const indentClass =
				indentLevel === 1
					? "ml-8"
					: indentLevel === 2
						? "ml-16"
						: indentLevel === 3
							? "ml-24"
							: indentLevel >= 4
								? "ml-32"
								: ""
			return (
				<div key={limitation.id ?? index} className={`mt-1 ${indentClass}`}>
					<div
						// biome-ignore lint/security/noDangerouslySetInnerHtml: we sanitize below
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(limitation.html),
						}}
					/>
				</div>
			)
		})
	}

	return (
		<div className="group flex items-center hover:cursor-pointer hover:bg-accent/50 mb-2">
			<div className="flex flex-col items-start">
				{renderCitationLocation()}
				<div className="flex items-start gap-2">
					<CondensedParagraph>{renderLimitations()}</CondensedParagraph>
				</div>
			</div>
		</div>
	)
}

export default ClaimComponent
