import { ScrollArea } from "@/components/ui/scroll-area"
import { useChartsContext } from "../../context/ChartsContext"

import InvalidityTableLimitationCell from "./InvalidityTableLimitationCell"

const InvalidityTableMode: React.FC = () => {
	const { filteredLimitations } = useChartsContext()

	return (
		<div className="flex h-full w-full px-3 py-2">
			<ScrollArea className="w-full h-full">
				<div className="flex flex-col gap-2">
					{filteredLimitations.map((limitation) => (
						<InvalidityTableLimitationCell
							key={limitation.id}
							collapsedLimitation={limitation}
						/>
					))}
				</div>
			</ScrollArea>
		</div>
	)
}

export default InvalidityTableMode
