import CustomInput from "@/components/input/CustomInput"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { LoadingButton } from "@/components/ui/loading-button"
import type React from "react"
import { useCallback, useMemo, useState } from "react"

import { CheckboxWithText } from "@/components/ui/checkbox"
import {
	useClaims,
	useDocumentNaming,
	useInvaliditySettings,
	useProjectSubject,
} from "@/hooks"
import { convertToUtcDateString } from "@/utils/dateUtils"
import { useChunkToLimitationUpdates } from "../../hooks"

interface Variable {
	id: string
	name: string
	current: string
}

interface EditCellBoilerplateModalProps {
	isOpen: boolean
	onClose: () => void
	applyToAllLimitations?: boolean
	claimLimitationLabel?: string
	claimLimitationText?: string
	claimLimitationId?: string
	documentId?: string
	cellHeader?: string
	cellFooter?: string
	cellMiddle?: string
	atCellLevel?: boolean
}

const EditCellBoilerplateModal: React.FC<EditCellBoilerplateModalProps> = ({
	isOpen,
	onClose,
	applyToAllLimitations = false,
	claimLimitationLabel = "1",
	claimLimitationText = "Example claim limitation text",
	claimLimitationId,
	documentId,
	cellHeader,
	cellFooter,
	cellMiddle,
	atCellLevel = false,
}) => {
	const [isSaving, setIsSaving] = useState(false)
	const { projectSubject } = useProjectSubject()
	const { updateCellBoilerplate } = useChunkToLimitationUpdates()
	const { settings } = useInvaliditySettings()
	const { getDocumentName } = useDocumentNaming()
	const { claimLimitations } = useClaims()

	const allClaimLimitationIds = useMemo(
		() => claimLimitations.map((limitation) => limitation.id) ?? [],
		[claimLimitations],
	)

	// The actual placeholders that might appear:
	//   {{reference_name}}, {{element_number}}, {{element_language}}, etc.
	const documentName = documentId ? getDocumentName(documentId) : ""
	const variables: Variable[] = [
		{
			id: "reference_name",
			name: "Reference Nickname",
			current: documentName,
		},
		{
			id: "element_number",
			name: "Claim Limitation Number",
			current: claimLimitationLabel,
		},
		{
			id: "element_language",
			name: "Claim Limitation Text",
			current: claimLimitationText,
		},
		{
			id: "subject_nickname",
			name: "Subject Nickname",
			current: getDocumentName(projectSubject?.documentId ?? "") || "",
		},
		{
			id: "subject_priority_date",
			name: "Subject Priority Date",
			current:
				convertToUtcDateString(projectSubject?.document?.patent?.priorityDate ?? "") || "",
		},
		{
			id: "subject_number",
			name: "Subject Number",
			current: projectSubject?.document?.patent?.number || "",
		},
	]
	const [header, setHeader] = useState<string>(
		() => cellHeader ?? settings.headerFormat ?? "",
	)
	const [footer, setFooter] = useState<string>(
		() => cellFooter ?? settings.footerFormat ?? "",
	)
	const [middle, setMiddle] = useState<string>(() => cellMiddle ?? "")
	const [overwrite, setOverwrite] = useState<boolean>(false)

	const handleSave = useCallback(async () => {
		setIsSaving(true)
		try {
			// Pass the raw placeholders as-is to the backend;
			// they will later be rendered (replaced) only when displayed.
			const payload = {
				patentId: projectSubject?.documentId ?? "",
				documentId: documentId ?? "",
				header,
				footer,
				middle,
				overwrite,
				claimLimitationIds: applyToAllLimitations
					? allClaimLimitationIds
					: [claimLimitationId],
			}

			await updateCellBoilerplate(payload)
		} catch (error) {
			console.error("Error updating invalidity boilerplate:", error)
		} finally {
			setIsSaving(false)
			setOverwrite(false)
			onClose()
		}
	}, [
		header,
		footer,
		middle,
		applyToAllLimitations,
		claimLimitationId,
		documentId,
		projectSubject,
		updateCellBoilerplate,
		onClose,
		overwrite,
		allClaimLimitationIds,
	])

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[800px]">
				<DialogHeader>
					<DialogTitle>Edit {atCellLevel ? "Cell" : "Table Cell"} Language</DialogTitle>
				</DialogHeader>

				<div className="space-y-4 pb-4">
					<div>
						<div className="flex items-center gap-4">
							<Label htmlFor="header" className="mb-0">
								Header
							</Label>
						</div>
						<CustomInput
							id="header"
							label=""
							value={header}
							onChange={setHeader}
							variables={variables}
						/>
					</div>

					<div className="space-y-2">
						<div className="flex items-center gap-4">
							<Label htmlFor="footer" className="mb-0">
								Middle
							</Label>
						</div>
						<CustomInput
							id="middle"
							label=""
							value={middle}
							onChange={setMiddle}
							variables={variables}
						/>
					</div>

					<div className="space-y-2">
						<div className="flex items-center gap-4">
							<Label htmlFor="footer" className="mb-0">
								Footer
							</Label>
						</div>
						<CustomInput
							id="footer"
							label=""
							value={footer}
							onChange={setFooter}
							variables={variables}
						/>
					</div>
					{!atCellLevel && (
						<div>
							<CheckboxWithText
								id="overwrite"
								checked={overwrite}
								onCheckedChange={(checked) => setOverwrite(checked as boolean)}
								label="Overwrite existing settings"
							/>
						</div>
					)}
				</div>

				<DialogFooter>
					<Button variant="outline" onClick={onClose}>
						Cancel
					</Button>
					<LoadingButton loading={isSaving} type="button" onClick={handleSave}>
						Save changes
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default EditCellBoilerplateModal
