import DualDocumentSelect from "@/components/documents/DualDocumentSelect"
import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { FloatingLabelInput } from "@/components/ui/floating-input"
import { LoadingButton } from "@/components/ui/loading-button"
import { useInvalidityChartUpdates } from "@/features/charts/hooks/mutations/useInvalidityChartUpdates"
import { usePriorArt } from "@/hooks/prior-art/usePriorArt"
import type React from "react"
import { useState } from "react"
// import InputWithLabel from "@/components/ui/input"
interface NewChartModalProps {
	open: boolean
	handleClose: () => void
}

/**
 * @description Prune invalidity charts by citation score
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} fetchElementChartPageData - Function to fetch the element chart page data.
 */
const NewChartModal: React.FC<NewChartModalProps> = ({ open, handleClose }) => {
	const { priorArtDocuments } = usePriorArt()
	const { addInvalidityChart } = useInvalidityChartUpdates()

	const [isLoading, setIsLoading] = useState(false)
	const [documentIds, setDocumentIds] = useState<string[]>([])
	const [chartName, setChartName] = useState("")
	const [chartNotes, setChartNotes] = useState("")

	// Determines whether both the chart name and at least one document are provided.
	const isFormValid = chartName.trim().length > 0 && documentIds.length > 0

	const resetModal = () => {
		setDocumentIds([])
		setChartName("")
		setChartNotes("")
		setIsLoading(false)
	}

	const onClose = () => {
		resetModal()
		handleClose()
	}

	const handleSave = async () => {
		// Extra check in case the button is somehow triggered when the form is not valid.
		if (!isFormValid) {
			alert("Please provide a chart name and select at least one document.")
			return
		}

		setIsLoading(true)
		try {
			await addInvalidityChart({
				name: chartName,
				notes: chartNotes,
				documentIds,
			})
		} catch (error) {
			console.error("Error creating chart:", error)
		} finally {
			setIsLoading(false)
			onClose()
		}
	}

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>New Chart</DialogTitle>
					<DialogDescription>Create a new chart by selecting documents.</DialogDescription>
				</DialogHeader>

				<div className="flex flex-col gap-4">
					{/* Document selection field with required indicator */}
					<div className="flex flex-col">
						<DualDocumentSelect
							handleDocumentSelect={setDocumentIds}
							documents={priorArtDocuments}
						/>
					</div>

					{/* Name field with required indicator */}
					<div className="flex flex-col">
						<FloatingLabelInput
							id="chartName"
							type="text"
							value={chartName}
							onChange={(e) => setChartName(e.target.value)}
							className="px-3 py-2 border rounded-md"
							label="Chart Name *"
						/>
					</div>

					{/* Notes field */}
					<div className="flex flex-col">
						<AutosizeTextarea
							id="chartNotes"
							value={chartNotes}
							onChange={(e) => setChartNotes(e.target.value)}
							className="px-3 py-2 border rounded-md"
							rows={4}
							placeholder="Add optional notes..."
						/>
					</div>
				</div>

				<DialogFooter>
					<div className="flex items-center justify-end gap-2">
						<Button variant="outline" onClick={() => onClose()} disabled={isLoading}>
							Cancel
						</Button>
						<LoadingButton
							variant="default"
							onClick={handleSave}
							loading={isLoading}
							disabled={isLoading || !isFormValid}
						>
							{isLoading ? "Creating..." : "Create"}
						</LoadingButton>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default NewChartModal
