import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

interface ChartOptionsState {
	showCitationsInClaim: boolean
	showCommentaryInClaim: boolean
	showUpdatedAt: boolean
	showUpdatedBy: boolean
	showReferencedFigures: boolean
	showFeedbackButtons: boolean

	setShowCitationsInClaim: (value: boolean) => void
	setShowCommentaryInClaim: (value: boolean) => void
	setShowUpdatedAt: (value: boolean) => void
	setShowUpdatedBy: (value: boolean) => void
	setShowReferencedFigures: (value: boolean) => void
	setShowFeedbackButtons: (value: boolean) => void

	toggleShowCitationsInClaim: () => void
	toggleShowCommentaryInClaim: () => void
	toggleShowUpdatedAt: () => void
	toggleShowUpdatedBy: () => void
	toggleShowReferencedFigures: () => void
	toggleShowFeedbackButtons: () => void
}

const useChartOptionsStore = create<ChartOptionsState>()(
	persist(
		(set) => ({
			showCitationsInClaim: false,
			showCommentaryInClaim: false,
			showUpdatedAt: false,
			showUpdatedBy: false,
			showReferencedFigures: false,
			showFeedbackButtons: true,

			setShowCitationsInClaim: (value) => set({ showCitationsInClaim: value }),
			setShowCommentaryInClaim: (value) => set({ showCommentaryInClaim: value }),
			setShowUpdatedAt: (value) => set({ showUpdatedAt: value }),
			setShowUpdatedBy: (value) => set({ showUpdatedBy: value }),
			setShowReferencedFigures: (value) => set({ showReferencedFigures: value }),
			setShowFeedbackButtons: (value) => set({ showFeedbackButtons: value }),

			toggleShowCitationsInClaim: () =>
				set((state) => ({ showCitationsInClaim: !state.showCitationsInClaim })),
			toggleShowCommentaryInClaim: () =>
				set((state) => ({ showCommentaryInClaim: !state.showCommentaryInClaim })),
			toggleShowUpdatedAt: () => set((state) => ({ showUpdatedAt: !state.showUpdatedAt })),
			toggleShowUpdatedBy: () => set((state) => ({ showUpdatedBy: !state.showUpdatedBy })),
			toggleShowReferencedFigures: () =>
				set((state) => ({ showReferencedFigures: !state.showReferencedFigures })),
			toggleShowFeedbackButtons: () =>
				set((state) => ({ showFeedbackButtons: !state.showFeedbackButtons })),
		}),
		{
			name: "chart-options", // name of the item in localStorage
			storage: createJSONStorage(() => localStorage),
		},
	),
)

export default useChartOptionsStore
