import { chartsApi } from "@/api/chartsApi"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

export function useInvalidityChartData(projectId: string, chartId: string) {
	const { data, isLoading, isError } = useQuery({
		queryKey: QUERY_KEYS.project.invalidity.chartMetadata(projectId, chartId),
		queryFn: async () => {
			return chartsApi.getInvalidityChart(chartId)
		},
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		data,
		isLoading,
		isError,
	}
}

export default useInvalidityChartData
