import { api } from "@/api"
import { useClearFeedbackDataByID } from "@/features/charts/hooks/useFeedbackData"
import { useAppStateStore } from "@/store"
import { useIsAddPriorArtRunning } from "@/store/processStore"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"
import { nanoid } from "nanoid"
import { useDeletePriorArt } from "./useDeletePriorArt"

export function useProjectPriorArtDocuments(projectId: string) {
	const { deletePriorArt } = useDeletePriorArt()
	const { addErrorMessage, addLoadingMessage, removeLoadingMessage, addSuccessMessage } =
		useAppStateStore()
	const isProcessing = useIsAddPriorArtRunning(projectId)
	const clearFeedbackChartDataForReferences = useClearFeedbackDataByID()

	const {
		data: priorArtDocuments = [],
		isLoading,
		error,
	} = useQuery({
		queryKey: QUERY_KEYS.project.priorArt(projectId),
		queryFn: () => api.getProjectPriorArtDocuments(projectId as string),
		enabled: !!projectId,
		refetchInterval: isProcessing ? 10000 : 60000, // refetch every 10 seconds if processing new prior art
		...DEFAULT_QUERY_OPTIONS,
	})

	// Wrap the base mutation with project-specific params
	const deleteProjectDocuments = async (documentIds: string[]) => {
		const key = nanoid()
		try {
			const message = `Removing ${documentIds.length} document(s) from project`
			addLoadingMessage(message, key)

			await deletePriorArt.mutateAsync({
				projectId,
				documentIds,
			})

			// clearFeedbackChartDataForReferences(projectId, documentIds)

			removeLoadingMessage(key)
			addSuccessMessage(`Successfully deleted ${documentIds.length} document(s).`)
		} catch (error) {
			removeLoadingMessage(key)
			addErrorMessage(
				"An unexpected error occurred while deleting documents. Please try again.",
			)
			throw error // Re-throw to let the mutation error handling take care of it
		}
	}

	return {
		priorArtDocuments: priorArtDocuments,
		priorArtIds: priorArtDocuments.map((document) => document.documentId),
		isLoading,
		error,
		deleteDocuments: deleteProjectDocuments,
	}
}

export default useProjectPriorArtDocuments
