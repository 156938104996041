/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { api } from "@/api"
import { usePortfolioMetadata, useProjectMetadata } from "@/hooks"
import useApi from "@/hooks/useApi"
import { useAppStateStore } from "@/store"
import { type ApiResponse, ParentType } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils"
import { useMutation, useQueryClient } from "@tanstack/react-query"

/**
 * @description Hook for handling cover pleading (summary, section 112, etc.) operations
 * @returns {object} - The cover pleading operations
 */
const useCoverPleading = () => {
	const queryClient = useQueryClient()
	const { handleError } = useApi()
	const { addErrorMessage } = useAppStateStore()
	const { subjectId, projectName } = useProjectMetadata()
	const { portfolioName } = usePortfolioMetadata()

	const getCoverExport = async (
		projectId: string,
		portfolioId: string,
	): Promise<ApiResponse> => {
		try {
			const response: any = await api.getCoverExport(projectId, portfolioId)

			const downloadFile = async (url: string) => {
				const fileResponse = await fetch(url)
				if (!fileResponse.ok) throw new Error("Failed to download the file")
				const fileBlob = await fileResponse.blob()
				const localUrl = window.URL.createObjectURL(fileBlob)
				const link = document.createElement("a")
				link.href = localUrl
				const fileName = projectId
					? projectName?.replace(/[\s']/g, "")
					: portfolioName?.replace(/[\s']/g, "")
				link.setAttribute("download", `${fileName}_Summary.docx`)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				window.URL.revokeObjectURL(localUrl)
			}

			if (response.url) {
				await downloadFile(response.url)
			} else {
				if (process.env.NODE_ENV !== "production") {
					console.error("No download URL found")
				}
				addErrorMessage("Error downloading file. Try again later.")
				return
			}

			return { success: true, data: response as any, status: 200 }
		} catch (error) {
			addErrorMessage("Error downloading file. Try again later.")
			return handleError(error, "Error fetching project summary export")
		}
	}

	const generateSummariesMutation = useMutation({
		mutationKey: MUTATION_KEYS.cover.generate(),
		mutationFn: async ({
			documentIds,
			id,
			parent,
			temperature,
		}: {
			documentIds: string[]
			id: string
			parent: ParentType
			temperature: number
		}) => {
			// Process references in chunks of 10
			for (let i = 0; i < documentIds.length; i += 10) {
				const chunk = documentIds.slice(i, i + 10)
				await api.generatePriorArtSummaries(chunk, id, parent, temperature)
			}
		},
		onError: (error) => {
			console.error("Error generating reference summaries:", error)
		},
		onSuccess: (_, variables) => {
			if (variables.parent === ParentType.PROJECT) {
				queryClient.invalidateQueries({
					queryKey: QUERY_KEYS.project.priorArt(variables.id),
				})
			} else if (variables.parent === ParentType.PORTFOLIO) {
				queryClient.invalidateQueries({
					queryKey: QUERY_KEYS.portfolio.priorArt(variables.id),
				})
			}
		},
	})

	return {
		getCoverExport,
		generateSummaries: generateSummariesMutation.mutate,
		isGeneratingSummaries: generateSummariesMutation.isPending,
	}
}

export default useCoverPleading
