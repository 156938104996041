/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, SidebarPage } from "@/components"
import DocumentDetailLayout from "@/components/documents/DocumentDetailLayout"
import { useFullDocument } from "@/hooks"
import type React from "react"
import { useParams } from "react-router-dom"
import UserDocumentPageHeader from "./components/UserDocumentPageHeader"

/**
 * @description User document page, displays selected document details
 */
const UserDocumentPage: React.FC = () => {
	const { documentId } = useParams()
	const { document, isLoading, isError } = useFullDocument(documentId)
	const pageName = document?.document.title || "Untitled"

	return (
		<SidebarPage page={pageName}>
			<UserDocumentPageHeader name={document?.document.title || "Untitled"} />
			<div className="flex justify-between items-center gap-2">
				{isLoading ? (
					<Loader />
				) : isError ? (
					<div>Error loading document</div>
				) : (
					<DocumentDetailLayout document={document} />
				)}
			</div>
		</SidebarPage>
	)
}

export default UserDocumentPage
