/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ProjectHeader, SidebarPage } from "@/components"
import { usePortfolioMetadata, useProjectMetadata } from "@/hooks"
import { PAGE_DISPLAY_NAMES, type Pages } from "@/types/pages"
import type React from "react"
import type { ReactNode } from "react"

/**
 * @description The project page component
 * @param {ReactNode} children - The children of the component
 */
interface ProjectPageProps {
	pageName: Pages
	children: ReactNode
}

const ProjectPage: React.FC<ProjectPageProps> = ({ children, pageName }) => {
	const { projectName } = useProjectMetadata()
	const { portfolioName } = usePortfolioMetadata()
	const name = portfolioName || projectName
	return (
		<SidebarPage page={`${name} - ${PAGE_DISPLAY_NAMES[pageName]}`}>
			<ProjectHeader />
			{children}
		</SidebarPage>
	)
}

export default ProjectPage
