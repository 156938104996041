import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import type { ProjectMetadata } from "@/types"
import { invalidateProjectRelatedQueries } from "@/utils/query/invalidation"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { useMutation, useQueryClient } from "@tanstack/react-query"

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useRenamePortfolio = () => {
	const queryClient = useQueryClient()
	const { handleError } = useApi()
	const { portfolioId } = useParentContext()

	const renamePortfolioMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.rename(),
		mutationFn: ({ name }: { name: string }) => {
			const payload: { [key: string]: any } = {
				portfolio_id: portfolioId,
				name: name,
			}

			return api.updatePortfolioDetails(portfolioId, payload)
		},
		onMutate: async ({ name }) => {
			// Cancel any outgoing refetches
			await queryClient.cancelQueries({
				queryKey: QUERY_KEYS.portfolio.metadata(portfolioId),
			})

			// Snapshot the previous value
			const previousMetadata = queryClient.getQueryData<ProjectMetadata>(
				QUERY_KEYS.portfolio.metadata(portfolioId),
			)

			// Optimistically update the metadata
			queryClient.setQueryData<ProjectMetadata>(
				QUERY_KEYS.portfolio.metadata(portfolioId),
				(old) => (old ? { ...old, name } : old),
			)

			// Return context with the snapshotted value
			return { previousMetadata }
		},
		onError: (error, _variables, context) => {
			// Revert to the previous value on error
			if (context?.previousMetadata) {
				queryClient.setQueryData(
					QUERY_KEYS.portfolio.metadata(portfolioId),
					context.previousMetadata,
				)
			}
			handleError(error, "Error updating project details")
		},
		onSuccess: (_data, _variables) => {
			invalidateProjectRelatedQueries(queryClient, {
				portfolioId: portfolioId,
			})
		},
	})

	return {
		renamePortfolio: renamePortfolioMutation.mutate,
		isPending: renamePortfolioMutation.isPending,
	}
}

export default useRenamePortfolio
