import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import { QUERY_KEYS } from "@/utils"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useAuthInfo } from "@propelauth/react"
import { useQuery } from "@tanstack/react-query"

export function useProjectMetadata() {
	const { user } = useAuthInfo()
	const { projectId } = useParentContext()

	const projectMetadataQuery = useQuery({
		queryKey: QUERY_KEYS.project.metadata(projectId),
		queryFn: () => api.getProjectMetadata(projectId as string),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const priorityDate = projectMetadataQuery.data?.priorityDate // todo: fallback rto subject
	const _isProjectOwner = projectMetadataQuery.data?.createdBy === user?.userId

	return {
		projectMetadata: projectMetadataQuery.data,
		isLoading: projectMetadataQuery.isLoading,
		isFetching: projectMetadataQuery.isFetching,
		isError: projectMetadataQuery.isError,
		refetchProjectMetadata: projectMetadataQuery.refetch,
		priorityDate,
		subjectId: projectMetadataQuery.data?.subjectId ?? "",
		projectCreatedBy: projectMetadataQuery.data?.createdBy,
		projectName: projectMetadataQuery.data?.name,
		projectType: projectMetadataQuery.data?.type,
		projectBudget: projectMetadataQuery.data?.budget,
	}
}

export default useProjectMetadata
