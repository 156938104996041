import { useParentContext } from "@/context/ParentContext"
import type { ProjectDocumentMetadata } from "@/types"
import useNavigationState from "../useNavigationState"
import { usePortfolioPriorArtDocuments } from "./usePortfolioPriorArtDocuments"
import { useProjectPriorArtDocuments } from "./useProjectPriorArtDocuments"

export interface BasePriorArtHook {
	priorArtDocuments: ProjectDocumentMetadata[]
	priorArtIds: string[]
	isLoading: boolean
	error?: Error
	deletePriorArt: (ids: string[]) => Promise<void>
}

export function usePriorArt(): BasePriorArtHook {
	const { projectId, portfolioId } = useParentContext()
	const { isPortfolioPage } = useNavigationState()

	const projectRefs = useProjectPriorArtDocuments(projectId || "")
	const portfolioRefs = usePortfolioPriorArtDocuments(portfolioId || "")

	const projectHook: BasePriorArtHook = {
		priorArtDocuments: projectRefs.priorArtDocuments,
		priorArtIds: projectRefs.priorArtIds.filter((id) => id !== undefined),
		isLoading: projectRefs.isLoading,
		error: projectRefs.error || undefined,
		deletePriorArt: projectRefs.deleteDocuments,
	}

	const portfolioHook: BasePriorArtHook = {
		priorArtDocuments: portfolioRefs.priorArtDocuments,
		priorArtIds: portfolioRefs.priorArtIds.filter((id) => id !== undefined),
		isLoading: portfolioRefs.isLoading,
		error: portfolioRefs.error || undefined,
		deletePriorArt: portfolioRefs.deleteDocuments,
	}

	return isPortfolioPage ? portfolioHook : projectHook
}
