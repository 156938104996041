import { TableCellCondensed, TableRow } from "@/components/ui/table"
import { ChartColor } from "@/types"
import type { PortfolioMatrixRow } from "@/types/project"
import { Cross2Icon } from "@radix-ui/react-icons"
import type React from "react"
interface PortfolioMatrixRowProps {
	row: PortfolioMatrixRow
	onCellClick: (subjectId: string, chartId: string) => void
}

export const PortfolioMatrixRowComponent: React.FC<PortfolioMatrixRowProps> = ({
	row,
	onCellClick,
}) => {
	return (
		<TableRow>
			<TableCellCondensed className="sticky left-0 bg-background font-medium whitespace-nowrap">
				<div
					className="flex items-center gap-2 cursor-pointer"
					onClick={() => onCellClick(row.subject.documentId, null)}
				>
					{row.subject.nickname}
				</div>
			</TableCellCondensed>
			{row.cells.map((cell) => (
				<TableCellCondensed
					key={cell.documentIds[0]}
					className="p-2 cursor-pointer shrink-0"
					style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}
					onClick={() => {
						onCellClick(row.subject.documentId, cell.chartId)
					}}
				>
					<div
						className="h-7 rounded mx-auto text-center flex items-center justify-center"
						style={{
							backgroundColor: adjustColorShade(cell?.color, cell?.percentage, row.cells),
						}}
					>
						{!cell || cell.color === ChartColor.GRAY ? (
							<Cross2Icon className="w-4 h-4 text-gray-500" />
						) : (
							<div className="text-xs font-medium text-black" />
						)}
					</div>
				</TableCellCondensed>
			))}
		</TableRow>
	)
}

// Utility function for adjusting color shade
const adjustColorShade = (
	baseColor: string,
	percentage: number,
	cells: PortfolioMatrixRow["cells"],
): string => {
	if (!baseColor || baseColor === ChartColor.GRAY) return ChartColor.GRAY
	if (baseColor === "NONE") return "transparent"

	// Find all percentages for this color
	const allPercentages = cells
		.filter((art) => art?.color === baseColor)
		.map((art) => art.percentage)

	// Calculate relative position
	const min = Math.min(...allPercentages)
	const max = Math.max(...allPercentages)
	const range = max - min

	// Convert to relative scale (0.4 to 1.0)
	// If range is 0, use middle value (0.7)
	const relativeScale = range === 0 ? 0.7 : 0.4 + ((percentage - min) / range) * 0.6

	// For hex colors
	if (baseColor.startsWith("#")) {
		const r = Number.parseInt(baseColor.slice(1, 3), 16)
		const g = Number.parseInt(baseColor.slice(3, 5), 16)
		const b = Number.parseInt(baseColor.slice(5, 7), 16)

		const newR = Math.round(r + (255 - r) * (1 - relativeScale))
		const newG = Math.round(g + (255 - g) * (1 - relativeScale))
		const newB = Math.round(b + (255 - b) * (1 - relativeScale))

		return `rgb(${newR}, ${newG}, ${newB})`
	}

	return baseColor
}

export default PortfolioMatrixRowComponent
