import { api } from "@/api"
import useAppStateStore from "@/store/appStateStore"
import { useIsAddPriorArtRunning } from "@/store/processStore"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"
import { nanoid } from "nanoid"
import { useDeletePriorArt } from "./useDeletePriorArt"

export function usePortfolioPriorArtDocuments(portfolioId: string) {
	const { deletePriorArt } = useDeletePriorArt()
	const { addErrorMessage, addLoadingMessage, removeLoadingMessage, addSuccessMessage } =
		useAppStateStore()

	const isProcessing = useIsAddPriorArtRunning(null, portfolioId)

	const {
		data: priorArtDocuments = [],
		isLoading,
		error,
	} = useQuery({
		queryKey: QUERY_KEYS.portfolio.priorArt(portfolioId),
		queryFn: () => api.getPortfolioPriorArtRows(portfolioId),
		enabled: !!portfolioId,
		refetchInterval: isProcessing ? 5000 : false, // refetch every 5 seconds if processing new prior art
		...DEFAULT_QUERY_OPTIONS,
	})

	const deletePortfolioDocuments = async (documentIds: string[]) => {
		const key = nanoid()
		try {
			const message = `Removing ${documentIds.length} document(s) from portfolio`
			addLoadingMessage(message, key)

			await deletePriorArt.mutateAsync({
				portfolioId,
				documentIds,
			})

			removeLoadingMessage(key)
			addSuccessMessage(`Successfully deleted ${documentIds.length} document(s).`)
		} catch (error) {
			removeLoadingMessage(key)
			addErrorMessage(
				"An unexpected error occurred while deleting documents. Please try again.",
			)
			throw error // Re-throw to let the mutation error handling take care of it
		}
	}

	return {
		priorArtDocuments: priorArtDocuments,
		priorArtIds: priorArtDocuments.map((document) => document.documentId),
		isLoading,
		error,
		deleteDocuments: deletePortfolioDocuments,
	}
}

export default usePortfolioPriorArtDocuments
