import { api } from "@/api"
import { ParentProvider } from "@/context/ParentContext"
import { useProjectStore } from "@/store"
import { useQueryClient } from "@tanstack/react-query"
import type React from "react"
import { useEffect, useState } from "react"
import { Navigate, Outlet, useParams } from "react-router-dom"

export const ProjectRoute: React.FC = () => {
	const queryClient = useQueryClient()
	const { projectId, portfolioId } = useParams<{
		projectId: string
		portfolioId: string
	}>()
	const { resetProjectStore } = useProjectStore()
	const [isLoading, setIsLoading] = useState(true)
	const [hasAccess, setHasAccess] = useState(false)

	useEffect(() => {
		let isMounted = true
		resetProjectStore()

		// Clear the project query cache
		if (projectId) {
			queryClient.invalidateQueries({
				queryKey: ["project", projectId],
				exact: false, // ensures all queries that start with prefix are removed
			})
		}

		async function checkAccess() {
			try {
				if (!projectId) {
					setIsLoading(false)
					return
				}
				const hasPermission = await api.getUserProjectAccessCheck(projectId)
				if (isMounted) {
					setHasAccess(hasPermission)
					setIsLoading(false)
				}
			} catch (error) {
				if (process.env.NODE_ENV !== "production") {
					console.error("Error checking access:", error)
				}
				if (isMounted) {
					setHasAccess(false)
					setIsLoading(false)
				}
			}
		}

		checkAccess()
		return () => {
			isMounted = false
		}
	}, [projectId, resetProjectStore, queryClient])

	if (isLoading) {
		return null
	}

	if (!hasAccess) {
		return <Navigate to={`/project/${projectId}/unauthorized`} replace />
	}

	return (
		<ParentProvider projectId={projectId} portfolioId={portfolioId}>
			<Outlet />
		</ParentProvider>
	)
}
