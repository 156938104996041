import { Loader } from "@/components"
import {
	AdminPageLayout,
	NoAdminAccessMessage,
	OverviewDetailsCard,
	UsageLogTable,
} from "@/features/admin/components"
import { useUserStore } from "@/store"
import { RoleType } from "@/types"
import { PageName } from "@/types/pages"
import { useMemo } from "react"
import { useAdminManagement } from "../hooks/useAdminManagement"

function AdminPage() {
	const { organizationId, role } = useUserStore()
	const { adminOverview, adminProjects, isLoading } = useAdminManagement(organizationId)

	const isAdmin = useMemo(
		() => role === RoleType.ADMIN || role === RoleType.OWNER,
		[role],
	)

	// If the user isn't an admin, render NoAdminAccessMessage.
	if (!isAdmin) {
		return (
			<AdminPageLayout isSuperAdmin={false} page={PageName.AdminOverview}>
				<NoAdminAccessMessage />
			</AdminPageLayout>
		)
	}

	if (isLoading.adminOverview) {
		return (
			<AdminPageLayout isSuperAdmin={false} page={PageName.AdminOverview}>
				<Loader />
			</AdminPageLayout>
		)
	}

	if (!adminOverview) {
		return (
			<AdminPageLayout isSuperAdmin={false} page={PageName.AdminOverview}>
				<p className="text-destructive">Error fetching organization details.</p>
			</AdminPageLayout>
		)
	}

	return (
		<AdminPageLayout isSuperAdmin={false} page={PageName.AdminOverview}>
			<div className="mt-2 flex flex-col gap-4">
				{/* Organization overview details */}
				<OverviewDetailsCard overview={adminOverview} />

				{/* Project-by-project overview */}
				{isLoading.adminProjects ? (
					<Loader />
				) : adminProjects ? (
					<UsageLogTable
						projects={adminProjects.flatMap((project) =>
							"children" in project ? project.children : [project],
						)}
					/>
				) : (
					<p className="text-destructive">Error fetching projects overview.</p>
				)}
			</div>
		</AdminPageLayout>
	)
}

export default AdminPage
