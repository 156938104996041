import type { ParentDocumentMetadata } from "@/types"
import { useMemo } from "react"
import { usePriorArt } from "../prior-art/usePriorArt"
import { useProjectSubject } from "./useProjectSubject"

export function useAllProjectDocuments() {
	const { projectSubject, isLoading: isLoadingProjectSubject } = useProjectSubject()
	const { priorArtDocuments, isLoading: isLoadingPriorArtDocuments } = usePriorArt()

	// Create combined document metadata list
	const allDocumentsMetadata: ParentDocumentMetadata[] = useMemo(
		() => [
			// Add subject document if it exists
			...(projectSubject?.document
				? [
						{
							documentId: projectSubject?.documentId,
							documentRole: "SUBJECT" as const,
							document: projectSubject,
							nickname: projectSubject?.nickname,
							tags: projectSubject?.tags,
							notes: projectSubject?.notes,
							createdAt: projectSubject?.createdAt,
							updatedAt: projectSubject?.updatedAt,
						},
					]
				: []),
			// Add prior art documents
			...(priorArtDocuments ?? []),
		],
		[projectSubject, priorArtDocuments],
	)

	return {
		allDocumentsMetadata,
		isLoading: isLoadingProjectSubject || isLoadingPriorArtDocuments,
	}
}

export default useAllProjectDocuments
