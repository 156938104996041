/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { AddDocumentsModal, AddPatentsModal, DocumentMetadataTable } from "@/components"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { H4 } from "@/components/ui/typography"
import { useParentContext } from "@/context/ParentContext"
import { UnprocessedDocumentsProvider } from "@/context/UnprocessedDocumentsContext"
import {
	useNavigationState,
	usePortfolioPriorArtDocuments,
	useProjectDocuments,
} from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import useDocumentStatus from "@/hooks/useDocumentStatus"
import useUpdateDocumentMetadata from "@/hooks/useUpdateDocumentMetadata"
import useUpdateDocuments from "@/hooks/useUpdateDocuments"
import { useAppStateStore, useAreProcessesPending } from "@/store"
import { useIsAddPriorArtRunning } from "@/store/processStore"
import { DocumentRole, DocumentStatus, conflictingReferenceProcesses } from "@/types"
import type { ParentDocumentMetadata } from "@/types"
import { PlusIcon } from "lucide-react"
import type React from "react"
import { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ProcessReferencesModal, SearchReferencesModal } from "./"

interface ReferencesTableProps {
	inUserDocuments?: boolean
	documents: ParentDocumentMetadata[]
	title?: string
	onRowClick?: (document: ParentDocumentMetadata) => void
}

/**
 * @description Reference details page for invalidity project
 */
const ReferencesTable: React.FC<ReferencesTableProps> = ({
	inUserDocuments = false,
	title,
	onRowClick,
	documents,
}) => {
	// Local state setup
	const [showAddModal, setShowAddModal] = useState(false)
	const [showProcessReferencesModal, setShowProcessReferencesModal] = useState(false)
	const [showUploadModal, setShowUploadModal] = useState(false)
	const [showSearchModal, setShowSearchModal] = useState(false)
	const [_showPatchReferencesModal, setShowPatchReferencesModal] = useState(false)
	const [_showDismissStatusesModal, setShowDismissStatusesModal] = useState(false)
	const [_selectedReferencesToDismiss, setSelectedReferencesToDismiss] = useState<
		ParentDocumentMetadata[]
	>([])
	const [selectedReferences, setSelectedReferences] = useState<ParentDocumentMetadata[]>(
		[],
	)
	const [selectedReferencesToReprocess, setSelectedReferencesToReprocess] = useState<
		ParentDocumentMetadata[]
	>([])
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const [isDeleteSelectedLoading, setIsDeleteSelectedLoading] = useState(false)
	const [isReprocess, setIsReprocess] = useState<boolean>(false)
	// const [isProcessing, setIsProcessing] = useState<boolean>(false)

	const navigate = useNavigate()
	const { updateDocuments } = useUpdateDocuments()
	const { updateDocumentMetadata } = useUpdateDocumentMetadata()
	const { parentType } = useParentContext()
	// const { reprocessDocuments } = useProcessDocuments()
	const { getDocumentName } = useDocumentNaming()
	const { isPortfolioPage } = useNavigationState()

	const { projectId, portfolioId } = useParentContext()
	const { deleteDocuments: deleteProjectDocuments } = useProjectDocuments(projectId)
	const { deleteDocuments: deletePortfolioDocuments } =
		usePortfolioPriorArtDocuments(portfolioId)
	const { addErrorMessage } = useAppStateStore()

	const isProcessing = useIsAddPriorArtRunning(projectId, portfolioId)

	// Block adding documents if recharting or downloading chart is in progress
	const isReferenceDisabled = useAreProcessesPending(
		conflictingReferenceProcesses,
		projectId || null,
		portfolioId || null,
	)

	const { documentStatuses, removeStatuses } = useDocumentStatus(
		portfolioId,
		projectId ? [projectId] : null,
		DocumentRole.PRIOR_ART,
		isProcessing,
		documents,
	)

	// Handle deletions
	const handleDeleteSelected = useCallback(async () => {
		setIsDeleteSelectedLoading(true)
		const referenceIds = selectedReferences.map((document) => document.documentId)
		try {
			await (projectId ? deleteProjectDocuments : deletePortfolioDocuments)(referenceIds)
			removeStatuses(referenceIds)
		} catch (_error) {
			addErrorMessage(
				"An unexpected error occurred while deleting documents. Please try again.",
			)
		} finally {
			setIsDeleteSelectedLoading(false)
		}
	}, [
		projectId,
		selectedReferences,
		deleteProjectDocuments,
		deletePortfolioDocuments,
		removeStatuses,
		addErrorMessage,
	])

	const handleRemovePriorArtFromProject = useCallback(
		async (document: ParentDocumentMetadata) => {
			const refId = document.documentId
			if (refId) {
				await (projectId ? deleteProjectDocuments : deletePortfolioDocuments)([refId])
			}
		},
		[projectId, deleteProjectDocuments, deletePortfolioDocuments],
	)

	const handleSearchReferencesClick = useCallback(() => {
		setShowSearchModal(true)
	}, [])

	const _handlePatchReferencesClick = useCallback(() => {
		setShowPatchReferencesModal((prev) => !prev)
	}, [])

	const handleAddPublishedPatentsClick = useCallback(() => {
		setShowAddModal((prev) => !prev)
	}, [])

	const handleAddOtherDocumentsClick = useCallback(() => {
		setShowUploadModal((prev) => !prev)
	}, [])

	// Hiding dismiss statuses for now
	// const handleDismissReferenceStatuses = useCallback(async () => {
	// 	try {
	// 		const selectedIds = selectedReferencesToDismiss.map(
	// 			(document) => document.documentId,
	// 		)
	// 		await updateDocuments({
	// 			documentIds: selectedIds,
	// 			status: DocumentStatus.PROCESSED,
	// 		})
	// 		setSelectedReferencesToReprocess([])
	// 		setSelectedReferences([])
	// 		setShowDismissStatusesModal(false)
	// 	} catch (_error) {
	// 		addErrorMessage("Failed to update document statuses")
	// 	}
	// }, [selectedReferencesToDismiss, updateDocuments, addErrorMessage])

	// const handleReprocessReferences = useCallback(
	// 	async (mode: CitationPolicy) => {
	// 		setShowProcessReferencesModal(false)
	// 		await reprocessDocuments(selectedReferencesToReprocess, isReprocess, mode)
	// 		setSelectedReferencesToReprocess([])
	// 		setSelectedReferences([])
	// 	},
	// 	[selectedReferencesToReprocess, reprocessDocuments, isReprocess],
	// )

	const handleTagUpdate = useCallback(
		async (
			selectedRows: ParentDocumentMetadata[],
			addTags: string[] | null,
			setTags: string[] | null,
		) => {
			try {
				if (addTags || setTags) {
					const referenceIds = selectedRows.map((row) => row.documentId)
					await updateDocumentMetadata({
						documentIds: referenceIds,
						setTags,
					})
				}
			} catch (_error) {
				addErrorMessage("Failed to update tags")
			}
		},
		[updateDocumentMetadata, addErrorMessage],
	)

	const handleSaveName = useCallback(
		async (document: ParentDocumentMetadata, newName: string, isNickname = true) => {
			try {
				if (isNickname) {
					await updateDocumentMetadata({
						documentIds: [document.documentId],
						nickname: newName,
					})
				} else {
					await updateDocuments({
						documentIds: [document.documentId],
						title: newName,
					})
				}
			} catch (error) {
				addErrorMessage(
					typeof error === "string"
						? error
						: `Failed to update ${isNickname ? "name" : "title"}`,
				)
			}
		},
		[updateDocumentMetadata, updateDocuments, addErrorMessage],
	)

	const handleSaveNote = useCallback(
		async (document: ParentDocumentMetadata, newNote: string) => {
			try {
				await updateDocumentMetadata({
					documentIds: [document.documentId],
					notes: newNote,
				})
			} catch (_error) {
				addErrorMessage("Failed to save note")
			}
		},
		[updateDocumentMetadata, addErrorMessage],
	)

	const handleReprocessClick = useCallback(
		(selectedRows: ParentDocumentMetadata[], isReprocess = true) => {
			setSelectedReferencesToReprocess(selectedRows)
			setIsReprocess(isReprocess)
			setShowProcessReferencesModal(true)
		},
		[],
	)

	const handleDismissSelected = () => {
		setSelectedReferencesToDismiss(
			selectedReferences.filter((ref) => {
				const status = documentStatuses.find(
					([pid, status]) => pid === projectId && status.key === ref.documentId,
				)[1]
				return (
					status?.status === DocumentStatus.RECHART ||
					status?.status === DocumentStatus.REPROCESS
				)
			}),
		)
		setShowDismissStatusesModal(true)
	}

	const handleRowSelection = useCallback((selectedRows: ParentDocumentMetadata[]) => {
		setSelectedReferences(selectedRows)
	}, [])

	const documentNames = useMemo(
		() => selectedReferencesToReprocess?.map((ref) => getDocumentName(ref.documentId)),
		[selectedReferencesToReprocess, getDocumentName],
	)

	const handleSelectProject = useCallback(
		(projectId: string) => {
			navigate(`/portfolio/${portfolioId}/project/${projectId}/subject`)
		},
		[navigate, portfolioId],
	)

	return (
		<>
			{documents?.length > 0 ? (
				<DocumentMetadataTable
					documents={documents}
					onDeleteRow={handleRemovePriorArtFromProject}
					height="calc(100vh - 100px)"
					onRowSelection={handleRowSelection}
					onTagUpdate={handleTagUpdate}
					onDeleteSelected={handleDeleteSelected}
					onSaveName={handleSaveName}
					onSaveNote={handleSaveNote}
					onReprocessSelected={handleReprocessClick}
					onDismissSelected={handleDismissSelected}
					onSelectProject={handleSelectProject}
					enableRowDelete={true}
					isSearch={false}
					isReference={true}
					isDeleteSelectedLoading={isDeleteSelectedLoading}
					title={title}
					inUserDocuments={inUserDocuments}
					onRowClick={onRowClick}
					isProcessing={isProcessing}
				>
					{!inUserDocuments && !isPortfolioPage && (
						<Button
							variant="outline"
							onClick={handleSearchReferencesClick}
							disabled={isReferenceDisabled}
							size="sm"
						>
							Search
						</Button>
					)}
					<DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
						<DropdownMenuTrigger asChild>
							<Button
								size="sm"
								disabled={isReferenceDisabled}
								onClick={() => setDropdownOpen(true)}
							>
								<span className="hidden lg:inline">Add</span>
								<PlusIcon className="w-4 h-4 md:ml-0 lg:ml-2" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							<DropdownMenuItem
								onClick={() => {
									handleAddPublishedPatentsClick()
									setDropdownOpen(false)
								}}
							>
								Add published patents/applications
							</DropdownMenuItem>
							<DropdownMenuItem
								onClick={() => {
									handleAddOtherDocumentsClick()
									setDropdownOpen(false)
								}}
							>
								Upload other documents
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</DocumentMetadataTable>
			) : (
				<div className="flex justify-center items-center flex-col mt-12">
					<H4 className="text-center">This {parentType.toLowerCase()} has no references.</H4>
					<div className="flex gap-2 mt-3">
						{!isPortfolioPage && (
							<Button
								variant="outline"
								onClick={handleSearchReferencesClick}
								disabled={isReferenceDisabled}
								size="sm"
							>
								Search
							</Button>
						)}
						<Button
							variant="outline"
							onClick={handleAddPublishedPatentsClick}
							disabled={isReferenceDisabled}
							size="sm"
						>
							Add by Number
						</Button>
						<Button
							variant="outline"
							onClick={handleAddOtherDocumentsClick}
							disabled={isReferenceDisabled}
							size="sm"
						>
							Upload Files
						</Button>
					</div>
				</div>
			)}

			<AddPatentsModal
				open={showAddModal}
				handleClose={handleAddPublishedPatentsClick}
				isProcessing={isProcessing}
			/>
			<AddDocumentsModal
				open={showUploadModal}
				handleClose={() => setShowUploadModal(false)}
				isProcessing={isProcessing}
			/>
			{!inUserDocuments && (
				<UnprocessedDocumentsProvider>
					<SearchReferencesModal
						open={showSearchModal}
						handleClose={() => setShowSearchModal(false)}
					/>
				</UnprocessedDocumentsProvider>
			)}
			<ProcessReferencesModal
				isOpen={showProcessReferencesModal}
				onOpenChange={setShowProcessReferencesModal}
				onConfirm={() => {}}
				documentNames={documentNames}
				isReprocess={isReprocess}
			/>

			{/* <DismissDocumentStatusModal
				isOpen={showDismissStatusesModal}
				onOpenChange={setShowDismissStatusesModal}
				onConfirm={handleDismissReferenceStatuses}
				documentNames={selectedReferencesToDismiss
					.filter((document) => {
						const status = getDocumentStatus(document)
						return status === DocumentStatus.RECHART || status === DocumentStatus.REPROCESS
					})
					.map((document) => getDocumentName(document.documentId))}
			/> */}
		</>
	)
}

export default ReferencesTable
