import { TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"
import { Tooltip } from "@radix-ui/react-tooltip"

interface ClaimLimitationLabelPillProps {
	label: string
	id: string
	size?: "sm" | "lg"
	className?: string
	onClick?: () => void
}

export const ClaimLimitationLabelPill = ({
	label,
	id,
	size = "sm",
	className,
	onClick,
}: ClaimLimitationLabelPillProps) => {
	return (
		<Tooltip>
			<TooltipTrigger asChild>
				<div
					onClick={onClick}
					className={cn(
						// Base styles
						"inline-block rounded-full border cursor-pointer",
						// Light mode styles
						"border-blue-300 bg-blue-50 text-black",
						// Dark mode styles
						"dark:border-blue-700 dark:bg-blue-950 dark:text-white",
						// Size variants
						size === "sm" ? "px-2 py-0.5 text-xs" : "px-3 py-1 text-sm",
						// Additional classes
						className,
					)}
				>
					{label}
				</div>
			</TooltipTrigger>
			<TooltipContent>View</TooltipContent>
		</Tooltip>
	)
}

export default ClaimLimitationLabelPill
