// services/frontend/src/hooks/useDocumentHandler.ts
import { useParentContext } from "@/context/ParentContext"
import { usePatentDetails } from "@/hooks"
import {
	DocumentRole,
	type PatentNumberOption,
	type UnprocessedDocument,
	UnprocessedDocumentType,
	type UnpublishedPatentEntry,
} from "@/types"
import { nanoid } from "nanoid"
import { useCallback, useMemo, useState } from "react"

const useUnprocessedDocuments = () => {
	const [unprocessedDocuments, setUnprocessedDocuments] = useState<
		UnprocessedDocument[]
	>([])
	const { projectId, portfolioId } = useParentContext()

	// Patent details handling
	const [isLoadingPatentDetails, setIsLoadingPatentDetails] = useState(false)
	const { getPatentDetails } = usePatentDetails()

	const addUnprocessedDocumentToList = (document: UnprocessedDocument) => {
		setUnprocessedDocuments((prev) => {
			const newDocs = [...prev, document]
			return newDocs
		})
	}

	const removeUnprocessedDocument = (documentId: string) => {
		setUnprocessedDocuments((prev) => prev.filter((doc) => doc.id !== documentId))
	}

	// Function to handle adding documents based on type
	const addUnprocessedDocuments = useCallback(
		async (
			documentRole: DocumentRole,
			documentType: UnprocessedDocumentType,
			options: {
				files?: File[]
				patentDetails?: PatentNumberOption[]
				unpublishedPatent?: UnpublishedPatentEntry
				patentNumbers?: string[]
			} = {},
		) => {
			const { files, patentDetails, unpublishedPatent, patentNumbers } = options
			try {
				const createUnprocessedDocument = (
					type: UnprocessedDocumentType,
					content: any,
				): UnprocessedDocument => {
					// If the document is a published patent/application, initialize selectedKindCode
					if (
						type === UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION &&
						content.details
					) {
						// Create a shallow copy in case you want to avoid mutating the original content object
						const newContent = { ...content }
						const kindCodes = Object.keys(newContent.details)
						if (kindCodes.length > 0) {
							newContent.selectedKindCode = kindCodes[0]
						}
						// Use the updated content
						content = newContent
					}

					return {
						id: nanoid(),
						type,
						role: documentRole,
						...(type === UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION
							? { patentDetails: content }
							: type === UnprocessedDocumentType.UNPUBLISHED_PATENT
								? { unpublishedPatent: content }
								: { file: content }),
					}
				}

				switch (documentType) {
					case UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION:
						if (patentNumbers) {
							setIsLoadingPatentDetails(true)
							const response = await getPatentDetails(
								patentNumbers,
								portfolioId ?? projectId,
								false,
							)

							if (response.error) {
								console.error("Error fetching patent details:", response.error)
							} else {
								for (const detail of response.data) {
									const document = createUnprocessedDocument(documentType!, {
										...detail,
										inventors: Array.isArray(detail.inventors)
											? detail.inventors
											: [detail.inventors],
									})
									addUnprocessedDocumentToList(document)
								}
							}
							setIsLoadingPatentDetails(false)
						} else {
							for (const detail of patentDetails ?? []) {
								addUnprocessedDocumentToList(createUnprocessedDocument(documentType!, detail))
							}
						}
						break
					case UnprocessedDocumentType.UNPUBLISHED_PATENT:
						addUnprocessedDocumentToList(
							createUnprocessedDocument(documentType!, unpublishedPatent),
						)
						break
					case UnprocessedDocumentType.REFERENCE:
						for (const file of files ?? []) {
							addUnprocessedDocumentToList(createUnprocessedDocument(documentType!, file))
						}
						break
					case UnprocessedDocumentType.OFFICE_ACTION:
						for (const file of files ?? []) {
							addUnprocessedDocumentToList(createUnprocessedDocument(documentType!, file))
						}
						break
					default:
						for (const file of files ?? []) {
							addUnprocessedDocumentToList(createUnprocessedDocument(documentType!, file))
						}
				}
			} catch (error) {
				console.error("Error adding document:", error)
			}
		},
		[addUnprocessedDocumentToList],
	)

	// Reset all state
	const resetAll = () => {
		setUnprocessedDocuments([])
		setIsLoadingPatentDetails(false)
	}

	const unprocessedPriorArtDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.PRIOR_ART)
	}, [unprocessedDocuments])

	const unprocessedSubjectDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.SUBJECT)
	}, [unprocessedDocuments, unprocessedDocuments.length])

	const unprocessedContextDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.CONTEXT)
	}, [unprocessedDocuments])

	const unprocessedOfficeActionDocuments = useMemo(() => {
		return unprocessedDocuments.filter((doc) => doc.role === DocumentRole.OFFICE_ACTION)
	}, [unprocessedDocuments])

	// Update selectedKindCode on UnprocessedDocument
	const updateSelectedKindCode = (documentId: string, kindCode: string) => {
		setUnprocessedDocuments((prevDocs) => {
			const newDocs = prevDocs.map((doc) => {
				const updated = doc.id === documentId ? { ...doc, selectedKindCode: kindCode } : doc
				return updated
			})
			return newDocs
		})
	}
	return {
		unprocessedDocuments,
		setUnprocessedDocuments,
		addUnprocessedDocuments,
		removeUnprocessedDocument,
		isLoadingPatentDetails,
		resetAll,
		unprocessedPriorArtDocuments,
		unprocessedSubjectDocuments,
		unprocessedContextDocuments,
		unprocessedOfficeActionDocuments,
		// Update details on unprocessed document (kind codes, dates)
		updateSelectedKindCode,
	}
}

export default useUnprocessedDocuments
