import useUnprocessedDocuments from "@/hooks/useUnprocessedDocuments"
import type React from "react"
import { createContext, useContext } from "react"

export interface UnprocessedDocumentsContextValue
	extends ReturnType<typeof useUnprocessedDocuments> {}

const UnprocessedDocumentsContext = createContext<
	UnprocessedDocumentsContextValue | undefined
>(undefined)

export const UnprocessedDocumentsProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const unprocessedDocumentsState = useUnprocessedDocuments()
	return (
		<UnprocessedDocumentsContext.Provider value={unprocessedDocumentsState}>
			{children}
		</UnprocessedDocumentsContext.Provider>
	)
}

export function useUnprocessedDocumentsContext() {
	const context = useContext(UnprocessedDocumentsContext)
	if (!context) {
		throw new Error(
			"useUnprocessedDocumentsContext must be used within a UnprocessedDocumentsProvider",
		)
	}
	return context
}
