/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DocumentViewer, Loader } from "@/components"
import { LoadingButton } from "@/components/ui/loading-button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { H4 } from "@/components/ui/typography"
import { useParentContext } from "@/context/ParentContext"
import { usePriorArt } from "@/hooks/prior-art/usePriorArt"
import { ParentType } from "@/types"
import { PageName } from "@/types/pages"
import { useMemo, useState } from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import DocumentSummary from "./components/DocumentSummary"
import useCoverPleading from "./hooks/useCoverPleading"

const CoverPleadingPage: React.FC = () => {
	const { projectId, portfolioId, parentType } = useParentContext()
	const { generateSummaries, getCoverExport, isGeneratingSummaries } = useCoverPleading()

	const { priorArtDocuments, isLoading: isLoadingPriorArt } = usePriorArt()

	// Project or Portfolio values
	const parentId = projectId ? projectId : portfolioId
	const isLoading = isLoadingPriorArt || isGeneratingSummaries
	const allDocumentIds = priorArtDocuments?.map(
		(priorArtDocuments) => priorArtDocuments.documentId,
	)

	// States
	const [downloadIsLoading, setDownloadIsLoading] = useState(false)

	// const [isAnalyzingSection112, setIsAnalyzingSection112] = useState(false)
	const [showDocumentViewer, setShowDocumentViewer] = useState(false)
	const [documentViewerDocumentId, setDocumentViewerDocumentId] = useState<
		string | null
	>(null)
	const [documentViewerChunkId, setDocumentViewerChunkId] = useState<string | null>(null)
	const [documentViewerCitationText, setDocumentViewerCitationText] = useState<
		string | null
	>(null)

	const handleDownloadCoverPleading = async () => {
		setDownloadIsLoading(true)
		try {
			await getCoverExport(projectId || "", portfolioId || "")
		} finally {
			setDownloadIsLoading(false)
		}
	}

	const handleGenerateSummaries = async () => {
		await generateSummaries({
			documentIds: allDocumentIds.filter((id) => id !== undefined),
			id: parentId || "",
			parent: projectId ? ParentType.PROJECT : ParentType.PORTFOLIO,
			temperature: 0.0,
		})
	}

	const allDocumentsLackCover = useMemo(() => {
		return priorArtDocuments.every(
			(doc) => !doc.documentCover || doc.documentCover.length === 0,
		)
	}, [priorArtDocuments])

	const downloadDisabled = isLoading || allDocumentsLackCover || downloadIsLoading

	return (
		<ProjectPage pageName={projectId ? PageName.ProjectCover : PageName.PortfolioCover}>
			<div className="flex flex-col">
				{priorArtDocuments?.length > 0 ? (
					<div>
						<div className="border-b p-2 flex justify-between items-center">
							<div className="flex gap-2">
								<LoadingButton
									className="h-9"
									variant="outline"
									onClick={handleGenerateSummaries}
									loading={isLoading}
									size="sm"
								>
									{isLoading ? "Generating..." : "Generate Summaries"}
								</LoadingButton>

								<LoadingButton
									className="h-9"
									onClick={() => handleDownloadCoverPleading()}
									loading={downloadIsLoading}
									disabled={downloadDisabled}
									size="sm"
								>
									{downloadIsLoading ? "Downloading..." : "Download"}
								</LoadingButton>
							</div>
						</div>

						{isLoading ? (
							<div className="mt-10">
								<Loader />
							</div>
						) : (
							<div className="flex-1">
								<ScrollArea className="h-[calc(100vh-105px)]">
									<div className="grid grid-cols-1 gap-2 p-2">
										{priorArtDocuments.map((parentToDocument) => (
											<DocumentSummary
												key={parentToDocument.documentId}
												parentToDocument={parentToDocument}
												parentType={projectId ? ParentType.PROJECT : ParentType.PORTFOLIO}
												setDocumentViewerChunkId={setDocumentViewerChunkId}
												setShowDocumentViewer={setShowDocumentViewer}
												setDocumentViewerDocumentId={setDocumentViewerDocumentId}
												setDocumentViewerCitationText={setDocumentViewerCitationText}
											/>
										))}
									</div>
								</ScrollArea>
							</div>
						)}
					</div>
				) : (
					<div className="flex justify-center items-center flex-col mt-12">
						<H4>This {parentType.toLowerCase()} has no references.</H4>
					</div>
				)}
			</div>
			{documentViewerDocumentId && (
				<DocumentViewer
					documentId={documentViewerDocumentId}
					selectedDocumentChunkId={documentViewerChunkId || undefined}
					open={showDocumentViewer}
					handleClose={() => setShowDocumentViewer(false)}
					citationText={documentViewerCitationText || undefined}
				/>
			)}
		</ProjectPage>
	)
}

export default CoverPleadingPage
