import { api } from "@/api"
import { CitationFormat } from "@/types"
import { create } from "zustand"

interface ProjectStore {
	showAssertedClaims: boolean
	draftingClaimID: string | null

	selectedCitationFormat: CitationFormat

	// citation format
	updateSelectedCitationFormat: (citationFormat: CitationFormat) => void
	// drafting claim
	updateDraftingClaimID: (claimID: string | null) => void
	updateShowAssertedClaims: (showAssertedClaims: boolean) => void
	resetProjectStore: () => void

	personalProjectId: string | null
	isLoadingPersonalProject: boolean
	personalProjectError: Error | null

	// Actions
	initializePersonalProject: () => Promise<void>
	setPersonalProjectId: (id: string | null) => void
}

const initialState: Omit<
	ProjectStore,
	| "updateDraftingClaimID"
	| "updateSelectedCitationFormat"
	| "updateShowAssertedClaims"
	| "resetProjectStore"
	| "initializePersonalProject"
	| "setPersonalProjectId"
> = {
	draftingClaimID: null,
	showAssertedClaims: false,
	selectedCitationFormat: CitationFormat.DEFAULT,
	personalProjectId: null,
	isLoadingPersonalProject: false,
	personalProjectError: null,
}

const useProjectStore = create<ProjectStore>((set) => ({
	...initialState,

	updateSelectedCitationFormat: (citationFormat: CitationFormat) =>
		set(() => ({ selectedCitationFormat: citationFormat })),

	updateDraftingClaimID: (claimID: string | null) =>
		set(() => ({ draftingClaimID: claimID })),

	updateShowAssertedClaims: (showAssertedClaims: boolean) =>
		set(() => ({ showAssertedClaims })),

	resetProjectStore: () => set(() => ({ ...initialState })),

	initializePersonalProject: async () => {
		set({ isLoadingPersonalProject: true, personalProjectError: null })
		try {
			const projectId = await api.getUserPersonalProjectId()
			set({ personalProjectId: projectId })
		} catch (error) {
			set({ personalProjectError: error as Error })
			if (process.env.NODE_ENV !== "production") {
				console.error("Error fetching personal project ID:", error)
			}
		} finally {
			set({ isLoadingPersonalProject: false })
		}
	},

	setPersonalProjectId: (id: string | null) => set({ personalProjectId: id }),
}))

export default useProjectStore
