import type { PatentSearchRequest } from "@/types"
import { apiClient } from "./client"

export const searchApi = {
	async search(projectId: string, options: { [key: string]: any } = {}) {
		const payload: PatentSearchRequest = {
			project_id: projectId,
			max_results: 10,
			limitation_ids: [],
			keywords: [],
			claim_type: "Independent Claims",
			dedupe_family_id: true,
			search_strategy: "Regular",
			...options,
		}
		return await apiClient.get("get_prior_art_search", payload)
	},
}
