import { api } from "@/api"
import { ParentProvider } from "@/context/ParentContext"
import { useQueryClient } from "@tanstack/react-query"
import type React from "react"
import { useEffect, useState } from "react"
import { Navigate, Outlet, useParams } from "react-router-dom"

export const PortfolioRoute: React.FC = () => {
	const queryClient = useQueryClient()
	const { portfolioId, projectId } = useParams<{
		portfolioId: string
		projectId: string
	}>()
	const [isLoading, setIsLoading] = useState(true)
	const [hasAccess, setHasAccess] = useState(false)

	useEffect(() => {
		let isMounted = true

		// Clear cache for previous portfolio data
		if (portfolioId) {
			queryClient.invalidateQueries({
				queryKey: ["portfolio", portfolioId],
				exact: false, // ensures all queries that start with prefix are removed
			})
		}

		async function checkAccess() {
			try {
				if (!portfolioId) {
					setIsLoading(false)
					return
				}
				const hasPermission = await api.getUserPortfolioAccessCheck(portfolioId)
				if (isMounted) {
					setHasAccess(hasPermission)
					setIsLoading(false)
				}
			} catch (error) {
				if (process.env.NODE_ENV !== "production") {
					console.error("Error checking access:", error)
				}
				if (isMounted) {
					setHasAccess(false)
					setIsLoading(false)
				}
			}
		}

		checkAccess()
		return () => {
			isMounted = false
		}
	}, [portfolioId, queryClient])

	if (isLoading) {
		return null
	}

	if (!hasAccess) {
		return <Navigate to={`/portfolio/${portfolioId}/unauthorized`} replace />
	}

	return (
		<ParentProvider portfolioId={portfolioId} projectId={projectId}>
			<Outlet />
		</ParentProvider>
	)
}
