/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { CheckboxWithText } from "@/components/ui/checkbox"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { LoadingButton } from "@/components/ui/loading-button"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select"
import { useChunkToLimitationUpdates } from "@/features/charts/hooks"
import { useClaims } from "@/hooks"
import type React from "react"
import { useState } from "react"

interface CopyCitationsModalProps {
	open: boolean
	handleClose: () => void
	documentId: string
	claimLimitationId: string
	claimLimitationLabel: string
}

/**
 * @description Prune invalidity charts by citation score
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} fetchElementChartPageData - Function to fetch the element chart page data.
 */
const CopyCitationsModal: React.FC<CopyCitationsModalProps> = ({
	open,
	handleClose,
	documentId,
	claimLimitationId,
	claimLimitationLabel,
}) => {
	const { copyChunkToLimitations, isCopyingChunkToLimitations } =
		useChunkToLimitationUpdates()
	const { collapsedLimitations } = useClaims()

	const [isLoading, setIsLoading] = useState(false)
	const [sourceLimitationId, setSourceLimitationId] = useState<string | null>(null)
	const [replace, setReplace] = useState(false)

	const resetModal = () => {
		setSourceLimitationId(null)
		setReplace(false)
		setIsLoading(false)
	}

	const onClose = () => {
		resetModal()
		handleClose()
	}

	const handleSave = async () => {
		setIsLoading(true)
		try {
			await copyChunkToLimitations({
				targetLimitationId: claimLimitationId,
				sourceLimitationId: sourceLimitationId,
				documentId: documentId,
				replace: replace,
			})
		} catch (error) {
			console.error("Error copying invalidity citations:", error)
		} finally {
			setIsLoading(false)
			onClose()
		}
	}

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>{`Copy citations to claim ${claimLimitationLabel}`}</DialogTitle>
					<DialogDescription>
						{`Copy citations from another claim to claim ${claimLimitationLabel}. If replace is checked, claim ${claimLimitationLabel} citations will be overwritten with the source citations. If not, the citations will be appended.`}
					</DialogDescription>
				</DialogHeader>

				<div className="flex items-center gap-2">
					<Select
						value={sourceLimitationId ?? ""}
						onValueChange={(value) => setSourceLimitationId(value)}
						disabled={isLoading}
					>
						<SelectTrigger className="w-[300px]">
							<SelectValue placeholder="Select source claim" />
						</SelectTrigger>
						<SelectContent>
							{collapsedLimitations.map((limitation) => (
								<SelectItem key={limitation.id} value={limitation.id}>
									{limitation.label}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>

				<CheckboxWithText
					label={`Replace claim ${claimLimitationLabel} citations with source citations`}
					checked={replace}
					onCheckedChange={(checked) => setReplace(checked as boolean)}
				/>

				<DialogFooter>
					<div className="flex items-center justify-end gap-2">
						<Button variant="outline" onClick={() => onClose()} disabled={isLoading}>
							Cancel
						</Button>
						<LoadingButton
							variant="default"
							onClick={handleSave}
							loading={isCopyingChunkToLimitations}
							disabled={isCopyingChunkToLimitations}
						>
							{isCopyingChunkToLimitations ? "Applying..." : "Apply"}
						</LoadingButton>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default CopyCitationsModal
