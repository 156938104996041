/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader, SummaryEditor } from "@/components"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import { useParentContext } from "@/context/ParentContext"
import useCoverPleading from "@/features/cover/hooks/useCoverPleading"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { type ParentDocumentMetadata, ParentType } from "@/types"
import type { DocumentCoverSentence } from "@/types/documents"
import { Pencil1Icon } from "@radix-ui/react-icons"
import { RefreshCwIcon } from "lucide-react"
import { type FC, useEffect, useState } from "react"
import type React from "react"
import { useNavigate } from "react-router-dom"
interface DocumentSummaryProps {
	parentToDocument: ParentDocumentMetadata
	parentType: ParentType
	setDocumentViewerChunkId: (chunkId: string) => void
	setShowDocumentViewer: (show: boolean) => void
	setDocumentViewerDocumentId: (documentId: string) => void
	setDocumentViewerCitationText: (citationText: string) => void
}

/**
 * Extracts all quoted segments (text wrapped in "") from a given string.
 * Returns an array of { fullQuote, strippedQuote, index }.
 */
const extractQuotes = (text: string) => {
	const quoteMatches = Array.from(text.matchAll(/"([^"]+)"/g))
	return quoteMatches.map((quoteMatch) => ({
		fullQuote: quoteMatch[0],
		strippedQuote: quoteMatch[1],
		index: quoteMatch.index ?? 0,
	}))
}

// Example: might match things like Smith '123 at 10 etc. or [someCitation]
const CITATION_REGEX =
	/(?:[A-Za-z]+\s+'\d{3}\s+at\s+[^.]+)|(?:\[[^\]]+\](?:,\s*\[[^\]]+\])*)/g

const DocumentSummary: FC<DocumentSummaryProps> = ({
	parentToDocument,
	parentType,
	setDocumentViewerChunkId,
	setShowDocumentViewer,
	setDocumentViewerDocumentId,
	setDocumentViewerCitationText,
}) => {
	const { projectId, portfolioId } = useParentContext()
	const navigate = useNavigate()
	const { generateSummaries, isGeneratingSummaries } = useCoverPleading()
	const { getDocumentPortfolioName, getDocumentName } = useDocumentNaming()

	// Map from stripped quote -> chunkId
	const [quoteToChunkIdMap, setQuoteToChunkIdMap] = useState<Record<string, string>>({})
	const [isEditing, setIsEditing] = useState(false)

	// Build a chunk map for all quoted text in documentCover
	useEffect(() => {
		if (Array.isArray(parentToDocument.documentCover)) {
			const map: Record<string, string> = {}

			for (const coverSentence of parentToDocument.documentCover as DocumentCoverSentence[]) {
				const sentence = coverSentence.sentence.map((item) => item.text).join(" ")
				const quotes = extractQuotes(sentence)
				const chunkIds: string[] = []
				for (const part of coverSentence.sentence) {
					if ("chunkId" in part) {
						chunkIds.push(part.chunkId)
					}
				}
				// We pair each found quote with chunkIds in order
				const maxLength = Math.min(quotes.length, chunkIds.length)

				for (let i = 0; i < maxLength; i++) {
					const { strippedQuote } = quotes[i]
					map[strippedQuote] = chunkIds[i]
				}
			}
			setQuoteToChunkIdMap(map)
		}
	}, [parentToDocument.documentCover])

	// Handle editing
	const handleEditClick = () => {
		setIsEditing(true)
	}

	const handleCitationClick = async (e: React.MouseEvent, strippedQuote: string) => {
		e.preventDefault()

		// Directly look up the chunkId by the stripped quote
		const chunkId = quoteToChunkIdMap[strippedQuote]
		if (chunkId) {
			setDocumentViewerChunkId(chunkId)
			setDocumentViewerCitationText(strippedQuote)
			setShowDocumentViewer(true)
			setDocumentViewerDocumentId(parentToDocument.documentId || "")
		}
	}

	// Renders the summary text, making quoted segments clickable
	const formatSummaryText = (text: string, referenceId: string) => {
		if (!text) {
			return <span className="text-gray-500"> — </span>
		}

		const parts: {
			type: string
			content: string
			strippedContent?: string
			citation?: string | null
			index?: number
		}[] = []
		let currentPosition = 0

		const quoteMatches = extractQuotes(text)
		const citationMatches = text.match(CITATION_REGEX) || []

		quoteMatches.forEach((quoteMatch, idx) => {
			if (quoteMatch.index > currentPosition) {
				parts.push({
					type: "text",
					content: text.slice(currentPosition, quoteMatch.index).trim(),
				})
			}

			parts.push({
				type: "quote",
				content: quoteMatch.fullQuote, // includes the "" in display
				strippedContent: quoteMatch.strippedQuote, // text inside quotes
				citation: citationMatches[idx] || null,
				index: idx,
			})

			currentPosition = quoteMatch.index + quoteMatch.fullQuote.length
		})

		if (currentPosition < text.length) {
			parts.push({
				type: "text",
				content: text.slice(currentPosition).trim(),
			})
		}

		return (
			<span>
				{parts.map((part, index) =>
					part.type === "quote" ? (
						<span
							key={`quote-${index}`}
							className="hover:bg-blue-100 dark:hover:bg-accent cursor-pointer"
							data-quote-group={`${referenceId}-${index}`}
							onClick={(e) => handleCitationClick(e, part.strippedContent || "")}
						>
							{part.content}{" "}
						</span>
					) : (
						<span key={`text-${index}`}>{part.content} </span>
					),
				)}
			</span>
		)
	}

	const handleTitleClick = () => {
		if (parentType === ParentType.PORTFOLIO) {
			navigate(`/portfolio/${portfolioId}/references/${parentToDocument.documentId}`)
		} else {
			navigate(`/project/${projectId}/references/${parentToDocument.documentId}`)
		}
	}

	const handleGenerateSummary = async () => {
		const parentId = parentType === ParentType.PORTFOLIO ? portfolioId : projectId
		await generateSummaries({
			documentIds: [parentToDocument.documentId || ""],
			id: parentId || "",
			parent: parentType,
			temperature: 1.0,
		})
	}

	// Combine all cover-sentence text (if it's an array) into a single string for display
	const documentSummary: string = Array.isArray(parentToDocument.documentCover)
		? (parentToDocument.documentCover as DocumentCoverSentence[])
				.map((documentCoverSentence) => {
					return documentCoverSentence.sentence.map((item) => item.text).join(" ")
				})
				.join(" ")
		: ""

	return (
		<div key={parentToDocument.documentId} className="px-4 py-3 border rounded-md">
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-2">
					<Label
						className="mb-0 hover:cursor-pointer hover:text-blue-600"
						onClick={handleTitleClick}
					>
						{parentType === ParentType.PORTFOLIO
							? getDocumentPortfolioName(parentToDocument.documentId || "")
							: getDocumentName(parentToDocument.documentId || "")}
					</Label>

					<Button
						variant="ghost"
						size="icon"
						onClick={() => handleEditClick()}
						disabled={isGeneratingSummaries}
					>
						<Pencil1Icon className="h-4 w-4" />
					</Button>

					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="ghost"
									size="icon"
									onClick={handleGenerateSummary}
									disabled={isGeneratingSummaries || isEditing}
								>
									<RefreshCwIcon className="h-4 w-4" />
								</Button>
							</TooltipTrigger>
							<TooltipContent>Regenerate summary</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</div>

			{isGeneratingSummaries ? (
				<Loader message="Regenerating summary..." />
			) : (
				<p className="text-sm mt-2">
					{formatSummaryText(documentSummary, parentToDocument.documentId || "")}
				</p>
			)}
			{isEditing && (
				<SummaryEditor
					open={isEditing}
					handleClose={() => {
						setIsEditing(false)
					}}
					parentToDocument={parentToDocument}
					parentType={parentType}
				/>
			)}
		</div>
	)
}

export default DocumentSummary
