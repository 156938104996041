/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import { cn } from "@/lib/utils"
import type { CollapsedClaim } from "@/types"
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import { useEffect, useState } from "react"

// Use the same filter icon as TableFilter and import a file icon
import { ListFilterIcon } from "lucide-react"

interface ClaimMultiSelectProps {
	claims: CollapsedClaim[]
	onClaimSelect: (selectedClaims: string[]) => void
	className?: string
	/** If true, show only an abbreviated view with filter icon and a summary label */
	abbreviated?: boolean
	filterLimitations?: string[]
}

/**
 * @description Claim select to control claims displayed in the table
 * @param {function} onClaimSelect - Function to handle the selection of claims.
 */
function ClaimMultiSelect({
	claims = [],
	onClaimSelect,
	className,
	abbreviated = true,
	filterLimitations,
}: ClaimMultiSelectProps) {
	const [open, setOpen] = useState(false)
	const [selectedClaims, setSelectedClaims] = useState<string[]>([])

	const getPopoverWidth = () => {
		if (!claims || claims.length === 0) return 200
		const allLabels = claims.flatMap(
			(claim) => claim.collapsedLimitations?.map((limitation) => limitation.label) || [],
		)
		const longestLabel = allLabels.reduce((a, b) => (a.length > b.length ? a : b), "")
		return Math.min(500, Math.max(200, longestLabel.length * 8 + 48))
	}

	const popoverWidth = getPopoverWidth()

	useEffect(() => {
		const allElements = claims.flatMap((claim) =>
			claim.collapsedLimitations.map((limitation) => limitation.id),
		)
		setSelectedClaims(allElements)
	}, [claims]) // Only run when claims change

	// Only update from selectedLimitations if it's not null/undefined
	useEffect(() => {
		if (filterLimitations && filterLimitations.length > 0) {
			setSelectedClaims(filterLimitations)
		}
	}, [filterLimitations]) // Only run once on mount

	// Compute some numbers used both in the abbreviated and detailed views.
	const totalLimitations = claims.flatMap((c) => c.collapsedLimitations).length
	const assertedIds = claims
		.filter((c) => c.isAsserted)
		.flatMap((c) => c.collapsedLimitations.map((l) => l.id))
	const isAllClaimsSelected = selectedClaims.length === totalLimitations
	const isAssertedClaimsSelected =
		selectedClaims.length === assertedIds.length &&
		JSON.stringify(selectedClaims.sort()) === JSON.stringify(assertedIds.sort())

	// Handler to toggle selection for all limitations of a claim when the claim header is clicked.
	const handleClaimToggle = (claim: CollapsedClaim) => {
		const allIds = claim.collapsedLimitations.map((l) => l.id)
		setSelectedClaims((prev) => {
			const isEverySelected = allIds.every((id) => prev.includes(id))
			if (isEverySelected) {
				return prev.filter((id) => !allIds.includes(id))
			}
			return [...prev, ...allIds.filter((id) => !prev.includes(id))]
		})
	}

	const handleLimitationToggle = (id: string) => {
		setSelectedClaims((prev) => {
			if (prev.includes(id)) {
				return prev.filter((l) => l !== id)
			}
			return [...prev, id]
		})
	}

	const handleSelectAll = () => {
		setSelectedClaims((prev) =>
			prev.length === totalLimitations
				? []
				: claims.flatMap((c) => c.collapsedLimitations.map((l) => l.id)),
		)
	}

	const handleSelectAsserted = () => {
		setSelectedClaims((prev) => {
			const assertedIds = claims
				.filter((c) => c.isAsserted)
				.flatMap((c) => c.collapsedLimitations.map((l) => l.id))
			return prev.length === assertedIds.length &&
				JSON.stringify(prev.sort()) === JSON.stringify(assertedIds.sort())
				? []
				: assertedIds
		})
	}

	const handleClose = () => {
		onClaimSelect(selectedClaims)
		setOpen(false)
	}

	return (
		<Popover
			open={open}
			onOpenChange={(isOpen) => (isOpen ? setOpen(true) : handleClose())}
		>
			<PopoverTrigger asChild>
				{abbreviated ? (
					<Button variant="outline" aria-expanded={open} size="icon" className="relative">
						<ListFilterIcon className="h-4 w-4" />
						{!isAllClaimsSelected && (
							<div className="absolute top-0 right-0 h-2 w-2 rounded-full bg-blue-500 transform translate-x-1/2 -translate-y-1/2" />
						)}
					</Button>
				) : (
					<Button
						variant="outline"
						aria-expanded={open}
						// Use a smaller width when abbreviated
						className={cn(
							abbreviated ? "w-[80px]" : "w-[200px]",
							"justify-between",
							className,
						)}
					>
						{/* {abbreviated ? (
						// Abbreviated style: instead of showing "All", "Asserted", etc,
						// simply show the regular filter icon when no filter is applied,
						// or a blue doc icon (FileText) when a filter is active.
						<div className="flex items-center space-x-1 truncate">
							<ListFilterIcon className="h-4 w-4" />
							{!isAllClaimsSelected && (
								<div className="h-2 w-2 rounded-full bg-blue-500" />
							)}
						</div>
					) : ( */}
						{/* Detailed style (existing code) with dropdown arrow. */}
						<div className="flex items-center space-x-1 truncate">
							{selectedClaims.length === totalLimitations ? (
								<span>All claims</span>
							) : selectedClaims.length > 0 ? (
								<>
									{(() => {
										const assertedIds = claims
											.filter((c) => c.isAsserted)
											.flatMap((c) => c.collapsedLimitations.map((l) => l.id))

										if (
											selectedClaims.length === assertedIds.length &&
											JSON.stringify(selectedClaims.sort()) === JSON.stringify(assertedIds.sort())
										) {
											return <span>Asserted claims</span>
										}

										return claims.map((claim) => {
											const claimLimitationIds = claim.collapsedLimitations.map((l) => l.id)
											const allClaimLimitationsSelected = claimLimitationIds.every((id) =>
												selectedClaims.includes(id),
											)

											if (allClaimLimitationsSelected) {
												return (
													<span
														key={claim.id}
														className="text-secondary-foreground px-1 rounded border"
													>
														{claim.number}
													</span>
												)
											}
											// Render a tag for each selected limitation, including the first one.
											return claim.collapsedLimitations
												.filter((limitation) => selectedClaims.includes(limitation.id))
												.map((limitation) => (
													<span
														key={limitation.id}
														className="text-secondary-foreground px-1 rounded border"
													>
														{limitation.label}
													</span>
												))
										})
									})()}
								</>
							) : (
								<span className="text-muted-foreground">Select claims</span>
							)}
						</div>
						{/* )} */}
						{/* Only show dropdown arrow for the non-abbreviated view */}
						{!abbreviated && <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />}
					</Button>
				)}
			</PopoverTrigger>
			<PopoverContent
				forceInline
				style={{ width: `${popoverWidth}px` }}
				className="p-0"
				align="start"
			>
				<ScrollArea className="h-[300px]">
					<div className="p-1">
						<Button
							variant="ghost"
							className="w-full justify-between font-bold"
							onClick={handleSelectAll}
						>
							<div className="flex items-center">
								<span>Select All</span>
							</div>
							{isAllClaimsSelected && <CheckIcon className="h-4 w-4" />}
						</Button>
						<Button
							variant="ghost"
							className="w-full justify-between font-bold"
							onClick={handleSelectAsserted}
						>
							<div className="flex items-center">
								<span>Asserted Claims</span>
							</div>
							{isAssertedClaimsSelected && <CheckIcon className="h-4 w-4" />}
						</Button>
						{claims.map((claim) => (
							<div key={claim.id} className="mb-1">
								<Button
									variant="ghost"
									className="w-full justify-between font-semibold"
									onClick={() => handleClaimToggle(claim)}
								>
									<div className="flex items-center">
										<span>Claim {claim.number}</span>
									</div>
									{claim.collapsedLimitations.every((l) => selectedClaims.includes(l.id)) && (
										<CheckIcon className="h-4 w-4" />
									)}
								</Button>
								<div className="ml-4 border-l-2 border-muted">
									{claim.collapsedLimitations.map((limitation) => (
										<Button
											key={limitation.id}
											variant="ghost"
											className="w-full justify-between pl-6"
											onClick={() => handleLimitationToggle(limitation.id)}
										>
											<span>{limitation.label}</span>
											{selectedClaims.includes(limitation.id) && <CheckIcon className="h-4 w-4" />}
										</Button>
									))}
								</div>
							</div>
						))}
					</div>
				</ScrollArea>
			</PopoverContent>
		</Popover>
	)
}

export default ClaimMultiSelect
