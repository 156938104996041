import FigureChunk from "@/components/images/FigureChunk"
import type { DocumentChunk } from "@/types"
import type React from "react"

interface FigureComponentProps {
	chunk: DocumentChunk
}

export const FigureComponent: React.FC<FigureComponentProps> = ({ chunk }) => {
	if (!chunk) {
		return null
	}

	const renderCitationLocation = () => {
		if (!chunk.figureNumber) return null

		return (
			<div className="w-[75px] shrink-0 text-sm text-gray-500 whitespace-pre-line">
				Figure {chunk.figureNumber}
			</div>
		)
	}

	return (
		<div className="group flex items-center hover:cursor-pointer hover:bg-accent/50 mb-2">
			<div className="flex flex-col items-start">
				{renderCitationLocation()}
				<FigureChunk figureChunk={chunk} />
			</div>
		</div>
	)
}

export default FigureComponent
