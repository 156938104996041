import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable"
import type React from "react"
import { useMemo } from "react"
import { useChartsContext } from "../../context/ChartsContext"
import DocumentPanel from "./DocumentPanel"

const InvalidityDocumentMode: React.FC = () => {
	// Destructure the states and callbacks we need from the hook
	const { chartData } = useChartsContext()

	// Suppose we manage claim panel size locally:
	const claimPanelSize = 40

	// Document IDs
	const { docId1, docId2 } = useMemo(() => {
		const documentIds = Object.values(chartData?.documentIds ?? {})
		return {
			docId1: documentIds[0] || null,
			docId2: documentIds[1] || null,
		}
	}, [chartData])

	// Calculate sizes for document panels. If two docs exist, each gets half of the document area.
	const numDocs = docId2 ? 2 : 1
	const documentPanelSize = 100 - claimPanelSize
	// If two documents exist, assign half of the documentPanelSize to each.
	const singleDocPanelSize = documentPanelSize / numDocs
	// When there is only one document, use the whole available size.
	const firstPanelSize = docId2 ? singleDocPanelSize : documentPanelSize

	return (
		// Wrap the panels in a flex container to force horizontal (side-by-side) layout.
		<ResizablePanelGroup direction="horizontal">
			<div className="flex h-full w-full">
				{docId1 && (
					<ResizablePanel defaultSize={firstPanelSize} className="flex-1">
						<DocumentPanel documentId={docId1} />
					</ResizablePanel>
				)}

				{docId2 && (
					<>
						<ResizableHandle withHandle orientation="vertical" />
						<ResizablePanel defaultSize={singleDocPanelSize} className="flex-1">
							<DocumentPanel documentId={docId2} />
						</ResizablePanel>
					</>
				)}
			</div>
		</ResizablePanelGroup>
	)
}

export default InvalidityDocumentMode
