import { api } from "@/api"
import { Button } from "@/components/ui/button"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { H4 } from "@/components/ui/typography"
import { useUserStore } from "@/store"
import type { AdminOverview, BillingCycleSummary, OrganizationPlan } from "@/types"
import { formatCurrency } from "@/utils"
import type React from "react"
import { useEffect, useState } from "react"

interface DetailsCardProps {
	fields: { key: string; label: string }[]
	title: string
	details: { [key: string]: string | number | boolean | string[] }
}

// Detail card structure
const DetailsCard: React.FC<DetailsCardProps> = ({ fields, title, details }) => {
	return (
		<Card className="w-full">
			<CardHeader>
				<H4>{title}</H4>
			</CardHeader>
			<CardContent>
				<div className="grid grid-cols-[repeat(auto-fit,minmax(100px,1fr))] gap-2">
					{fields.map(
						({ key, label }) =>
							details[key] !== null &&
							details[key] !== "null" && (
								<div key={key} className="flex flex-col items-start">
									<dt className="text-sm font-medium text-muted-foreground">{label}</dt>
									<dd className="text-lg font-semibold">{details[key]}</dd>
								</div>
							),
					)}
				</div>
			</CardContent>
		</Card>
	)
}

// Plan details card
export const PlanDetailsCard: React.FC<{ plan: OrganizationPlan }> = ({ plan }) => {
	const [formattedPlan, setFormattedPlan] = useState(null)

	const planFields = [
		{ key: "monthlyLicensePrice", label: "Monthly License" },
		{ key: "yearlyLicensePrice", label: "Yearly License" },
		{ key: "monthlySeatPrice", label: "Monthly Seat" },
		{ key: "yearlySeatPrice", label: "Yearly Seat" },
		{ key: "invProjectPrice", label: "Invalidity Project" },
		{ key: "appProjectPrice", label: "Application Project" },
		{ key: "pfaProjectPrice", label: "Portfolio" },
		{ key: "documentPrice", label: "Document" },
		{ key: "documentsExportedPrice", label: "Exported Document" },
		{ key: "documentsAddedPrice", label: "Processed Document" },
	]

	useEffect(() => {
		if (plan) {
			const formatted = Object.fromEntries(
				Object.entries(plan).map(([key, value]) =>
					typeof value === "number" ? [key, formatCurrency(value)] : [key, String(value)],
				),
			)
			setFormattedPlan(formatted)
		} else {
			setFormattedPlan(null)
		}
	}, [plan])

	if (!formattedPlan) {
		return null
	}

	return <DetailsCard fields={planFields} title="Plan Details" details={formattedPlan} />
}

// Overview details card
export const OverviewDetailsCard: React.FC<{ overview: AdminOverview }> = ({
	overview,
}) => {
	const { organizationId } = useUserStore()

	const {
		totalUsers,
		totalProjects,
		totalPortfolios,
		totalDocumentsAdded,
		totalDocumentsExported,
		totalBalance,
		defaultBudget,
	} = overview

	const [budget, setBudget] = useState<string>(
		defaultBudget ? defaultBudget.toString() : "",
	)
	const [isSaving, setIsSaving] = useState<boolean>(false)

	const handleSaveBudget = async () => {
		setIsSaving(true)
		try {
			await api.updateOrganization(organizationId, { defaultBudget: Number(budget) })
		} catch (error) {
			console.error("Error updating default budget", error)
		} finally {
			setIsSaving(false)
		}
	}

	const formattedStatistics = {
		totalUsers,
		totalProjects,
		totalPortfolios,
		totalDocumentsAdded,
		totalDocumentsExported,
		totalBalance: formatCurrency(totalBalance),
		defaultBudget: (
			<div className="flex items-center gap-2">
				<Input
					type="text"
					value={budget}
					onChange={(e) => setBudget(e.target.value)}
					className="w-24"
				/>
				<Button onClick={handleSaveBudget} disabled={isSaving} size="sm">
					{isSaving ? "..." : "Save"}
				</Button>
			</div>
		),
	}

	const fields = [
		{ key: "totalUsers", label: "Users" },
		{ key: "totalProjects", label: "Projects" },
		{ key: "totalPortfolios", label: "Portfolios" },
		{ key: "totalDocumentsAdded", label: "Documents Processed" },
		{ key: "totalDocumentsExported", label: "Documents Exported" },
		{ key: "totalBalance", label: "Balance" },
		{ key: "defaultBudget", label: "Default Budget" },
	]

	if (!overview) return null

	return (
		<Card className="w-full">
			<CardHeader>
				<H4>Overview</H4>
			</CardHeader>
			<CardContent>
				<div className="grid grid-cols-[repeat(auto-fit,minmax(100px,1fr))] gap-2">
					{fields.map(({ key, label }) =>
						formattedStatistics[key] !== null && formattedStatistics[key] !== "null" ? (
							<div key={key} className="flex flex-col items-start">
								<dt className="text-sm font-medium text-muted-foreground">{label}</dt>
								<dd className="text-lg font-semibold">{formattedStatistics[key]}</dd>
							</div>
						) : null,
					)}
				</div>
			</CardContent>
		</Card>
	)
}

// Current billing details card
export const CurrentBillingDetailsCard: React.FC<{
	cycleSummary: BillingCycleSummary
}> = ({ cycleSummary }) => {
	const fields = [
		{ key: "startDate", label: "From" },
		{ key: "endDate", label: "To" },
		{ key: "balance", label: "Balance" },
	]

	if (!cycleSummary) {
		return null
	}

	const formattedDetails = Object.fromEntries(
		Object.entries(cycleSummary).map(([key, value]) =>
			typeof value === "number" ? [key, formatCurrency(value)] : [key, String(value)],
		),
	)

	return (
		<DetailsCard
			fields={fields}
			title="Current Billing Cycle"
			details={formattedDetails}
		/>
	)
}

// Overview card for super admin
export const SuperAdminOverviewCard: React.FC<{
	overview: AdminOverview
}> = ({ overview }) => {
	const fields = [
		{ key: "totalOrganizations", label: "Organizations" },
		{ key: "totalUsers", label: "Users" },
		{ key: "totalProjects", label: "Projects" },
		{ key: "totalPortfolios", label: "Portfolios" },
		{ key: "totalDocumentsAdded", label: "Documents Added" },
		{ key: "totalDocumentsExported", label: "Documents Exported" },
		{ key: "totalBalance", label: "Balance" },
		// { key: "totalRevenue", label: "Revenue" },
	]

	// Only pick the numeric fields we need
	const formattedStatistics = {
		totalOrganizations: overview.totalOrganizations,
		totalUsers: overview.totalUsers,
		totalProjects: overview.totalProjects,
		totalPortfolios: overview.totalPortfolios,
		totalDocumentsAdded: overview.totalDocumentsAdded,
		totalDocumentsExported: overview.totalDocumentsExported,
		totalBalance: formatCurrency(overview.totalBalance),
		// totalRevenue: formatCurrency(overview.totalRevenue),
	}

	return <DetailsCard fields={fields} title="Overview" details={formattedStatistics} />
}
