/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */

import { Loader } from "@/components"
import { useParentContext } from "@/context/ParentContext"
import { useClaims } from "@/hooks"
import { PageName } from "@/types/pages"
import type React from "react"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import ProjectPage from "../../components/projects/ProjectPage"
import { ExportChartsModal } from "./components"
import ProjectChartsTable from "./components/ProjectChartsTable"
import ArchivedChartsModal from "./components/modals/ArchivedChartsModal"
import NewChartModal from "./components/modals/NewChartModal"
import { ChartSummaries } from "./components/summary/ChartSummaries"
import { useProjectChartsData } from "./hooks/queries/useProjectChartsData"
/**
 * @description Renders the invalidity table page
 */
const InvalidityChartsOverviewPage: React.FC = () => {
	const { projectId, portfolioId, inPortfolio } = useParentContext()
	const { projectClaims } = useClaims()
	const navigate = useNavigate()

	const { activeCharts, archivedCharts } = useProjectChartsData(projectId)

	const [showExportModal, setShowExportModal] = useState<boolean>(false)
	const [showNewChartModal, setShowNewChartModal] = useState(false)
	const [showArchivedChartsModal, setShowArchivedChartsModal] = useState(false)

	const selectChart = useCallback(
		(chartId: string, claimLimitationId?: string) => {
			if (inPortfolio) {
				navigate(`/portfolio/${portfolioId}/project/${projectId}/charts/${chartId}`, {
					state: { claimLimitationIdFromSummary: claimLimitationId },
				})
			} else {
				navigate(`/project/${projectId}/charts/${chartId}`, {
					state: { claimLimitationIdFromSummary: claimLimitationId },
				})
			}
		},
		[navigate, projectId, portfolioId, inPortfolio],
	)

	if (!projectClaims || !activeCharts) {
		return (
			<ProjectPage pageName={PageName.ProjectCharts}>
				<div className="flex justify-center items-center flex-col mt-12">
					<Loader message="Loading project data..." />
				</div>
			</ProjectPage>
		)
	}

	return (
		<ProjectPage pageName={PageName.ProjectCharts}>
			<div className="relative flex flex-col h-[calc(100vh-60px)]">
				{/* Sticky area for the summary table and actions */}
				<div className="sticky top-0 z-10 bg-background px-3">
					<ChartSummaries projectCharts={activeCharts} handleSelect={selectChart} />
				</div>
				<ProjectChartsTable
					projectCharts={activeCharts}
					onNewChart={() => setShowNewChartModal(true)}
					onShowArchivedCharts={
						archivedCharts?.length > 0 ? () => setShowArchivedChartsModal(true) : undefined
					}
					onShowExportModal={() => setShowExportModal(true)}
					selectChart={selectChart}
				/>
			</div>

			<ExportChartsModal
				open={showExportModal}
				handleClose={() => setShowExportModal(false)}
				activeCharts={activeCharts}
			/>
			<NewChartModal
				open={showNewChartModal}
				handleClose={() => setShowNewChartModal(false)}
			/>
			<ArchivedChartsModal
				archivedCharts={archivedCharts}
				open={showArchivedChartsModal}
				handleClose={() => setShowArchivedChartsModal(false)}
			/>
		</ProjectPage>
	)
}

export default InvalidityChartsOverviewPage
