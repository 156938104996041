import TooltipButton from "@/components/ui/tooltip-button"
import {
	AlignVerticalSpaceAround,
	CopyPlusIcon,
	PlusIcon,
	SearchIcon,
} from "lucide-react"
import type React from "react"

interface InvalidityTableCitationCellTooltipsProps {
	isInvalidityLoading: boolean
	// showNoMoreCitations: boolean
	onAddFromDocument: () => void
	onCopyEvidence: () => void
	onEditHeaderFooter: () => void
	onFindMoreCitations: () => void
}

const InvalidityTableCitationCellTooltips: React.FC<
	InvalidityTableCitationCellTooltipsProps
> = ({
	isInvalidityLoading,
	// showNoMoreCitations,
	onFindMoreCitations,
	onAddFromDocument,
	onCopyEvidence,
	onEditHeaderFooter,
}) => {
	const isDisabled = isInvalidityLoading

	return (
		<div className="flex items-center justify-center space-x-2">
			{onFindMoreCitations && (
				<TooltipButton
					icon={<SearchIcon className="h-4 w-4" />}
					tooltipText="Find more citations"
					onClick={onFindMoreCitations}
					disabled={isInvalidityLoading}
					srText="Find more citations"
					size="xsIcon"
					variant="ghost"
				/>
			)}
			{onCopyEvidence && (
				<TooltipButton
					icon={<CopyPlusIcon className="h-4 w-4" />}
					tooltipText="Copy evidence"
					onClick={onCopyEvidence}
					disabled={isDisabled}
					srText="Copy evidence from another claim"
					size="icon"
					variant="ghost"
				/>
			)}
			<TooltipButton
				icon={<PlusIcon className="h-4 w-4" />}
				tooltipText="Add from document"
				onClick={onAddFromDocument}
				disabled={isDisabled}
				srText="Add citation"
				size="icon"
				variant="ghost"
			/>
			<TooltipButton
				icon={<AlignVerticalSpaceAround className="h-4 w-4" />}
				tooltipText="Edit header/footer"
				onClick={onEditHeaderFooter}
				disabled={isDisabled}
				srText="Edit header/footer"
				size="icon"
				variant="ghost"
			/>
		</div>
	)
}

export default InvalidityTableCitationCellTooltips
