import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import type { PortfolioMetadata } from "@/types"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

export function usePortfolioMetadata() {
	const { portfolioId } = useParentContext()

	const portfolioMetadataQuery = useQuery<PortfolioMetadata>({
		queryKey: ["portfolio", portfolioId, "metadata"],
		queryFn: () => api.getPortfolioMetadata(portfolioId),
		enabled: !!portfolioId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const subjectIds = portfolioMetadataQuery.data?.projects?.map((p) => p.subjectId)
	const portfolioProjects = portfolioMetadataQuery.data?.projects

	return {
		portfolioMetadata: portfolioMetadataQuery.data,
		isLoading: portfolioMetadataQuery.isLoading,
		isFetching: portfolioMetadataQuery.isFetching,
		refetchMetadata: portfolioMetadataQuery.refetch,
		portfolioCreatedBy: portfolioMetadataQuery.data?.createdBy,
		portfolioName: portfolioMetadataQuery.data?.name,
		subjectIds,
		portfolioProjects,
		portfolioType: portfolioMetadataQuery.data?.type,
		portfolioBudget: portfolioMetadataQuery.data?.budget,
	}
}

export default usePortfolioMetadata
