import { chartsApi } from "@/api/chartsApi"
import type { InvaliditySummaryRow } from "@/types"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { type UseQueryOptions, useQuery } from "@tanstack/react-query"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

/**
 * Use this hook to fetch and prepare summary-chart data for a given document.
 * In the new model the API returns an array of DocumentInvaliditySummaryRow objects
 * with a single "color" property per row.
 */
export const useChartSummaryData = (
	projectId: string,
	chartId?: string,
	options?: Partial<UseQueryOptions<any, Error>>,
): {
	invaliditySummaryData: InvaliditySummaryRow[]
	isLoading: boolean
	isError: boolean
} => {
	const summaryQuery = useQuery({
		queryKey: QUERY_KEYS.project.invalidity.chartSummaryData(projectId, chartId),
		queryFn: async () => await chartsApi.getChartSummary(projectId, chartId),
		enabled: Boolean(projectId) && (options?.enabled ?? true),
		...DEFAULT_QUERY_OPTIONS,
		...options,
	})

	return {
		invaliditySummaryData: summaryQuery.data,
		isLoading: summaryQuery.isLoading,
		isError: summaryQuery.isError,
	}
}

export default useChartSummaryData
