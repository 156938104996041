import { useEffect, useRef, useState } from "react"
import { Page } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { useDebouncedCallback } from "use-debounce"

import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "@/components/ui/context-menu"
import type { Bbox, DocumentChunk } from "@/types"
import { formatDocumentChunkLocation } from "@/utils/projectUtils"
import { CopyToClipboardButton } from ".."

interface PdfPageWithBoundingBoxesProps {
	pageNumber: number
	scale: number
	documentChunks: DocumentChunk[]
	onChunkSelect: (id: string) => void
	selectedChunkId: string | null
	copyToClipboard: (text: string) => void
	onChunkHover: (id: string) => void
	hoveredChunkId: string | null
	width?: number
}

export default function PdfPageWithBoundingBoxes({
	pageNumber,
	scale,
	documentChunks,
	onChunkSelect,
	selectedChunkId,
	copyToClipboard,
	onChunkHover,
	hoveredChunkId,
	width = 800, // defualt
}: PdfPageWithBoundingBoxesProps) {
	const containerRef = useRef<HTMLDivElement>(null)

	// Track the user's live 'width' separately from what we actually apply to the PDF.
	const [pendingWidth, setPendingWidth] = useState(width)
	const [pdfWidth, setPdfWidth] = useState(width)

	// Whether we are in the middle of resizing.
	const [resizing, setResizing] = useState(false)

	// Called after the user stops resizing for 200ms.
	const debouncedApplyWidth = useDebouncedCallback(() => {
		setPdfWidth(pendingWidth) // Update the PDF size once user stops dragging
		setResizing(false)
	}, 300)

	useEffect(() => {
		// If the parent 'width' changes, set resizing to true and trigger the debounced apply.
		if (width !== pdfWidth) {
			setPendingWidth(width)
			setResizing(true)
			debouncedApplyWidth()
		}
	}, [width, pdfWidth, debouncedApplyWidth])

	// Store the page canvas dimensions so we can position bounding boxes
	const [dimensions, setDimensions] = useState<{
		width: number
		height: number
		left: number
		top: number
	} | null>(null)

	function calculateDimensions() {
		if (!containerRef.current) return
		const canvas = containerRef.current.querySelector("canvas")
		if (canvas) {
			const canvasRect = canvas.getBoundingClientRect()
			const containerRect = containerRef.current.getBoundingClientRect()

			setDimensions({
				width: canvasRect.width,
				height: canvasRect.height,
				left: canvasRect.left - containerRect.left,
				top: canvasRect.top - containerRect.top,
			})
		}
	}

	return (
		<div style={{ position: "relative" }} ref={containerRef}>
			<Page
				pageNumber={pageNumber}
				scale={scale}
				width={pdfWidth} // The PDF stays at old size until debounced
				onRenderSuccess={calculateDimensions} // Update bounding-box offsets
				renderTextLayer={false}
				renderAnnotationLayer={false}
			/>

			{/* Hide bounding boxes while resizing */}
			{!resizing &&
				dimensions &&
				documentChunks.map((chunk) => {
					const bboxes =
						typeof chunk.bboxes === "string"
							? JSON.parse(chunk.bboxes).map((bbox: string) => JSON.parse(bbox))
							: chunk.bboxes
					const bboxArray = Array.isArray(bboxes) ? bboxes : [bboxes]

					const boundingBoxElements = bboxArray.map((bbox: Bbox, index: number) => {
						const top = dimensions.top + bbox.top * dimensions.height
						const left = dimensions.left + bbox.left * dimensions.width
						const w = bbox.width * dimensions.width
						const h = bbox.height * dimensions.height
						const boxCenter = left + w / 2 - dimensions.left
						const isRightHalf = boxCenter > dimensions.width / 2

						return (
							<div
								key={`${chunk.id}-box-${index}`}
								onClick={() => onChunkSelect(chunk.id)}
								onMouseEnter={() => onChunkHover(chunk.id)}
								onMouseLeave={() => onChunkHover("")}
								data-chunk-id={chunk.id}
								style={{
									position: "absolute",
									top: `${top - 4}px`,
									left: `${left - 4}px`,
									width: `${w + 8}px`,
									height: `${h + 8}px`,
									border:
										chunk.id === selectedChunkId
											? "1px solid blue"
											: chunk.id === hoveredChunkId
												? "1px solid rgb(96, 165, 250)"
												: "",
									backgroundColor:
										chunk.id === selectedChunkId
											? "rgba(59, 130, 246, 0.1)"
											: chunk.id === hoveredChunkId
												? "rgba(96, 165, 250, 0.05)"
												: "transparent",
									boxSizing: "border-box",
									borderRadius: "4px",
								}}
								className="hover:cursor-pointer"
							>
								{chunk.id === selectedChunkId && (
									<CopyToClipboardButton
										index={1}
										text={`${chunk.text}\n\n${formatDocumentChunkLocation(chunk.location)}`}
										className="absolute flex items-center justify-center w-8 h-8 -translate-y-1/2 opacity-80 hover:opacity-100"
										style={{
											top: "50%",
											[isRightHalf ? "right" : "left"]: "-36px",
										}}
									/>
								)}
							</div>
						)
					})

					return (
						<ContextMenu key={chunk.id}>
							<ContextMenuTrigger>{boundingBoxElements}</ContextMenuTrigger>
							<ContextMenuContent>
								<ContextMenuItem onClick={() => copyToClipboard(chunk.text)}>
									Copy
								</ContextMenuItem>
								<ContextMenuItem
									onClick={() =>
										copyToClipboard(
											`${chunk.text}\n\n${formatDocumentChunkLocation(chunk.location)}`,
										)
									}
								>
									Copy with Citation
								</ContextMenuItem>
							</ContextMenuContent>
						</ContextMenu>
					)
				})}
		</div>
	)
}
