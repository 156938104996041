/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { AddDocumentsModal, AddPatentsModal, Loader, SidebarPage } from "@/components"
import { Button } from "@/components/ui/button"
import { H4 } from "@/components/ui/typography"
import { usePriorArt } from "@/hooks/prior-art/usePriorArt"
import type { ProjectDocumentMetadata } from "@/types"
import { PageName } from "@/types/pages"
import type React from "react"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import ReferencesTable from "../references/components/ReferencesTable"

/**
 * @description User documents page for files associated with the user
 */
const UserDocumentsPage: React.FC = () => {
	const navigate = useNavigate()

	const [showAddReferencesModal, setShowAddReferencesModal] = useState<boolean>(false)
	const [showUploadModal, setShowUploadModal] = useState<boolean>(false)
	const { priorArtDocuments, isLoading } = usePriorArt()

	const handleGoToDocument = useCallback(
		(reference: ProjectDocumentMetadata) => {
			navigate(`/documents/${reference.documentId}`)
		},
		[navigate],
	)

	return (
		<SidebarPage page={PageName.Documents}>
			{isLoading ? (
				<Loader />
			) : priorArtDocuments?.length > 0 ? (
				<div className="pt-2 mb-2">
					<ReferencesTable
						inUserDocuments={true}
						title="My Documents"
						onRowClick={handleGoToDocument}
						documents={priorArtDocuments as ProjectDocumentMetadata[]}
					/>
				</div>
			) : (
				<div className="flex justify-center items-center flex-col mt-12">
					<H4>Add files to get started.</H4>
					<div className="flex flex-row gap-2">
						<Button
							className="mt-3"
							variant="outline"
							onClick={() => setShowAddReferencesModal(true)}
						>
							Add Published Patents/Applications
						</Button>
						<Button
							className="mt-3"
							variant="outline"
							onClick={() => setShowUploadModal(true)}
						>
							Upload Other Documents
						</Button>
					</div>
				</div>
			)}

			<AddPatentsModal
				open={showAddReferencesModal}
				handleClose={() => setShowAddReferencesModal(false)}
			/>
			<AddDocumentsModal
				open={showUploadModal}
				handleClose={() => setShowUploadModal(false)}
				showProcessSettings={false}
			/>
		</SidebarPage>
	)
}

export default UserDocumentsPage
