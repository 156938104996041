import { TableBody } from "@/components/ui/table"
import { useParentContext } from "@/context/ParentContext"
import { usePortfolioMatrix, usePortfolioMetadata } from "@/hooks"
import type React from "react"
import { useNavigate } from "react-router-dom"
import PortfolioMatrixRowComponent from "./PortfolioMatrixRow"

const PortfolioMatrixBody: React.FC = () => {
	const navigate = useNavigate()
	const { portfolioId } = useParentContext()
	const { portfolioMatrix } = usePortfolioMatrix()
	const { portfolioProjects } = usePortfolioMetadata()

	const handleCellClick = async (subjectId: string, chartId?: string) => {
		if (!portfolioProjects) {
			console.warn("No projects found in portfolio")
			return
		}

		const selectedProject = portfolioProjects.find((p) => p.subjectId === subjectId)
		const projectId = selectedProject?.id

		if (projectId) {
			if (chartId) {
				navigate(`/portfolio/${portfolioId}/project/${projectId}/charts/${chartId}`)
			} else {
				navigate(`/portfolio/${portfolioId}/project/${projectId}/charts`)
			}
		}
	}

	return (
		<TableBody>
			{portfolioMatrix.map((row) => (
				<PortfolioMatrixRowComponent
					key={row.subject.documentId}
					row={row}
					onCellClick={handleCellClick}
				/>
			))}
		</TableBody>
	)
}

export default PortfolioMatrixBody
