/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */

import { ProjectPage } from "@/components"
import { PageName } from "@/types/pages"
import type React from "react"
import InvalidityChartPageContent from "./InvalidityChartPageContent"
import { ChartsContextProvider } from "./context/ChartsContext"

const InvalidityChartsPage: React.FC = () => {
	return (
		<ProjectPage pageName={PageName.ProjectCharts}>
			<ChartsContextProvider>
				<InvalidityChartPageContent />
			</ChartsContextProvider>
		</ProjectPage>
	)
}

export default InvalidityChartsPage
