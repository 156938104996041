import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useParentContext } from "@/context/ParentContext"
import {
	ClearConfirmationModal,
	GenerateContextModal,
	SearchResultsModal,
} from "@/features/context/components/ContextModals"
import {
	useFileUtils,
	usePortfolioMetadata,
	useProjectMetadata,
	useSearchDocuments,
} from "@/hooks"
import { useAppStateStore, useProcessStore } from "@/store"
import { ProcessType, type ProjectContext, ProjectType } from "@/types"
import { PageName } from "@/types/pages"
import { nanoid } from "nanoid"
import type React from "react"
import { useState } from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import {
	ExpertKnowledge,
	ImportantFacts,
	SearchInstructions,
	TermsAndDefinitions,
} from "./components/ContextSections"
import { GenerateContextDropdown } from "./components/GenerateContextDropdown"
import useProjectContext from "./hooks/useProjectContext"

/**
 * Default context data, used for clearing or initializing context.
 */
const defaultContextData: ProjectContext = {
	expertKnowledge: {
		fieldDescription: "",
		requiredEducation: "",
		yearsExperience: 0,
		technicalSkills: [],
	},
	termsAndDefinitions: [],
	importantFacts: [],
	searchContext: {
		queryInstructions: [],
		filterSteps: [],
	},
}

/**
 * @description Context page
 */
const ContextPage: React.FC = () => {
	// Hooks
	const { uploadToS3 } = useFileUtils()
	const { semanticSearchDocuments } = useSearchDocuments()
	const {
		generatePatentContextFromId,
		generatePatentContextFromText,
		updateProjectContext,
		context,
		isGeneratingContext,
	} = useProjectContext()
	const { projectId, portfolioId } = useParentContext()
	const { subjectId, projectType } = useProjectMetadata()
	const { portfolioType } = usePortfolioMetadata()
	// Stores
	const { addProcess, removeProcess } = useProcessStore()
	const { addErrorMessage, addSuccessMessage } = useAppStateStore()

	// State
	const [contextData, setContextData] = useState<ProjectContext | null>(null)
	const [showClearConfirmation, setShowClearConfirmation] = useState(false)
	const [searchResults, setSearchResults] = useState([])
	const [showSpinner, setShowSpinner] = useState(false)
	const [sentSearchQuery, setSentSearchQuery] = useState("")
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false)
	const [_isLoading, _setIsLoading] = useState(false)
	const [_dropdownOpen, _setDropdownOpen] = useState(false)
	const [originalContextData, setOriginalContextData] = useState<ProjectContext | null>(
		context ?? defaultContextData,
	)

	/**
	 * Searches for a term by calling the semanticSearchDocuments hook.
	 */
	const searchTerm = async (query: string) => {
		setSentSearchQuery(query)
		setShowSpinner(true)
		setIsSearchModalOpen(true)

		const response = await semanticSearchDocuments(
			projectId,
			query,
			[subjectId],
			"semantic",
		)

		if (response) {
			setSearchResults(response)
		} else {
			addErrorMessage("Error searching for term.")
		}
		setShowSpinner(false)
	}

	const handleSearchResultsClose = () => {
		setIsSearchModalOpen(false)
		setSearchResults([])
		setSentSearchQuery("")
		setShowSpinner(false)
	}

	/**
	 * Checks if the project context is empty.
	 */
	const isContextEmpty = () => {
		return (
			!context?.expertKnowledge &&
			(context?.termsAndDefinitions ?? []).length === 0 &&
			(context?.importantFacts ?? []).length === 0 &&
			(context?.searchContext?.queryInstructions?.length ?? 0) === 0 &&
			(context?.searchContext?.filterSteps?.length ?? 0) === 0
		)
	}

	const handleClearConfirmation = () => {
		setShowClearConfirmation(true)
	}

	/**
	 * Handles saving (updating) the project context.
	 */
	const handleSave = async () => {
		try {
			await updateProjectContext(projectId, contextData)
			addSuccessMessage("Patent context saved successfully")
		} catch (error) {
			console.error("Error saving patent context:", error)
			addErrorMessage("Failed to save patent context. Please try again.")
		}
	}

	/**
	 * Clears the entire project context.
	 */
	const handleClear = async () => {
		try {
			setContextData(defaultContextData)
			await updateProjectContext(projectId, defaultContextData)
			setIsEditing(false)
			addSuccessMessage("Patent context cleared successfully")
		} catch (_error) {
			addErrorMessage("Failed to clear patent context. Please try again.")
		}
	}

	const [isEditing, setIsEditing] = useState(false)
	const [url, setUrl] = useState("")
	const [inputContext, setInputContext] = useState("")
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalType, setModalType] = useState<"url" | "document" | "input" | null>(null)
	const [files, setFiles] = useState<File[]>([])

	/**
	 * Begins a process for adding new context, depending on the given source.
	 */
	const handleAddContext = async (source: string) => {
		const key = nanoid()

		try {
			switch (source) {
				case "subject-patent":
					addProcess({
						id: key,
						type: ProcessType.GENERATE_CONTEXT,
						projectIds: [projectId],
						portfolioId: portfolioId ?? "",
					})
					await generatePatentContextFromId(projectId, subjectId)
					break
				case "document":
					setModalType("document")
					setIsModalOpen(true)
					break
				case "url":
					setModalType("url")
					setIsModalOpen(true)
					break
				case "input":
					setModalType("input")
					setIsModalOpen(true)
					break
			}
		} catch (error) {
			console.error("Error generating patent context:", error)
			addErrorMessage("Failed to generate patent context. Please try again.")
		} finally {
			removeProcess(key)
		}
	}

	const isValidUrl = (url: string) => {
		try {
			new URL(url)
			return true
		} catch {
			return false
		}
	}

	const handleUrlSubmit = () => {
		if (isValidUrl(url)) {
			setIsModalOpen(false)
			setUrl("")
		} else {
			addErrorMessage("Invalid URL. Please enter a valid URL.")
		}
	}

	const handleFileChange = (selectedFiles: File[]) => {
		setFiles(selectedFiles)
	}

	const handleFileUpload = async () => {
		const key = nanoid()
		addProcess({
			id: key,
			type: ProcessType.GENERATE_CONTEXT,
			projectIds: [projectId],
		})
		try {
			setIsModalOpen(false)
			await uploadToS3(files[0], projectId, "application/pdf")
			const document_ids = "00000"
			await generatePatentContextFromId(projectId, document_ids[0])
			await generatePatentContextFromId(projectId, document_ids[0])
		} catch (error) {
			console.error("Error generating patent context:", error)
			addErrorMessage("Failed to generate patent context. Please try again.")
		} finally {
			setFiles([])
			removeProcess(key)
		}
	}

	const handleInputSubmit = async () => {
		const key = nanoid()
		addProcess({
			id: key,
			type: ProcessType.GENERATE_CONTEXT,
			projectIds: [projectId],
		})
		try {
			setIsModalOpen(false)
			await generatePatentContextFromText(projectId, inputContext)
			await generatePatentContextFromText(projectId, inputContext)
			setInputContext("")
		} catch (error) {
			console.error("Error generating patent context:", error)
			addErrorMessage("Failed to generate patent context. Please try again.")
		} finally {
			removeProcess(key)
		}
	}

	/**
	 * Enters edit mode, saving the current context data for later resets.
	 */
	const handleEdit = () => {
		setOriginalContextData(context ?? defaultContextData)
		setContextData(context ?? defaultContextData)
		setIsEditing(true)
	}

	/**
	 * Resets changes by reverting to the original context data (before editing).
	 */
	const handleReset = () => {
		if (originalContextData) {
			setContextData(originalContextData)
		}
		setIsEditing(false)
	}

	/**
	 * If editing, this saves changes to the server, else it toggles edit mode on.
	 */
	const handleSaveContext = () => {
		if (isEditing) {
			handleSave()
			setIsEditing(false)
		} else {
			handleEdit()
		}
	}

	return (
		<ProjectPage pageName={PageName.ProjectContext}>
			<div className="flex flex-col h-[calc(100vh-60px)]">
				<div className="flex justify-between items-center p-2 bg-background z-10 border-b">
					<div className="gap-2 flex flex-row">
						<GenerateContextDropdown
							isEditing={isEditing}
							isGeneratingContext={isGeneratingContext}
							onGenerateContext={handleAddContext}
						/>
					</div>
					<div className="space-x-2">
						{isEditing && (
							<Button onClick={handleReset} variant="outline" className="h-9">
								Reset
							</Button>
						)}
						{isEditing && !isContextEmpty() && (
							<Button
								disabled={isGeneratingContext}
								onClick={handleClearConfirmation}
								variant="outline"
								className="h-9"
							>
								Clear
							</Button>
						)}
						<Button
							disabled={isGeneratingContext}
							onClick={handleSaveContext}
							className="h-9"
						>
							{isEditing ? "Save Changes" : "Edit Context"}
						</Button>
					</div>
				</div>
				<div className="flex flex-grow h-full overflow-hidden">
					{/* Left column with Terms, Definitions & Search Instructions */}
					<div className="w-1/2 h-full">
						<ScrollArea className="h-full px-2 py-2">
							<TermsAndDefinitions
								terms={
									isEditing
										? (contextData?.termsAndDefinitions ?? [])
										: (context?.termsAndDefinitions ?? [])
								}
								onChange={(terms) =>
									setContextData({
										...contextData!,
										termsAndDefinitions: terms,
									})
								}
								isEditing={isEditing}
								searchTerm={searchTerm}
								showSearchButton={projectType !== ProjectType.APP}
							/>
							<div className="mt-2">
								<SearchInstructions
									value={isEditing ? contextData?.searchContext : context?.searchContext}
									onChange={(updatedSearchContext) =>
										setContextData({
											...contextData!,
											searchContext: updatedSearchContext,
										})
									}
									isEditing={isEditing}
								/>
							</div>
						</ScrollArea>
					</div>
					{/* Right column with Important Facts & Expert Knowledge */}
					<div className="w-1/2 h-full">
						<ScrollArea className="h-full px-2 py-2">
							<ImportantFacts
								contextData={
									isEditing
										? (contextData ?? defaultContextData)
										: (context ?? defaultContextData)
								}
								setContextData={setContextData}
								isEditing={isEditing}
								searchTerm={searchTerm}
								showSearchButton={projectType !== ProjectType.APP}
							/>
							<div className="mt-2">
								<ExpertKnowledge
									value={isEditing ? contextData?.expertKnowledge : context?.expertKnowledge}
									onChange={(value) =>
										setContextData({ ...contextData!, expertKnowledge: value })
									}
									isEditing={isEditing}
								/>
							</div>
						</ScrollArea>
					</div>
				</div>
			</div>

			<GenerateContextModal
				isOpen={isModalOpen}
				onOpenChange={setIsModalOpen}
				modalType={modalType}
				url={url}
				setUrl={setUrl}
				files={files}
				handleFileChange={handleFileChange}
				inputContext={inputContext}
				setInputContext={setInputContext}
				handleUrlSubmit={handleUrlSubmit}
				handleFileUpload={handleFileUpload}
				handleInputSubmit={handleInputSubmit}
				isValidUrl={isValidUrl}
			/>
			<ClearConfirmationModal
				isOpen={showClearConfirmation}
				onOpenChange={setShowClearConfirmation}
				onConfirm={handleClear}
			/>
			<SearchResultsModal
				isOpen={isSearchModalOpen}
				onOpenChange={handleSearchResultsClose}
				searchResults={searchResults}
				sentSearchQuery={sentSearchQuery}
				showSpinner={showSpinner}
			/>
		</ProjectPage>
	)
}

export default ContextPage
