import { Separator } from "@/components/ui/separator"
import TooltipButton from "@/components/ui/tooltip-button"
import { useClaims } from "@/hooks"
import type { ClaimLimitation, InvalidityChart } from "@/types"
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */
import type * as React from "react"
import { useState } from "react"
import ChartSummary from "./ChartSummary"
import SummaryTableRowLabelColumn from "./ChartSummaryRowLabelColumn"

export interface ChartSummariesProps {
	projectCharts: InvalidityChart[]
	handleSelect: (claimLimitationId: string | null, documentId: string) => void
}

/**
 * Updated ChartSummaries component that now calculates a per‑column width.
 * Each document column will take up an equal percentage of the available width,
 * but will not go below a minimum width (100px). If the available width is too small,
 * horizontal scrolling will be enabled.
 */
export const ChartSummaries: React.FC<ChartSummariesProps> = ({
	projectCharts,
	handleSelect,
}) => {
	const { claimLimitations } = useClaims()
	const [isExpanded, setIsExpanded] = useState(false)
	const chartRowHeightCollapsed =
		claimLimitations.length > 0 ? 140 / claimLimitations.length : 0

	return (
		<div className="relative group flex flex-col w-full">
			<div className="max-h-[50vh] overflow-y-auto">
				<div className="flex relative w-full overflow-x-auto">
					{/* Left sticky column for row labels. Rendered only if isExpanded */}
					{isExpanded && (
						<SummaryTableRowLabelColumn
							claimLimitations={claimLimitations as unknown as ClaimLimitation[]}
							style={{ width: "75px", flexShrink: 0 }}
						/>
					)}

					{/* Container for chart columns */}
					<div className="flex flex-row min-w-full">
						{projectCharts.map((chart) => {
							const minColumnWidth = 100 // Minimum width for each column.
							const widthPercentage = 100 / projectCharts.length
							const columnWidth = `max(${minColumnWidth}px, ${widthPercentage}%)`

							return (
								<div
									key={chart.id}
									className="p-1"
									style={{
										width: columnWidth,
										minWidth: `${minColumnWidth}px`,
									}}
								>
									<ChartSummary
										chartId={chart.id}
										chartName={chart.name}
										summaryChartRowHeightCollapsed={chartRowHeightCollapsed}
										isExpanded={isExpanded}
										handleSelect={handleSelect}
										style={{ width: "100%" }}
									/>
								</div>
							)
						})}
					</div>
				</div>
			</div>

			{/* Separator with the toggle button appears only on hover. */}
			<div className="mt-2 relative">
				<Separator className="w-full" />
				<div className="absolute inset-0 flex justify-center items-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
					<TooltipButton
						icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
						tooltipText={isExpanded ? "Collapse" : "Expand"}
						onClick={() => setIsExpanded(!isExpanded)}
						size="xsIcon"
						variant="outline"
					/>
				</div>
			</div>
		</div>
	)
}

export default ChartSummaries
