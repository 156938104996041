import type {
	Claim,
	ClaimLimitation,
	CollapsedClaim,
	CollapsedLimitation,
} from "@/types"
/**
 * Converts a list of collapsed claims with collapsed limitations to a list of claims with limitations
 */
export function expandCollapsedClaims(collapsedClaims: CollapsedClaim[]): Claim[] {
	const claims: Claim[] = []
	collapsedClaims.forEach((collapsedClaim) => {
		const claim: Claim = {
			id: collapsedClaim.id,
			number: collapsedClaim.number,
			isAsserted: collapsedClaim.isAsserted,
			isIndependent: collapsedClaim.isIndependent,
			patentId: collapsedClaim.patentId ?? "",
			limitations: [],
		}
		const claimLimitations: ClaimLimitation[] = []
		collapsedClaim.collapsedLimitations.forEach((group) => {
			group.limitations.forEach((lim) => {
				claimLimitations.push(lim)
			})
			claim.limitations = claimLimitations
		})
		claims.push(claim)
	})

	return claims
}

/**
 * Converts a list of collapsed limitations to a list of claim limitations
 */
export function getFlattenedLimitationsFromCollapsed(
	collapsedLimitations: CollapsedLimitation[],
): ClaimLimitation[] {
	return collapsedLimitations.map((cl) => ({
		id: cl.id,
		label: cl.label,
		text: cl.limitations.map((lim) => lim.text).join(" "),
		html: cl.limitations.map((lim) => lim.html).join(" "),
		groups: cl.limitations.flatMap((lim) => lim.groups),
		commentary:
			cl.limitations
				.map((lim) => lim.commentary)
				.filter(Boolean)
				.join("; ") || null,
		claimId: cl.claimId,
	}))
}

export function isCollapsedClaim(
	claim: Claim | CollapsedClaim,
): claim is CollapsedClaim {
	return "collapsedLimitations" in claim
}

export function getFullClaimText(claim: Claim | CollapsedClaim): string {
	return isCollapsedClaim(claim)
		? claim.collapsedLimitations
				.map((l) => l.limitations.map((l) => l.text).join(" "))
				.join(" ")
		: claim.limitations.map((l) => l.text).join(" ")
}
