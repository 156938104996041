import { api } from "@/api"
import { chartsApi } from "@/api/chartsApi"
import { useParentContext } from "@/context/ParentContext"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import { invalidateAllInvalidityChartData } from "@/utils"
import { useMutation, useQueryClient } from "@tanstack/react-query"

/**
 * @description Updates the header and footer boilerplate for a chunk to a limitation
 */
export function useInvalidityChartUpdates() {
	const queryClient = useQueryClient()
	const { handleError } = useApi()
	const { projectId } = useParentContext()

	const addInvalidityChartMutation = useMutation({
		mutationFn: async ({
			name,
			notes,
			documentIds,
		}: {
			name: string
			notes: string
			documentIds: string[]
		}) => {
			return chartsApi.createInvalidityChart(projectId, name, notes, documentIds)
		},
		onError: (error) => {
			handleError(error, "Error updating chunk to limitation boilerplate")
		},
		onSuccess: () => {
			invalidateAllInvalidityChartData(queryClient, projectId)
		},
	})

	const updateInvalidityChartMutation = useMutation({
		mutationFn: async ({
			chartId,
			options,
		}: {
			chartId: string
			options: {
				name?: string
				notes?: string
				active?: boolean
				tags?: string[]
			}
		}) => {
			return api.updateInvalidityChart(chartId, options)
		},
		onError: (error) => {
			handleError(error, "Error updating invalidity chart")
		},
		onSuccess: () => {
			invalidateAllInvalidityChartData(queryClient, projectId)
		},
	})

	return {
		addInvalidityChart: addInvalidityChartMutation.mutate,
		isAddingInvalidityChart: addInvalidityChartMutation.isPending,

		updateInvalidityChart: updateInvalidityChartMutation.mutate,
		isUpdatingInvalidityChart: updateInvalidityChartMutation.isPending,
	}
}

export default useInvalidityChartUpdates
