import { api } from "@/api"
import {
	DocumentStatus,
	type ParentDocumentMetadata,
	type ProcessingDocumentStatus,
	StatusType,
} from "@/types"
import { singleProjectStatusMapping } from "@/utils/projectUtils"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery, useQueryClient } from "@tanstack/react-query"

// projectId, ProcessingDocumentStatus
type GetDocumentStatusesResult = Array<[string, ProcessingDocumentStatus]>

export const useDocumentStatus = (
	portfolioId: string,
	projectIds: string[],
	documentType: string | null,
	isProcessing: boolean,
	documents?: ParentDocumentMetadata[],
) => {
	const queryClient = useQueryClient()
	const {
		data: documentStatuses,
		isLoading,
		isError,
		isFetching,
	} = useQuery<GetDocumentStatusesResult>({
		queryKey: QUERY_KEYS.documentStatuses(portfolioId, projectIds),
		queryFn: async () => {
			if (!portfolioId && (!projectIds || projectIds.length === 0)) {
				return []
			}
			const statuses = await api.getDocumentStatuses(portfolioId, projectIds, documentType)
			return statuses
		},
		enabled: !!documents && documents.length > 0,
		refetchInterval: (query) => {
			if (isProcessing) {
				return 10000
			}
			const data = query.state.data as GetDocumentStatusesResult | undefined
			if (!data) {
				return 5000
			}
			const processingStatuses: DocumentStatus[] = [
				DocumentStatus.UPLOADED,
				DocumentStatus.PROCESSING,
				DocumentStatus.QUEUED,
				DocumentStatus.CHARTING,
			]
			const stillProcessing =
				data?.some(([_, doc]) => processingStatuses.includes(doc.status)) ?? false
			return stillProcessing ? 5000 : false
		},
		...DEFAULT_QUERY_OPTIONS,
	})

	const aggregateStatuses = (statuses: DocumentStatus[]): StatusType => {
		// 1) If at least one is PROCESSING, QUEUED, or CHARTING => "processing"
		if (
			statuses.some(
				(s) =>
					s === DocumentStatus.PROCESSING ||
					s === DocumentStatus.QUEUED ||
					s === DocumentStatus.CHARTING,
			)
		) {
			return StatusType.PROCESSING
		}

		// 2) If all are PROCESSED or UPLOADED => "processed"
		if (
			statuses.every(
				(s) => s === DocumentStatus.PROCESSED || s === DocumentStatus.UPLOADED,
			)
		) {
			return StatusType.SUCCESS
		}

		// 3) If all are DB_ERROR => "error"
		if (statuses.length > 0 && statuses.every((s) => s === DocumentStatus.DB_ERROR)) {
			return StatusType.ERROR
		}

		// 4) Otherwise, if there's at least one "warning" state => "warning"
		const warningStatuses: DocumentStatus[] = [
			DocumentStatus.RECHART,
			DocumentStatus.REPROCESS,
			DocumentStatus.REUPLOAD,
		]

		if (
			statuses.some((s) => warningStatuses.includes(s)) ||
			(statuses.some((s) => s === DocumentStatus.DB_ERROR) &&
				!statuses.every((s) => s === DocumentStatus.DB_ERROR))
		) {
			return StatusType.WARNING
		}

		// 5) If none of the above matched, return error
		return StatusType.ERROR
	}

	/**
	 * getDisplayStatus now uses the fetched `documentStatuses` from React Query.
	 */
	const getDisplayStatus = (
		projectId: string | null,
		document: ParentDocumentMetadata,
	): {
		aggregated?: StatusType
		singleProjectStatus?: StatusType
	} => {
		if (!documentStatuses || documentStatuses.length === 0) {
			return { aggregated: StatusType.UNKNOWN, singleProjectStatus: StatusType.UNKNOWN }
		}

		const findDocumentStatusObject = (
			pid: string,
			docId: string,
		): ProcessingDocumentStatus | undefined => {
			const entry = documentStatuses.find(
				([projectId, status]) => projectId === pid && status.key === docId,
			)
			if (!entry) {
				return undefined
			}
			const [, processingDocumentStatus] = entry
			return processingDocumentStatus
		}

		if (projectId) {
			// We are at the project level, so just return
			const statusObj = findDocumentStatusObject(projectId, document.documentId ?? "")
			if (statusObj?.status) {
				return singleProjectStatusMapping[statusObj.status]
			}
			// No known status => unknown
			return { singleProjectStatus: StatusType.UNKNOWN }
		}
		// We are at the portfolio level => gather statuses from all projects that contain this doc
		const relevantProjectIds = document.document?.projectIds || []
		const statuses: DocumentStatus[] = []

		for (const pid of relevantProjectIds) {
			const processingDocumentStatus = findDocumentStatusObject(
				pid,
				document.documentId ?? "",
			)
			if (processingDocumentStatus) {
				statuses.push(processingDocumentStatus.status)
			}
		}

		if (statuses.length === 0) {
			// If no statuses found, treat as "none" or "error"
			return { aggregated: StatusType.ERROR }
		}
		const aggregated = aggregateStatuses(statuses)
		return { aggregated }
	}

	const removeStatuses = (documentIds: string[]) => {
		queryClient.setQueryData<GetDocumentStatusesResult>(
			QUERY_KEYS.documentStatuses(portfolioId, projectIds),
			(oldData) => {
				if (!oldData) return oldData
				return oldData.filter(([, status]) => !documentIds.includes(status.key))
			},
		)
	}

	return {
		documentStatuses,
		aggregateStatuses,
		getDisplayStatus,
		isLoading,
		isError,
		isFetching,
		removeStatuses,
	}
}

export default useDocumentStatus
