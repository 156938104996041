import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import type { DocumentStatus, ProjectDocumentMetadata } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { type QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"

/* -------------------------
   Types
------------------------- */
type BasePayload = {
	documentIds: string[]
}

type UpdateDocumentsPayload = BasePayload & {
	status?: DocumentStatus
	title?: string
}

/* -------------------------
   Helper Functions
------------------------- */
function handleOptimisticUpdate(
	queryClient: QueryClient,
	projectId: string | undefined,
	portfolioId: string | undefined,
	documentIds: string[],
	updateFn: (ref: ProjectDocumentMetadata) => ProjectDocumentMetadata,
) {
	const projectQueryKey = projectId ? QUERY_KEYS.project.priorArt(projectId) : []
	const portfolioQueryKey = portfolioId ? QUERY_KEYS.portfolio.priorArt(portfolioId) : []

	const previousProjectReferences =
		queryClient.getQueryData<ProjectDocumentMetadata[]>(projectQueryKey)
	const previousPortfolioReferences =
		queryClient.getQueryData<ProjectDocumentMetadata[]>(portfolioQueryKey)

	// Project references
	if (!portfolioId) {
		queryClient.setQueryData<ProjectDocumentMetadata[] | undefined>(
			projectQueryKey,
			(oldData) => {
				if (!oldData) return oldData
				return oldData.map((doc) =>
					documentIds.includes(doc.documentId ?? "") ? updateFn(doc) : doc,
				)
			},
		)
	}

	// Portfolio references
	if (portfolioId) {
		queryClient.setQueryData<ProjectDocumentMetadata[] | undefined>(
			portfolioQueryKey,
			(oldData) => {
				if (!oldData) return oldData
				return oldData.map((doc) =>
					documentIds.includes(doc.documentId ?? "") ? updateFn(doc) : doc,
				)
			},
		)
	}

	return { previousProjectReferences, previousPortfolioReferences }
}

function handleOptimisticRollback(
	queryClient: QueryClient,
	projectId: string | undefined,
	portfolioId: string | undefined,
	previousProjectReferences?: ProjectDocumentMetadata[],
	previousPortfolioReferences?: ProjectDocumentMetadata[],
) {
	if (!portfolioId && projectId && previousProjectReferences) {
		queryClient.setQueryData(
			QUERY_KEYS.project.priorArt(projectId),
			previousProjectReferences,
		)
	}
	if (portfolioId && previousPortfolioReferences) {
		queryClient.setQueryData(
			QUERY_KEYS.portfolio.priorArt(portfolioId),
			previousPortfolioReferences,
		)
	}
}

// function invalidateDocumentQueries(
// 	queryClient: QueryClient,
// 	projectId?: string,
// 	portfolioId?: string,
// ) {
// 	queryClient.invalidateQueries({
// 		queryKey: [
// 			QUERY_KEYS.project.document.full(projectId, documentId),
// 			QUERY_KEYS.project.document.figures(projectId, documentId),
// 			QUERY_KEYS.portfolio.document.full(portfolioId, documentId),
// 			QUERY_KEYS.portfolio.document.figures(portfolioId, documentId),
// 		],
// 	})
// }

/* -------------------------
   Hooks
------------------------- */
export const useUpdateDocuments = () => {
	const queryClient = useQueryClient()
	const { projectId, portfolioId } = useParentContext()

	// 1) Single mutation for updating documents (status, title, etc.)
	const updateDocuments = useMutation({
		mutationKey: MUTATION_KEYS.document.update(),
		mutationFn: async ({ documentIds, ...fields }: UpdateDocumentsPayload) => {
			return api.updateDocuments(documentIds, fields.title ?? null)
		},
		onMutate: async ({ documentIds, ...fields }) => {
			return handleOptimisticUpdate(
				queryClient,
				projectId ?? undefined,
				portfolioId ?? undefined,
				documentIds,
				(ref) => ({ ...ref, ...fields }),
			)
		},
		onError: (_error, _variables, context) => {
			handleOptimisticRollback(
				queryClient,
				projectId ?? undefined,
				portfolioId ?? undefined,
				context?.previousProjectReferences,
				context?.previousPortfolioReferences,
			)
		},
		onSuccess: () => {
			// invalidateDocumentQueries(queryClient, projectId, portfolioId)
		},
	})

	return {
		updateDocuments: updateDocuments.mutate,
	}
}

export default useUpdateDocuments
