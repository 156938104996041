import { Loader } from "@/components"
import { Button } from "@/components/ui/button"
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable"
import { ScrollArea } from "@/components/ui/scroll-area"
import { H4 } from "@/components/ui/typography"
import useDocumentFigures from "@/hooks/document/useDocumentFigures"
import { useProjectMetadata } from "@/hooks/project/useProjectMetadata"
import { cn } from "@/lib/utils"
import {
	type Claim,
	type CollapsedClaim,
	type DocumentChunk,
	DocumentRole,
	DocumentType,
	type PortfolioDocument,
	type ProjectDocument,
} from "@/types"
import type React from "react"
import { useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import ClaimList from "../claims/ClaimList"
import { FigureViewer } from "../images/FigureViewer"
import PdfViewer from "../pdf/PdfDisplay"
import PatentAbstract from "./patents/PatentAbstract"
import PatentMetadata from "./patents/PatentMetadata"
import PatentNumberDisplay from "./patents/PatentNumberDisplay"
import DocumentDates from "./sections/DocumentDates"
import Figures from "./sections/Figures"
import DocumentBody from "./sections/body/DocumentBody"

interface DocumentDetailLayoutProps {
	document: ProjectDocument | PortfolioDocument
	startDisplayPDF?: boolean
	onChangePDFVisibility?: (visible: boolean) => void
	claims?: Claim[] | CollapsedClaim[]
	citationText?: string
	selectedDocumentChunkId?: string
	highlightedRef?: React.RefObject<HTMLDivElement | HTMLTableRowElement>
	showBackButton?: boolean
	isModal?: boolean
}

const DocumentDetailLayout: React.FC<DocumentDetailLayoutProps> = ({
	document,
	startDisplayPDF = false,
	onChangePDFVisibility,
	claims,
	citationText,
	selectedDocumentChunkId,
	highlightedRef,
	showBackButton = false,
	isModal = false,
}) => {
	const navigate = useNavigate()
	const { priorityDate } = useProjectMetadata()
	const doc = document?.document
	const { figureChunks, updateFigure } = useDocumentFigures(doc?.id ?? "")

	const isPatent = useMemo(() => {
		return doc?.type === DocumentType.PATENT
	}, [doc?.type])

	const [showPdf, setShowPdf] = useState<boolean>(() => {
		// Always show PDF for non-patent docs, else respect startDisplayPDF
		return !isPatent || startDisplayPDF
	})
	const [leftPanelSize, setLeftPanelSize] = useState<number>(50)
	const [selectedChunkId, setSelectedChunkId] = useState<string | null>(
		selectedDocumentChunkId ?? null,
	)
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [showFigureViewer, setShowFigureViewer] = useState<boolean>(false)
	const [selectedFigureChunk, setSelectedFigureChunk] = useState<DocumentChunk | null>(
		null,
	)

	const selectedChunkRef = useRef<HTMLDivElement>(null)

	const effectiveClaims = useMemo(() => {
		if (isPatent && claims) {
			return claims
		}
		return doc?.patent?.claims || []
	}, [isPatent, claims, doc?.patent?.claims])

	const documentType = doc?.type
	const pdfUrl = useMemo(() => {
		return (
			(documentType === DocumentType.PATENT && doc?.patent?.pdfUrl) ||
			document?.url ||
			undefined
		)
	}, [documentType, document, doc?.patent?.pdfUrl])

	const isSubjectDocument = useMemo(() => {
		return "documentRole" in document && document.documentRole === DocumentRole.SUBJECT
	}, [document])

	const isClaimHighlighted = useMemo(() => {
		if (doc?.type !== DocumentType.PATENT) {
			return false
		}
		return effectiveClaims?.some((claim) => {
			const claimText = Object.values(claim)[0] as string
			return claimText?.slice(0, 75) === citationText?.slice(0, 75)
		})
	}, [doc, effectiveClaims, citationText])

	if (!doc) {
		return <Loader />
	}

	function handleTogglePDF() {
		const toggleValue = !showPdf
		setShowPdf(toggleValue)
		setSelectedChunkId(null)
		if (onChangePDFVisibility) {
			onChangePDFVisibility(toggleValue)
		}
	}

	const handleFigureReferenceSelect = (figureChunk: DocumentChunk) => {
		setShowFigureViewer(true)
		setSelectedFigureChunk(figureChunk)
	}

	const handleChunkSelect = (chunkId: string | null) => {
		setSelectedChunkId(chunkId)
	}

	const handleEdit = () => {
		setIsEditing(!isEditing)
	}

	const handleSave = () => {
		setIsEditing(false)
	}

	// --- Render helper functions ---
	const renderDocumentHeader = () => (
		<>
			{showBackButton && (
				<div
					onClick={() => navigate(-1)}
					className="text-sm text-muted-foreground hover:text-foreground cursor-pointer mb-2 flex items-center gap-1"
				>
					<span>←</span>
					<span>Back to References</span>
				</div>
			)}
			<div className="flex justify-between mb-2 w-full">
				{doc.title ? <H4 className="mb-4 break-words">{doc.title}</H4> : null}
				{doc.patent?.number && <PatentNumberDisplay finalIdString={doc.patent.number} />}
			</div>
		</>
	)

	const renderActionButtons = () => (
		<div className="flex justify-end p-2 gap-2">
			<Button variant="outline" size="sm" onClick={isEditing ? handleSave : handleEdit}>
				{isEditing ? "Done" : "Edit"}
			</Button>
			{!showPdf && (
				<Button variant="outline" size="sm" onClick={handleTogglePDF}>
					View PDF
				</Button>
			)}
		</div>
	)

	const renderDocumentContent = (includeActions = false, includeMetadata = false) => (
		<>
			{renderDocumentHeader()}
			{includeActions && renderActionButtons()}
			{includeMetadata && renderMetadata()}
			{doc.type === DocumentType.PATENT && <PatentAbstract patent={doc.patent} />}
			<Figures documentId={doc.id} showTitle={true} editMode={isEditing} />
			<DocumentBody
				document={doc}
				body={doc.body}
				figureChunks={figureChunks || []}
				citationText={citationText}
				selectedChunkId={selectedChunkId}
				onChunkSelect={handleChunkSelect}
				editMode={isEditing}
				onFigureReferenceSelect={handleFigureReferenceSelect}
				selectedChunkRef={selectedChunkRef}
			/>
		</>
	)

	const renderMetadata = () => (
		<div className="flex flex-col gap-2 mb-4">
			{doc.patent && <PatentMetadata patent={doc.patent} />}
			<DocumentDates
				publicationDate={doc.patent?.publicationDate}
				priorityDate={priorityDate}
				isEditing={isEditing}
			/>
		</div>
	)

	const renderClaims = () => (
		<>
			{doc.type === DocumentType.PATENT && effectiveClaims?.length > 0 && (
				<div className="mb-12">
					<ClaimList
						claims={effectiveClaims}
						startCollapsed={false}
						editingBool={true}
						highlightedRef={
							isClaimHighlighted
								? (highlightedRef as React.RefObject<HTMLTableRowElement>)
								: undefined
						}
						patentId={doc.id}
						isSubject={isSubjectDocument}
					/>
				</div>
			)}
		</>
	)

	const renderMetadataAndClaims = () => (
		<>
			{renderMetadata()}
			{renderClaims()}
		</>
	)

	const renderLeftPanel = () => (
		<ScrollArea className="px-3 py-2 h-full">{renderDocumentContent()}</ScrollArea>
	)

	const renderRightPanel = () => (
		<div>
			{showFigureViewer ? (
				<FigureViewer
					selectedFigure={selectedFigureChunk}
					documentId={doc.id}
					figureChunks={figureChunks}
					updateFigureChunk={updateFigure}
					onHide={() => setShowFigureViewer(false)}
				/>
			) : showPdf ? (
				<PdfViewer
					documentId={doc.id}
					url={pdfUrl}
					documentChunks={doc.body}
					onHidePdf={handleTogglePDF}
					onChunkSelect={handleChunkSelect}
					selectedChunkId={selectedChunkId}
					documentType={documentType}
				/>
			) : (
				<>
					{renderActionButtons()}
					<ScrollArea className="px-3 py-2 h-full">{renderMetadataAndClaims()}</ScrollArea>
				</>
			)}
		</div>
	)

	const renderSinglePanel = () => (
		<ScrollArea className="px-3 py-2 h-full">
			{renderDocumentContent(true, true)}
			<div className="mb-4">{renderClaims()}</div>
		</ScrollArea>
	)

	// --- Final return ---
	return (
		<div className="h-full w-full overflow-hidden">
			{/* Large screen layout */}
			<div
				className={cn(
					"hidden lg:block overflow-hidden",
					isModal ? "h-[calc(100vh-90px)]" : "h-[calc(100vh-55px)]",
				)}
			>
				<ResizablePanelGroup direction="horizontal" className="h-full">
					<ResizablePanel defaultSize={leftPanelSize} onResize={setLeftPanelSize}>
						{showPdf ? renderSinglePanel() : renderLeftPanel()}
					</ResizablePanel>

					<ResizableHandle withHandle />

					<ResizablePanel defaultSize={100 - leftPanelSize}>
						{renderRightPanel()}
					</ResizablePanel>
				</ResizablePanelGroup>
			</div>

			{/* Small screen layout */}
			<div className="lg:hidden h-[calc(100vh-55px)] overflow-hidden">
				{showPdf ? (
					<PdfViewer
						documentId={doc.id}
						url={pdfUrl}
						documentChunks={doc.body}
						onHidePdf={handleTogglePDF}
						onChunkSelect={handleChunkSelect}
						selectedChunkId={selectedChunkId}
						documentType={documentType}
					/>
				) : (
					<>{renderSinglePanel()}</>
				)}
			</div>
		</div>
	)
}

export default DocumentDetailLayout
