import { useProjectMetadata } from "./useProjectMetadata"
import { useProjectSubject } from "./useProjectSubject"

export function useGetProjectPriorityData() {
	const { projectMetadata } = useProjectMetadata()
	const { projectSubject, isLoading: isLoadingProjectSubject } = useProjectSubject()

	const priorityDate =
		projectMetadata?.priorityDate ||
		projectSubject?.document?.patent?.priorityDate ||
		projectSubject?.document?.patent?.filingDate ||
		null

	return {
		priorityDate,
	}
}

export default useGetProjectPriorityData
