import { ScrollArea } from "@/components/ui/scroll-area"
import type React from "react"
import { useEffect } from "react"
import { useChartsContext } from "../../context/ChartsContext"
import { ClaimInvalidityCard } from "./ClaimInvalidityCard"
import VerticalClaimNavigation from "./VerticalClaimNavigation"

export interface ClaimCardsListProps {
	projectId: string
	documentIds: string[]
	selectedClaimRef: React.MutableRefObject<HTMLDivElement | null>
}

const ClaimCardsList: React.FC<ClaimCardsListProps> = ({
	projectId,
	documentIds = [],
	selectedClaimRef,
}) => {
	const { activeLimitationId, filteredLimitations } = useChartsContext()

	/**
	 * Whenever activeLimitationId changes, scroll the card
	 * currently referenced by selectedClaimRef into view.
	 */
	useEffect(() => {
		if (activeLimitationId && selectedClaimRef.current) {
			// Scroll so that the active card is visible.
			selectedClaimRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center",
			})
		}
	}, [activeLimitationId, selectedClaimRef])

	return (
		<div className="flex h-full">
			{/* Navigation sidebar with fixed width and full height */}
			<div className="w-24 flex-shrink-0 h-full">
				{documentIds.length > 0 && <VerticalClaimNavigation />}
			</div>

			{/* Main content with claims */}
			<ScrollArea className="h-full  py-2 pr-3 pl-2">
				{filteredLimitations.map((limitation) => {
					const isSelected = activeLimitationId === limitation.id
					return (
						<div
							key={limitation.id}
							ref={
								isSelected
									? (el) => {
											// If card is the selected limitation, store ref
											selectedClaimRef.current = el
										}
									: null
							}
							className="mb-2"
						>
							<ClaimInvalidityCard
								limitation={limitation}
								projectId={projectId}
								documents={documentIds}
							/>
						</div>
					)
				})}
			</ScrollArea>
		</div>
	)
}

export default ClaimCardsList
