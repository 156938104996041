import { Card, CardContent, CardHeader } from "@/components/ui/card"
import {
	Table,
	TableBody,
	TableCellCondensed,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table"
import { H4 } from "@/components/ui/typography"
import type { PortfolioSummary, ProjectSummary } from "@/types"
import { ChevronDown, ChevronRight } from "lucide-react"
import React, { useState } from "react"

/**
 * UsageLogTable renders the combined list of projects/portfolios.
 * Portfolio rows that include a `children` property will be expandable.
 */
function UsageLogTable({
	projects,
}: { projects: (ProjectSummary | PortfolioSummary)[] }) {
	const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set())

	const toggleRow = (id: string) => {
		setExpandedRows((prev) => {
			const next = new Set(prev)
			if (next.has(id)) {
				next.delete(id)
			} else {
				next.add(id)
			}
			return next
		})
	}

	const formatTimestamp = (timestamp: number | string | null): string => {
		if (!timestamp) return "-"
		// Check if timestamp is a numeric seconds value. If not, assume it's a valid date string.
		const numTimestamp = Number(timestamp)
		const date = !Number.isNaN(numTimestamp)
			? new Date(numTimestamp * 1000)
			: new Date(timestamp)
		if (Number.isNaN(date.getTime())) return "-" // fail-safe in case of invalid date

		const today = new Date()
		const yesterday = new Date(today)
		yesterday.setDate(yesterday.getDate() - 1)

		const dateFormat = (date: Date) => {
			return date
				.toLocaleDateString("en-US", {
					day: "2-digit",
					month: "2-digit",
					year: "2-digit",
				})
				.replace(/\//g, ".")
		}

		if (date.toDateString() === today.toDateString()) {
			return "Today"
		}
		if (date.toDateString() === yesterday.toDateString()) {
			return "Yesterday"
		}
		return dateFormat(date)
	}

	// Render each row and, if present and expanded, its children rows recursively.
	const renderRow = (project: ProjectSummary | PortfolioSummary, level = 0) => {
		const isExpanded = expandedRows.has(project.id)
		const hasChildren =
			"children" in project &&
			Array.isArray(project.children) &&
			project.children.length > 0

		return (
			<React.Fragment key={project.id}>
				<TableRow>
					<TableCellCondensed>{project.name || "N/A"}</TableCellCondensed>
					<TableCellCondensed>{project.type || "N/A"}</TableCellCondensed>
					<TableCellCondensed>{project.createdBy || "N/A"}</TableCellCondensed>
					<TableCellCondensed>{formatTimestamp(project.createdAt)}</TableCellCondensed>
					<TableCellCondensed>{formatTimestamp(project.updatedAt)}</TableCellCondensed>
					<TableCellCondensed>{project.documentsProcessed ?? "-"}</TableCellCondensed>
					<TableCellCondensed>{project.documentsExported ?? "-"}</TableCellCondensed>
					<TableCellCondensed>{project.balance ?? "$0.00"}</TableCellCondensed>
					<TableCellCondensed>
						{hasChildren && (
							<button
								type="button"
								onClick={() => toggleRow(project.id)}
								className="focus:outline-none"
							>
								{isExpanded ? (
									<ChevronDown className="h-4 w-4" />
								) : (
									<ChevronRight className="h-4 w-4" />
								)}
							</button>
						)}
					</TableCellCondensed>
				</TableRow>
				{isExpanded &&
					hasChildren &&
					project.children.map((child) => renderRow(child, level + 1))}
			</React.Fragment>
		)
	}

	return (
		<Card>
			<CardHeader>
				<H4>Portfolios & Projects</H4>
			</CardHeader>
			<CardContent>
				{/* Container with max-height and scrollbar */}
				<div className="max-h-[500px] overflow-y-auto">
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead>Name</TableHead>
								<TableHead>Type</TableHead>
								<TableHead>Creator</TableHead>
								<TableHead>Created</TableHead>
								<TableHead>Active</TableHead>
								<TableHead>Processed</TableHead>
								<TableHead>Exported</TableHead>
								<TableHead>Balance</TableHead>
								<TableHead />
							</TableRow>
						</TableHeader>
						<TableBody>{projects.map((project) => renderRow(project))}</TableBody>
					</Table>
				</div>
			</CardContent>
		</Card>
	)
}

export default UsageLogTable
