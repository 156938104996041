import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import usePortfolioMetadata from "@/hooks/portfolio/usePortfolioMetadata"
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import { XIcon } from "lucide-react"
import type React from "react"
import { useState } from "react"

interface MultiProjectSelectProps {
	selectedProjectIds: string[]
	onChange: (selected: string[]) => void
	disabled?: boolean
}

const MultiProjectSelect: React.FC<MultiProjectSelectProps> = ({
	selectedProjectIds,
	onChange,
	disabled,
}) => {
	const { portfolioProjects } = usePortfolioMetadata()
	const [open, setOpen] = useState(false)
	const projectIds = portfolioProjects.map((p) => p.id)
	const areAllProjectsSelected = () => selectedProjectIds.length === projectIds.length

	const handleProjectToggle = (id: string) => {
		const newSelection = selectedProjectIds.includes(id)
			? selectedProjectIds.filter((item) => item !== id)
			: [...selectedProjectIds, id]
		onChange(newSelection)
	}

	const handleSelectAll = () => {
		onChange(areAllProjectsSelected() ? [] : projectIds)
	}

	const handleDeselectAll = () => {
		onChange([])
	}

	const getDisplayText = (id: string) => {
		const project = portfolioProjects.find((p) => p.id === id)
		return project?.name || id
	}

	return (
		<Popover
			open={open}
			onOpenChange={(isOpen) => (isOpen ? setOpen(true) : setOpen(false))}
		>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					aria-expanded={open}
					className="justify-between w-full"
					disabled={disabled}
				>
					<div className="flex items-center space-x-1 truncate">
						{areAllProjectsSelected() ? (
							<span>All Projects</span>
						) : selectedProjectIds.length > 0 ? (
							<>
								{selectedProjectIds.slice(0, 5).map((id) => (
									<span key={id} className="text-secondary-foreground px-2 rounded border">
										{getDisplayText(id)}
									</span>
								))}
								{selectedProjectIds.length > 5 && (
									<span className="text-secondary-foreground">
										+{selectedProjectIds.length - 5} more
									</span>
								)}
							</>
						) : (
							<span>Select Projects</span>
						)}
					</div>
					<ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="p-0 min-w-[350px]" align="start" forceInline>
				<ScrollArea className="h-[300px]">
					<div className="p-1">
						<Button
							variant="ghost"
							className="w-full justify-start"
							onClick={handleSelectAll}
						>
							<div className="flex items-center">
								<CheckIcon
									className={`mr-2 h-4 w-4 ${
										selectedProjectIds.length === projectIds.length ? "opacity-100" : "opacity-0"
									}`}
								/>
								<span>Select All</span>
							</div>
						</Button>
						<Button
							variant="ghost"
							className="w-full justify-start"
							onClick={handleDeselectAll}
						>
							<div className="flex items-center">
								<XIcon
									className={`mr-2 h-4 w-4 ${
										selectedProjectIds.length > 0 ? "opacity-100" : "opacity-0"
									}`}
								/>
								<span>Deselect All</span>
							</div>
						</Button>
						{projectIds.map((id) => (
							<Button
								key={id}
								variant="ghost"
								className="w-full justify-start"
								onClick={() => handleProjectToggle(id)}
							>
								<div className="flex items-center">
									<CheckIcon
										className={`mr-2 h-4 w-4 ${
											selectedProjectIds.includes(id) ? "opacity-100" : "opacity-0"
										}`}
									/>
									<span>{getDisplayText(id)}</span>
								</div>
							</Button>
						))}
					</div>
				</ScrollArea>
			</PopoverContent>
		</Popover>
	)
}

export default MultiProjectSelect
