import { Button } from "@/components/ui/button"
import { Dialog, DialogContent } from "@/components/ui/dialog"
import { cn } from "@/lib/utils"
import type { DocumentChunk } from "@/types/invalidity"
import { Expand, RotateCcw, RotateCw, X } from "lucide-react"
import type React from "react"
import { type CSSProperties, useEffect, useRef, useState } from "react"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 */

interface ImageViewerProps {
	open: boolean
	imageUrl?: string
	imageName?: string
	figureChunk?: DocumentChunk
	handleClose: () => void
}

interface ImageSize {
	width: number
	height: number
}

const BUTTON_HEIGHT = 40 // approximate header height

/**
 * @description Renders a modal for viewing an image with rotation controls.
 */
const ImageViewer: React.FC<ImageViewerProps> = ({
	open,
	imageUrl,
	imageName,
	figureChunk,
	handleClose,
}) => {
	const [rotation, setRotation] = useState<number>(0)
	const [imageSize, setImageSize] = useState<ImageSize>({ width: 0, height: 0 })
	const [originalSize, setOriginalSize] = useState<ImageSize>({ width: 0, height: 0 })

	const url = figureChunk?.figureUrl ?? imageUrl
	const name = figureChunk?.figureNumber ?? imageName

	const imageRef = useRef<HTMLImageElement>(null)

	/**
	 * When the dialog opens, reset rotation and recalc image size
	 */
	useEffect(() => {
		if (open) {
			setRotation(0)
			if (url && url !== "null") {
				resetImageSize(url)
			}
		}
	}, [open, url])

	const handleCloseAndReset = () => {
		handleClose()
	}

	/**
	 * Load the image to get its natural dimensions
	 */
	function resetImageSize(url: string) {
		const img = new Image()
		img.onload = () => {
			const size = { width: img.width, height: img.height }
			setOriginalSize(size)
			// Initial rotation = 0
			adjustSizes(size, 0)
		}
		img.src = url
	}

	/**
	 * Adjusts the displayed size so that the image fits properly
	 */
	function adjustSizes(size: ImageSize, newRotation: number) {
		let { width, height } = size
		const rot = newRotation % 360
		// Swap width & height if rotating 90 or 270
		if (rot === 90 || rot === 270) {
			;[width, height] = [height, width]
		}

		// Use viewport dimensions, accounting for padding and controls
		const maxWidth = window.innerWidth * 0.9 - 64 // 90% of viewport minus padding
		const maxHeight = window.innerHeight * 0.9 - BUTTON_HEIGHT - 64 // 90% of viewport minus header and padding

		const _aspectRatio = width / height
		let scaledWidth = width
		let scaledHeight = height

		// Scale down if needed, maintaining aspect ratio
		if (width > maxWidth || height > maxHeight) {
			const widthScale = maxWidth / width
			const heightScale = maxHeight / height
			const scale = Math.min(widthScale, heightScale)

			scaledWidth = width * scale
			scaledHeight = height * scale
		}

		setImageSize({
			width: scaledWidth,
			height: scaledHeight,
		})
	}

	/**
	 * Rotate image 90 deg left
	 */
	function handleRotateLeft() {
		const newRotation = (rotation - 90 + 360) % 360
		setRotation(newRotation)
		adjustSizes(originalSize, newRotation)
	}

	/**
	 * Rotate image 90 deg right
	 */
	function handleRotateRight() {
		const newRotation = (rotation + 90) % 360
		setRotation(newRotation)
		adjustSizes(originalSize, newRotation)
	}

	/**
	 * Put image in fullscreen mode
	 */
	function handleExpand() {
		if (imageRef.current?.requestFullscreen) {
			imageRef.current.requestFullscreen()
		}
	}

	// Use a more constrained width for the dialog content
	const dialogContentClass = cn(
		"p-4 flex flex-col items-center justify-center",
		"w-auto h-auto",
		"max-w-[800px] max-h-[90vh]", // Cap max width at 800px
		"min-w-[300px] min-h-[200px]",
		"overflow-hidden",
	)

	const imageStyle: CSSProperties = {
		objectFit: "contain",
		transform: `rotate(${rotation}deg)`,
		width: imageSize.width,
		height: imageSize.height,
		maxWidth: "100%",
		maxHeight: "calc(90vh - 80px)", // Account for header and padding
	}

	return (
		<Dialog open={open} onOpenChange={handleCloseAndReset}>
			<DialogContent className={dialogContentClass} hideCloseButton>
				{/* Left-side controls */}
				<div className="absolute top-4 left-4 flex space-x-2 z-10">
					<Button
						variant="ghost"
						size="icon"
						onClick={handleRotateLeft}
						aria-label="Rotate left"
					>
						<RotateCcw size={18} className="text-current" />
					</Button>
					<Button
						variant="ghost"
						size="icon"
						onClick={handleRotateRight}
						aria-label="Rotate right"
					>
						<RotateCw size={18} className="text-current" />
					</Button>
					<Button
						variant="ghost"
						size="icon"
						onClick={handleExpand}
						aria-label="Expand image"
					>
						<Expand size={18} className="text-current" />
					</Button>
				</div>

				{/* Close button on the right */}
				<Button
					variant="ghost"
					size="icon"
					onClick={handleCloseAndReset}
					aria-label="Close image viewer"
					className="absolute top-4 right-4 z-10"
				>
					<X size={18} className="text-current" />
				</Button>

				{/* Image container */}
				<div className="flex items-center justify-center w-full h-full pt-14">
					<img
						ref={imageRef}
						src={url}
						alt={name}
						style={imageStyle}
						className="rounded-sm"
					/>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default ImageViewer
