import TooltipButton from "@/components/ui/tooltip-button"
import { useParentContext } from "@/context/ParentContext"
import { useChunkToLimitationUpdates } from "@/features/charts/hooks"
import { useFeedbackChartDataStore } from "@/features/charts/hooks/useFeedbackChartDataStore"
import { useSharing } from "@/hooks"
import type { ChunkToLimitation } from "@/types"
import { convertUtcToLocal } from "@/utils/dateUtils"
import { Cross2Icon, PlusIcon } from "@radix-ui/react-icons"
import { ThumbsDownIcon, ThumbsUpIcon } from "lucide-react"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type React from "react"
import { useCallback, useState } from "react"
import { useChartsContext } from "../../context/ChartsContext"
import useChartOptionsStore from "../../hooks/chartOptionsStore"
import { ColorPopover } from "../ColorPopover"

interface InvalidityCitationActionsProps {
	citation: ChunkToLimitation
	showVerify?: boolean
	showRemove?: boolean
	showFeedback?: boolean
	showColor?: boolean
	children?: React.ReactNode
}

const InvalidityCitationActions: React.FC<InvalidityCitationActionsProps> = ({
	citation,
	showRemove = true,
	showFeedback = true,
	showColor = true,
	children,
}) => {
	// const citation = useMemo(() => toCitation(rawCitation), [rawCitation])
	const { updateCitation } = useChunkToLimitationUpdates()
	const { feedbackChartData, removeFeedbackCitation, addFeedbackCitation } =
		useFeedbackChartDataStore()
	const { projectId } = useParentContext()
	const { getUserEmailFromId } = useSharing()
	const { showUpdatedAt, showUpdatedBy } = useChartOptionsStore()

	// Feedback logic
	const { invalidityChartId } = useChartsContext()

	const getCitationFeedback = useCallback(
		(cit: ChunkToLimitation) =>
			feedbackChartData[projectId]?.[invalidityChartId]?.[cit.claimLimitationId]?.find(
				(c) => c.id === cit.id,
			)?.feedback,
		[feedbackChartData, projectId, invalidityChartId],
	)

	const [citationFeedback, setCitationFeedback] = useState<
		"positive" | "negative" | undefined
	>(getCitationFeedback(citation))

	const handleColorChange = (color: string) => {
		updateCitation({
			citation,
			options: {
				color,
			},
		})
	}

	const handleFeedbackClick = (
		citation: ChunkToLimitation,
		type: "positive" | "negative",
	) => {
		const currentFeedback = getCitationFeedback(citation)
		if (currentFeedback === type) {
			removeFeedbackCitation(
				projectId,
				invalidityChartId,
				citation.claimLimitationId,
				citation.id,
			)
			setCitationFeedback(undefined)
		} else {
			addFeedbackCitation(projectId, invalidityChartId, citation.claimLimitationId, {
				...citation,
				feedback: type,
			})
			setCitationFeedback(type)
		}
	}

	const handleToggleRemoveCitation = async () => {
		await updateCitation({
			citation,
			options: { removed: !citation.removed },
		})
	}
	const userEmail = citation.updatedById ? getUserEmailFromId(citation.updatedById) : ""

	const getUpdatedInfo = () => {
		if (!citation.updatedAt && !citation.updatedById) return null

		const parts = []
		if (showUpdatedAt) {
			parts.push(
				`Updated at ${convertUtcToLocal(citation.updatedAt, "MM/dd/yyyy hh:mm a")}`,
			)
		}
		if (showUpdatedBy && userEmail) {
			parts.push(`by ${userEmail}`)
		}

		return parts.length > 0 ? (
			<p className="text-xs text-gray-500 ml-2 italic">{parts.join(" ")}</p>
		) : null
	}

	return (
		<div className="flex gap-4 items-start">
			{/* Color Picker Section */}
			{showColor && (
				<div className="flex-shrink-0">
					<ColorPopover currentColor={citation.color} onColorChange={handleColorChange} />
				</div>
			)}

			{/* Main Content and Feedback */}
			<div className="flex-1">
				{children}
				{/* Feedback (thumbs up and down) buttons moved here */}
				{showFeedback && (
					<div className="flex gap-1 justify-between">
						<div className="flex gap-1 items-center">
							<TooltipButton
								onClick={() => handleFeedbackClick(citation, "positive")}
								icon={<ThumbsUpIcon className="h-4 w-4" />}
								tooltipText="Positive feedback"
								isActive={citationFeedback === "positive"}
								activeColor="text-green-500"
								size="xsIcon"
								variant="ghost"
							/>
							<TooltipButton
								onClick={() => handleFeedbackClick(citation, "negative")}
								icon={<ThumbsDownIcon className="h-4 w-4" />}
								tooltipText="Negative feedback"
								isActive={citationFeedback === "negative"}
								activeColor="text-red-500"
								size="xsIcon"
								variant="ghost"
							/>
						</div>
						{getUpdatedInfo()}
					</div>
				)}
			</div>

			{/* Remove Citation Button */}
			{showRemove && (
				<div className="flex-shrink-0">
					<TooltipButton
						onClick={handleToggleRemoveCitation}
						icon={
							citation.removed ? (
								<PlusIcon className="h-4 w-4" />
							) : (
								<Cross2Icon className="h-4 w-4" />
							)
						}
						tooltipText={citation.removed ? "Add citation back" : "Remove citation"}
						size="xsIcon"
						variant="ghost"
					/>
				</div>
			)}
		</div>
	)
}

export default InvalidityCitationActions
