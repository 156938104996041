import { ElementPill, ErrorMessage, Loader } from "@/components"
import { Button } from "@/components/ui/button"
import { useParentContext } from "@/context/ParentContext"
import { useClaimStrength, useClaims } from "@/hooks"
import { ChartColor, type ClaimStrength } from "@/types"
import { useEffect } from "react"
interface PatchReferencesProps {
	isVisible: boolean
	unselectedClaims: ClaimStrength[]
	selectedClaims: ClaimStrength[]
	setSelectedClaims: (claims: ClaimStrength[]) => void
	setUnselectedClaims: (claims: ClaimStrength[]) => void
}

/**
 * @description Renders the Patch references (claims-based search for prior art) as a simple <div> container
 * without its own modal. You can embed <PatchReferences> anywhere, e.g. inside your <SearchReferencesModal>.
 */
export function PatchClaimsSearchSection({
	isVisible,
	unselectedClaims,
	selectedClaims,
	setSelectedClaims,
	setUnselectedClaims,
}: PatchReferencesProps) {
	const { getCollapsedLimitationById } = useClaims()

	// Global state from store
	const { projectId } = useParentContext()

	// Local state setup
	const { claimStrengths, isLoading, error } = useClaimStrength(projectId)

	useEffect(() => {
		if (claimStrengths) {
			setUnselectedClaims(claimStrengths.filter((item) => item.color === ChartColor.GREEN))
			setSelectedClaims(claimStrengths.filter((item) => item.color !== ChartColor.GREEN))
		}
	}, [claimStrengths])

	// Handle claim selection
	const handleAddElement = (element: ClaimStrength) => {
		setSelectedClaims([...selectedClaims, element])
		setUnselectedClaims(unselectedClaims.filter((item) => item !== element))
	}
	const handleRemoveElement = (element: ClaimStrength) => {
		setSelectedClaims(selectedClaims.filter((item) => item !== element))
		setUnselectedClaims([...unselectedClaims, element])
	}

	// Add these new handlers
	const handleSelectAll = () => {
		setSelectedClaims([...selectedClaims, ...unselectedClaims])
		setUnselectedClaims([])
	}

	const handleUnselectAll = () => {
		setUnselectedClaims([...unselectedClaims, ...selectedClaims])
		setSelectedClaims([])
	}

	if (!isVisible) return null

	if (isLoading) return <Loader message="Loading claim strengths..." />
	if (error) return <ErrorMessage />

	return (
		<div className="flex flex-col w-full h-full">
			{!isLoading && !error && (
				<div className="flex gap-4 mt-4">
					<div className="flex-1 flex flex-col">
						<div className="flex gap-2 items-center">
							<h4 className="text-sm">Selected</h4>
							<Button
								onClick={handleUnselectAll}
								disabled={selectedClaims.length === 0}
								variant="outline"
								size="sm"
							>
								Unselect All
							</Button>
						</div>
						<div className="w-full border rounded-md p-2 mt-2">
							<div className="flex flex-wrap gap-1 bg-muted p-2 min-h-[100px]">
								{selectedClaims.map((element, index) => (
									<ElementPill
										key={index}
										handleClick={() => handleRemoveElement(element)}
										color={element.color}
										label={getCollapsedLimitationById(element.id)?.label}
										isSelected={true}
									/>
								))}
							</div>
						</div>
					</div>
					<div className="flex-1 flex flex-col">
						<div className="flex gap-2 items-center">
							<h4 className="text-sm">Unselected</h4>
							<Button
								onClick={handleSelectAll}
								variant="outline"
								size="sm"
								disabled={unselectedClaims.length === 0}
							>
								Select All
							</Button>
						</div>
						<div className="w-full border rounded-md p-2 mt-2">
							<div className="flex flex-wrap gap-1 p-2 min-h-[100px]">
								{unselectedClaims.map((element, index) => (
									<ElementPill
										key={index}
										handleClick={() => handleAddElement(element)}
										color={element.color}
										label={getCollapsedLimitationById(element.id)?.label}
										isSelected={false}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default PatchClaimsSearchSection
