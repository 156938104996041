import type { DocumentChunk, DocumentCoverSentence } from "@/types"
import { apiClient } from "./client"

export const coverApi = {
	async getCoverExport(projectId: string, portfolioId: string) {
		const payload = {
			project_id: projectId,
			portfolio_id: portfolioId,
		}
		return await apiClient.get("get_cover_export", payload)
	},

	async getDocumentChunksByIds(documentChunkIds: string[]): Promise<DocumentChunk[]> {
		const payload = {
			document_chunk_ids: documentChunkIds,
		}
		return await apiClient.get("get_document_chunks_by_ids", payload)
	},

	async updateDocumentSummary(
		projectId: string,
		portfolioId: string,
		documentId: string,
		sentences: DocumentCoverSentence[],
	) {
		const snakeCaseSentences = sentences.map((sentence) => ({
			...sentence,
			sentence: sentence.sentence.map((part) => {
				if ("chunkId" in part) {
					return {
						text: part.text,
						chunk_id: part.chunkId,
						start_index: part.startIndex,
						end_index: part.endIndex,
					}
				}
				return part
			}),
		}))
		const payload = {
			project_id: projectId,
			portfolio_id: portfolioId,
			document_id: documentId,
			sentences: snakeCaseSentences,
		}
		return await apiClient.post("update_document_summary", payload)
	},
}
