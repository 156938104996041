/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { LoadingButton } from "@/components/ui/loading-button"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSuperAdminOrganizations } from "../../hooks/useSuperAdminOrganizations"
interface DeleteOrganizationModalProps {
	open: boolean
	onClose: () => void
	orgId: string
	orgName: string
}

function DeleteOrganizationModal({
	open,
	onClose,
	orgId,
	orgName,
}: DeleteOrganizationModalProps) {
	const [confirmOrgName, setConfirmOrgName] = useState<string>("")
	const { deleteOrganization, isLoading, isDeletePending } = useSuperAdminOrganizations()
	const navigate = useNavigate()

	const handleDelete = async () => {
		await deleteOrganization({ organizationId: orgId })
		onClose()
		navigate("/superadmin/organizations")
	}

	const isLoadingState = isLoading || isDeletePending

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="max-w-md">
				<DialogHeader>
					<DialogTitle>Delete organization {orgName}?</DialogTitle>
					<DialogDescription>
						All users and projects will be deleted from the database. You cannot undo this
						action. Type "{orgName}" to confirm.
					</DialogDescription>
				</DialogHeader>
				<Input
					placeholder="Confirm Organization Name"
					onChange={(e) => setConfirmOrgName(e.target.value)}
					value={confirmOrgName}
				/>
				<DialogFooter className="sm:justify-end">
					<Button variant="outline" onClick={onClose} disabled={isLoadingState}>
						Cancel
					</Button>
					<LoadingButton
						variant="destructive"
						onClick={handleDelete}
						disabled={confirmOrgName !== orgName}
						loading={isLoadingState}
					>
						{isLoadingState ? "Deleting..." : "Delete"}
					</LoadingButton>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default DeleteOrganizationModal
