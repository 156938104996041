import type { ProjectMetadata } from "@/types"
import type { QueryClient } from "@tanstack/react-query"
import { PARTIAL_INVALIDITY_KEYS, QUERY_KEYS } from "./keys"

/* -------------------------------------------------------------------------- */
/*                                 Utilities                                  */
/* -------------------------------------------------------------------------- */

/**
 * Invalidates multiple queries based on the provided query keys.
 * Use this utility when you have a collection of queries whose associated data
 * needs recalculating or refetching (e.g., changes in user info, project data, etc.).
 *
 * @param queryClient - The React Query client instance
 * @param queryKeys - An array of queries (keys) to invalidate
 * @param exact - Whether the query key match needs to be exact
 */
export const invalidateMultipleQueries = (
	queryClient: QueryClient,
	queryKeys: unknown[],
	exact = false,
) => {
	for (const queryKey of queryKeys) {
		queryClient.invalidateQueries({ queryKey, exact })
	}
}

/**
 * Invalidates all queries in the cache whose data (serialized to a string) contains the provided ID.
 * Useful for cases where the ID can appear in any stored data for a query.
 *
 * @param queryClient - The React Query client instance
 * @param id - The ID to match and invalidate in the cached query data
 */
export const invalidateAnyQueriesContainingId = (
	queryClient: QueryClient,
	id: string,
) => {
	const queries = queryClient.getQueryCache().findAll()

	for (const query of queries) {
		const cachedData = query.state.data
		if (cachedData && JSON.stringify(cachedData).includes(id)) {
			queryClient.invalidateQueries({ queryKey: query.queryKey })
		}
	}
}

/**
 * Invalidates only the queries matching any of the provided queryKeys
 * whose data (serialized to a string) contains the provided ID.
 *
 * @param queryClient - The React Query client instance
 * @param id - The ID to match and invalidate in the cached query data
 * @param queryKeys - An array of queries (keys) to check
 */
export const invalidateSpecificQueriesContainingId = (
	queryClient: QueryClient,
	id: string,
	queryKeys: unknown[],
) => {
	const queries = queryClient.getQueryCache().findAll({ queryKey: queryKeys })

	for (const query of queries) {
		const cachedData = query.state.data
		if (cachedData && JSON.stringify(cachedData).includes(id)) {
			queryClient.invalidateQueries({ queryKey: query.queryKey })
		}
	}
}

/* -------------------------------------------------------------------------- */
/*                       Higher-level Invalidation Logic                      */
/* -------------------------------------------------------------------------- */

/**
 * Invalidates queries that are tied to user administration.
 * Typically used when organization users list changes (add/remove/edit).
 */
export const invalidateUserRelatedQueries = (
	queryClient: QueryClient,
	orgId?: string,
) => {
	invalidateMultipleQueries(queryClient, [
		QUERY_KEYS.admin.users(orgId),
		QUERY_KEYS.admin.overview(orgId),
	])
}

/**
 * Invalidates invalidity (prior-art / invalidity) queries related to a given project
 * or to references, limitations, or color filters.
 */
export const invalidateInvalidityRelatedQueries = (
	queryClient: QueryClient,
	projectId: string | null,
) => {
	invalidateAllInvalidityChartData(queryClient, projectId)
	invalidateMultipleQueries(queryClient, [QUERY_KEYS.project.claimStrengths(projectId)])
}

export const invalidateLimitationInvalidityQueries = (
	queryClient: QueryClient,
	projectId: string,
	chartId: string,
	limitationIds: string[],
	documents?: string[],
	_colors?: string[],
) => {
	for (const limitationId of limitationIds) {
		invalidateInvalidityChartDataForLimitation(
			queryClient,
			projectId,
			chartId,
			limitationId,
		)
	}

	const summaryQueryKeys = documents?.map((documentId) =>
		QUERY_KEYS.project.invalidity.chartSummaryData(projectId, documentId),
	)
	invalidateMultipleQueries(queryClient, [
		...(summaryQueryKeys ?? []),
		QUERY_KEYS.project.claimStrengths(projectId),
		QUERY_KEYS.project.invalidity.chartSummaryData(projectId, chartId),
	])
}

/**
 * Invalidates prior-art queries associated with a project or a portfolio.
 * Also triggers invalidation of related invalidity queries if a project is specified.
 */
export const invalidatePriorArtRelatedQueries = (
	queryClient: QueryClient,
	projectId?: string,
	portfolioId?: string,
) => {
	if (projectId) {
		invalidateMultipleQueries(queryClient, [QUERY_KEYS.project.priorArt(projectId)])
		invalidateInvalidityRelatedQueries(queryClient, projectId)
	}

	if (portfolioId) {
		invalidateMultipleQueries(queryClient, [
			QUERY_KEYS.portfolio.priorArt(portfolioId),
			QUERY_KEYS.portfolio.matrix.overview(portfolioId),
		])
	}
}

/**
 * Invalidates organization-related queries such as overview, projects, and billing details.
 * Typically used when an organization's details have changed.
 */
export const invalidateOrgRelatedQueries = (
	queryClient: QueryClient,
	orgId: string,
) => {
	invalidateMultipleQueries(queryClient, [
		QUERY_KEYS.admin.overview(orgId),
		QUERY_KEYS.admin.projects(orgId),
		QUERY_KEYS.admin.billing(orgId),
	])
}

/**
 * Invalidates queries related to a project's metadata and relevant lists of projects
 * (e.g., active, archived). Typically used when the project's name or key metadata changes.
 */
export const invalidateProjectRelatedQueries = (
	queryClient: QueryClient,
	options?: {
		projectId?: string
		portfolioId?: string
	},
) => {
	const list = [QUERY_KEYS.projects.active(), QUERY_KEYS.projects.archived()]

	if (options?.projectId) {
		list.push(QUERY_KEYS.project.metadata(options.projectId))
	}
	if (options?.portfolioId) {
		list.push(QUERY_KEYS.portfolio.metadata(options.portfolioId))
	}

	invalidateMultipleQueries(queryClient, list)
}

/**
 * Invalidates queries that may contain the provided document ID. Specifically
 * checks for prior art and invalidity queries at both project and portfolio level.
 * Also checks if the document is the project's subject document, triggering
 * additional project metadata invalidations if so.
 */
export const invalidateDocumentRelatedQueries = (
	queryClient: QueryClient,
	documentId: string,
	projectId: string | null,
	portfolioId: string | null,
) => {
	const queryKeys = [
		projectId ? QUERY_KEYS.project.priorArt(projectId) : [],
		portfolioId ? QUERY_KEYS.portfolio.priorArt(portfolioId) : [],
		portfolioId ? QUERY_KEYS.portfolio.matrix.overview(portfolioId) : [],
	]

	// Invalidate any queries that contain this document in their data
	invalidateMultipleQueries(queryClient, queryKeys)
	invalidateInvalidityRelatedQueries(queryClient, projectId)

	// If this document is the subject of the project, invalidate subject & metadata queries
	if (projectId) {
		const projectData = queryClient.getQueryData<ProjectMetadata>(
			QUERY_KEYS.project.metadata(projectId),
		)
		if (projectData?.subjectId === documentId) {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.subject(projectId),
			})
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.metadata(projectId),
			})
		}
	}
}

export const invalidateDocumentChunkRelatedQueries = (
	queryClient: QueryClient,
	documentId: string,
	projectId: string,
	portfolioId: string,
) => {
	invalidateMultipleQueries(queryClient, [
		QUERY_KEYS.portfolio.matrix.overview(portfolioId),
	])

	invalidateInvalidityRelatedQueries(queryClient, projectId)
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.document.full(documentId),
	})

	if (isDocumentSubject(queryClient, documentId, projectId)) {
		queryClient.invalidateQueries({
			queryKey: QUERY_KEYS.project.subject(projectId),
		})
	}
}

/**
 * Invalidates project settings and claims queries whenever something in the
 * settings changes that might affect invalidity data or related claims.
 */
export const invalidateSettingsRelatedQueries = (
	queryClient: QueryClient,
	projectId: string,
	portfolioId: string | null,
) => {
	invalidateMultipleQueries(queryClient, [
		QUERY_KEYS.invaliditySettings(projectId, portfolioId),
		QUERY_KEYS.project.claims(projectId),
	])
	invalidateInvalidityRelatedQueries(queryClient, projectId)
}

export const invalidateClaimRelatedQueries = (
	queryClient: QueryClient,
	documentId: string,
	projectId: string,
) => {
	if (projectId) {
		if (isDocumentSubject(queryClient, documentId, projectId)) {
			invalidateMultipleQueries(queryClient, [
				QUERY_KEYS.project.claims(projectId),
				QUERY_KEYS.project.claimStrengths(projectId),
			])
		}
	}
	invalidateInvalidityRelatedQueries(queryClient, projectId)
	invalidateMultipleQueries(queryClient, [QUERY_KEYS.document.full(documentId)])
}

/**
 * Helper function to check if the provided document is the subject of the project.
 */
const isDocumentSubject = (
	queryClient: QueryClient,
	documentId: string,
	projectId: string,
) => {
	if (projectId) {
		const projectData = queryClient.getQueryData<ProjectMetadata>(
			QUERY_KEYS.project.metadata(projectId),
		)
		if (projectData?.subjectId === documentId) {
			return true
		}
	}
	return false
}

export const invalidateInvalidityChartDataForLimitation = (
	queryClient: QueryClient,
	projectId: string,
	chartId: string,
	limitationId: string,
) => {
	queryClient.invalidateQueries({
		queryKey: PARTIAL_INVALIDITY_KEYS.limitationDataForLimitation(
			projectId,
			limitationId,
		),
		exact: false,
	})

	queryClient.invalidateQueries({
		queryKey: PARTIAL_INVALIDITY_KEYS.limitationChartData(projectId, chartId, [
			limitationId,
		]),
		exact: false,
	})
}

export const invalidateAllInvalidityChartData = (
	queryClient: QueryClient,
	projectId: string | null,
) => {
	queryClient.invalidateQueries({
		queryKey: PARTIAL_INVALIDITY_KEYS.limitationData(projectId),
		exact: false,
	})
	queryClient.invalidateQueries({
		queryKey: PARTIAL_INVALIDITY_KEYS.documentSummaryData(projectId),
		exact: false,
	})
	queryClient.invalidateQueries({
		queryKey: QUERY_KEYS.project.charts.all(projectId),
	})
}
