/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { InfoPopover, MultiDocumentSelect } from "@/components"
import SearchInput from "@/components/search/SearchInput"
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle"
import { useNavigationState } from "@/hooks"
import { type ParentDocumentMetadata, SearchMode } from "@/types"
import type React from "react"

interface SearchDocumentsHeaderProps {
	searchMode: SearchMode
	setSearchMode: (mode: SearchMode) => void
	handleDocumentChange: (selected: string[]) => void
	handleSemanticSearch: () => void
	searchQuery: string
	setSearchQuery: (query: string) => void
	disabled: boolean
	documents: ParentDocumentMetadata[]
}

export default function SearchDocumentsHeader({
	searchMode,
	setSearchMode,
	handleDocumentChange,
	handleSemanticSearch,
	searchQuery,
	setSearchQuery,
	disabled,
	documents,
}: SearchDocumentsHeaderProps) {
	const { isDocumentsPage } = useNavigationState()
	const infoPopoverContent =
		"Semantic search finds citations that match your query in meaning. Keyword search finds citations with exact matches to the language in your query."

	const handleSearchQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setSearchQuery(e.target.value)
	}

	const handleSearchModeChange = (value: SearchMode) => {
		if (value !== searchMode && value !== null) {
			setSearchMode(value)
		}
	}

	return (
		<div className="flex mb-2.5 items-center gap-2 px-2 pt-2">
			{!isDocumentsPage && (
				<div className="max-w-500px">
					<MultiDocumentSelect
						handleChange={handleDocumentChange}
						disabled={disabled}
						documents={documents}
					/>
				</div>
			)}
			<div className="flex-grow">
				<SearchInput
					placeholder="Search selected documents..."
					disabled={disabled}
					value={searchQuery}
					onChange={handleSearchQueryChange}
					onSearch={handleSemanticSearch}
					className="min-w-[250px]"
				/>
			</div>
			<div className="flex gap-1">
				<ToggleGroup
					type="single"
					value={searchMode}
					onValueChange={(value) => value && handleSearchModeChange(value as SearchMode)}
					disabled={disabled}
				>
					<ToggleGroupItem
						value={SearchMode.SEMANTIC}
						aria-label="semantic search"
						className={`px-3 py-2 ${searchMode === SearchMode.SEMANTIC ? "bg-tertiary text-primary-foreground" : ""}`}
					>
						Semantic
					</ToggleGroupItem>
					<ToggleGroupItem
						value={SearchMode.KEYWORD}
						aria-label="keyword search"
						className={`px-3 py-2 ${searchMode === SearchMode.KEYWORD ? "bg-tertiary text-primary-foreground" : ""}`}
					>
						Keyword
					</ToggleGroupItem>
				</ToggleGroup>
				<InfoPopover content={infoPopoverContent} />
			</div>
		</div>
	)
}
