import type { DocumentChunk } from "@/types"
import type React from "react"
/**
 * Example regexes for figure triggers, numbers, and full references
 */
export const figureNumberRegex = /\d+(?:[A-Za-z]|\([A-Za-z]\))?/g
export const figureTriggerRegex = /\b(?:fig(?:\.|s)?|figures)\b\.?/i
export const fullFigureRegex = new RegExp(
	// 1) The figure trigger (FIG, FIG., FIGS, FIGS., Figures, etc.)
	"(" +
		"(?:[Ff][Ii][Gg](?:\\.|s)?|[Ff]igures)" +
		"\\.?" + // optional trailing period
		")" +
		// optional whitespace
		"\\s*" +
		// 2) The actual figure references, e.g. "16(a) and 16(b)", "2(a)-2(b)", etc.
		"(" +
		"(?:" +
		"\\d+(?:[A-Za-z]|\\([A-Za-z]\\))?" +
		"(?:\\s*(?:-|and|,|&)\\s*\\d+(?:[A-Za-z]|\\([A-Za-z]\\))?)*" +
		")" +
		")",
	"gi",
)

/**
 * parseFigureReferences
 * Splits a single string based on (FIG, FIGS., etc.) then extracts each sub-figure reference
 * so you can link or handle them individually.
 */
export function parseFigureReferences(
	text: string,
	figureChunks?: DocumentChunk[],
	handleFigureReferenceClick?: (figureChunk: DocumentChunk) => void,
	_isDarkMode?: boolean,
): React.ReactNode[] {
	const matches = Array.from(text.matchAll(fullFigureRegex))
	if (!matches.length) return [text]

	const result: React.ReactNode[] = []
	let lastIndex = 0

	for (const match of matches) {
		const fullMatch = match[0] // e.g. "FIGS. 16(a) and 16(b)"
		const matchIndex = match.index ?? 0
		const triggerText = match[1] // e.g. "FIGS."
		const refsText = match[2] // e.g. "16(a) and 16(b)"

		// Push the text before this match
		result.push(text.slice(lastIndex, matchIndex))

		// We allow sub-references to be separated by "and", "-", commas, or "&".
		const tokens = refsText.split(/(\s*(?:and|,|&|-)\s*)/i)
		const figureNumbers = tokens.filter((token) => token.match(figureNumberRegex))

		if (figureNumbers.length === 1) {
			const chunkMatch = figureChunks?.find((c) => {
				const candidate = c.figureNumber?.toUpperCase() || ""
				return candidate.includes(figureNumbers[0].toUpperCase())
			})
			result.push(
				<span
					key={crypto.randomUUID()}
					className={`cursor-pointer ${
						chunkMatch
							? "bg-blue-100 dark:bg-blue-900 border-b border-blue-500 dark:border-blue-400"
							: "bg-yellow-100 dark:bg-yellow-900"
					}`}
					style={chunkMatch ? { borderBottomStyle: "dashed", borderBottomWidth: "1px" } : {}}
					onClick={() => {
						if (chunkMatch && handleFigureReferenceClick) {
							handleFigureReferenceClick(chunkMatch)
						}
					}}
				>
					{`${triggerText} ${figureNumbers[0]}`}
				</span>,
			)
		} else {
			result.push(triggerText)
			tokens.forEach((token, _i) => {
				if (!token) return
				if (token.match(figureNumberRegex)) {
					const chunkMatch = figureChunks?.find((c) => {
						const candidate = c.figureNumber?.toUpperCase() || ""
						return candidate.includes(token.toUpperCase())
					})
					result.push(
						<span
							key={crypto.randomUUID()}
							className={`cursor-pointer ${
								chunkMatch
									? "bg-blue-100 dark:bg-blue-900 border-b border-blue-500 dark:border-blue-400"
									: "bg-yellow-100 dark:bg-yellow-900"
							}`}
							style={
								chunkMatch ? { borderBottomStyle: "dashed", borderBottomWidth: "1px" } : {}
							}
							onClick={() => {
								if (chunkMatch && handleFigureReferenceClick) {
									handleFigureReferenceClick(chunkMatch)
								}
							}}
						>
							{token}
						</span>,
					)
				} else {
					result.push(token)
				}
			})
		}

		lastIndex = matchIndex + fullMatch.length
	}

	// Add any trailing text after the last match
	result.push(text.slice(lastIndex))

	return result
}
