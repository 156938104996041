import { searchApi } from "@/api/searchApi"
import { useUnprocessedDocumentsContext } from "@/context/UnprocessedDocumentsContext"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import { useAppStateStore } from "@/store"
import { ClaimTypes } from "@/types"
import type { ApiResponse, ClaimStrength, PatentNumberOption } from "@/types"
import type { SearchFilters } from "@/types"
import { DocumentRole, UnprocessedDocumentType } from "@/types"
import { convertToUtcDateString } from "@/utils/dateUtils"
import { useState } from "react"
import { useGetProjectPriorityData } from "./project/useGetProjectPriorityData"

export const useSearchFilters = (initialFilters?: Partial<SearchFilters>) => {
	const { priorityDate } = useGetProjectPriorityData()

	// Get initial toDate from priority/filing dates
	const getInitialToDate = () => {
		if (priorityDate) {
			const parsedDate = new Date(priorityDate)
			if (!Number.isNaN(parsedDate.getTime())) {
				return parsedDate
			}
		}

		if (priorityDate) {
			return new Date(priorityDate)
		}

		return null
	}

	const getDefaultFilters = (): SearchFilters => ({
		fromDate: null,
		toDate: getInitialToDate(),
		cpcCodes: [],
		countryCodes: [],
		assignees: [],
		types: [],
		statuses: [],
		maxResults: 25,
		dedupeFamilyId: true,
		selectedClaims: [],
		unselectedClaims: [],
		claimType: ClaimTypes.INDEPENDENT_CLAIMS,
	})

	const [filters, setFilters] = useState<SearchFilters>({
		...getDefaultFilters(),
		...initialFilters,
	})

	const updateFilter = <K extends keyof SearchFilters>(
		key: K,
		value: SearchFilters[K],
	) => {
		setFilters((prev) => {
			const newFilters = { ...prev, [key]: value }
			return newFilters
		})
	}

	const resetFilters = () => {
		setFilters(getDefaultFilters())
	}

	// Add helper functions for claim management
	const updateSelectedClaims = (claims: ClaimStrength[]) => {
		updateFilter("selectedClaims", claims)
	}

	const updateUnselectedClaims = (claims: ClaimStrength[]) => {
		updateFilter("unselectedClaims", claims)
	}

	return {
		filters,
		updateFilter,
		setFilters,
		resetFilters,
		updateSelectedClaims,
		updateUnselectedClaims,
	}
}

/**
 * @description A custom hook for prior art searching
 */
const usePriorArtSearch = () => {
	const { handleError } = useApi()
	const { addUnprocessedDocuments } = useUnprocessedDocumentsContext()
	const { addErrorMessage } = useAppStateStore()
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)

	// Constants for retry logic
	const MAX_RETRIES = 2
	const RETRY_DELAY = 5000

	const resetSearch = () => {
		setIsLoading(false)
		setIsError(false)
	}

	const searchPriorArt = async (
		projectId: string,
		searchPayload: {
			max_results?: number
			to_date?: string
			from_date?: string
			types?: string[]
			statuses?: string[]
			cpc_codes?: string[]
			country_codes?: string[]
			assignees?: string[]
			dedupe_family_id?: boolean
			limitation_ids?: string[]
			claim_type?: ClaimTypes
		} = {},
	): Promise<ApiResponse<PatentNumberOption[]>> => {
		try {
			const priorArt: any = await searchApi.search(projectId, searchPayload)
			const parsedPriorArt = priorArt.map((patent: any) => {
				const prefix = patent.number.match(/^[A-Z]+/)?.[0] || ""
				return { ...patent, number: patent.number.replace(/-/g, ""), prefix }
			})
			return { success: true, data: parsedPriorArt }
		} catch (error) {
			console.error("Error searching for prior art:", error)
			return handleError(error, "Error searching for prior art")
		}
	}

	const handleSearch = async (
		projectId: string,
		filters: SearchFilters,
		retryCount = 0,
	) => {
		setIsLoading(true)
		setIsError(false)

		const payload = {
			max_results: filters.maxResults || 10,
			dedupe_family_id: filters.dedupeFamilyId,
			claim_type: filters.claimType,
			...(filters.toDate || filters.fromDate
				? {
						to_date: filters.toDate ? convertToUtcDateString(filters.toDate) : undefined,
						from_date: filters.fromDate
							? convertToUtcDateString(filters.fromDate)
							: undefined,
					}
				: {}),
			...(filters.types.length > 0 && { types: filters.types }),
			...(filters.statuses.length > 0 && { statuses: filters.statuses }),
			...(filters.cpcCodes.length > 0 && { cpc_codes: filters.cpcCodes }),
			...(filters.countryCodes.length > 0 && { country_codes: filters.countryCodes }),
			...(filters.assignees.length > 0 && { assignees: filters.assignees }),
			...(filters.selectedClaims.length > 0 && {
				limitation_ids: filters.selectedClaims.map((item) => item.id),
			}),
		}

		try {
			const priorArt = await searchPriorArt(projectId, payload)
			if (Array.isArray(priorArt.data) && priorArt.data.length > 0) {
				addUnprocessedDocuments(
					DocumentRole.PRIOR_ART,
					UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION,
					{
						patentDetails: priorArt.data,
					},
				)
				setIsLoading(false)
			} else {
				if (retryCount < MAX_RETRIES) {
					await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY))
					return handleSearch(projectId, filters, retryCount + 1)
				}
				addErrorMessage("Search timed out. Please try again.")
				setIsLoading(false)
				setIsError(true)
			}
		} catch (_error) {
			if (retryCount < MAX_RETRIES) {
				await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY))
				return handleSearch(projectId, filters, retryCount + 1)
			}
			setIsLoading(false)
			setIsError(true)
		}
	}

	return {
		searchPriorArt,
		handleSearch,
		isLoading,
		isError,
		resetSearch,
	}
}

export default usePriorArtSearch
