import { ConfirmRemoveChunkModal } from "@/components"
import FigureChunk from "@/components/images/FigureChunk"
import FiguresModal from "@/components/images/FiguresModal"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import ImageScrollBox from "@/components/images/ImageScrollBox"
import { Label } from "@/components/ui/label"
import useDocumentFigures from "@/hooks/document/useDocumentFigures"
import type { DocumentChunk } from "@/types/invalidity"
import type React from "react"
import { useState } from "react"

/**
 * @description Subject abstract and images component
 * @param {object} details - The details to display
 */
interface FiguresProps {
	documentId: string
	showTitle?: boolean
	editMode?: boolean
}

const Figures: React.FC<FiguresProps> = ({
	documentId,
	showTitle = true,
	editMode = false,
}) => {
	const [open, setOpen] = useState(false)
	const [selectedFigure, setSelectedFigure] = useState<DocumentChunk | null>(null)
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
	const [figureToDelete, setFigureToDelete] = useState<DocumentChunk | null>(null)

	const { figureChunks, deleteFigure, isError, isLoading } =
		useDocumentFigures(documentId)

	// error and loading handling
	if (isError) {
		return <div className="text-red-500">Error loading figures</div>
	}

	if (isLoading) {
		return <div className="animate-pulse">Loading figures...</div>
	}

	const handleFigureClick = (chunk: DocumentChunk) => {
		setSelectedFigure(chunk)
		setOpen(true)
	}

	const handleDeleteClick = (chunk: DocumentChunk, e: React.MouseEvent) => {
		e.stopPropagation() // Prevent figure click when clicking delete
		setFigureToDelete(chunk)
		setIsDeleteDialogOpen(true)
	}

	const handleConfirmDelete = async () => {
		if (!figureToDelete) return
		await deleteFigure({ documentId, documentChunkId: figureToDelete.id })
		setIsDeleteDialogOpen(false)
		setFigureToDelete(null)
	}

	return (
		<div>
			{figureChunks && figureChunks.length > 0 && (
				<div className="mb-4">
					{showTitle && (
						<div className="flex items-center gap-2 mb-2">
							<Label>Figures ({figureChunks.length})</Label>
						</div>
					)}
					<ImageScrollBox>
						<div className="flex gap-2 p-2">
							{figureChunks.map((chunk) => (
								<FigureChunk
									key={chunk.id}
									figureChunk={chunk}
									onClick={() => handleFigureClick(chunk)}
									editMode={editMode}
									onDeleteClick={handleDeleteClick}
								/>
							))}
						</div>
					</ImageScrollBox>
				</div>
			)}

			<FiguresModal
				documentId={documentId}
				selectedFigure={selectedFigure}
				open={open}
				onOpenChange={setOpen}
			/>

			<ConfirmRemoveChunkModal
				open={isDeleteDialogOpen}
				onOpenChange={setIsDeleteDialogOpen}
				onConfirm={handleConfirmDelete}
				isFigure={true}
			/>
		</div>
	)
}

export default Figures
