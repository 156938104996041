/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { usePriorArt } from "@/hooks/prior-art/usePriorArt"
import { PageName } from "@/types/pages"
import type React from "react"
import ProjectPage from "../../components/projects/ProjectPage"
import { ReferencesTable } from "./components"

/**
 * @description Reference details page for invalidity project or portfolio
 */
const ReferencesPage: React.FC = () => {
	const { priorArtDocuments, isLoading } = usePriorArt()

	if (isLoading) {
		return (
			<ProjectPage pageName={PageName.ProjectReferences}>
				<div className="flex justify-center items-center flex-col mt-12">
					<Loader message="Loading..." />
				</div>
			</ProjectPage>
		)
	}

	return (
		<ProjectPage pageName={PageName.ProjectReferences}>
			{priorArtDocuments && <ReferencesTable documents={priorArtDocuments} />}
		</ProjectPage>
	)
}

export default ReferencesPage
