import Figure from "./Figure"

interface FigureUrlsDisplayProps {
	figureUrls: string[]
	onFigureClick?: (imageUrl: string, documentId?: string) => void
	selectedIndex?: number
	documentId?: string
}

export function FigureUrlsDisplay({
	figureUrls,
	onFigureClick,
	selectedIndex,
	documentId,
}: FigureUrlsDisplayProps) {
	const urls = Array.isArray(figureUrls) ? figureUrls : []

	return (
		<div className="w-full">
			<div className="overflow-x-auto">
				<div className="flex gap-2 p-2">
					{urls.map((url, index) => (
						<div
							key={`${url}-${index}`}
							className={`flex-shrink-0 ${
								selectedIndex === index ? "border-2 border-blue-500 shadow-md scale-105" : ""
							}`}
						>
							<Figure
								imageUrl={url}
								imageName={url.split("/").pop() || `Figure ${index + 1}`}
								onClick={() => onFigureClick?.(url, documentId)}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default FigureUrlsDisplay
