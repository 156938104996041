/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { ErrorMessage } from "@/components"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { Spinner } from "@/components/ui/spinner"
import { H4 } from "@/components/ui/typography"
import useFullDocument from "@/hooks/document/useFullDocument"
import { VisuallyHidden } from "@radix-ui/react-visually-hidden"
import type React from "react"
import { useRef } from "react"
import DocumentDetailLayout from "./DocumentDetailLayout"

interface DocumentViewerProps {
	open: boolean
	handleClose: () => void
	documentId: string
	selectedDocumentChunkId?: string
	citationText?: string
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({
	open,
	handleClose,
	documentId,
	selectedDocumentChunkId,
	citationText,
}) => {
	const { document, isLoading, isError } = useFullDocument(documentId)
	const highlightedRef = useRef<HTMLDivElement | HTMLTableRowElement | null>(null)

	if (!open) {
		return null
	}

	if (!document || isLoading) {
		return (
			<Dialog open={open} onOpenChange={handleClose}>
				<DialogContent className="max-w-[90vw] max-h-[90vh] flex flex-col p-0 overflow-hidden">
					<div className="flex items-center justify-center h-full m-12">
						<Spinner />
					</div>
				</DialogContent>
			</Dialog>
		)
	}

	if (isError) {
		return (
			<Dialog open={open} onOpenChange={handleClose}>
				<DialogContent className="max-w-[90vw] max-h-[90vh] flex flex-col p-0 overflow-hidden">
					<div className="flex items-center justify-center h-full m-12">
						<ErrorMessage />
					</div>
				</DialogContent>
			</Dialog>
		)
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="max-w-[90vw] max-h-[90vh] flex flex-col p-0 overflow-hidden">
				<VisuallyHidden>
					<DialogTitle>
						<H4>{document.nickname}</H4>
					</DialogTitle>
				</VisuallyHidden>
				<VisuallyHidden asChild>
					<DialogDescription>{document?.document?.title} details</DialogDescription>
				</VisuallyHidden>
				<div className="flex flex-col h-full">
					<DialogHeader className="sticky top-0 z-10 bg-background py-2 px-3 border-b">
						<div className="flex justify-between items-center gap-2">
							<div className="flex items-center gap-2">
								<H4>{document.nickname}</H4>
							</div>
						</div>
					</DialogHeader>

					<DocumentDetailLayout
						document={document}
						selectedDocumentChunkId={selectedDocumentChunkId}
						startDisplayPDF={!!selectedDocumentChunkId}
						highlightedRef={highlightedRef}
						citationText={citationText}
					/>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default DocumentViewer
