import { ParentType } from "@/types"
import type React from "react"
import { createContext, useContext } from "react"

export interface ParentContextType {
	projectId: string | null // can be null if not available
	portfolioId: string | null // can be null if not available
	parentType: ParentType
	parentId: string // computed value that is guaranteed to be non-null
	inPortfolio: boolean
}

const ParentContext = createContext<ParentContextType | undefined>(undefined)

export function useParentContext(): ParentContextType {
	const context = useContext(ParentContext)
	if (!context) {
		throw new Error("useParentContext must be used within a ParentProvider")
	}
	return context
}

interface ParentProviderProps {
	projectId?: string | null
	portfolioId?: string | null
	children: React.ReactNode
}

export function ParentProvider({
	projectId = null,
	portfolioId = null,
	children,
}: ParentProviderProps) {
	if (projectId === null && portfolioId === null) {
		throw new Error("At least one of projectId or portfolioId must be provided")
	}

	const parentType: ParentType =
		portfolioId !== null ? ParentType.PORTFOLIO : ParentType.PROJECT
	const parentId: string = portfolioId !== null ? portfolioId : (projectId as string)
	const inPortfolio = parentType === ParentType.PORTFOLIO

	return (
		<ParentContext.Provider
			value={{
				projectId,
				portfolioId,
				parentType,
				parentId,
				inPortfolio,
			}}
		>
			{children}
		</ParentContext.Provider>
	)
}
