import { EditableSentenceDisplay } from "@/components"
import { Button } from "@/components/ui/button"
import { CardContent } from "@/components/ui/card"
import { Card } from "@/components/ui/card"
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable"
import useSentencesEditor from "@/hooks/useSentencesEditor"
import type { DocumentCoverSentence, Quote } from "@/types"
import type React from "react"
import { useState } from "react"

type SentencesEditorProps = {
	onSave: (
		sentences: DocumentCoverSentence[],
		chunkIdToTextMap: Map<string, string>,
	) => void
	initialSentences: DocumentCoverSentence[]
	documentId: string
}

const defaultSizes = {
	sentences: 70,
	summary: 30,
}

const SentencesEditor: React.FC<SentencesEditorProps> = ({
	onSave,
	initialSentences,
	documentId,
}) => {
	const [sentences, setSentences] = useState<DocumentCoverSentence[]>(initialSentences)
	const [panelSizes, setPanelSizes] = useState<{
		sentences: number
		summary: number
	}>(defaultSizes)
	const [panelKey, setPanelKey] = useState(0) // Used to force a re-render of the panel when the user clicks on resize handle

	const resetPanels = () => {
		setPanelKey((prev) => prev + 1)
		setPanelSizes(defaultSizes)
	}

	const finalSummary: string = sentences
		.map((sentence) => sentence.sentence.map((part) => part.text).join(" "))
		.join(" ")

	// Check if the user edited summary is valid
	const quotes: Quote[][] = sentences.map((sentence) => {
		return sentence.sentence.filter((part): part is Quote => "chunkId" in part)
	})

	const { documentChunks } = useSentencesEditor(
		quotes.flatMap((sentenceQuotes) => sentenceQuotes.map((quote) => quote.chunkId)),
		documentId,
	)

	const chunkIdToTextMap = new Map<string, string>()
	for (const chunk of documentChunks ?? []) {
		const cleanedChunkText = chunk.text.replace(/\n/g, " ").replace(/\s+/g, " ").trim()
		chunkIdToTextMap.set(chunk.id, cleanedChunkText)
	}

	// Compute validation for each sentence’s quote parts
	const sentenceValidationErrors = sentences.map((sentence) =>
		sentence.sentence.map((part) => {
			if ("chunkId" in part) {
				const chunkText = chunkIdToTextMap.get(part.chunkId)
				const cleanedQuote = part.text.replace(/["]/g, "").replace(/\.$/, "")
				// If the chunkText is missing or doesn’t include the cleaned quote, return an error message.
				if (!chunkText || !chunkText.includes(cleanedQuote)) {
					return "This quote is not a substring of its associated chunk."
				}
			}
			return null
		}),
	)

	const isSaveValid = sentenceValidationErrors.every((sentenceErrors) =>
		sentenceErrors.every((error) => error === null),
	)

	return (
		<div className="h-full w-full overflow-hidden">
			<ResizablePanelGroup key={panelKey} direction="vertical">
				<div className="flex items-center justify-between py-2 px-4 border-b bg-gray-100">
					<h2 className="text-lg font-semibold">Sentences</h2>
					<div className="flex gap-4">
						<div className="flex items-center gap-1">
							<span className="w-3 h-3 bg-blue-500 border-2 border-black-500 inline-block rounded-full" />
							<span className="text-sm">Quote</span>
						</div>
						<div className="flex items-center gap-1">
							<span className="w-3 h-3 bg-white border-2 border-black-500 inline-block rounded-full" />
							<span className="text-sm">Filler</span>
						</div>
					</div>
				</div>
				<ResizablePanel
					defaultSize={panelSizes.sentences}
					onResize={(size) => {
						setPanelSizes({ ...panelSizes, sentences: size })
					}}
				>
					<div className="flex flex-col h-full">
						<div className="flex-1 p-4 overflow-y-auto">
							{sentences.map((sentence, index) => (
								<EditableSentenceDisplay
									key={index}
									sentence={sentence}
									validationErrors={sentenceValidationErrors[index]}
									onChange={(updatedSentence) => {
										const newSentences = [...sentences]
										newSentences[index] = updatedSentence
										setSentences(newSentences)
									}}
								/>
							))}
						</div>
					</div>
				</ResizablePanel>
				<ResizableHandle
					withHandle
					onClick={() => {
						resetPanels()
					}}
				/>
				<ResizablePanel
					defaultSize={panelSizes.summary}
					onResize={(size) => {
						setPanelSizes({ ...panelSizes, summary: size })
					}}
				>
					<div className="flex flex-col h-full">
						{/* Header */}
						<div className="flex-shrink-0 py-2 px-4 border-b bg-gray-100">
							<h2 className="text-lg font-semibold">Summary</h2>
						</div>

						<div className="flex-1 overflow-y-auto p-4">
							<Card className="h-fit">
								<CardContent className="p-4 text-sm">{finalSummary}</CardContent>
							</Card>
						</div>
					</div>
				</ResizablePanel>
				{/* Button row stays fixed at the bottom */}
				<div className="flex-shrink-0 flex justify-end gap-2 px-4 pb-4 pt-2">
					<Button
						variant="outline"
						onClick={() => {
							setSentences(initialSentences)
						}}
					>
						Reset
					</Button>
					<Button
						disabled={!isSaveValid}
						onClick={() => {
							const cleanedSentences = sentences.map((sentence) => ({
								...sentence,
								sentence: sentence.sentence.map((part) => {
									if ("chunkId" in part) {
										return {
											...part,
											text: part.text.replace(/["]/g, "").replace(/\.$/, ""),
										}
									}
									return part
								}),
							}))
							onSave(cleanedSentences, chunkIdToTextMap)
						}}
					>
						Save
					</Button>
				</div>
			</ResizablePanelGroup>
		</div>
	)
}

export default SentencesEditor
