/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import UnprocessedDocumentsList from "@/components/documents/unprocessed/UnprocessedDocumentsList"
import SearchFiltersComponent from "@/components/search/SearchFilters"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { useParentContext } from "@/context/ParentContext"
import { useUnprocessedDocumentsContext } from "@/context/UnprocessedDocumentsContext"
import { usePriorArtSearch } from "@/hooks"
import { usePatentDetails } from "@/hooks"
import { useSearchFilters } from "@/hooks/usePriorArtSearch"
import useProcessDocuments from "@/hooks/useProcessDocuments"
import { ProcessType, type UnprocessedDocument } from "@/types"
import { VisuallyHidden } from "@radix-ui/react-visually-hidden"
import type React from "react"
import { useState } from "react"

interface SearchReferencesModalProps {
	open: boolean
	handleClose: () => void
}

/**
 * @description Search for references modal (merged patch references + search references)
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 */
const SearchReferencesModal: React.FC<SearchReferencesModalProps> = ({
	open,
	handleClose,
}) => {
	/**
	 * Common references logic
	 */
	const { handleSearch, isLoading, isError, resetSearch } = usePriorArtSearch()
	const { projectId, portfolioId } = useParentContext()
	const { processDocuments } = useProcessDocuments(projectId, portfolioId)
	const {
		unprocessedDocuments,
		removeUnprocessedDocument,
		updateSelectedKindCode,
		resetAll,
	} = useUnprocessedDocumentsContext()
	const { filters, updateFilter, resetFilters } = useSearchFilters()

	// Basic UI state
	const [hasSubmitted, setHasSubmitted] = useState(false)
	const [selectedReferences, setSelectedReferences] = useState<UnprocessedDocument[]>([])
	// Add state for selected document IDs
	const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([])

	// Budget confirmation state for adding references
	const [isProcessingReferences, setIsProcessingReferences] = useState<boolean>(false)
	const [showBudgetConfirmation, setShowBudgetConfirmation] = useState<boolean>(false)
	const [budgetExceededAmount, setBudgetExceededAmount] = useState<number | null>(null)
	const [pendingReferences, setPendingReferences] = useState<UnprocessedDocument[]>([])

	// Get the hook for checking reference (patent) details
	const { getPatentDetails } = usePatentDetails()

	// Handle selection changes
	const handleSelectionChange = (documentId: string, isSelected: boolean) => {
		setSelectedDocumentIds((prev) =>
			isSelected ? [...prev, documentId] : prev.filter((id) => id !== documentId),
		)
	}

	// Reset modal state
	const resetModal = () => {
		setHasSubmitted(false)
		setSelectedReferences([])
		resetFilters()
		resetSearch()
		setSelectedDocumentIds([])
		resetAll()
		// Also reset any pending budget confirmation
		resetBudgetCheck()
	}

	// Resets the budget check confirmation state.
	const resetBudgetCheck = () => {
		setShowBudgetConfirmation(false)
		setBudgetExceededAmount(null)
		setPendingReferences([])
	}

	/**
	 * Add references to project with budget check.
	 *
	 * When called, this handler extracts the patent/reference numbers from the selected documents,
	 * calls getPatentDetails (which internally checks the remaining budget), and if any detail indicates
	 * the budget will be exceeded, pops up a confirmation step.
	 */
	const handleAddReferences = async (addAll = false) => {
		setIsProcessingReferences(true)
		const documentsToProcess: UnprocessedDocument[] = addAll
			? unprocessedDocuments
			: unprocessedDocuments.filter((doc) => selectedDocumentIds.includes(doc.id))

		if (documentsToProcess.length === 0) {
			setIsProcessingReferences(false)
			return
		}

		// Extract the reference numbers (assuming each document has a property "number")
		const referenceNumbers = documentsToProcess.map((doc) => doc.patentDetails?.number)
		try {
			const response = await getPatentDetails(referenceNumbers, projectId, false, 1)
			// If any result signals a budget exceedance, present a confirmation UI
			const overBudgetItem = response.find((item) => item.isBudgetExceeded)
			if (overBudgetItem) {
				// Set budgetExceededAmount similar to PatentNumberInput and store processed docs.
				setBudgetExceededAmount(-overBudgetItem.remainingBudget)
				setPendingReferences(documentsToProcess)
				setShowBudgetConfirmation(true)
				setIsProcessingReferences(false)
				return
			}
			// Otherwise, immediately process documents.
			handleClose()
			await processDocuments(
				ProcessType.ADD_PRIOR_ART,
				portfolioId,
				[projectId],
				documentsToProcess,
			)
		} catch (error) {
			console.error("Error during budget check:", error)
		} finally {
			setIsProcessingReferences(false)
		}
	}

	/**
	 * When the user confirms to proceed despite budget constraints,
	 * this function will process the pending references.
	 */
	const confirmAddReferences = async () => {
		setIsProcessingReferences(true)
		try {
			handleClose()
			await processDocuments(
				ProcessType.ADD_PRIOR_ART,
				portfolioId,
				[projectId],
				pendingReferences,
			)
		} catch (error) {
			console.error("Error processing references:", error)
		} finally {
			setIsProcessingReferences(false)
			resetBudgetCheck()
		}
	}

	// Update search click handler
	const handleSearchClick = async () => {
		setHasSubmitted(true)
		await handleSearch(projectId, filters)
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="sm:max-w-[85vw] max-h-[85vh] w-full flex flex-col min-w-[600px]">
				<DialogHeader>
					<DialogTitle>Search for Patents</DialogTitle>
					<VisuallyHidden asChild>
						<DialogDescription>Search for patents to add to this project.</DialogDescription>
					</VisuallyHidden>
				</DialogHeader>

				{!hasSubmitted && (
					<div className="flex-grow overflow-y-auto gap-6">
						{/* Search Filters */}
						<SearchFiltersComponent
							disabled={isLoading}
							isInCreate={false}
							filters={filters}
							updateFilter={updateFilter}
						/>
					</div>
				)}

				{/* ***********************************
					Results, Loader, or Budget Confirmation
					********************************** */}
				{hasSubmitted &&
					(isLoading || isProcessingReferences ? (
						<div className="flex items-center justify-center h-64">
							<Loader message={isLoading ? "Searching..." : "Processing..."} />
						</div>
					) : showBudgetConfirmation ? (
						<div className="space-y-4">
							<Alert variant="destructive">
								<AlertDescription>
									This action will exceed this project's budget by $
									{budgetExceededAmount?.toLocaleString()}. Are you sure you want to continue?
								</AlertDescription>
							</Alert>
							{pendingReferences.length > 0 && (
								<div className="mt-4 border rounded p-4">
									<h3 className="text-sm font-medium mb-2">References to be added:</h3>
									<div className="space-y-3">
										{pendingReferences.map((doc) => (
											<div key={doc.id} className="text-sm">
												<div className="font-medium">
													{doc.patentDetails?.number || "Reference"}
												</div>
											</div>
										))}
									</div>
								</div>
							)}
							<div className="flex justify-end gap-2">
								<Button variant="outline" onClick={resetBudgetCheck} className="h-9">
									Cancel
								</Button>
								<Button variant="destructive" onClick={confirmAddReferences} className="h-9">
									Continue Anyway
								</Button>
							</div>
						</div>
					) : (
						<div>
							{unprocessedDocuments?.length > 0 ? (
								<div className="flex flex-col h-[calc(85vh-120px)]">
									<UnprocessedDocumentsList
										documents={unprocessedDocuments}
										onRemove={removeUnprocessedDocument}
										updateSelectedKindCode={updateSelectedKindCode}
										showActions={true}
										selectedDocuments={selectedDocumentIds}
										onSelectionChange={handleSelectionChange}
										hideRemoveButton={true}
									/>
									<div className="flex flex-wrap justify-between mt-4 gap-2">
										<Button variant="outline" onClick={resetModal} className="h-9">
											Clear Results
										</Button>
										<div className="flex gap-2">
											<Button
												className="h-9"
												variant={selectedReferences.length === 0 ? "default" : "outline"}
												onClick={() => handleAddReferences(true)}
											>
												Add All ({unprocessedDocuments.length})
											</Button>
											<Button
												variant="default"
												className="h-9"
												onClick={() => handleAddReferences(false)}
												disabled={selectedDocumentIds.length === 0}
											>
												Add Selected ({selectedDocumentIds.length})
											</Button>
										</div>
									</div>
								</div>
							) : (
								<div className="flex flex-col items-center mt-8">
									<p className="mb-4">No references found. Try again.</p>
									<Button variant="outline" onClick={resetModal}>
										Search Again
									</Button>
								</div>
							)}
						</div>
					))}

				{isError && (
					<p className="text-red-500 mt-2">An error occurred. Please try again.</p>
				)}

				<DialogFooter>
					{!hasSubmitted && (
						<div className="flex justify-end mt-4 gap-2">
							<Button variant="outline" onClick={handleClose}>
								Cancel
							</Button>
							<Button onClick={handleSearchClick} variant="default">
								Search
							</Button>
						</div>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default SearchReferencesModal
