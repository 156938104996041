import type { DocumentChunk } from "@/types/invalidity"
import type { RefObject } from "react"
import { Card } from "../ui/card"

interface FigureThumbnailsProps {
	figureChunks: DocumentChunk[]
	selectedImageIndex: number
	setSelectedImageIndex: (index: number) => void
	thumbnailContainerRef: RefObject<HTMLDivElement>
	thumbnailRefs: RefObject<(HTMLDivElement | null)[]>
}

export function FigureThumbnails({
	figureChunks,
	selectedImageIndex,
	setSelectedImageIndex,
	thumbnailContainerRef,
	thumbnailRefs,
}: FigureThumbnailsProps) {
	// Ensure figureChunks is an array
	const chunks = Array.isArray(figureChunks) ? figureChunks : []

	return (
		<div className="flex-none">
			<div
				ref={thumbnailContainerRef}
				className="flex overflow-x-auto gap-2 p-2 scroll-smooth"
			>
				{chunks.map((chunk, index) => (
					<Card
						key={chunk.id || index}
						ref={(el) => {
							if (thumbnailRefs.current) {
								thumbnailRefs.current[index] = el
							}
						}}
						className={`shrink-0 cursor-pointer transition-all duration-200 ${
							selectedImageIndex === index
								? "border-2 border-blue-500 shadow-md scale-105"
								: "border hover:border-gray-400"
						}`}
						onClick={() => setSelectedImageIndex(index)}
					>
						<div className="h-[80px] w-[80px] m-2">
							<img
								src={chunk.figureUrl}
								alt={`Figure: ${chunk.figureNumber}`}
								className="rounded-md object-contain h-full w-full"
								style={{
									transform: `rotate(${(chunk.figureRotation || 0) * 90}deg)`,
									transition: "none",
								}}
							/>
						</div>
					</Card>
				))}
			</div>
		</div>
	)
}
