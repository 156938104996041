/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useState } from "react"

interface UseFileHandlerOptions {
	maxFiles?: number
	supportedFileTypes?: string[]
	maxFileSize?: number // default to 100MB
}

/**
 * @description Hook for handling file uploading (type and size validation)
 */
const useFileHandler = ({
	maxFiles,
	supportedFileTypes = [],
	maxFileSize = 100 * 1024 * 1024, // 100MB default
}: UseFileHandlerOptions = {}) => {
	const [files, setFiles] = useState<File[]>([])
	const [fileErrors, setFileErrors] = useState<string[]>([])
	const [fileLimitError, setFileLimitError] = useState<string>("")

	// Reset all state
	const resetAll = () => {
		setFiles([])
		setFileErrors([])
		setFileLimitError("")
	}

	/**
	 * Validates the file type based on supported file types.
	 * Expects supportedFileTypes to be normalized (e.g., all lower-case) and formatted consistently (either MIME types or extensions).
	 */
	function isFileTypeValid(file: File, supportedFileTypes: string[]): boolean {
		// Early return if no restrictions
		if (supportedFileTypes.length === 0) return true

		const fileMimeType = file.type.toLowerCase()
		const fileNameParts = file.name.split(".")
		const fileExtension =
			fileNameParts.length > 1 ? `.${fileNameParts.pop()?.toLowerCase()}` : ""

		return supportedFileTypes.some((type) => {
			// Compare both MIME type and extension
			return type === fileMimeType || type === fileExtension
		})
	}

	// Validate and add selected files
	const handleFileChange = (selectedFiles: File[]) => {
		// Combine with existing
		const currentFilesCount = files.length
		const totalFilesCount = currentFilesCount + selectedFiles.length
		let allowedFiles = selectedFiles

		// Enforce maxFiles limit and track skipped files
		if (maxFiles && totalFilesCount > maxFiles) {
			const allowedCount = maxFiles - currentFilesCount
			allowedFiles = selectedFiles.slice(0, allowedCount)
			const skippedFiles = selectedFiles.slice(allowedCount)
			const skippedFileNames = skippedFiles.map((file) => file.name).join(", ")
			setFileLimitError(
				`You can only upload up to ${maxFiles} file(s). The following file(s) were skipped: ${skippedFileNames}`,
			)
		} else {
			setFileLimitError("")
		}

		const validFiles: File[] = []
		const newErrors: string[] = []

		for (const file of allowedFiles) {
			const isTypeValid = isFileTypeValid(
				file,
				supportedFileTypes.map((t) => t.toLowerCase()),
			)
			const isFileSizeValid = file.size <= maxFileSize

			if (isTypeValid && isFileSizeValid) {
				validFiles.push(file)
			} else {
				let errorMessage = `[${file.name}] `
				if (!isTypeValid) {
					errorMessage += " unsupported file type."
				}
				if (!isFileSizeValid) {
					const sizeLimitMB = maxFileSize / (1024 * 1024)
					errorMessage += ` size exceeds the maximum of ${sizeLimitMB} MB.`
				}
				newErrors.push(errorMessage)
			}
		}

		// Update state
		setFiles((prev) => [...prev, ...validFiles])
		setFileErrors((prev) => [...prev, ...newErrors])
		return validFiles
	}

	// Remove a file
	const handleRemoveFile = (fileToRemove: File) => {
		setFiles((prevFiles) => prevFiles.filter((f) => f !== fileToRemove))
		setFileErrors([])
		setFileLimitError("")
	}

	return {
		files,
		fileErrors,
		fileLimitError,
		handleFileChange,
		handleRemoveFile,
		resetAll,
	}
}

export default useFileHandler
