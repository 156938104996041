import { Table } from "@/components/ui/table"
import { usePortfolioMatrix } from "@/hooks"
import type React from "react"
import PortfolioMatrixBody from "./components/PortfolioMatrixBody"
import PortfolioMatrixHeader from "./components/PortfolioMatrixHeader"

export const PortfolioMatrixTable: React.FC = () => {
	const { portfolioMatrix } = usePortfolioMatrix()
	if (!portfolioMatrix) return null

	return (
		<div className="w-full">
			<Table>
				<PortfolioMatrixHeader />
				<PortfolioMatrixBody />
			</Table>
		</div>
	)
}
