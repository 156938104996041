import { Loader } from "@/components"
import DocumentNamePill from "@/components/documents/DocumentNamePill"
import SearchInput from "@/components/search/SearchInput"
import { Label } from "@/components/ui/label"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useChunkToLimitationUpdates } from "@/features/charts/hooks/mutations/useChunkToLimitationUpdates"
import { useFullDocument } from "@/hooks"
import useDocumentFigures from "@/hooks/document/useDocumentFigures"
import { useDocumentSearch } from "@/hooks/useDocumentSearch"
import { BlockType, ChartColor, type DocumentChunk } from "@/types"
import type { ClaimCitation } from "@/types/invalidity"
import { CitationType } from "@/types/invalidity"
import type React from "react"
import { useMemo } from "react"
import { useChartsContext } from "../../context/ChartsContext"
import PotentialCitation from "./PotentialCitation"

interface DocumentPanelProps {
	documentId: string
}

const DocumentPanel: React.FC<DocumentPanelProps> = ({ documentId }) => {
	const {
		activeChunkId,
		selectedDocumentChunkRef,
		selectedDocumentSection,
		activeLimitationId,
		activeLimitationInvalidityData,
		isCombination,
	} = useChartsContext()

	const { document, isLoading: isDocLoading } = useFullDocument(documentId)

	const { figureChunks } = useDocumentFigures(documentId)

	// Add document search functionality
	const {
		query: docSearchQuery,
		setQuery: setDocSearchQuery,
		resultIds: docSearchResultIds,
		setResultIds: setDocSearchResultIds,
		loading: docSearchLoading,
		error: docSearchError,
		search: handleDocSearch,
	} = useDocumentSearch([documentId])

	// Update filteredDocChunks to include search results
	const filteredDocChunks = useMemo(() => {
		return (document?.document?.body ?? []).filter((chunk, index) => {
			if (!chunk) return false
			if (
				chunk.type === BlockType.CAPTION ||
				chunk.type === BlockType.FIGURE ||
				chunk.type === BlockType.UNKNOWN
			) {
				return false
			}
			if (chunk.text.trim() === "" && index === 0) {
				return false // hide empty text if first chunk
			}
			if (docSearchResultIds !== null && !docSearchResultIds.has(chunk.id)) {
				return false
			}
			return true
		})
	}, [document, docSearchResultIds])

	// Retrieve claim data from document
	const claims = useMemo(() => document?.document?.patent?.claims ?? [], [document])

	// Get citation update functions from the hook
	const { addCitation, updateCitation } = useChunkToLimitationUpdates()

	// Handle adding chunk citations
	const handleAddChunkCitation = (chunk: DocumentChunk) => {
		if (!activeLimitationId) return

		const existingCitations =
			activeLimitationInvalidityData?.[activeLimitationId]?.[documentId] || []

		const existingCitation = existingCitations.find(
			(c: any) => c.documentChunkId === chunk.id,
		)

		if (existingCitation?.removed) {
			updateCitation({
				citation: existingCitation,
				options: { removed: false, color: ChartColor.GREEN },
			})
		} else if (!existingCitation) {
			addCitation({
				documentChunkId: chunk.id,
				claimLimitationId: activeLimitationId,
				documentId: documentId,
				color: ChartColor.GREEN,
			})
		}
	}

	// Handle adding claim citations
	const handleAddClaimCitation = (claim: ClaimCitation) => {
		if (!activeLimitationId) return

		const existingCitations =
			activeLimitationInvalidityData?.[activeLimitationId]?.[documentId] || []

		const existingCitation = existingCitations.find(
			(c: any) => c.claimCitationId === claim.id,
		)

		if (existingCitation?.removed) {
			updateCitation({
				citation: existingCitation,
				options: { removed: false, color: ChartColor.GREEN },
			})
		} else if (!existingCitation) {
			addCitation({
				claimCitationId: claim.id,
				documentId: documentId,
				claimLimitationId: activeLimitationId,
				color: ChartColor.GREEN,
			})
		}
	}

	// Render the "body" section using the filtered chunks
	const renderBodySection = () => {
		if (filteredDocChunks.length === 0) {
			return <p className="text-center text-muted">No results found</p>
		}

		return filteredDocChunks.map((chunk: DocumentChunk) => (
			<div
				key={chunk.id}
				className="mb-2"
				ref={chunk.id === activeChunkId ? selectedDocumentChunkRef : undefined}
			>
				<PotentialCitation
					documentId={documentId}
					type={CitationType.CHUNK}
					data={chunk}
					onAddCitation={handleAddChunkCitation}
					showFigures={false}
				/>
			</div>
		))
	}

	// Render the "figures" section using the document's figure chunks
	const renderFiguresSection = () => {
		if (figureChunks.length === 0) {
			return <p className="text-center text-muted">No figures found</p>
		}

		return figureChunks.map((figure: DocumentChunk) => (
			<div key={figure.id} className="mb-2">
				<PotentialCitation
					documentId={documentId}
					type={CitationType.CHUNK}
					data={figure}
					onAddCitation={handleAddChunkCitation}
					showFigures={true}
				/>
			</div>
		))
	}

	// Render the "claims" section using claim citations
	const renderClaimsSection = () => {
		if (claims.length === 0) {
			return <p className="text-center text-muted">No claims found</p>
		}

		return claims.map((claim: ClaimCitation) => (
			<PotentialCitation
				key={claim.id}
				documentId={documentId}
				type={CitationType.CLAIM}
				data={claim}
				onAddCitation={handleAddClaimCitation}
			/>
		))
	}

	// Render the search bar with fixed positioning
	const renderSearchBar = () => (
		<div className="sticky top-[48px] left-0 bg-background border-b px-3 py-1 z-10">
			<div className="flex flex-col gap-1 w-full">
				<div className="flex items-center gap-2">
					{isCombination && <DocumentNamePill id={documentId} size="sm" />}
					<div className="relative flex-1">
						<SearchInput
							placeholder="Search document"
							disabled={docSearchLoading}
							value={docSearchQuery}
							onChange={(e) => {
								setDocSearchQuery(e.target.value)
								if (e.target.value === "") {
									setDocSearchResultIds(null)
								}
							}}
							onSearch={handleDocSearch}
							setResultIds={setDocSearchResultIds}
							className="w-full pr-20 h-10"
						/>
					</div>
				</div>
				<div className="flex items-center justify-center">
					{docSearchLoading && <Loader message="Searching document..." />}
					{docSearchError && <span className="text-red-500">Error</span>}
				</div>
			</div>
		</div>
	)

	// Update renderSection to use a nested structure for proper sticky behavior
	const renderSection = () => {
		switch (selectedDocumentSection) {
			case "body":
				return (
					<div className="flex flex-col h-full">
						{renderSearchBar()}
						<div className="flex-1 overflow-y-auto">
							<div className="px-4 py-2">{renderBodySection()}</div>
						</div>
					</div>
				)
			case "figures":
				return (
					<div className="flex flex-col h-full">
						{renderSearchBar()}
						<div className="flex-1 overflow-y-auto">
							<div className="px-4 py-2">{renderFiguresSection()}</div>
						</div>
					</div>
				)
			case "claims":
				return (
					<div className="flex flex-col h-full">
						{renderSearchBar()}
						<div className="flex-1 overflow-y-auto">
							<div className="px-4 py-2">{renderClaimsSection()}</div>
						</div>
					</div>
				)
			default:
				return (
					<div className="flex flex-col h-full">
						{renderSearchBar()}
						<ScrollArea>
							<div className="px-4 py-2 space-y-4">
								<Label className="mb-2 mt-2 text-lg">Claims</Label>
								{renderClaimsSection()}

								<Label className="mb-2 mt-2 text-lg">Description</Label>
								{renderBodySection()}

								<Label className="mb-2 mt-2 text-lg">Figures</Label>
								{renderFiguresSection()}
							</div>
						</ScrollArea>
					</div>
				)
		}
	}

	// Update the main return to maintain the structure
	return (
		<div className="h-full">
			{isDocLoading ? (
				<div className="px-3 py-2">
					<Loader />
				</div>
			) : (
				renderSection()
			)}
		</div>
	)
}

export default DocumentPanel
