import type { UpdateDocumentChunkRequest } from "@/types"
import type { DocumentChunk } from "@/types/invalidity"
import { useEffect, useRef, useState } from "react"
import { FigureInteractiveArea } from "./FigureInteractiveArea"
import { FigureModalActionsBar } from "./FigureModalActionsBar"
import { FigureThumbnails } from "./FigureThumbnails"

interface FigureViewerProps {
	documentId: string
	figureChunks: DocumentChunk[]
	updateFigureChunk: (params: {
		documentId: string
		payload: UpdateDocumentChunkRequest
	}) => void
	selectedFigure?: DocumentChunk
	showThumbnails?: boolean
	className?: string
	onHide?: () => void
}

export function FigureViewer({
	documentId,
	figureChunks,
	updateFigureChunk,
	selectedFigure,
	showThumbnails = true,
	className,
	onHide,
}: FigureViewerProps) {
	const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)
	const [rotation, setRotation] = useState<number>(0)
	const [imageSize, setImageSize] = useState<{ width: number; height: number }>({
		width: 0,
		height: 0,
	})
	const [originalSize, setOriginalSize] = useState<{ width: number; height: number }>({
		width: 0,
		height: 0,
	})
	const [scale, setScale] = useState(1)
	const [position, setPosition] = useState({ x: 0, y: 0 })
	const [isDragging, setIsDragging] = useState(false)
	const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
	const [isValidAspectRatio, setIsValidAspectRatio] = useState<boolean>(true)
	const [isEditingFigureNumber, setIsEditingFigureNumber] = useState(false)
	const [tempFigureNumber, setTempFigureNumber] = useState("")

	const figureNumberInputRef = useRef<HTMLInputElement>(null)
	const thumbnailContainerRef = useRef<HTMLDivElement>(null)
	const thumbnailRefs = useRef<(HTMLDivElement | null)[]>([])

	// Track the previous figure ID to see if selectedFigure actually changed.
	const prevFigureIdRef = useRef<string | undefined>(undefined)

	const updateFigureRotation = (documentChunkId: string, figureRotation: number) => {
		updateFigureChunk({
			documentId,
			payload: {
				document_chunk_id: documentChunkId,
				figure_rotation: figureRotation,
			},
		})
	}

	const rotationToStep = (degrees: number) => (degrees / 90) % 4

	function handleRotateLeft() {
		const chunk = figureChunks[selectedImageIndex]
		if (!chunk) return

		const newRotation = (rotation - 90 + 360) % 360
		setRotation(newRotation)
		adjustSizes(originalSize, newRotation)

		updateFigureRotation(chunk.id, rotationToStep(newRotation))
	}

	function handleRotateRight() {
		const chunk = figureChunks[selectedImageIndex]
		if (!chunk) return

		const newRotation = (rotation + 90) % 360
		setRotation(newRotation)
		adjustSizes(originalSize, newRotation)

		updateFigureRotation(chunk.id, rotationToStep(newRotation))
	}

	function adjustSizes(size: { width: number; height: number }, newRotation: number) {
		let { width, height } = size
		const rot = newRotation % 360
		if (rot === 90 || rot === 270) {
			;[width, height] = [height, width]
		}

		const maxWidth = window.innerWidth * 0.6 - 64
		const maxHeight = window.innerHeight * 0.9 - 250

		const aspectRatio = width / height
		let scaledWidth = width
		let scaledHeight = height

		if (height > maxHeight) {
			scaledHeight = maxHeight
			scaledWidth = scaledHeight * aspectRatio
		}

		if (scaledWidth > maxWidth) {
			scaledWidth = maxWidth
			scaledHeight = scaledWidth / aspectRatio
		}

		setImageSize({
			width: scaledWidth,
			height: scaledHeight,
		})
	}

	const handleExpand = () => {
		const el = document.documentElement
		if (el.requestFullscreen) el.requestFullscreen()
	}

	const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
		const img = event.currentTarget
		const aspectRatio = img.naturalWidth / img.naturalHeight

		if (aspectRatio > 9) {
			setIsValidAspectRatio(false)
		}
	}

	const handleFigureNumberChange = (newNumber: string) => {
		const chunk = figureChunks[selectedImageIndex]
		if (!chunk) return

		const isDuplicate = figureChunks.some(
			(c, idx) => idx !== selectedImageIndex && c.figureNumber === newNumber,
		)

		if (isDuplicate) {
			setTempFigureNumber(chunk.figureNumber || "")
			return
		}

		updateFigureChunk({
			documentId,
			payload: {
				document_chunk_id: chunk.id,
				figure_number: newNumber,
			},
		})
	}

	// Effect for initializing image when selected image changes
	useEffect(() => {
		const chunk = figureChunks[selectedImageIndex]
		if (!chunk?.figureUrl) return

		const initialRotation = (chunk.figureRotation || 0) * 90
		setRotation(initialRotation)

		const img = new Image()
		img.onload = () => {
			const size = { width: img.width, height: img.height }
			setOriginalSize(size)
			adjustSizes(size, initialRotation)
		}
		img.src = chunk.figureUrl
	}, [selectedImageIndex, figureChunks])

	// Reset position and scale when image changes
	useEffect(() => {
		setScale(1)
		setPosition({ x: 0, y: 0 })
	}, [selectedImageIndex])

	// Sync with thumbnails
	useEffect(() => {
		if (thumbnailRefs.current[selectedImageIndex]) {
			thumbnailRefs.current[selectedImageIndex]?.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "center",
			})
		}
	}, [selectedImageIndex])

	// Handle figure number editing
	useEffect(() => {
		if (isEditingFigureNumber && figureChunks[selectedImageIndex]) {
			setTempFigureNumber(figureChunks[selectedImageIndex].figureNumber || "")
		}
	}, [isEditingFigureNumber, figureChunks, selectedImageIndex])

	// Sync with selected figure: only if selectedFigure's ID actually changed
	useEffect(() => {
		if (!selectedFigure) return

		if (prevFigureIdRef.current === selectedFigure.id) {
			// The same figure ID was already synced; do nothing
			return
		}
		prevFigureIdRef.current = selectedFigure.id

		const newIndex = figureChunks.findIndex((chunk) => chunk.id === selectedFigure.id)
		if (newIndex !== -1) {
			setSelectedImageIndex(newIndex)
		}
	}, [selectedFigure?.id, figureChunks])

	// Keyboard navigation
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (isEditingFigureNumber) return

			if (e.key === "ArrowLeft") {
				e.preventDefault()
				if (selectedImageIndex > 0) {
					setSelectedImageIndex(selectedImageIndex - 1)
				}
			}
			if (e.key === "ArrowRight") {
				e.preventDefault()
				if (selectedImageIndex < figureChunks.length - 1) {
					setSelectedImageIndex(selectedImageIndex + 1)
				}
			}
		}

		window.addEventListener("keydown", handleKeyDown)
		return () => window.removeEventListener("keydown", handleKeyDown)
	}, [selectedImageIndex, figureChunks.length, isEditingFigureNumber])

	if (!isValidAspectRatio) {
		return null
	}

	return (
		<div className={`flex flex-col w-full h-full overflow-hidden ${className}`}>
			<div className="w-full">
				<FigureModalActionsBar
					figureChunks={figureChunks}
					selectedImageIndex={selectedImageIndex}
					setSelectedImageIndex={setSelectedImageIndex}
					handleRotateLeft={handleRotateLeft}
					handleRotateRight={handleRotateRight}
					handleExpand={handleExpand}
					isEditingFigureNumber={isEditingFigureNumber}
					setIsEditingFigureNumber={setIsEditingFigureNumber}
					figureNumberInputRef={figureNumberInputRef}
					tempFigureNumber={tempFigureNumber}
					setTempFigureNumber={setTempFigureNumber}
					handleFigureNumberChange={handleFigureNumberChange}
					onHide={onHide}
				/>
			</div>

			<FigureInteractiveArea
				imageUrl={figureChunks[selectedImageIndex]?.figureUrl ?? ""}
				rotation={rotation}
				imageWidth={imageSize.width}
				imageHeight={imageSize.height}
				onImageLoad={handleImageLoad}
				scale={scale}
				setScale={setScale}
				position={position}
				setPosition={setPosition}
				isDragging={isDragging}
				setIsDragging={setIsDragging}
				dragStart={dragStart}
				setDragStart={setDragStart}
			/>

			{showThumbnails && (
				<FigureThumbnails
					figureChunks={figureChunks}
					selectedImageIndex={selectedImageIndex}
					setSelectedImageIndex={setSelectedImageIndex}
					thumbnailContainerRef={thumbnailContainerRef}
					thumbnailRefs={thumbnailRefs}
				/>
			)}
		</div>
	)
}
