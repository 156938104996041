import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "@/components/ui/alert-dialog"

interface ConfirmRemoveChunkDialogProps {
	open: boolean
	onOpenChange: (open: boolean) => void
	onConfirm: () => void
	isFigure?: boolean
}

const ConfirmRemoveChunkModal = ({
	open,
	onOpenChange,
	onConfirm,
	isFigure = false,
}: ConfirmRemoveChunkDialogProps) => {
	const title = isFigure ? "Remove figure?" : "Remove section?"
	const description = `Are you sure you want to remove this ${isFigure ? "figure" : "section"}? It will be removed from all citations.`

	return (
		<AlertDialog open={open} onOpenChange={onOpenChange}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>{title}</AlertDialogTitle>
					<AlertDialogDescription>{description}</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>Cancel</AlertDialogCancel>
					<AlertDialogAction onClick={onConfirm}>Remove</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export default ConfirmRemoveChunkModal
