import { chartsApi } from "@/api/chartsApi"
import { useParentContext } from "@/context/ParentContext"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import useDocumentNaming from "@/hooks/useDocumentNaming"
import { useAppStateStore } from "@/store"
import type { ChunkToLimitation } from "@/types/invalidity"
import {
	invalidateAllInvalidityChartData,
	invalidateInvalidityRelatedQueries,
	invalidateLimitationInvalidityQueries,
} from "@/utils"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
export function useChunkToLimitationUpdates() {
	const queryClient = useQueryClient()
	const { handleError } = useApi()
	const { projectId } = useParentContext()
	const { addErrorMessage } = useAppStateStore()
	const { getDocumentName } = useDocumentNaming()
	const selectedColors = []

	const { invalidityChartId } = useParams()
	// const { selectedLimitations, selectedColors } = useChartsContext()
	/**
	 * @description Updates the header and footer boilerplate for a chunk to a limitation
	 */
	const updateChunkToLimitationBoilerplateMutation = useMutation({
		mutationFn: async ({
			header,
			footer,
			middle,
			claimLimitationIds,
			updateAllElements,
		}: {
			header: string
			footer: string
			middle: string
			claimLimitationIds?: string[]
			updateAllElements?: boolean
		}) => {
			return chartsApi.updateCellBoilerplate(
				invalidityChartId,
				header,
				footer,
				middle,
				updateAllElements,
				claimLimitationIds,
			)
		},
		onError: (error) => {
			handleError(error, "Error updating chunk to limitation boilerplate")
		},
		onSuccess: (_, variables) => {
			if (variables.updateAllElements) {
				invalidateAllInvalidityChartData(queryClient, projectId)
			} else {
				invalidateLimitationInvalidityQueries(
					queryClient,
					projectId,
					invalidityChartId,
					variables.claimLimitationIds,
					[],
					selectedColors,
				)
			}
		},
	})

	/**
	 * @description Updates the language of a reference citation
	 * @param {string} citationId - The id of the citation to update the language for
	 * @param {object} options - Additional options to pass to the request
	 */
	const updateChunkToLimitationMutation = useMutation({
		mutationFn: async ({
			citation,
			options,
		}: {
			citation: ChunkToLimitation
			options: {
				verified?: boolean
				removed?: boolean
				score?: number
				color?: string
			}
		}) => {
			return chartsApi.updateChunkToLimitation(
				invalidityChartId,
				citation.claimLimitationId,
				options,
				citation.documentChunkId,
				citation.claimCitationId,
			)
		},

		onError: (error, _variables, _context) => {
			handleError(error, "Error updating document chunk to claim limitation")
		},
		onSuccess: (_, variables) => {
			invalidateLimitationInvalidityQueries(
				queryClient,
				projectId,
				invalidityChartId,
				[variables.citation.claimLimitationId],
				[],
				selectedColors,
			)
		},
	})

	/**
	 * @description Adds a reference citation to the project
	 * @param {string} invalidityId - The id of the invalidity to add the citation to
	 * @param {string} referenceId - The id of the reference to add the citation to
	 * @param {string} color - The color of the citation to add
	 * @param {string} text - The text of the citation to add
	 * @param {ChunkLocation} location - The location details of the citation
	 * @param {string} projectId - The id of the project
	 * @param {boolean} isFeature - Whether this is a feature citation
	 * @param {string[]} figureUrls - The URLs of the figures to add
	 * @param {string[]} figureRefs - The references of the figures to add
	 * @param {string} claimNumber - The claim number for the citation
	 */
	const addChunkToLimitationMutation = useMutation({
		mutationFn: async ({
			documentChunkId,
			claimCitationId,
			claimLimitationId,
			documentId,
			color,
		}: {
			documentChunkId?: string
			claimCitationId?: string
			claimLimitationId: string
			documentId: string
			color: string
		}) => {
			const response = await chartsApi.createChunkToLimitation(
				claimLimitationId,
				color,
				projectId,
				invalidityChartId,
				documentChunkId,
				claimCitationId,
				documentId,
			)
			return response
		},
		onError: (error) => {
			handleError(error, "Error adding reference citation")
		},
		onSuccess: (_, variables) =>
			invalidateLimitationInvalidityQueries(
				queryClient,
				projectId,
				invalidityChartId,
				[variables.claimLimitationId],
				[],
				selectedColors,
			),
	})

	/**
	 * @description Deletes a reference citation from a project
	 * @param {string} invalidityCitationId - The id of the invalidity citation to delete
	 */
	const toggleRemoveAllDocumentChunkCitations = useMutation({
		mutationFn: async (documentChunkId: string) => {
			return chartsApi.toggleRemoveAllDocumentChunkCitations(documentChunkId)
		},
		onError: (error) => {
			handleError(error, "Error deleting reference citation")
		},
		onSuccess: () => invalidateInvalidityRelatedQueries(queryClient, projectId),
	})

	/**
	 * @description Mass toggle verified on all citations in a reference
	 */
	const toggleVerifyAllCitations = useMutation({
		mutationFn: async ({
			documentId,
			verified,
		}: {
			documentId: string
			verified: boolean
		}) => {
			return chartsApi.toggleVerifyAllDocumentCitations(documentId, verified)
		},
		onError: (_error, variables) => {
			addErrorMessage(
				`Error updating all chart citations for ${getDocumentName(variables.documentId)}`,
			)
		},
		onSuccess: () => {
			invalidateInvalidityRelatedQueries(queryClient, projectId)
		},
	})

	/**
	 * @description Find more citations for a limitation using AI
	 */
	const findMoreCitationsForLimitationMutation = useMutation({
		mutationFn: async ({
			claimLimitationId,
			documentId,
			positiveExamples,
			negativeExamples,
		}: {
			claimLimitationId: string
			documentId: string
			positiveExamples: string[]
			negativeExamples: string[]
		}) => {
			return chartsApi.findMoreCitationsForLimitation(
				invalidityChartId,
				claimLimitationId,
				documentId,
				positiveExamples,
				negativeExamples,
			)
		},
		onError: (error) => {
			handleError(error, "Error finding more citations for limitation")
		},
		onSuccess: (_, variables) => {
			invalidateLimitationInvalidityQueries(
				queryClient,
				projectId,
				invalidityChartId,
				[variables.claimLimitationId],
				[variables.documentId],
				selectedColors,
			)
		},
	})

	/**
	 * @description Prune invalidities by color
	 */
	const pruneChunkToLimitationByColor = useMutation({
		mutationFn: async ({
			documentIds,
			colors,
		}: {
			documentIds: string[]
			colors: string[]
		}) => {
			return chartsApi.pruneChunkToLimitationByColor(documentIds, colors)
		},
		onError: (error) => {
			handleError(error, "Error pruning invalidities by color")
		},
		onSuccess: (_, variables) => {
			invalidateLimitationInvalidityQueries(
				queryClient,
				projectId,
				invalidityChartId,
				[],
				variables.documentIds,
				selectedColors,
			)
		},
	})

	/**
	 * @description Copies invalidities from one element to another
	 */
	const copyChunkToLimitations = useMutation({
		mutationFn: async ({
			documentId,
			sourceLimitationId,
			targetLimitationId,
			replace,
		}: {
			documentId: string
			sourceLimitationId: string
			targetLimitationId: string
			replace: boolean
		}) => {
			return chartsApi.copyChunkToLimitations(
				invalidityChartId,
				documentId,
				sourceLimitationId,
				targetLimitationId,
				replace,
			)
		},
		onError: (error) => {
			handleError(error, "Error copying invalidities from one element to another")
		},
		onSuccess: (_, variables) => {
			invalidateLimitationInvalidityQueries(
				queryClient,
				projectId,
				invalidityChartId,
				[variables.sourceLimitationId, variables.targetLimitationId],
				[variables.documentId],
				selectedColors,
			)
		},
	})

	return {
		findMoreCitationsForLimitation: findMoreCitationsForLimitationMutation.mutateAsync,
		isFindingMoreCitations: findMoreCitationsForLimitationMutation.isPending,

		addCitation: addChunkToLimitationMutation.mutate,
		isAddingCitation: addChunkToLimitationMutation.isPending,

		toggleVerifyAllCitations: toggleVerifyAllCitations.mutate,
		isVerifyingAllCitations: toggleVerifyAllCitations.isPending,

		toggleRemoveAllDocumentChunkCitations:
			toggleRemoveAllDocumentChunkCitations.mutateAsync, // need to await to close modal
		isDeletingAllDocumentChunkCitations: toggleRemoveAllDocumentChunkCitations.isPending,

		pruneChunkToLimitationByColor: pruneChunkToLimitationByColor.mutateAsync,
		isPruningChunkToLimitationByColor: pruneChunkToLimitationByColor.isPending,

		// Use for individual citation updates to removed, verified, or color
		updateCitation: updateChunkToLimitationMutation.mutate,
		isUpdatingCitation: updateChunkToLimitationMutation.isPending,

		copyChunkToLimitations: copyChunkToLimitations.mutateAsync,
		isCopyingChunkToLimitations: copyChunkToLimitations.isPending,

		updateCellBoilerplate: updateChunkToLimitationBoilerplateMutation.mutateAsync,
		isUpdatingChunkToLimitationBoilerplate:
			updateChunkToLimitationBoilerplateMutation.isPending,
	}
}

export default useChunkToLimitationUpdates
