import { DocumentChunkComponent } from "@/components/documents/sections/body/DocumentChunk"
import useChartOptionsStore from "@/features/charts/hooks/chartOptionsStore"
import type { ChunkToLimitation } from "@/types"
import { BlockType } from "@/types"
import { useEffect, useRef } from "react"
import type React from "react"
import { useChartsContext } from "../../context/ChartsContext"
import ClaimComponent from "../ClaimComponent"
import { FigureComponent } from "../FigureComponent"
import InvalidityCitationActions from "./InvalidityCitationActions"
interface InvalidityTableCitationProps {
	citation: ChunkToLimitation
	showVerify?: boolean
	showRemove?: boolean
	showFeedback?: boolean
	showColor?: boolean
}

export const InvalidityTableCitation: React.FC<InvalidityTableCitationProps> = ({
	citation,
	showVerify = true,
	showRemove = true,
	showFeedback = true,
	showColor = true,
}) => {
	const { activeLimitationId } = useChartsContext()
	const containerRef = useRef<HTMLDivElement>(null)
	const { showFeedbackButtons, showReferencedFigures } = useChartOptionsStore()

	useEffect(() => {
		if (activeLimitationId === citation.claimLimitationId && containerRef.current) {
			containerRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			})
		}
	}, [activeLimitationId, citation.claimLimitationId])

	return (
		<div ref={containerRef} className="my-4">
			<InvalidityCitationActions
				citation={citation}
				showColor={showColor}
				showRemove={showRemove}
				showVerify={showVerify}
				showFeedback={showFeedback && showFeedbackButtons}
			>
				{citation.documentChunk?.type === BlockType.FIGURE ? (
					<FigureComponent chunk={citation.documentChunk} />
				) : citation.documentChunk ? (
					<DocumentChunkComponent
						chunk={citation.documentChunk}
						showFigures={showReferencedFigures}
					/>
				) : (
					<ClaimComponent claim={citation.claimCitation} />
				)}
			</InvalidityCitationActions>
		</div>
	)
}

export default InvalidityTableCitation
