import FigureChunk from "@/components/images/FigureChunk"
import ImageViewer from "@/components/images/ImageViewer"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { ScrollArea } from "@/components/ui/scroll-area"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip"
import type { DocumentChunk } from "@/types/invalidity"
import { CheckCircle, Eye } from "lucide-react"
import type React from "react"
import { useEffect, useState } from "react"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

interface FigureSelectModalProps {
	open: boolean
	handleClose: () => void
	figureChunks: DocumentChunk[]
	handleSetSelectedUrls: (images: string[]) => void
	selectedUrls?: string[]
}

/**
 * @description Renders a modal for selecting reference figure from the document to add to a citation
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {string} refId - The id of the reference to attach the figures to.
 * @param {function} handleAttachFigures - Function to handle the attachment of figures.
 * @param {boolean} isAddingCitation - Whether the figures are being added to a citation.
 * @param {function} setReferenceFiguresToAttach - Function to set the reference figures to attach.
 * @param {string[]} existingFigureUrls - The existing figure urls.
 */
const FigureSelectModal: React.FC<FigureSelectModalProps> = ({
	open,
	handleClose,
	figureChunks,
	handleSetSelectedUrls,
	selectedUrls = [],
}) => {
	// Use controlled state
	const [selectedFigureUrls, setSelectedFigureUrls] = useState<Set<string>>(
		() => new Set(selectedUrls),
	)

	// Parent-level image viewer (remove if relying entirely on child's image modal)
	const [viewingImage, setViewingImage] = useState<string | null>(null)

	// Update selection when modal opens
	useEffect(() => {
		if (open) {
			setSelectedFigureUrls(new Set(selectedUrls))
		}
	}, [open, selectedUrls])

	const toggleSelectFigure = (figureUrl: string) => {
		const newSelection = new Set(selectedFigureUrls)
		if (newSelection.has(figureUrl)) {
			newSelection.delete(figureUrl)
		} else {
			newSelection.add(figureUrl)
		}
		setSelectedFigureUrls(newSelection)
	}

	const handleSubmit = () => {
		// Convert to array
		const selectedFigureUrlsArray = Array.from(new Set(selectedFigureUrls))
		handleSetSelectedUrls(selectedFigureUrlsArray)
		handleClose()
	}

	if (!figureChunks || figureChunks.length === 0) return null

	return (
		<>
			<Dialog open={open} onOpenChange={handleClose}>
				<DialogContent className="sm:max-w-4xl">
					<DialogHeader>
						<DialogTitle>Select figure(s)</DialogTitle>
					</DialogHeader>

					<ScrollArea className="h-[60vh]">
						<div className="grid grid-cols-2 md:grid-cols-3 gap-4 p-2">
							{figureChunks.map((figureChunk) => (
								<div
									key={figureChunk.figureUrl}
									className={`relative rounded-md border-2 ${
										selectedFigureUrls.has(figureChunk.figureUrl)
											? "border-blue-500"
											: "border-gray-200"
									}`}
								>
									{/* Center the FigureChunk */}
									<div className="flex justify-center items-center">
										<FigureChunk
											figureChunk={figureChunk}
											onClick={() => toggleSelectFigure(figureChunk.figureUrl)}
											height={150} // Custom height
											showBorder={false} // Hide border
										/>
									</div>

									{/* Overlay the figureNumber in top-left */}
									{figureChunk.figureNumber && (
										<div className="absolute top-1 left-1 bg-black/50 text-white text-xs px-2 py-1 rounded">
											Figure {figureChunk.figureNumber}
										</div>
									)}

									{/* Overlay for View and Select buttons in top-right */}
									<div className="absolute right-1 top-1 flex gap-1">
										<TooltipProvider>
											<Tooltip>
												<TooltipTrigger asChild>
													<Button
														variant="ghost"
														size="icon"
														className="bg-background"
														onClick={(e) => {
															e.stopPropagation()
															setViewingImage(figureChunk.figureUrl)
														}}
													>
														<Eye className="h-5 w-5 text-gray-500" />
													</Button>
												</TooltipTrigger>
												<TooltipContent>
													<p>View image</p>
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>

										<TooltipProvider>
											<Tooltip>
												<TooltipTrigger asChild>
													<Button
														variant="ghost"
														size="icon"
														className="bg-background"
														onClick={(e) => {
															e.stopPropagation()
															toggleSelectFigure(figureChunk.figureUrl)
														}}
													>
														<CheckCircle
															className={`h-5 w-5 ${
																selectedFigureUrls.has(figureChunk.figureUrl)
																	? "text-blue-500"
																	: "text-gray-300"
															}`}
														/>
													</Button>
												</TooltipTrigger>
												<TooltipContent>
													<p>
														{selectedFigureUrls.has(figureChunk.figureUrl) ? "Deselect" : "Select"}
													</p>
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>
									</div>
								</div>
							))}
						</div>
					</ScrollArea>

					<div className="mt-2 flex justify-end gap-2">
						<Button variant="outline" onClick={handleClose}>
							Cancel
						</Button>
						<Button onClick={handleSubmit} disabled={selectedFigureUrls.size === 0}>
							Done
						</Button>
					</div>
				</DialogContent>
			</Dialog>

			{/* Parent-level image viewer (delete if you rely on child's ImageViewer) */}
			{viewingImage && (
				<ImageViewer
					imageUrl={viewingImage}
					imageName="Figure"
					open={!!viewingImage}
					handleClose={() => setViewingImage(null)}
				/>
			)}
		</>
	)
}

export default FigureSelectModal
