import type { ChunkToLimitation } from "@/types"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

export type FeedbackChartData = {
	[projectId: string]: {
		[chartId: string]: {
			[claimLimitationId: string]: ChunkToLimitation[] // Captures citations for all documents
		}
	}
}

interface FeedbackChartDataStore {
	feedbackChartData: FeedbackChartData
	addFeedbackCitation: (
		projectId: string,
		chartId: string,
		claimLimitationId: string,
		newCitation: ChunkToLimitation,
	) => void
	removeFeedbackCitation: (
		projectId: string,
		chartId: string,
		claimLimitationId: string,
		citationId: string,
	) => void
	clearFeedbackChartDataByID: (projectId: string, chartId?: string[]) => void
}

/**
 * @description Hook for handling feedback chart data
 * @returns {object} - The feedback chart data
 */
export const useFeedbackChartDataStore = create<FeedbackChartDataStore>()(
	persist(
		(set) => ({
			feedbackChartData: {},
			addFeedbackCitation: (projectId, chartId, claimLimitationId, newCitation) =>
				set((state) => {
					// TODO @christina: can we make this less ugly?
					const updatedFeedbackChartData = { ...state.feedbackChartData }

					if (!updatedFeedbackChartData[projectId]) {
						updatedFeedbackChartData[projectId] = {}
					}
					if (!updatedFeedbackChartData[projectId][chartId]) {
						updatedFeedbackChartData[projectId][chartId] = {}
					}
					if (!updatedFeedbackChartData[projectId][chartId][claimLimitationId]) {
						updatedFeedbackChartData[projectId][chartId][claimLimitationId] = []
					}

					// Ensure duplicate citations are not added
					const existingCitationIndex = updatedFeedbackChartData[projectId][chartId][
						claimLimitationId
					].findIndex((citation) => citation.documentChunkId === newCitation.documentChunkId)

					if (existingCitationIndex === -1) {
						// If the citation doesn't exist, add it to the array
						updatedFeedbackChartData[projectId][chartId][claimLimitationId].push(newCitation)
					} else {
						// If the citation exists, update it
						updatedFeedbackChartData[projectId][chartId][claimLimitationId][
							existingCitationIndex
						] = newCitation
					}
					return { feedbackChartData: updatedFeedbackChartData }
				}),
			removeFeedbackCitation: (projectId, chartId, claimLimitationId, citationId) =>
				set((state) => {
					const updatedFeedbackChartData = { ...state.feedbackChartData }
					if (updatedFeedbackChartData[projectId]?.[chartId]?.[claimLimitationId]) {
						updatedFeedbackChartData[projectId][chartId][claimLimitationId] =
							updatedFeedbackChartData[projectId][chartId][claimLimitationId].filter(
								(citation) => citation.documentChunkId !== citationId,
							)
					}
					return { feedbackChartData: updatedFeedbackChartData }
				}),
			clearFeedbackChartDataByID: (projectId, documentIds) =>
				set((state) => {
					const updatedFeedbackChartData = { ...state.feedbackChartData }
					if (documentIds && documentIds.length > 0) {
						if (updatedFeedbackChartData[projectId]) {
							for (const documentId of documentIds) {
								delete updatedFeedbackChartData[projectId][documentId]
							}
						}
					} else {
						delete updatedFeedbackChartData[projectId]
					}
					return { feedbackChartData: updatedFeedbackChartData }
				}),
		}),
		{
			name: "feedback-chart-storage",
			storage: createJSONStorage(() => localStorage), // localStorage only stores strings
		},
	),
)

export default useFeedbackChartDataStore
