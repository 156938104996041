import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import type { ClaimEdit } from "@/types"
import { toSnakeCase } from "@/utils/dataUtils"
import { invalidateClaimRelatedQueries } from "@/utils/query/invalidation"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { MUTATION_KEYS } from "@/utils/query/keys"
import { useMutation, useQueryClient } from "@tanstack/react-query"
/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useUpdateClaims = () => {
	const queryClient = useQueryClient()
	const { projectId } = useParentContext()

	const udpateClaimsMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.claims.update(),
		mutationFn: ({ patentId, claims }: { patentId: string; claims: ClaimEdit[] }) =>
			api.updateClaims({ patent_id: patentId, claims: toSnakeCase(claims) }),
		onSuccess: (_, variables) => {
			invalidateClaimRelatedQueries(queryClient, variables.patentId, projectId)
		},
	})

	return {
		updateClaims: udpateClaimsMutation.mutate,
		isLoadingUpdateClaims: udpateClaimsMutation.isPending,
		isErrorUpdateClaims: udpateClaimsMutation.isError,
		isSuccessUpdateClaims: udpateClaimsMutation.isSuccess,
		errorUpdateClaims: udpateClaimsMutation.error,
	}
}

export default useUpdateClaims
