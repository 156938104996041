import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { useChartsContext } from "@/features/charts/context/ChartsContext"
import { cn } from "@/lib/utils"
import { DocumentSection } from "@/types/documents"
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons"
import React, { useState } from "react"

interface DocumentSectionSelectProps {
	/** The type of the document (e.g. "patent"). If not 'patent', the 'claims' option will be hidden */
	documentType: string
	disabled?: boolean
}

/**
 * @description A select component that allows users to pick a document section.
 * If the document type is not "patent", the "claims" option is excluded.
 */
const DocumentSectionSelect: React.FC<DocumentSectionSelectProps> = ({
	documentType,
	disabled = false,
}) => {
	const { selectedDocumentSection, setSelectedDocumentSection } = useChartsContext()
	const [open, setOpen] = useState(false)

	// Get all possible sections from the enum.
	let sections = Object.values(DocumentSection)
	// If the document type is not a patent, filter out the "claims" section.
	if (documentType.toLowerCase() !== "patent") {
		sections = sections.filter((section) => section !== DocumentSection.CLAIMS)
	}

	const handleSelectSection = (section: DocumentSection) => {
		setSelectedDocumentSection(section)
		setOpen(false)
	}

	// Helper to convert enum value (e.g., "all") to a nicely formatted label ("All").
	const formatSection = (section: DocumentSection) =>
		section.charAt(0).toUpperCase() + section.slice(1)

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					disabled={disabled}
					className={cn("w-[130px] justify-between")}
					size="sm"
				>
					<span>{formatSection(selectedDocumentSection)}</span>
					<ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[200px] p-0">
				{sections.map((section) => (
					<Button
						key={section}
						variant="ghost"
						className="w-full justify-start"
						onClick={() => handleSelectSection(section)}
					>
						<div className="flex items-center">
							{selectedDocumentSection === section && <CheckIcon className="mr-2 h-4 w-4" />}
							<span>{formatSection(section)}</span>
						</div>
					</Button>
				))}
			</PopoverContent>
		</Popover>
	)
}

export default React.memo(DocumentSectionSelect)
