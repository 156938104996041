import {
	NavigationMenu,
	NavigationMenuItem,
	NavigationMenuList,
} from "@/components/ui/navigation-menu"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import type React from "react"
import { useLocation, useNavigate } from "react-router-dom"

/**
 * @description Invalidity project header containing title, navigation tabs, and sharing
 */
const AdminHeader = ({ isSuperAdmin }: { isSuperAdmin: boolean }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const adminPath = isSuperAdmin ? "superadmin" : "admin"

	const getTabValue = (pathname: string): string => {
		if (pathname.includes("/users")) {
			return "users"
		}
		if (pathname.includes("/billing")) {
			return "billing"
		}
		if (pathname.includes("/organizations")) {
			return "organizations"
		}
		return "overview"
	}

	const currentTabValue = getTabValue(location.pathname)

	// Handle nav item selection
	const handleTabChange = (_event: React.SyntheticEvent, newValue: string): void => {
		const path = ""
		switch (newValue) {
			case "overview":
				navigate(`${path}/${adminPath}`)
				break
			case "users":
				navigate(`${path}/${adminPath}/users`)
				break
			case "billing":
				navigate(`${path}/${adminPath}/billing`)
				break
			case "organizations":
				navigate(`${path}/${adminPath}/organizations`)
				break
			default:
				break
		}
	}

	// Render tab label
	const renderNavItem = (text: string, value: string) => (
		<NavigationMenuItem
			className={`px-3 py-1 text-sm hover:cursor-pointer font-medium ${
				currentTabValue === value
					? "bg-accent text-accent-foreground rounded-full"
					: "text-muted-foreground"
			}`}
			onClick={(event) => {
				handleTabChange(event, value)
			}}
		>
			{text}
		</NavigationMenuItem>
	)

	return (
		<div className="flex items-center justify-between px-4 py-2 border-b">
			<div className="flex items-center space-x-2">
				<NavigationMenu>
					<NavigationMenuList>
						{renderNavItem("Overview", "overview")}
						{!isSuperAdmin && renderNavItem("Users", "users")}
						{isSuperAdmin && renderNavItem("Organizations", "organizations")}
						{/* {renderNavItem("Billing", "billing")} */}
					</NavigationMenuList>
				</NavigationMenu>
			</div>
		</div>
	)
}

export default AdminHeader
