/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { chartsApi } from "@/api/chartsApi"
import { useParentContext } from "@/context/ParentContext"
import { useApi, useProjectMetadata } from "@/hooks"
import { useAppStateStore, useProcessStore } from "@/store"
import { type ApiResponse, ProcessType } from "@/types"
import { nanoid } from "nanoid"

export const useInvalidityChartExport = () => {
	const { handleError } = useApi()
	const { addErrorMessage } = useAppStateStore()
	const { addProcess, removeProcess } = useProcessStore()
	const { projectId, portfolioId } = useParentContext()
	const { projectName } = useProjectMetadata()

	const getInvalidityChartExport = async (
		includeClaims: boolean,
		includeSummary: boolean,
		introductionLanguage: string,
		chartIds: string[],
		colors: string[],
		claims: string[],
		exportType: string,
		isCitationBefore: boolean,
		patentCitationFormat: string,
		applicationCitationFormat: string,
		documentCitationFormat: string,
		foreignDocumentCitationFormat: string,
		figureFormat: string,
		figurePlacement: string,
		titleFormat: string,
	): Promise<ApiResponse> => {
		const key = nanoid()
		try {
			addProcess({
				id: key,
				type: ProcessType.DOWNLOAD_CHART,
				projectIds: [projectId],
				portfolioId: portfolioId,
			})
			const response: any = await chartsApi.getProjectExport(
				projectId,
				exportType,
				includeClaims,
				includeSummary,
				introductionLanguage,
				chartIds,
				colors,
				claims,
				isCitationBefore,
				patentCitationFormat,
				applicationCitationFormat,
				documentCitationFormat,
				foreignDocumentCitationFormat,
				figureFormat,
				figurePlacement,
				titleFormat,
			)

			const downloadFile = async (
				url: string,
				fileType: string,
				isMultipleFiles: boolean,
			) => {
				const fileResponse = await fetch(url)
				if (!fileResponse.ok) throw new Error(`Failed to download the ${fileType} file`)

				const fileBlob = await fileResponse.blob()
				const localUrl = window.URL.createObjectURL(fileBlob)
				const link = document.createElement("a")
				link.href = localUrl

				const fileName = projectName.replace(/[\s']/g, "")

				let extension = "zip" // default
				if (fileType === "excel") {
					extension = "xlsx"
				} else if (fileType === "word" && !isMultipleFiles) {
					// If it's just 1 doc, let's call it .docx
					extension = "docx"
				}

				link.setAttribute("download", `${fileName}_${fileType}.${extension}`)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				window.URL.revokeObjectURL(localUrl)
			}

			if (response.url?.zip) {
				// zip file
				await downloadFile(response.url.zip, "zip", false)
			} else if (response.url?.word) {
				// word file
				await downloadFile(response.url.word, "word", false)
			} else {
				if (process.env.NODE_ENV !== "production") {
					console.error("No download URL found")
				}
				addErrorMessage("Error downloading file. Try again later.")
				return
			}
			return { success: true, data: response }
		} catch (error) {
			return handleError(error, "Error getting element chart export for word")
		} finally {
			removeProcess(key)
		}
	}

	return {
		getInvalidityChartExport,
	}
}

export default useInvalidityChartExport
