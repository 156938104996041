/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Alert, AlertTitle } from "@/components/ui/alert"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { ProcessType } from "@/types"
import { capitalizeFirstLetter } from "@/utils"
import {
	AlertCircle,
	AlertTriangle,
	CheckCircle,
	ChevronDown,
	ChevronUp,
	X,
} from "lucide-react"
import type React from "react"
import { useCallback, useMemo, useState } from "react"
import { Badge } from "../ui/badge"
import { Button } from "../ui/button"
import { Spinner } from "../ui/spinner"

interface LoadingGroupAlertProps {
	loadingGroup: any
	removeLoadingGroup: (key: string, processType: string) => void
	processType: ProcessType
}

/**
 * @description Loading group alert component, used to group multiple alerts of the same type
 * @param {LoadingGroupAlertProps} props - The props for the component
 */
const LoadingGroupAlert: React.FC<LoadingGroupAlertProps> = ({
	loadingGroup,
	removeLoadingGroup,
	processType,
}) => {
	const [showDetails, setShowDetails] = useState(false)

	// Memoize count calculations
	const { successCount, errorCount, warningCount, loadingCount } = useMemo(() => {
		const total = loadingGroup.messages.length
		const success = loadingGroup.messages.filter((m) => m.status === "success").length
		const error = loadingGroup.messages.filter((m) => m.status === "error").length
		const warning = loadingGroup.messages.filter((m) => m.status === "warning").length
		const loading = total - success - error - warning
		const _isLoading = loadingGroup.messages.some((m) => m.status === "processing")

		return {
			successCount: success,
			errorCount: error,
			warningCount: warning,
			loadingCount: loading,
		}
	}, [loadingGroup.messages])

	// Memoize getStatusIcon
	const statusIcon = useMemo(() => {
		if (loadingCount > 0) {
			return <Spinner className="mr-2 h-4 w-4" />
		}
		if (errorCount > 0) {
			return <AlertCircle className="mr-2 h-4 w-4 text-red-500" />
		}
		if (warningCount > 0) {
			return <AlertTriangle className="mr-2 h-4 w-4 text-yellow-500" />
		}
		return <CheckCircle className="mr-2 h-4 w-4 text-blue-500" />
	}, [loadingCount, errorCount, warningCount])

	// Memoize title
	const title = useMemo(() => {
		const groupName = loadingGroup.name.startsWith("AndAIUserPersonal")
			? "your documents"
			: loadingGroup.name

		if (processType === ProcessType.ADD_PRIOR_ART) {
			if (loadingCount > 0) {
				return `Adding ${loadingCount} ${loadingCount === 1 ? "document" : "documents"} to ${groupName}`
			}
			if (errorCount > 0) {
				return `Added ${successCount} ${
					successCount === 1 ? "document" : "documents"
				} to ${groupName}. ${errorCount} failed`
			}
			if (warningCount > 0) {
				return `Added ${successCount} ${
					successCount === 1 ? "document" : "documents"
				} to ${groupName}. ${warningCount} skipped`
			}
			return `Added ${successCount} ${successCount === 1 ? "document" : "documents"} to ${groupName}`
		}
		if (processType === ProcessType.GENERATE_CONTEXT) {
			if (loadingCount > 0) {
				return `Generating context for ${loadingCount} ${loadingCount === 1 ? "project" : "projects"} in ${groupName}`
			}
			if (errorCount > 0) {
				return `Generated context for ${successCount} ${
					successCount === 1 ? "project" : "projects"
				} in ${groupName}. ${errorCount} failed`
			}
			if (warningCount > 0) {
				return `Generated context for ${successCount} ${
					successCount === 1 ? "project" : "projects"
				} in ${groupName}. ${warningCount} skipped`
			}
			return `Generated context for ${successCount} ${successCount === 1 ? "project" : "projects"} in ${groupName}`
		}
		if (processType === ProcessType.RECHART || processType === ProcessType.REPROCESS) {
			const action = processType === ProcessType.RECHART ? "Recharting" : "Reprocessing"
			const completedAction =
				processType === ProcessType.RECHART ? "Recharted" : "Reprocessed"
			if (loadingCount > 0 && successCount > 0) {
				return `${action} ${loadingCount} ${
					loadingCount === 1 ? "document" : "documents"
				} for ${groupName}. ${successCount} ${completedAction}.`
			}
			if (loadingCount > 0) {
				return `${action} ${loadingCount} ${loadingCount === 1 ? "document" : "documents"} for ${groupName}`
			}
			if (errorCount > 0) {
				return `${completedAction} ${successCount} ${
					successCount === 1 ? "document" : "documents"
				} for ${groupName}. ${errorCount} failed`
			}
			if (warningCount > 0) {
				return `${completedAction} ${successCount} ${
					successCount === 1 ? "document" : "documents"
				} for ${groupName}. ${warningCount} skipped`
			}
			return `${completedAction} ${successCount} ${successCount === 1 ? "document" : "documents"} for ${groupName}`
		}
		if (processType === ProcessType.ADD_SUBJECT) {
			if (loadingCount > 0) {
				return `Adding ${loadingCount} ${loadingCount === 1 ? "subject" : "subjects"} to ${groupName}`
			}
			if (errorCount > 0) {
				return `Added ${successCount} ${
					successCount === 1 ? "subject" : "subjects"
				} to ${groupName}. ${errorCount} failed`
			}
			if (warningCount > 0) {
				return `Added ${successCount} ${
					successCount === 1 ? "subject" : "subjects"
				} to ${groupName}. ${warningCount} skipped`
			}
			return `Added ${successCount} ${successCount === 1 ? "subject" : "subjects"} to ${groupName}`
		}
		if (processType === ProcessType.ADD_OFFICE_ACTION) {
			if (loadingCount > 0) {
				return `Adding ${loadingCount} ${loadingCount === 1 ? "office action" : "office actions"} to ${groupName}`
			}
			if (errorCount > 0) {
				return `Added ${successCount} ${
					successCount === 1 ? "office action" : "office actions"
				} to ${groupName}. ${errorCount} failed`
			}
			if (warningCount > 0) {
				return `Added ${successCount} ${
					successCount === 1 ? "office action" : "office actions"
				} to ${groupName}. ${warningCount} skipped`
			}
			return `Added ${successCount} ${successCount === 1 ? "office action" : "office actions"} to ${groupName}`
		}
	}, [
		processType,
		loadingCount,
		errorCount,
		warningCount,
		successCount,
		loadingGroup.name,
	])

	// Event handlers can be memoized using useCallback if necessary
	const handleToggleDetails = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			e.preventDefault()
			e.stopPropagation()
			setShowDetails(!showDetails)
		},
		[showDetails],
	)

	const handleRemoveLoadingGroup = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			e.preventDefault()
			e.stopPropagation()
			removeLoadingGroup(loadingGroup.key, loadingGroup.processType)
		},
		[loadingGroup.key, loadingGroup.processType, removeLoadingGroup],
	)

	return (
		<Alert variant="default" className="flex items-center pr-8 relative">
			<div className="w-full">
				<div className="flex items-center justify-between">
					<div className="flex items-center">
						{statusIcon}
						<AlertTitle className="mb-0 ml-2">{title}</AlertTitle>
					</div>
					<div className="flex items-center justify-end ml-2">
						{loadingGroup.messages.length > 0 && (
							<Tooltip>
								<TooltipTrigger asChild>
									<Button
										variant="ghost"
										size="icon"
										onClick={handleToggleDetails}
										onPointerDown={(e) => {
											e.preventDefault()
											e.stopPropagation()
										}}
										className="mr-2"
									>
										{showDetails ? (
											<ChevronUp className="h-4 w-4" />
										) : (
											<ChevronDown className="h-4 w-4" />
										)}
									</Button>
								</TooltipTrigger>
								<TooltipContent>{showDetails ? "Hide Details" : "View Details"}</TooltipContent>
							</Tooltip>
						)}

						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="ghost"
									size="icon"
									onClick={handleRemoveLoadingGroup}
									onPointerDown={(e) => {
										e.preventDefault()
										e.stopPropagation()
									}}
								>
									<X className="h-4 w-4" />
								</Button>
							</TooltipTrigger>
							<TooltipContent>Dismiss</TooltipContent>
						</Tooltip>
					</div>
				</div>
				{showDetails && (
					<div
						className="mt-2 space-y-2"
						onClick={(e) => e.stopPropagation()}
						onKeyDown={(e) => e.stopPropagation()}
						onPointerDown={(e) => e.stopPropagation()}
					>
						{loadingGroup.messages.map((message) => (
							<div
								key={message.displayName ? message.displayName.slice(0, 20) : message.name}
								className="flex flex-col"
							>
								<div className="flex items-center justify-between">
									<div className="flex items-center mr-2">
										{message.status === "success" ? (
											<CheckCircle className="mr-3 flex-shrink-0 h-4 w-4 text-blue-500" />
										) : message.status === "error" ? (
											<AlertCircle className="mr-3 flex-shrink-0 h-4 w-4 text-red-500" />
										) : message.status === "warning" ? (
											<AlertTriangle className="mr-3 flex-shrink-0 h-4 w-4 text-yellow-500" />
										) : (
											<Spinner className="mr-3 flex-shrink-0 h-4 w-4" />
										)}
										<span>
											{message.displayName ? message.displayName.slice(0, 20) : message.name}
										</span>
									</div>
									<Badge variant="outline">{capitalizeFirstLetter(message.status)}</Badge>
								</div>
								<p
									className={`ml-8 text-sm ${
										message.status === "error" ? "text-destructive" : "text-muted-foreground"
									}`}
								>
									{message.detail}
								</p>
							</div>
						))}
					</div>
				)}
			</div>
		</Alert>
	)
}

export default LoadingGroupAlert
