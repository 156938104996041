import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "@/components/ui/context-menu"
import { cleanDocumentChunkText } from "@/utils/formatStrings"
import { useState } from "react"

interface CopyContextMenuProps {
	children: React.ReactNode
	getText: () => string
	getCitation: () => string
	className?: string
}

export const CopyContextMenu: React.FC<CopyContextMenuProps> = ({
	children,
	getText,
	getCitation,
	className = "cursor-text select-text",
}) => {
	const [hasSelection, setHasSelection] = useState(false)

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text)
	}

	const handleCopyWithCitation = () => {
		const selection = window.getSelection()?.toString().trim()
		const text = selection && hasSelection ? selection : getText()
		const citation = getCitation()
		const textWithCitation = `${cleanDocumentChunkText(text)}\n\n${citation}`
		copyToClipboard(textWithCitation)
	}

	const handleContextMenuOpen = () => {
		const selection = window.getSelection()?.toString().trim()
		setHasSelection(!!selection)
	}

	return (
		<ContextMenu>
			<ContextMenuTrigger onContextMenu={handleContextMenuOpen} className={className}>
				{children}
			</ContextMenuTrigger>
			<ContextMenuContent>
				<ContextMenuItem
					onClick={() => {
						const selection = window.getSelection()?.toString().trim()
						copyToClipboard(selection || getText())
					}}
				>
					Copy
				</ContextMenuItem>
				<ContextMenuItem onClick={handleCopyWithCitation}>
					Copy with Citation
				</ContextMenuItem>
			</ContextMenuContent>
		</ContextMenu>
	)
}

export default CopyContextMenu
