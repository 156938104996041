import * as React from "react";
import { AutosizeTextarea } from "./autosize-textarea";
import { FloatingLabel } from "./floating-input";
import { cn } from "@/lib/utils";

export interface FloatingTextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  minHeight?: number;
}

const FloatingLabelTextarea = React.forwardRef<
  React.ElementRef<typeof AutosizeTextarea>,
  FloatingTextareaProps
>(({ id, label, error, className, minHeight = 0, ...props }, ref) => {
  return (
    <div className="relative w-full">
      <AutosizeTextarea
        id={id}
        ref={ref}
        placeholder=" "
        {...props}
        minHeight={minHeight}
        className={cn("peer", "w-full", className, error && "border-red-500")}
      />
      {label && (
        <FloatingLabel
          htmlFor={id}
          className="pointer-events-none absolute start-2 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-background px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
        >
          {label}
        </FloatingLabel>
      )}
      {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
    </div>
  );
});
FloatingLabelTextarea.displayName = "FloatingLabelTextarea";

export { FloatingLabelTextarea };
