import { DateTimePicker } from "@/components/ui/datetime-picker"
import { useParentContext } from "@/context/ParentContext"
import useUpdateProjectDetails from "@/hooks/project/useUpdateProjectDetails"
import { convertToUtcDate, getUtcDateString } from "@/utils/dateUtils"
import { DateTime } from "luxon"
import { useState } from "react"

interface DocumentDatesProps {
	publicationDate: string
	priorityDate: string
	isEditing: boolean
}

const DocumentDates: React.FC<DocumentDatesProps> = ({
	publicationDate,
	priorityDate,
	isEditing,
}) => {
	const { updateProjectDetails } = useUpdateProjectDetails()
	const { projectId } = useParentContext()

	const [selectedPriorityDate, setSelectedPriorityDate] = useState<DateTime>(() => {
		return priorityDate
			? DateTime.fromISO(priorityDate, { zone: "utc" })
			: DateTime.now().setZone("utc").startOf("day")
	})

	const handleDateChange = (date: Date | null) => {
		const newDate = date ? DateTime.fromJSDate(date, { zone: "utc" }) : null
		setSelectedPriorityDate(newDate)

		if (newDate) {
			try {
				const utcPriorityDate = convertToUtcDate(newDate)
				updateProjectDetails({
					projectId,
					options: {
						priority_date: utcPriorityDate.toISO(),
					},
				})
			} catch (error) {
				console.error(error)
			}
		}
	}

	return (
		<div className="flex gap-4">
			<div className="flex items-center gap-2">
				<span className="text-muted-foreground text-sm">Publication Date</span>
				<span className="text-foreground text-sm">
					{publicationDate ? getUtcDateString(publicationDate) : "—"}
				</span>
				<span className="text-muted-foreground">•</span>
			</div>

			<div className="flex items-center gap-2">
				<span className="text-muted-foreground text-sm">Priority Date</span>
				{isEditing ? (
					<DateTimePicker
						value={selectedPriorityDate.toUTC().toJSDate()}
						granularity="day"
						onChange={handleDateChange}
					/>
				) : (
					<span className="text-foreground text-sm">
						{priorityDate ? getUtcDateString(priorityDate) : "—"}
					</span>
				)}
			</div>
		</div>
	)
}

export default DocumentDates
