/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import type { UnpublishedPatentEntry } from "@/types"
import type React from "react"
import { useState } from "react"
import { Input } from "../../ui/input"

interface UnpublishedPatentFormProps {
	onSave: (unpublishedPatent: UnpublishedPatentEntry) => void
}

/**
 * @description Form for creating an unpublished patent entry
 *
 * Used in create project flow
 * @todo: Add remaining fields, improve UI
 */
const UnpublishedPatentForm: React.FC<UnpublishedPatentFormProps> = ({ onSave }) => {
	const [claims, _setClaims] = useState<Array<{ text: string }>>([])
	const [abstract, setAbstract] = useState("")
	const [body, setBody] = useState("")
	const [title, setTitle] = useState("")

	const handleSave = () => {
		// Split body into array of strings, remove empty strings
		const splitBody: string[] = body
			? body.split("\n").filter((line) => line.trim() !== "")
			: []

		// Create the unpublished patent entry
		const unpublishedPatent: UnpublishedPatentEntry = {
			title,
		}
		if (claims.length > 0) {
			unpublishedPatent.claims = claims.map((claim) => ({ text: claim.text || "" }))
		}
		if (abstract) {
			unpublishedPatent.abstract = abstract
		}
		if (splitBody.length > 0) {
			unpublishedPatent.body = splitBody
		}

		onSave(unpublishedPatent)
	}

	return (
		<div>
			<div className="space-y-4">
				<div>
					<Label htmlFor="title">Title</Label>
					<Input
						id="title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						placeholder="Enter title..."
					/>
				</div>
				<div>
					<Label htmlFor="claims">Claims</Label>
				</div>
				<div>
					<Label htmlFor="abstract">Abstract</Label>
					<Textarea
						id="abstract"
						value={abstract}
						onChange={(e) => setAbstract(e.target.value)}
						placeholder="Enter abstract..."
					/>
				</div>
				<div>
					<Label htmlFor="body">Description</Label>
					<Textarea
						id="body"
						value={body}
						onChange={(e) => setBody(e.target.value)}
						placeholder="Enter description..."
					/>
				</div>
			</div>

			<Button className="mt-4 align-right" onClick={handleSave}>
				Save
			</Button>
		</div>
	)
}

export default UnpublishedPatentForm
