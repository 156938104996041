import { ZoomIn, ZoomOut } from "lucide-react"
import {
	type MouseEvent,
	type SyntheticEvent,
	type WheelEvent,
	useCallback,
} from "react"
import { Button } from "../ui/button"

interface FigureInteractiveAreaProps {
	imageUrl: string
	rotation: number
	imageWidth: number
	imageHeight: number
	onImageLoad: (e: SyntheticEvent<HTMLImageElement>) => void

	// Position & scale
	scale: number
	setScale: (newScale: number | ((prev: number) => number)) => void
	position: { x: number; y: number }
	setPosition: (pos: { x: number; y: number }) => void

	isDragging: boolean
	setIsDragging: (drag: boolean) => void
	dragStart: { x: number; y: number }
	setDragStart: (pos: { x: number; y: number }) => void
}

export function FigureInteractiveArea({
	imageUrl,
	rotation,
	imageWidth,
	imageHeight,
	onImageLoad,

	scale,
	setScale,
	position,
	setPosition,

	isDragging,
	setIsDragging,
	dragStart,
	setDragStart,
}: FigureInteractiveAreaProps) {
	const handleMouseDown = (e: MouseEvent) => {
		e.preventDefault()
		setIsDragging(true)
		setDragStart({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		})
	}

	const handleMouseMove = (e: MouseEvent) => {
		if (isDragging) {
			e.preventDefault()
			setPosition({
				x: e.clientX - dragStart.x,
				y: e.clientY - dragStart.y,
			})
		}
	}

	const handleMouseUp = () => {
		setIsDragging(false)
	}

	// Trackpad/mouse typical wheel zoom
	const handleWheel = useCallback(
		(e: WheelEvent<HTMLDivElement>) => {
			e.preventDefault()
			const direction = e.deltaY > 0 ? -0.1 : 0.1
			setScale((prev) => {
				const newVal = prev + direction
				// clamp
				if (newVal < 0.2) return 0.2
				if (newVal > 3) return 3
				return newVal
			})
		},
		[setScale],
	)

	const handleZoomIn = () => {
		setScale((prev) => Math.min(prev + 0.25, 3))
	}

	const handleZoomOut = () => {
		setScale((prev) => Math.max(prev - 0.25, 0.2))
	}

	return (
		<div className="flex-1 overflow-hidden flex flex-col items-center mb-2 bg-accent relative">
			{/* Zoom controls */}
			<div className="absolute bottom-2 right-2 flex flex-col gap-2 z-10">
				<Button
					variant="ghost"
					size="icon"
					onClick={handleZoomIn}
					aria-label="Zoom in"
					className="bg-accent"
				>
					<ZoomIn size={18} />
				</Button>
				<Button
					variant="ghost"
					size="icon"
					onClick={handleZoomOut}
					aria-label="Zoom out"
					className="bg-accent"
				>
					<ZoomOut size={18} />
				</Button>
			</div>

			<div
				className="flex items-center justify-center flex-1"
				style={{ cursor: isDragging ? "grabbing" : "grab" }}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
				onMouseLeave={handleMouseUp}
				onWheel={handleWheel}
			>
				<img
					src={imageUrl}
					alt="Figure"
					className="rounded-md object-contain"
					onLoad={onImageLoad}
					style={{
						transform: `translate(${position.x}px, ${position.y}px) rotate(${rotation}deg) scale(${scale})`,
						width: imageWidth,
						height: imageHeight,
						maxWidth: "100%",
						transition: isDragging ? "none" : "transform 0.2s ease-out",
						transitionProperty: "translate, scale",
						userSelect: "none",
					}}
				/>
			</div>
		</div>
	)
}
