/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import PatentNumberInput from "@/components/documents/patents/PatentNumberInput"
import MultiProjectSelect from "@/components/projects/MultiProjectSelect"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { useParentContext } from "@/context/ParentContext"
import { useNavigationState, usePortfolioMetadata } from "@/hooks"
import useProcessDocuments from "@/hooks/useProcessDocuments"
import { docRoleToProcessType } from "@/hooks/useProcessDocuments"
import useUnprocessedDocuments from "@/hooks/useUnprocessedDocuments"
import { DocumentRole, type PatentNumberOption, UnprocessedDocumentType } from "@/types"
import { useState } from "react"
import ProcessSettings from "./ProcessSettings"
import UnprocessedDocumentsList from "./UnprocessedDocumentsList"

interface AddPatentsModalProps {
	open: boolean
	handleClose: () => void
	showProcessSettings?: boolean
	documentRole?: DocumentRole
	isProcessing?: boolean
}

/**
 * @description Modal for adding references by number
 * @param {boolean} open - Whether the modal is open
 * @param {() => void} handleClose - Function to close the modal
 * @param {boolean} isPortfolio - Whether the modal is for a portfolio
 */
const AddPatentsModal: React.FC<AddPatentsModalProps> = ({
	open,
	handleClose,
	isProcessing,
	showProcessSettings = true,
	documentRole = DocumentRole.PRIOR_ART,
}) => {
	const { projectId, portfolioId } = useParentContext()
	const { processDocuments } = useProcessDocuments(projectId, portfolioId)

	const { isDocumentsPage, isPortfolioPage } = useNavigationState()
	const { portfolioProjects } = usePortfolioMetadata()

	// If projectId exists, use it as the only selected project, otherwise use all portfolio projects
	const defaultProjectIds = projectId
		? [projectId]
		: portfolioProjects
			? portfolioProjects.map((p) => p.id)
			: []
	const [selectedProjectIds, setSelectedProjectIds] =
		useState<string[]>(defaultProjectIds)

	// Use the custom hook for handling unprocessed documents
	const {
		unprocessedDocuments,
		updateSelectedKindCode,
		removeUnprocessedDocument,
		addUnprocessedDocuments,
		resetAll,
	} = useUnprocessedDocuments()

	// Modal state
	const [useClaimsCheckboxChecked, setUseClaimsCheckboxChecked] = useState<boolean>(true)
	const [overwriteCheckboxChecked, setOverwriteCheckboxChecked] =
		useState<boolean>(false)
	const [_, setIsProcessingBudgetExceeded] = useState<boolean>(false)

	// Reset the modal state on close, including resetting project selection to default
	const handleResetModal = () => {
		setUseClaimsCheckboxChecked(true)
		resetAll()
		if (portfolioId) {
			setSelectedProjectIds(defaultProjectIds)
		}
		handleClose()
	}

	// Modified add click to process for each selected project if portfolio exists
	const handleAddClick = async () => {
		// Prevent multiple rapid clicks
		if (isProcessing) return
		try {
			// Immediately close the modal so the UI gives feedback even if processing is ongoing
			handleClose()
			if (unprocessedDocuments.length > 0) {
				await processDocuments(
					docRoleToProcessType[documentRole],
					portfolioId,
					selectedProjectIds,
					unprocessedDocuments,
					!isDocumentsPage, // Don't chart if in user documents
					useClaimsCheckboxChecked,
				)
			}
			setUseClaimsCheckboxChecked(true)
			resetAll()
		} finally {
			// Reset processing state for future adds
		}
	}

	const handleBudgetExceededConfirm = () => {
		setIsProcessingBudgetExceeded(true)
	}

	const handleAddDocuments = (patentDetails: PatentNumberOption[]) => {
		addUnprocessedDocuments(
			documentRole,
			UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION,
			{
				patentDetails,
			},
		)
	}

	return (
		<Dialog open={open} onOpenChange={handleResetModal}>
			<DialogContent className="w-auto min-w-2xl">
				<div className="flex items-center">
					<DialogHeader>
						<DialogTitle>
							{documentRole === DocumentRole.PRIOR_ART ? "Add Patents" : "Add Subjects"}
						</DialogTitle>
					</DialogHeader>

					{/* Only show project selection if in portfolio view (no specific project selected) */}
					{documentRole === DocumentRole.PRIOR_ART && isPortfolioPage && (
						<div className="ml-4">
							<MultiProjectSelect
								selectedProjectIds={selectedProjectIds}
								onChange={setSelectedProjectIds}
							/>
						</div>
					)}
				</div>

				<PatentNumberInput
					projectId={portfolioId ?? projectId}
					onDetails={handleAddDocuments}
					onCancel={handleResetModal}
					onlyShowInputError={unprocessedDocuments.length > 0}
					projectMultiplier={selectedProjectIds?.length}
					onBudgetExceededConfirm={handleBudgetExceededConfirm}
				/>
				{unprocessedDocuments.length > 0 && (
					<>
						<UnprocessedDocumentsList
							documents={unprocessedDocuments}
							onRemove={removeUnprocessedDocument}
							updateSelectedKindCode={updateSelectedKindCode}
						/>
						<div className="flex flex-row justify-between gap-2">
							{showProcessSettings && (
								<ProcessSettings
									useClaimsCheckboxChecked={useClaimsCheckboxChecked}
									setUseClaimsCheckboxChecked={setUseClaimsCheckboxChecked}
									overwriteCheckboxChecked={overwriteCheckboxChecked}
									setOverwriteCheckboxChecked={setOverwriteCheckboxChecked}
								/>
							)}

							<div className="flex justify-end gap-2">
								<Button variant="outline" onClick={handleResetModal}>
									Cancel
								</Button>
								<Button
									onClick={handleAddClick}
									disabled={unprocessedDocuments.length === 0 || isProcessing}
								>
									{isProcessing ? "Adding..." : "Add Patents"}
								</Button>
							</div>
						</div>
					</>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default AddPatentsModal
