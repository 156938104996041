import { CheckboxWithText } from "@/components/ui/checkbox"

interface ProcessSettingsProps {
	useClaimsCheckboxChecked: boolean
	setUseClaimsCheckboxChecked: (checked: boolean) => void
	overwriteCheckboxChecked: boolean
	setOverwriteCheckboxChecked: (checked: boolean) => void
}

const ProcessSettings = ({
	useClaimsCheckboxChecked,
	setUseClaimsCheckboxChecked,
	overwriteCheckboxChecked,
	setOverwriteCheckboxChecked,
}: ProcessSettingsProps) => {
	return (
		<div className="flex flex-col gap-2">
			{/* Hiding this checkbox feature for now, may want to add back in the future */}
			{/* <div>
        <CheckboxWithText
          id="complete-charts"
          checked={isCheckboxChecked}
          onCheckedChange={(checked) =>
            setIsCheckboxChecked(checked as boolean)
          }
          label="Complete claim and feature chart(s)"
        />
      </div> */}
			{/* <CheckboxWithText
				id="with-claims"
				checked={useClaimsCheckboxChecked}
				onCheckedChange={(checked) => setUseClaimsCheckboxChecked(checked as boolean)}
				label="Include claims in charting"
			/> */}
			<CheckboxWithText
				id="overwrite"
				checked={overwriteCheckboxChecked}
				onCheckedChange={(checked) => setOverwriteCheckboxChecked(checked as boolean)}
				label="Overwrite existing documents"
			/>
		</div>
	)
}

export default ProcessSettings
