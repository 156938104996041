/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import type { InvalidityChart } from "@/types/invalidity"
import type React from "react"
import { useState } from "react"
import ProjectChartsTable from "../ProjectChartsTable"

interface ArchivedChartsModalProps {
	archivedCharts: InvalidityChart[]
	open: boolean
	handleClose: () => void
}

/**
 * @description Prune invalidity charts by citation score
 * @param {boolean} open - Whether the modal is open.
 * @param {function} handleClose - Function to close the modal.
 * @param {function} fetchElementChartPageData - Function to fetch the element chart page data.
 */
const ArchivedChartsModal: React.FC<ArchivedChartsModalProps> = ({
	archivedCharts,
	open = true,
	handleClose,
}) => {
	const [isLoading, setIsLoading] = useState(false)

	const resetModal = () => {
		setIsLoading(false)
	}

	const onClose = () => {
		resetModal()
		handleClose()
	}

	return (
		<Dialog open={open} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-5xl">
				<DialogHeader>
					<DialogTitle>Archived Charts</DialogTitle>
				</DialogHeader>

				<ProjectChartsTable
					projectCharts={archivedCharts}
					onNewChart={() => onClose()}
					isArchived
				/>

				<DialogFooter>
					<div className="flex items-center justify-end gap-2">
						<Button variant="outline" onClick={() => onClose()} disabled={isLoading}>
							Done
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default ArchivedChartsModal
