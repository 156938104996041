/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import {
	AdminPageLayout,
	NoAdminAccessMessage,
	UsersTable,
} from "@/features/admin/components"
import { OrganizationDetailsTable } from "@/features/admin/superadmin/components"
import { useUserStore } from "@/store"
import { RoleType } from "@/types"
import { PageName } from "@/types/pages"
import { useMemo, useState } from "react"
import { useAdminUsers } from "../hooks/useAdminUsers"
import { useSuperAdminOrganizations } from "../hooks/useSuperAdminOrganizations"

/**
 * SuperAdminOrganizationPage component handles the display of organization management
 * for super administrators.
 */
function SuperAdminOrganizationPage() {
	// State for selected organization
	const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null)

	// Hooks
	const { isLoading, organizations } = useSuperAdminOrganizations()
	const { adminUsers, isLoading: isLoadingUsers } = useAdminUsers(selectedOrgId)
	const { role } = useUserStore()

	// Memoized values
	const isSuperAdmin = useMemo(() => role === RoleType.OWNER, [role])
	const selectedOrg = useMemo(
		() => organizations?.find((org) => org.id === selectedOrgId),
		[organizations, selectedOrgId],
	)

	// Render helpers
	const renderContent = () => {
		if (!isSuperAdmin) return <NoAdminAccessMessage />
		if (isLoading) return <Loader />
		if (!organizations) return <div>No organizations found</div>

		return (
			<div className="space-y-8 mt-2">
				<OrganizationDetailsTable
					organizations={organizations}
					onOrgSelect={setSelectedOrgId}
					selectedOrgId={selectedOrgId}
				/>
				{selectedOrgId && (
					<div className="mt-8">
						<h2 className="text-xl font-semibold mb-4">Users for {selectedOrg?.name}</h2>
						{isLoadingUsers ? (
							<Loader />
						) : !adminUsers ? (
							<p className="text-destructive">Error fetching users.</p>
						) : (
							<UsersTable
								orgName={selectedOrg?.name ?? ""}
								orgId={selectedOrgId}
								users={adminUsers.users ?? []}
							/>
						)}
					</div>
				)}
			</div>
		)
	}

	return (
		<AdminPageLayout isSuperAdmin={true} page={PageName.SuperAdminOverview}>
			{renderContent()}
		</AdminPageLayout>
	)
}

export default SuperAdminOrganizationPage
