import { AutosizeTextarea } from "@/components/ui/autosize-textarea"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { Cross2Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons"
import type React from "react"

interface SearchInputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	/** The current search value */
	value: string
	/** Called when the search value changes */
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	/** Called when a search action should occur (via click or Enter key) */
	onSearch: () => void
	/** Placeholder text */
	placeholder?: string
	/** Whether the search input is disabled */
	disabled?: boolean
	/** Minimum height for the textarea */
	minHeight?: number
	/** Maximum height for the textarea */
	maxHeight?: number
	/** Whether the search input supports multi-line input */
	isMultiLineSupported?: boolean
	/** Called when the search value changes */
	setResultIds?: (ids: Set<string> | null) => void
}

const SearchInput: React.FC<SearchInputProps> = ({
	value,
	onChange,
	onSearch,
	placeholder = "Search...",
	disabled = false,
	minHeight = 36,
	maxHeight = Number.MAX_SAFE_INTEGER,
	isMultiLineSupported = false,
	className,
	setResultIds,
	...props
}) => {
	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		// When pressing "Enter" without Shift, trigger the search
		if (e.key === "Enter" && (!isMultiLineSupported || !e.shiftKey)) {
			e.preventDefault()
			onSearch()
		}
	}

	const handleClear = () => {
		const event = {
			target: { value: "" },
		} as React.ChangeEvent<HTMLTextAreaElement>
		onChange(event)
		setResultIds?.(null)
	}

	return (
		<div className="relative">
			<AutosizeTextarea
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				onKeyDown={handleKeyDown}
				disabled={disabled}
				minHeight={minHeight}
				maxHeight={isMultiLineSupported ? maxHeight : minHeight}
				className={cn(
					"pr-20", // increased padding to accommodate both buttons
					"resize-none",
					className,
				)}
				{...props}
			/>
			<div className={cn("absolute right-2 flex gap-1", "top-1/2 -translate-y-1/2")}>
				{value && (
					<Button
						size="icon"
						variant="ghost"
						className="h-8 w-8"
						onClick={handleClear}
						disabled={disabled}
					>
						<Cross2Icon className="h-4 w-4 text-muted-foreground" />
					</Button>
				)}
				<Button
					size="icon"
					variant="ghost"
					className="h-8 w-8"
					onClick={onSearch}
					disabled={disabled}
				>
					<MagnifyingGlassIcon className="h-4 w-4 text-muted-foreground" />
				</Button>
			</div>
		</div>
	)
}

export default SearchInput
