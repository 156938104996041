import { Button } from "@/components/ui/button"
import {
	BlueCheckboxMenuItem,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import useChartOptionsStore from "@/features/charts/hooks/chartOptionsStore"
import { EllipsisVertical } from "lucide-react"
import type React from "react"

interface ChartOptionsDropdownProps {
	isClaim: boolean
}

const ChartOptionsDropdown: React.FC<ChartOptionsDropdownProps> = ({ isClaim }) => {
	const {
		showCitationsInClaim,
		toggleShowCitationsInClaim,
		showCommentaryInClaim,
		toggleShowCommentaryInClaim,
		showUpdatedAt,
		toggleShowUpdatedAt,
		showUpdatedBy,
		toggleShowUpdatedBy,
		showReferencedFigures,
		toggleShowReferencedFigures,
		showFeedbackButtons,
		toggleShowFeedbackButtons,
	} = useChartOptionsStore()

	return (
		<DropdownMenu>
			<Tooltip>
				<TooltipTrigger asChild>
					<DropdownMenuTrigger asChild>
						<Button variant="ghost" size="xsIcon" className="focus-visible:ring-0">
							<EllipsisVertical className="h-4 w-4" />
						</Button>
					</DropdownMenuTrigger>
				</TooltipTrigger>
				<TooltipContent>
					<p>Chart Options</p>
				</TooltipContent>
			</Tooltip>

			<DropdownMenuContent align="end">
				{isClaim ? (
					<>
						<BlueCheckboxMenuItem
							checked={showCitationsInClaim}
							onSelect={(e) => {
								e.preventDefault()
								toggleShowCitationsInClaim()
							}}
						>
							Show Evidence Citations
						</BlueCheckboxMenuItem>
						<BlueCheckboxMenuItem
							checked={showCommentaryInClaim}
							onSelect={(e) => {
								e.preventDefault()
								toggleShowCommentaryInClaim()
							}}
						>
							Show Claim Commentary
						</BlueCheckboxMenuItem>
					</>
				) : (
					<>
						<BlueCheckboxMenuItem
							checked={showUpdatedAt}
							onSelect={(e) => {
								e.preventDefault()
								toggleShowUpdatedAt()
							}}
						>
							Show Updated At
						</BlueCheckboxMenuItem>
						<BlueCheckboxMenuItem
							checked={showUpdatedBy}
							onSelect={(e) => {
								e.preventDefault()
								toggleShowUpdatedBy()
							}}
						>
							Show Updated By
						</BlueCheckboxMenuItem>
						<BlueCheckboxMenuItem
							checked={showReferencedFigures}
							onSelect={(e) => {
								e.preventDefault()
								toggleShowReferencedFigures()
							}}
						>
							Show Referenced Figures
						</BlueCheckboxMenuItem>
						<BlueCheckboxMenuItem
							checked={showFeedbackButtons}
							onSelect={(e) => {
								e.preventDefault()
								toggleShowFeedbackButtons()
							}}
						>
							Show Feedback Buttons
						</BlueCheckboxMenuItem>
					</>
				)}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export default ChartOptionsDropdown
