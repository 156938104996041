/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { CountryCodeSelect, InfoPopover, ItemTags } from "@/components"
import { CheckboxWithText } from "@/components/ui/checkbox"
import { DateRangePicker } from "@/components/ui/date-range-picker"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItemWithLabel } from "@/components/ui/radio-group"
import { PatchClaimsSearchSection } from "@/features/references/components/PatchClaimsSearchSection"
import { ClaimTypes } from "@/types"
import type { SearchFilters } from "@/types"
import { SUPPORTED_JURISDICTIONS } from "@/types/project"
import type React from "react"
/**
 * @description Prior art search filters
 */
interface SearchFiltersProps {
	disabled: boolean
	isInCreate?: boolean
	filters: SearchFilters
	updateFilter: (
		key: keyof SearchFilters,
		value: SearchFilters[keyof SearchFilters],
	) => void
}

const SearchFiltersComponent: React.FC<SearchFiltersProps> = ({
	disabled,
	isInCreate,
	filters,
	updateFilter,
}) => {
	const {
		assignees,
		toDate,
		fromDate,
		cpcCodes,
		countryCodes,
		maxResults,
		dedupeFamilyId,
		selectedClaims,
		unselectedClaims,
		claimType,
	} = filters

	const assigneeContent =
		"Assignee names require an exact match. For comprehensive results, enter all valid versions of an assignee name (e.g., 'Google', 'Google Inc.', 'Google, Inc.', etc.)"

	const renderItemTags = (
		label: string,
		items: string[],
		setItems: (items: string[]) => void,
		type: string,
		infoContent?: React.ReactNode,
	) => (
		<div className="space-y-2">
			<div className="flex items-center space-x-1">
				<Label className="mr-1">{label}</Label>
				{infoContent && <InfoPopover className="ml-1 mb-1" content={assigneeContent} />}
			</div>
			<ItemTags
				items={items}
				setItems={setItems}
				type={type as "cpc" | "assignee"}
				disabled={disabled}
			/>
		</div>
	)

	return (
		<div className="space-y-6">
			{/* Deduplicate by, Max Results, Priority Date, Patent Offices */}
			<div className="flex flex-wrap gap-4">
				<div className="space-y-2">
					<div className="flex items-center space-x-2">
						<Label>Patent Offices</Label>
					</div>
					<CountryCodeSelect
						countryCodes={SUPPORTED_JURISDICTIONS}
						selectedCountryCodes={countryCodes}
						setSelectedCountryCodes={(codes) => updateFilter("countryCodes", codes)}
					/>
				</div>

				<div>
					<DateRangePicker
						fromDate={fromDate}
						toDate={toDate}
						onFromDateChange={(date) => updateFilter("fromDate", date)}
						onToDateChange={(date) => updateFilter("toDate", date)}
						label="Priority Date"
						disabled={disabled}
					/>
				</div>

				<div className="space-y-2">
					<Label>Max Results</Label>
					<Input
						type="number"
						placeholder="Optional"
						value={maxResults}
						onChange={(e) => {
							const value = Number.parseInt(e.target.value, 10)
							if (!Number.isNaN(value)) {
								updateFilter("maxResults", value)
							}
						}}
						disabled={disabled}
						className="h-10"
					/>
				</div>

				{dedupeFamilyId !== undefined && (
					<div className="space-y-4">
						<div className="flex flex-col space-y-2">
							<Label>Deduplicate By</Label>
							<CheckboxWithText
								id="Family ID"
								checked={dedupeFamilyId}
								onCheckedChange={(checked) => {
									updateFilter("dedupeFamilyId", checked as boolean)
								}}
								label="Family ID"
							/>
						</div>
					</div>
				)}
			</div>

			{/* Assignees and CPC Codes */}
			<div className="flex gap-4 w-full">
				<div className="flex-1">
					{renderItemTags(
						"Assignees",
						assignees,
						(items) => updateFilter("assignees", items),
						"assignee",
						assigneeContent,
					)}
				</div>
				<div className="flex-1">
					{renderItemTags(
						"CPC Codes",
						cpcCodes,
						(items) => updateFilter("cpcCodes", items),
						"cpc",
					)}
				</div>
			</div>

			{/* Claims */}
			<div>
				<div className="space-y-2">
					<div className="flex items-center space-x-2">
						<Label>Claims</Label>
					</div>
					<RadioGroup
						className="flex flex-row gap-4"
						value={claimType}
						onValueChange={(value) => updateFilter("claimType", value as ClaimTypes)}
					>
						<RadioGroupItemWithLabel value={ClaimTypes.ALL_CLAIMS} label="All" />
						<RadioGroupItemWithLabel value={ClaimTypes.ASSERTED_CLAIMS} label="Asserted" />
						<RadioGroupItemWithLabel
							value={ClaimTypes.INDEPENDENT_CLAIMS}
							label="Independent"
						/>
						<RadioGroupItemWithLabel value={ClaimTypes.SELECTED_CLAIMS} label="Selected" />
					</RadioGroup>
				</div>
				{claimType === ClaimTypes.SELECTED_CLAIMS && (
					<PatchClaimsSearchSection
						isVisible
						unselectedClaims={unselectedClaims}
						selectedClaims={selectedClaims}
						setSelectedClaims={(claims) => updateFilter("selectedClaims", claims)}
						setUnselectedClaims={(claims) => updateFilter("unselectedClaims", claims)}
					/>
				)}
			</div>
		</div>
	)
}

export default SearchFiltersComponent
