/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import { useParentContext } from "@/context/ParentContext"
import ChatComponent from "@/features/chat/components/ChatComponent"
import { useAllProjectDocuments } from "@/hooks/project/useAllProjectDocuments"
import { PageName } from "@/types/pages"
import type React from "react"
import ProjectPage from "../../components/projects/ProjectPage"

/**
 * @description Project-level chat page
 */
const ProjectChatPage: React.FC = () => {
	const { projectId } = useParentContext()
	const { allDocumentsMetadata, isLoading } = useAllProjectDocuments()

	return (
		<ProjectPage pageName={PageName.Chat}>
			{isLoading ? (
				<div className="flex justify-center items-center flex-col mt-12">
					<Loader message="Loading..." />
				</div>
			) : (
				<ChatComponent
					projectId={projectId}
					disabledBool={false}
					documents={allDocumentsMetadata}
				/>
			)}
		</ProjectPage>
	)
}

export default ProjectChatPage
