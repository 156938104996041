import type { components } from "schema"
import type { KeysToCamelCase } from "./utils"

export type ProjectToDocument = KeysToCamelCase<
	components["schemas"]["ProjectDocument"]
>
export type PortfolioToDocument = KeysToCamelCase<
	components["schemas"]["PortfolioToDocument"]
>
export type Document = KeysToCamelCase<components["schemas"]["Document"]>

export type ProjectDocument = KeysToCamelCase<components["schemas"]["ProjectDocument"]>
export type PortfolioDocument = KeysToCamelCase<
	components["schemas"]["PortfolioDocument"]
>

export type Patent = KeysToCamelCase<components["schemas"]["Patent"]>
export type Reference = KeysToCamelCase<components["schemas"]["Reference"]>
export type Standard = KeysToCamelCase<components["schemas"]["Standard"]>
export type OfficeAction = KeysToCamelCase<components["schemas"]["OfficeAction"]>
export type ProjectDocumentMetadata = KeysToCamelCase<
	components["schemas"]["ProjectDocumentMetadata"]
>

export type PatentNumberOption = KeysToCamelCase<
	components["schemas"]["PatentNumberOption"]
> & {
	selectedKindCode?: string
}

export type PatentResult = KeysToCamelCase<components["schemas"]["PatentResult"]>

export type ReferenceParagraphCitation = KeysToCamelCase<
	components["schemas"]["ReferenceParagraphCitation"]
>

export type BaseClaimStatusReason = KeysToCamelCase<
	components["schemas"]["ClaimStatusReason"]
>
export type ClaimStatusReason = Omit<BaseClaimStatusReason, "referenceCitations"> & {
	referenceCitations?: ReferenceParagraphCitation[]
	arguments?: string[]
	amendments?: ClaimAmendment[]
	index: number
	claimIndex: number
}

export type ClaimDetails = Omit<
	KeysToCamelCase<components["schemas"]["ClaimDetails"]>,
	"reasons"
> & {
	reasons: ClaimStatusReason[]
}

export type ClaimAmendment = KeysToCamelCase<components["schemas"]["ClaimAmendment"]>

export type ClaimArgumentResponse = KeysToCamelCase<
	components["schemas"]["ClaimArgumentResponse"]
>

export type ClaimAmendmentResponse = KeysToCamelCase<
	components["schemas"]["ClaimAmendmentResponse"]
>

// Document Types
export type DocumentToProcess = components["schemas"]["DocumentToProcess"]
export type ProcessSettings = components["schemas"]["ProcessSettings"]
export type PortfolioDocumentMetadata = KeysToCamelCase<
	components["schemas"]["PortfolioDocumentMetadata"]
>

export type ParentDocumentMetadata = ProjectDocumentMetadata | PortfolioDocumentMetadata

export type DocumentMetadata = KeysToCamelCase<
	components["schemas"]["DocumentMetadata"]
>

// TODO: align with backend
export type DocumentStatusSchema = components["schemas"]["DocumentStatus"]
export const DocumentStatus = {
	PROCESSING: "PROCESSING",
	CHARTING: "CHARTING",
	PROCESSED: "PROCESSED",
	ERROR: "ERROR",
	REPROCESS: "REPROCESS",
	RECHART: "RECHART",
	REUPLOAD: "REUPLOAD",
	DB_ERROR: "DB_ERROR",
	UPLOADED: "UPLOADED",
	QUEUED: "QUEUED",
} as const satisfies Record<string, DocumentStatusSchema>
export type DocumentStatus = (typeof DocumentStatus)[keyof typeof DocumentStatus]

export type DocumentTypeSchema = components["schemas"]["DocumentType"]
export const DocumentType = {
	OFFICE_ACTION: "OFFICE_ACTION",
	STANDARD: "STANDARD",
	PATENT: "PATENT",
	REFERENCE: "REFERENCE",
} as const satisfies Record<string, DocumentTypeSchema>
export type DocumentType = (typeof DocumentType)[keyof typeof DocumentType]

export type DocumentRoleSchema = components["schemas"]["DocumentRole"]
export const DocumentRole = {
	SUBJECT: "SUBJECT",
	PRIOR_ART: "PRIOR_ART",
	CONTEXT: "CONTEXT",
	OFFICE_ACTION: "OFFICE_ACTION",
} as const satisfies Record<string, DocumentRoleSchema>
export type DocumentRole = (typeof DocumentRole)[keyof typeof DocumentRole]

export type PatentTypeSchema = components["schemas"]["PatentType"]
export const PatentType = {
	PUBLISHED_PATENT: "PUBLISHED_PATENT",
	AMENDED_PATENT: "AMENDED_PATENT",
	PUBLISHED_APPLICATION: "PUBLISHED_APPLICATION",
	SUBMITTED_APPLICATION: "SUBMITTED_APPLICATION",
	UNSUBMITTED_APPLICATION: "UNSUBMITTED_APPLICATION",
} as const satisfies Record<string, PatentTypeSchema>
export type PatentType = (typeof PatentType)[keyof typeof PatentType]

export type FileTypeSchema = components["schemas"]["FileType"]
export const FileType = {
	PDF: "PDF",
	WEB: "WEB",
	TXT: "TXT",
	PPT: "PPT",
} as const satisfies Record<string, FileTypeSchema>
export type FileType = (typeof FileType)[keyof typeof FileType]

export type BlockTypeSchema = components["schemas"]["BlockType"]
export const BlockType = {
	TEXT: "TEXT",
	LIST: "LIST",
	LIST_ITEM: "LIST_ITEM",
	KEY_VALUE: "KEY_VALUE",
	FIGURE: "FIGURE",
	TABLE: "TABLE",
	CAPTION: "CAPTION",
	HEADER: "HEADER",
	FOOTER: "FOOTER",
	SECTION_HEADER: "SECTION_HEADER",
	TITLE: "TITLE",
	UNKNOWN: "UNKNOWN",
} as const satisfies Record<string, BlockTypeSchema>
export type BlockType = (typeof BlockType)[keyof typeof BlockType]

export enum UnprocessedDocumentType {
	PUBLISHED_PATENT_OR_APPLICATION = "PUBLISHED_PATENT_OR_APPLICATION",
	UNPUBLISHED_PATENT = "UNPUBLISHED_PATENT",
	STANDARD = "STANDARD",
	REFERENCE = "REFERENCE",
	OFFICE_ACTION = "OFFICE_ACTION",
}
export type UnpublishedPatentEntry = KeysToCamelCase<
	components["schemas"]["UnpublishedPatent"]
>

export type SearchModeSchema = components["schemas"]["SearchMode"]
export const SearchMode = {
	SEMANTIC: "semantic",
	KEYWORD: "keyword",
} as const satisfies Record<string, SearchModeSchema>
export type SearchMode = (typeof SearchMode)[keyof typeof SearchMode]

export interface UnprocessedDocument {
	id: string
	type: UnprocessedDocumentType
	role: DocumentRole
	patentDetails?: PatentNumberOption // used for published and unpublished patents
	unpublishedPatent?: UnpublishedPatentEntry
	selectedKindCode?: string
	file?: File
	warning?: string
	s3_key?: string
}

export enum DocumentChunkMode {
	CHART = "CHART", // Document viewer chart mode
	CITATION = "CITATION", // Citation in chart page
	DETAIL = "DETAIL", // Document viewer detail mode
	EDIT = "EDIT", // Edit document chunk dialog
	TABLE = "TABLE", // Table mode
}

export enum CitationFormat {
	FULL = "full",
	PAGES = "pages",
	PARAGRAPHS = "paragraphs",
	COLUMN_LINE = "column_line",
	PAGE_PARAGRAPH = "page_paragraph",
	DEFAULT = "default",
}

export enum DocumentSection {
	ALL = "all",
	BODY = "body",
	CLAIMS = "claims",
	FIGURES = "figures",
}

export type AggregatedDocumentStatus =
	| "processed"
	| "processing"
	| "warning"
	| "error"
	| "none"

// NOTE: Match with backend DocumentCoverSentence definition
export type DocumentCoverSentence = KeysToCamelCase<
	components["schemas"]["DocumentCoverSentence"]
>

export type Quote = KeysToCamelCase<components["schemas"]["Quote"]>
export type Filler = KeysToCamelCase<components["schemas"]["Filler"]>

export type ProcessingDocumentStatus = KeysToCamelCase<
	components["schemas"]["ProcessingDocumentStatus"]
>
