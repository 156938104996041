import type { UnprocessedDocument } from "@/types/documents"
import type { PatentNumberOption } from "@/types/documents"
import { Button } from "../../ui/button"

interface AddDocumentFooterProps {
	unprocessedDocuments: UnprocessedDocument[]
	fileErrors: string[]
	fileLimitError: string
	patentDetails?: PatentNumberOption[]
	handleReset: () => void
	handleAddAsPatentClick: (patentDetails: PatentNumberOption[]) => void
	handleAddClick: () => void
	isButtonDisabled: boolean
	isProcessing: boolean
	buttonLabel: string
	potentialPatents: { number: string; file: File }[]
}
const AddDocumentFooter: React.FC<AddDocumentFooterProps> = ({
	unprocessedDocuments,
	fileErrors,
	fileLimitError,
	patentDetails,
	handleReset,
	handleAddAsPatentClick,
	handleAddClick,
	isButtonDisabled,
	isProcessing,
	buttonLabel,
	potentialPatents,
}) => {
	return (
		<div className="flex items-center space-x-2">
			{(potentialPatents.length > 0 ||
				unprocessedDocuments.length > 0 ||
				fileErrors.length > 0 ||
				fileLimitError) && (
				<Button variant="outline" onClick={handleReset}>
					Reset
				</Button>
			)}
			{patentDetails && patentDetails.length > 0 && (
				<Button onClick={() => handleAddAsPatentClick(patentDetails)}>Add as Patent</Button>
			)}
			<Button
				onClick={handleAddClick}
				disabled={isButtonDisabled}
				variant={patentDetails && patentDetails.length > 0 ? "outline" : undefined}
			>
				{isProcessing
					? "Adding..."
					: patentDetails?.length > 0
						? "Add as Document"
						: buttonLabel}
			</Button>
		</div>
	)
}

export default AddDocumentFooter
