import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import type { DocumentCoverSentence, ProjectDocumentMetadata } from "@/types"
import { invalidateDocumentRelatedQueries } from "@/utils/query/invalidation"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { type QueryClient, useMutation, useQueryClient } from "@tanstack/react-query"

/* -------------------------
   Types
------------------------- */
type BasePayload = {
	documentIds: string[]
}

type UpdateMetadataPayload = BasePayload & {
	notes?: string
	nickname?: string
	addTags?: string[]
	setTags?: string[]
	documentCover?: DocumentCoverSentence[]
	[key: string]: any // for future metadata fields
}

/* -------------------------
   Helper Functions
------------------------- */
function handleOptimisticUpdate(
	queryClient: QueryClient,
	projectId: string | undefined,
	portfolioId: string | undefined,
	documentIds: string[],
	updateFn: (ref: ProjectDocumentMetadata) => ProjectDocumentMetadata,
) {
	const projectQueryKey = projectId ? QUERY_KEYS.project.priorArt(projectId) : []
	const portfolioQueryKey = portfolioId ? QUERY_KEYS.portfolio.priorArt(portfolioId) : []

	const previousProjectReferences =
		queryClient.getQueryData<ProjectDocumentMetadata[]>(projectQueryKey)
	const previousPortfolioReferences =
		queryClient.getQueryData<ProjectDocumentMetadata[]>(portfolioQueryKey)

	// Project references
	if (projectId) {
		queryClient.setQueryData<ProjectDocumentMetadata[] | undefined>(
			projectQueryKey,
			(oldData) => {
				if (!oldData) return oldData
				return oldData.map((doc) =>
					documentIds.includes(doc.documentId ?? "") ? updateFn(doc) : doc,
				)
			},
		)
	}

	// Portfolio references
	if (portfolioId) {
		queryClient.setQueryData<ProjectDocumentMetadata[] | undefined>(
			portfolioQueryKey,
			(oldData) => {
				if (!oldData) return oldData
				return oldData.map((doc) =>
					documentIds.includes(doc.documentId ?? "") ? updateFn(doc) : doc,
				)
			},
		)
	}

	return { previousProjectReferences, previousPortfolioReferences }
}

function handleOptimisticRollback(
	queryClient: QueryClient,
	projectId: string | undefined,
	portfolioId: string | undefined,
	previousProjectReferences?: ProjectDocumentMetadata[],
	previousPortfolioReferences?: ProjectDocumentMetadata[],
) {
	if (!portfolioId && previousProjectReferences) {
		queryClient.setQueryData(
			projectId ? QUERY_KEYS.project.priorArt(projectId) : [],
			previousProjectReferences,
		)
	}
	if (portfolioId && previousPortfolioReferences) {
		queryClient.setQueryData(
			QUERY_KEYS.portfolio.priorArt(portfolioId),
			previousPortfolioReferences,
		)
	}
}

/* -------------------------
   Hooks
------------------------- */
export const useUpdateDocumentMetadata = () => {
	const queryClient = useQueryClient()
	const { projectId, portfolioId } = useParentContext()

	// 2) Single mutation for updating metadata (notes, nickname, tags, etc.)
	const updateDocumentMetadata = useMutation({
		mutationKey: MUTATION_KEYS.document.metadata.update(),
		mutationFn: async ({ documentIds, ...metadata }: UpdateMetadataPayload) => {
			return api.updateDocumentMetadata(projectId, portfolioId, documentIds, metadata)
		},
		onMutate: async ({ documentIds, ...metadata }) => {
			return handleOptimisticUpdate(
				queryClient,
				projectId ?? undefined,
				portfolioId ?? undefined,
				documentIds,
				(ref) => {
					const updatedDoc = { ...ref }
					if (metadata.addTags) {
						updatedDoc.tags = [...(ref.tags || []), ...metadata.addTags]
					} else if (metadata.setTags) {
						updatedDoc.tags = metadata.setTags
					}
					return { ...updatedDoc, ...metadata }
				},
			)
		},
		onError: (_error, _variables, context) => {
			handleOptimisticRollback(
				queryClient,
				projectId ?? undefined,
				portfolioId ?? undefined,
				context?.previousProjectReferences,
				context?.previousPortfolioReferences,
			)
		},
		onSuccess: (_, variables) => {
			invalidateDocumentRelatedQueries(
				queryClient,
				variables.documentIds[0],
				projectId,
				portfolioId,
			) // right now we only update one document at a time, TODO extend invaldiation to take multiple ids
		},
	})
	return {
		updateDocumentMetadata: updateDocumentMetadata.mutate,
	}
}

export default useUpdateDocumentMetadata
