/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */

import { DragAndDropFileArea, Loader } from "@/components"
import {
	CreateProjectStep,
	type PatentNumberOption,
	ProjectType,
	UnprocessedDocumentType,
	type UnpublishedPatentEntry,
} from "@/types"
import { useCreateProjectContext } from "../CreateProjectContext"

// Import components directly instead of through index
import { Card } from "@/components/ui/card"
import UnpublishedPatentForm from "../../../components/documents/patents/UnpublishedPatentForm"
import DocumentList from "./DocumentList"
import DocumentTypeButton from "./DocumentTypeButton"

/**
 * @description Documents step displayed in create project flow
 */
import PatentNumberInput from "@/components/documents/patents/PatentNumberInput"
import { Button } from "@/components/ui/button"
import { DocumentRole } from "@/types"
import { useState } from "react"
/**
 * @description Documents step displayed in create project flow
 */
const DocumentsStep = () => {
	const {
		projectType,
		currentStep,
		selectedDocumentType,
		addUnprocessedDocuments,
		unprocessedSubjectDocuments,
		unprocessedPriorArtDocuments,
		unprocessedOfficeActionDocuments,
		getSubjectFromOfficeAction,
		unprocessedContextDocuments,
	} = useCreateProjectContext()
	const [areFilesUploading, setAreFilesUploading] = useState(false)
	const [isExtractingFromOfficeAction, setIsExtractingFromOfficeAction] = useState(false)

	// Default to PUBLISHED_PATENT when in SUBJECT step
	// TODO: remove this when upload allowed on subject step
	const effectiveDocumentType =
		currentStep === CreateProjectStep.SUBJECT
			? UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION
			: selectedDocumentType

	// Helper function to generate DocumentTypeButtons based on currentStep and projectType
	const getDocumentTypeButtons = () => {
		const buttons = []

		if (currentStep === CreateProjectStep.SUBJECT) {
			if (projectType === ProjectType.INV || projectType === ProjectType.APP) {
				buttons.push(
					// TODO: unhide when tested
					// <DocumentTypeButton
					// 	key={UnprocessedDocumentType.PUBLISHED_PATENT}
					// 	selectedType={UnprocessedDocumentType.PUBLISHED_PATENT}
					// />,
					// <DocumentTypeButton
					// 	key={UnprocessedDocumentType.REFERENCE}
					// 	selectedType={UnprocessedDocumentType.REFERENCE}
					// />,
				)
				if (projectType === ProjectType.APP) {
					buttons.push(
						<DocumentTypeButton
							key={UnprocessedDocumentType.UNPUBLISHED_PATENT}
							selectedType={UnprocessedDocumentType.UNPUBLISHED_PATENT}
						/>,
					)
				}
			}
		} else if (currentStep === CreateProjectStep.PRIOR_ART) {
			buttons.push(
				<DocumentTypeButton
					key={UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION}
					selectedType={UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION}
				/>,
				// TODO: unhide when tested
				// <DocumentTypeButton
				// 	key={UnprocessedDocumentType.UNPUBLISHED_PATENT}
				// 	selectedType={UnprocessedDocumentType.UNPUBLISHED_PATENT}
				// />,
				<DocumentTypeButton
					key={UnprocessedDocumentType.REFERENCE}
					selectedType={UnprocessedDocumentType.REFERENCE}
				/>,
			)
		} else if (currentStep === CreateProjectStep.CONTEXT) {
			buttons.push(
				<DocumentTypeButton
					key={UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION}
					selectedType={UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION}
				/>,
				<DocumentTypeButton
					key={UnprocessedDocumentType.REFERENCE}
					selectedType={UnprocessedDocumentType.REFERENCE}
				/>,
			)
		}

		if (
			currentStep === CreateProjectStep.SUBJECT &&
			unprocessedOfficeActionDocuments?.length > 0 &&
			unprocessedSubjectDocuments?.length === 0
		) {
			buttons.push(
				<Button
					onClick={handleAddPatentDetailsFromOfficeAction}
					disabled={isExtractingFromOfficeAction}
				>
					Extract from Office Action
				</Button>,
			)
		}

		return buttons?.length > 0 ? (
			<div className="grid grid-cols-2 gap-2">{buttons}</div>
		) : null
	}

	const handleAddUnpublishedPatentDocument = (
		unpublishedPatent: UnpublishedPatentEntry,
	) => {
		addUnprocessedDocuments(
			currentStep as DocumentRole,
			UnprocessedDocumentType.UNPUBLISHED_PATENT,
			{
				unpublishedPatent,
			},
		)
	}

	const handleAddPatentDetailsFromOfficeAction = async () => {
		setIsExtractingFromOfficeAction(true)
		const oaFile = unprocessedOfficeActionDocuments[0].file
		const patentDetailsResponse = await getSubjectFromOfficeAction(oaFile)

		if (patentDetailsResponse.success) {
			// Replace app. number with the patent number of the selected kind code patent
			const correctedNumberDetails = patentDetailsResponse.details.map((response) => ({
				...response,
				number: response.details[response.selectedKindCode]?.number ?? response.number,
			}))

			addUnprocessedDocuments(
				DocumentRole.SUBJECT,
				UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION,
				{ patentDetails: correctedNumberDetails },
			)
		}
		setIsExtractingFromOfficeAction(false)
	}

	const handleAddFileDocuments = (files: File[]) => {
		setAreFilesUploading(true)
		const docType =
			currentStep === CreateProjectStep.OFFICE_ACTION
				? UnprocessedDocumentType.OFFICE_ACTION
				: UnprocessedDocumentType.REFERENCE
		addUnprocessedDocuments(currentStep as DocumentRole, docType, {
			files,
		})
		setAreFilesUploading(false)
	}

	const handleAddPatentDetails = (patentDetails: PatentNumberOption[]) => {
		if (!currentStep) {
			console.error("No current step defined")
			return
		}
		addUnprocessedDocuments(
			currentStep as DocumentRole,
			UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION,
			{
				patentDetails,
			},
		)
	}

	// Helper function to determine if DragAndDropFileArea should be shown
	const shouldShowDragAndDrop = () => {
		return (
			currentStep === CreateProjectStep.OFFICE_ACTION ||
			(currentStep === CreateProjectStep.SUBJECT && projectType === ProjectType.SEP) ||
			effectiveDocumentType === UnprocessedDocumentType.STANDARD ||
			effectiveDocumentType === UnprocessedDocumentType.REFERENCE
		)
	}

	// Helper function to render content based on selectedDocumentType
	const renderContent = () => {
		if (
			effectiveDocumentType === UnprocessedDocumentType.PUBLISHED_PATENT_OR_APPLICATION
		) {
			return (
				<div className="w-full my-2 border border-gray-200 rounded-md p-4">
					<PatentNumberInput onDetails={handleAddPatentDetails} projectId={""} />
				</div>
			)
		}

		if (
			effectiveDocumentType === UnprocessedDocumentType.UNPUBLISHED_PATENT &&
			projectType === ProjectType.APP
		) {
			return (
				<Card className="w-full p-4 my-2">
					<UnpublishedPatentForm onSave={handleAddUnpublishedPatentDocument} />
				</Card>
			)
		}
		if (
			projectType === ProjectType.APP &&
			isExtractingFromOfficeAction &&
			unprocessedSubjectDocuments?.length === 0
		) {
			return (
				<div className="w-full my-2 border border-gray-200 rounded-md p-4">
					<Loader message="Extracting patent details from office action..." />
				</div>
			)
		}

		return null
	}

	// Helper function to get documents and title based on currentStep
	const getCurrentDocuments = () => {
		switch (currentStep) {
			case CreateProjectStep.SUBJECT:
				return {
					currentDocuments: unprocessedSubjectDocuments,
					title: "Subjects",
				}
			case CreateProjectStep.PRIOR_ART:
				return {
					currentDocuments: unprocessedPriorArtDocuments,
					title: "References",
				}
			case CreateProjectStep.CONTEXT:
				return {
					currentDocuments: unprocessedContextDocuments,
					title: "Context",
				}
			case CreateProjectStep.OFFICE_ACTION:
				return {
					currentDocuments: unprocessedOfficeActionDocuments,
					title: "Office Action",
				}
			default:
				return { currentDocuments: [], title: "" }
		}
	}

	const { currentDocuments, title } = getCurrentDocuments()

	return (
		<div>
			{getDocumentTypeButtons()}

			{shouldShowDragAndDrop() && (
				<div className="mt-2">
					{areFilesUploading ? (
						<div className="w-full my-2 border border-gray-200 rounded-md p-4">
							<Loader message="Uploading files..." />
						</div>
					) : (
						<DragAndDropFileArea
							handleFiles={handleAddFileDocuments}
							supportedFileTypes={[".pdf"]}
							maxFiles={currentStep === CreateProjectStep.OFFICE_ACTION ? 1 : 100}
						/>
					)}
				</div>
			)}

			{renderContent()}

			{currentDocuments?.length > 0 && (
				<DocumentList documents={currentDocuments} title={title} />
			)}
		</div>
	)
}

export default DocumentsStep
