import { Loader } from "@/components"
import SearchInput from "@/components/search/SearchInput"
import { Button } from "@/components/ui/button"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Label } from "@/components/ui/label"
import { scrollToCenter } from "@/features/charts/components/scrollHelpers"
import { useDocumentChunk } from "@/hooks"
import { useDocumentSearch } from "@/hooks/useDocumentSearch"
import useNavigationState from "@/hooks/useNavigationState"
import { cn } from "@/lib/utils"
import { useProjectStore } from "@/store"
import {
	BlockType,
	CitationFormat,
	type Document,
	type DocumentChunk,
	PatentType,
} from "@/types"
import { normalizeTextToMatch } from "@/utils/normalizeTextToMatch"
import { getDefaultCitationFormat } from "@/utils/projectUtils"
import Fuse from "fuse.js"
import { Plus } from "lucide-react"
import React from "react"
import { useEffect, useMemo, useRef, useState } from "react"
import { DocumentChunkComponent } from "./DocumentChunk"
import { DocumentChunkLocationFormatSelect } from "./DocumentChunkLocationFormatSelect"
import { EditDocumentChunkDialog } from "./EditDocumentChunkDialog"

interface DocumentBodyProps {
	document: Document
	body: DocumentChunk[]
	figureChunks: DocumentChunk[]
	citationText?: string
	highlightedRef?: React.RefObject<HTMLDivElement>
	selectedChunkId?: string | null
	onChunkSelect?: (id: string) => void
	editMode?: boolean
	onFigureReferenceSelect?: (figureChunk: DocumentChunk) => void
	filterIds?: string[]
	selectedChunkRef?: React.MutableRefObject<HTMLDivElement | null>
}

const DocumentBody: React.FC<DocumentBodyProps> = ({
	document,
	body,
	figureChunks,
	citationText,
	highlightedRef,
	selectedChunkId,
	onChunkSelect,
	editMode,
	onFigureReferenceSelect,
	filterIds,
	selectedChunkRef,
}) => {
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
	const bodyRef = useRef<HTMLDivElement>(null)
	const { addDocumentChunk, addDocumentChunkIsLoading } = useDocumentChunk()
	const { selectedCitationFormat, updateSelectedCitationFormat } = useProjectStore()

	const { isSubjectPage } = useNavigationState()

	const {
		query: docSearchQuery,
		setQuery: setDocSearchQuery,
		resultIds: docSearchResultIds,
		setResultIds: setDocSearchResultIds,
		loading: docSearchLoading,
		error: docSearchError,
		search: handleDocSearch,
	} = useDocumentSearch([document.id])

	const appliedCitationFormat = isSubjectPage
		? selectedCitationFormat
		: CitationFormat.DEFAULT

	const documentId = useMemo(() => document.id, [document])

	useEffect(() => {
		if (isSubjectPage && selectedCitationFormat === CitationFormat.DEFAULT) {
			updateSelectedCitationFormat(getDefaultCitationFormat(document))
		}
	}, [isSubjectPage, selectedCitationFormat, updateSelectedCitationFormat, document])

	useEffect(() => {
		if (selectedChunkId && selectedChunkRef?.current) {
			scrollToCenter(selectedChunkRef.current)
		}
	}, [selectedChunkId, selectedChunkRef])

	useEffect(() => {
		if (citationText && bodyRef.current) {
			setTimeout(() => {
				if (!bodyRef.current) return
				const firstHighlighted = bodyRef.current.querySelector(
					".bg-citation-highlight",
				) as HTMLElement
				if (firstHighlighted) {
					scrollToCenter(firstHighlighted)
				}
			}, 100)
		}
	}, [citationText])

	const isHighlighted = (plainText?: string, searchText?: string): boolean => {
		if (!plainText || !searchText) return false

		// Split the plain text into sentences for more granular matching
		const sentences = plainText.split(/[.!?]+/).map((s) => s.trim())

		const normalizedSearchText = normalizeTextToMatch(searchText)

		// Check each sentence for a match
		for (const sentence of sentences) {
			const normalizedSentence = normalizeTextToMatch(sentence)
			if (normalizedSentence.includes(normalizedSearchText)) {
				return true
			}
		}

		// If no direct match found, try fuzzy search on each sentence
		const fuse = new Fuse(sentences.map(normalizeTextToMatch), {
			includeScore: true,
			threshold: 0.2,
			distance: 1000,
			minMatchCharLength: 10,
		})

		const result = fuse.search(normalizedSearchText)
		return result.length > 0 && (result[0].score || 1) < 0.3
	}

	const handleAddChunk = () => {
		setIsAddDialogOpen(true)
	}

	const handleSaveNewChunk = (chunk: DocumentChunk) => {
		addDocumentChunk({
			documentId,
			text: chunk.text,
			location: chunk.location,
			figureUrls: chunk.figureUrls || [],
		})
		setIsAddDialogOpen(false)
	}

	// TODO move to component
	const renderBody = () => {
		const filteredChunks = body.filter((chunk, index) => {
			if (!chunk) return false
			if (
				chunk.type === BlockType.CAPTION ||
				chunk.type === BlockType.FIGURE ||
				chunk.type === BlockType.UNKNOWN
			) {
				return false
			}
			if (chunk.text.trim() === "" && index === 0) {
				return false // hide empty test if first chunk
			}
			if (docSearchResultIds !== null && !docSearchResultIds.has(chunk.id)) {
				return false
			}
			if (filterIds && !filterIds.includes(chunk.id)) {
				return false
			}
			return true
		})

		if (docSearchResultIds !== null && filteredChunks.length === 0) {
			return <p className="text-center text-muted">No results found</p>
		}

		return filteredChunks.map((chunk) => {
			const highlighted = isHighlighted(chunk.text, citationText)
			return (
				<React.Fragment key={chunk.id}>
					<div
						ref={chunk.id === selectedChunkId ? selectedChunkRef : null}
						data-chunk-id={chunk.id}
						className={cn(
							"group flex items-center hover:cursor-pointer hover:bg-accent/50 mb-2",
							selectedChunkId === chunk.id && "bg-accent",
							highlighted && "bg-citation-highlight",
						)}
						onClick={!editMode ? () => onChunkSelect?.(chunk.id) : undefined}
					>
						<DocumentChunkComponent
							chunk={chunk}
							citationText={citationText}
							citationFormat={appliedCitationFormat}
							highlightedRef={highlightedRef}
							isTextHighlighted={highlighted}
							selectedChunkId={selectedChunkId}
							onChunkSelect={onChunkSelect}
							figureChunks={figureChunks}
							editMode={editMode}
							onFigureReferenceSelect={onFigureReferenceSelect}
						/>
					</div>
				</React.Fragment>
			)
		})
	}

	return (
		<div className="mb-4 relative" ref={bodyRef}>
			{/* {!chartMode && ( */}
			<div className="flex flex-col gap-4 mb-4">
				<div className="flex items-center justify-between">
					<div className="flex items-center gap-2">
						<Label className="pb-0">Body</Label>
						{editMode && (
							<Button variant="ghost" size="icon" onClick={handleAddChunk}>
								<Plus className="h-4 w-4" />
							</Button>
						)}
					</div>
					<DocumentChunkLocationFormatSelect
						citationFormat={appliedCitationFormat}
						onFormatChange={updateSelectedCitationFormat}
						isPatentApplication={document.patent?.type === PatentType.PUBLISHED_APPLICATION}
					/>
				</div>
				<div className="flex flex-col gap-1 w-full">
					<div className="relative">
						<SearchInput
							placeholder="Search document"
							disabled={docSearchLoading}
							value={docSearchQuery}
							onChange={(e) => {
								setDocSearchQuery(e.target.value)
								if (e.target.value === "") {
									// Clear results if input is empty.
								}
							}}
							onSearch={handleDocSearch}
							setResultIds={setDocSearchResultIds}
							className="w-full pr-20"
						/>
					</div>
					<div className="flex items-center justify-center">
						{docSearchLoading && <Loader message="Searching document..." />}
						{docSearchError && <span className="text-red-500">Error</span>}
					</div>
				</div>
			</div>
			{/* )} */}

			<div className="px-2">{body?.length > 0 ? renderBody() : null}</div>

			<EditDocumentChunkDialog
				chunk={
					{
						id: "",
						text: "",
						location: {
							pages: [null, null],
							paragraphs: [null, null],
							columns: [null, null],
							lines: [null, null],
							section: null,
						},
						figureUrls: [],
					} as DocumentChunk
				}
				open={isAddDialogOpen}
				onOpenChange={setIsAddDialogOpen}
				onSave={handleSaveNewChunk}
				figureChunks={figureChunks}
				isSaving={addDocumentChunkIsLoading}
			/>
		</div>
	)
}

export default DocumentBody
