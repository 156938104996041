import { TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"
import { Tooltip } from "@radix-ui/react-tooltip"

interface RankPillProps {
	rank: number
	size?: "sm" | "lg"
	className?: string
}

export const RankPill = ({ rank, size = "sm", className }: RankPillProps) => {
	return (
		<Tooltip>
			<TooltipTrigger>
				<div
					className={cn(
						// Base styles
						"inline-block rounded-full border",
						// Light mode styles
						"border-blue-300 bg-blue-50 text-black",
						// Dark mode styles
						"dark:border-blue-700 dark:bg-blue-950 dark:text-white",
						// Size variants
						size === "sm" ? "px-2 py-0.5 text-xs" : "px-3 py-1 text-sm",
						className,
					)}
				>
					{rank}
				</div>
			</TooltipTrigger>
			<TooltipContent>Rank</TooltipContent>
		</Tooltip>
	)
}

export default RankPill
