/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Button } from "@/components/ui/button"
import { CheckboxWithText } from "@/components/ui/checkbox"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"
import { FloatingLabelInput } from "@/components/ui/floating-input"
import { LoadingButton } from "@/components/ui/loading-button"
import { useAppStateStore } from "@/store"
import type { User } from "@/types"
import { zodResolver } from "@hookform/resolvers/zod"
import { type SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useAdminUsers } from "../../hooks/useAdminUsers"

interface AddUserModalProps {
	open: boolean
	onClose: () => void
	users: User[]
	orgName: string
	orgId: string
}

const userSchema = z.object({
	firstName: z.string().min(1, "First name is required"),
	lastName: z.string().min(1, "Last name is required"),
	email: z.string().email("Invalid email address"),
	role: z.enum(["User", "Admin"]),
})

type UserFormData = z.infer<typeof userSchema>

function AddUserModal({ open, onClose, orgId }: AddUserModalProps) {
	const { adminUsers, createUser, isLoading, isCreatePending } = useAdminUsers(orgId)
	const { addErrorMessage } = useAppStateStore()

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<UserFormData>({
		resolver: zodResolver(userSchema),
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			role: "User",
		},
	})

	// Close modal and reset form
	const handleClose = () => {
		reset()
		onClose()
	}

	// Submit handler
	const onSubmit: SubmitHandler<UserFormData> = (data) => {
		// Check if user already exists
		if (adminUsers?.users?.some((u) => u.email === data.email)) {
			addErrorMessage(`User with email ${data.email} already exists.`)
			return
		}

		createUser({
			user: {
				email: data.email,
				firstName: data.firstName,
				lastName: data.lastName,
				role: data.role,
				enabled: true,
				emailConfirmed: false,
				id: "",
			},
			orgId,
		})
		handleClose()
	}

	const isLoadingState = isLoading || isCreatePending

	return (
		<Dialog open={open} onOpenChange={handleClose} aria-label="Add a new user">
			<DialogContent className="sm:max-w-[600px]">
				<DialogHeader>
					<DialogTitle>Add a new user</DialogTitle>
					<DialogDescription>
						The user will receive an email with instructions to log in.
					</DialogDescription>
				</DialogHeader>

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="grid gap-4 pb-4">
						<FloatingLabelInput
							{...register("firstName")}
							className="col-span-3"
							label="First Name"
							error={errors.firstName?.message}
						/>
						<FloatingLabelInput
							{...register("lastName")}
							className="col-span-3"
							label="Last Name"
							error={errors.lastName?.message}
						/>
						<FloatingLabelInput
							{...register("email")}
							className="col-span-3"
							label="Email"
							error={errors.email?.message}
						/>
						<div className="flex items-start space-x-3">
							<CheckboxWithText
								id="admin"
								onCheckedChange={(checked) => setValue("role", checked ? "Admin" : "User")}
								label="Make this user an admin"
							/>
						</div>
					</div>

					<DialogFooter>
						<Button variant="outline" onClick={handleClose} disabled={isLoadingState}>
							Cancel
						</Button>
						<LoadingButton type="submit" loading={isLoadingState} disabled={isLoadingState}>
							{isLoadingState ? "Adding..." : "Add User"}
						</LoadingButton>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	)
}

export default AddUserModal
