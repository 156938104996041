import { useParentContext } from "@/context/ParentContext"
import useSearchDocuments from "@/hooks/useSearchDocuments"
import { SearchMode } from "@/types"
import { useState } from "react"
/**
 * A reusable hook for searching within documents.
 * @param documentIds - The IDs of the document(s) to search within.
 */
export const useDocumentSearch = (documentIds: string[]) => {
	const [query, setQuery] = useState<string>("")
	const [resultIds, setResultIds] = useState<Set<string> | null>(null)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)

	const { semanticSearchDocuments } = useSearchDocuments()
	const { projectId } = useParentContext()

	const search = async () => {
		const trimmedQuery = query.trim()

		// If there's no query, clear the results.
		if (trimmedQuery === "") {
			setResultIds(null)
			return
		}

		setLoading(true)
		setError(false)

		try {
			const response = await semanticSearchDocuments(
				projectId,
				trimmedQuery,
				documentIds,
				SearchMode.SEMANTIC,
			)

			if (response) {
				// Gather chunk IDs from the search response.
				const ids = new Set<string>()
				for (const result of response) {
					if (result.id) {
						ids.add(result.id)
					}
				}

				setResultIds(ids.size > 0 ? ids : null)
			} else {
				setError(true)
				setResultIds(null)
			}
		} catch (err) {
			console.error("Document search error:", err)
			setError(true)
			setResultIds(null)
		} finally {
			setLoading(false)
		}
	}

	return { query, setQuery, resultIds, setResultIds, loading, error, search }
}
