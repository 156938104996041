import { Button } from "@/components/ui/button"
import type { DocumentChunk } from "@/types"
import { Plus } from "lucide-react"
import type React from "react"

export interface DocumentChunkActionsProps {
	chunk: DocumentChunk
	selectedLimitationId?: string
	onAddCitation?: (chunk: DocumentChunk) => void
	children: React.ReactNode
}

export const DocumentChunkActions: React.FC<DocumentChunkActionsProps> = ({
	chunk,
	selectedLimitationId,
	onAddCitation,
	children,
}) => {
	return (
		<div className="flex gap-4 items-start">
			{selectedLimitationId && (
				<div className="flex-shrink-0">
					<Button
						variant="ghost"
						size="icon"
						onClick={() => onAddCitation?.(chunk)}
						className="h-8 w-8"
					>
						<Plus className="h-4 w-4" />
					</Button>
				</div>
			)}
			<div className="flex-1">{children}</div>
		</div>
	)
}

export default DocumentChunkActions
