import { api } from "@/api"
import type { UpdateDocumentChunkRequest } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

type UpdateChunkPayload = {
	documentId: string
	payload: UpdateDocumentChunkRequest
}

const useDocumentFigures = (documentId: string) => {
	const queryClient = useQueryClient()
	const queryKey = QUERY_KEYS.document.figures(documentId)

	// Single doc query
	const figureChunksQuery = useQuery({
		queryKey,
		queryFn: async () => await api.getDocumentFigures(documentId),
		enabled: !!documentId,
		...DEFAULT_QUERY_OPTIONS,
	})

	const deleteFigure = useMutation({
		mutationKey: MUTATION_KEYS.document.chunk.delete(),
		mutationFn: async ({
			documentId,
			documentChunkId,
		}: {
			documentId: string
			documentChunkId: string
		}) => {
			return api.deleteDocumentChunk(documentChunkId, true)
		},

		onSuccess: (_, _variables) => {
			queryClient.invalidateQueries({
				queryKey,
			})
		},
	})

	const updateFigure = useMutation({
		mutationKey: MUTATION_KEYS.document.chunk.update(),
		mutationFn: async ({ documentId, payload }: UpdateChunkPayload) => {
			return api.updateDocumentChunk({ updateDocumentChunkRequest: payload })
		},
		onSuccess: (_, _variables) => {
			queryClient.invalidateQueries({
				queryKey,
			})
		},
	})

	return {
		figureChunks: figureChunksQuery.data,
		isLoading: figureChunksQuery.isLoading,
		isError: figureChunksQuery.isError,
		refetch: figureChunksQuery.refetch,

		deleteFigure: deleteFigure.mutate,
		updateFigure: updateFigure.mutate,
	}
}

export default useDocumentFigures
