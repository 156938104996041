import useDocumentNaming from "@/hooks/useDocumentNaming"
import useUpdateDocumentMetadata from "@/hooks/useUpdateDocumentMetadata"
import {
	type DocumentCoverSentence,
	type ParentDocumentMetadata,
	ParentType,
} from "@/types"
import { VisuallyHidden } from "@radix-ui/react-visually-hidden"
import type React from "react"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "../../ui/dialog"
import {} from "../../ui/resizable"
import { H4 } from "../../ui/typography"
import SentencesEditor from "./SentencesEditor"

interface SummaryEditorProps {
	open: boolean
	handleClose: () => void
	parentToDocument: ParentDocumentMetadata
	parentType: ParentType
}

const SummaryEditor: React.FC<SummaryEditorProps> = ({
	open,
	handleClose,
	parentToDocument,
	parentType,
}) => {
	const { updateDocumentMetadata } = useUpdateDocumentMetadata()
	const { getDocumentPortfolioName, getDocumentName } = useDocumentNaming()

	if (!open) {
		return null
	}

	const name =
		parentType === ParentType.PORTFOLIO
			? getDocumentPortfolioName(parentToDocument.documentId || "")
			: getDocumentName(parentToDocument.documentId || "")

	// Handle saving
	const handleSave = (
		sentences: DocumentCoverSentence[],
		chunkIdToTextMap: Map<string, string>,
	) => {
		// 1) Build the final array of sentences with quotes reinserted and startIndex/endIndex updated for quote parts
		const updatedSentences: DocumentCoverSentence[] = sentences.map((coverSentence) => {
			// For each sentence array
			const newSentenceParts = coverSentence.sentence.map((part) => {
				// If it’s a quote
				if ("chunkId" in part) {
					const chunkText = chunkIdToTextMap.get(part.chunkId) || ""
					// The user’s typed text should match some substring in chunkText
					const userTypedQuote = part.text

					// Attempt to locate the user’s typed quote inside the chunk text
					let startIndex = chunkText.indexOf(userTypedQuote)
					let endIndex = startIndex !== -1 ? startIndex + userTypedQuote.length : -1

					// If we can’t find it, handle gracefully
					if (startIndex === -1) {
						startIndex = 0
						endIndex = 0
					}

					return {
						...part,
						// Re-add quotes around the text
						text: `"${userTypedQuote}"`,
						startIndex,
						endIndex,
					}
				}
				return part
			})

			// Check if the second to last part of the sentence is a quote (citation comes last).
			if (newSentenceParts.length > 1) {
				const lastPart = newSentenceParts[newSentenceParts.length - 2]
				if ("chunkId" in lastPart) {
					// Remove the wrapping quotes to inspect the inner text.
					const innerText = lastPart.text.slice(1, -1)
					// If the inner text doesn't end with a period, add one.
					if (!innerText.endsWith(".")) {
						lastPart.text = `"${innerText}."`
					}
				}
			}

			return {
				...coverSentence,
				sentence: newSentenceParts,
			}
		})

		updateDocumentMetadata({
			documentIds: [parentToDocument.documentId || ""],
			documentCover: updatedSentences,
		})

		handleClose()
	}

	return (
		<Dialog open={open} onOpenChange={handleClose}>
			<DialogContent className="max-w-[90vw] max-h-[90vh] flex flex-col p-0 overflow-hidden">
				<VisuallyHidden>
					<DialogTitle>
						<span className="text-lg font-semibold">{name} Summary Editor</span>
					</DialogTitle>
				</VisuallyHidden>
				<VisuallyHidden asChild>
					<DialogDescription>Edit the summary for {name}</DialogDescription>
				</VisuallyHidden>
				<div className="flex flex-col h-[90vh]">
					<DialogHeader className="sticky top-0 z-10 bg-background py-2 px-3 border-b">
						<div className="flex justify-between items-center gap-2">
							<div className="flex items-center gap-2">
								<H4>Editing {name} Summary</H4>
							</div>
						</div>
					</DialogHeader>
					<div className="flex-1 min-h-0">
						<SentencesEditor
							onSave={handleSave}
							initialSentences={parentToDocument.documentCover as DocumentCoverSentence[]}
							documentId={parentToDocument.documentId || ""}
						/>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default SummaryEditor
