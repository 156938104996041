import { Input } from "@/components/ui/input"
import useFileHandler from "@/hooks/useFileHandler"
import { cn } from "@/lib/utils"
import { Upload } from "lucide-react"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import React, { type DragEvent, useCallback, useState } from "react"

interface DragAndDropFileAreaProps {
	handleFiles: (files: File[]) => void
	supportedFileTypes: string[]
	maxFiles?: number
	disabled?: boolean
}

/**
 * @description Drag and drop file area
 */
const DragAndDropFileArea: React.FC<DragAndDropFileAreaProps> = ({
	handleFiles,
	supportedFileTypes,
	maxFiles = 100,
	disabled,
}) => {
	const fileInputRef = React.useRef<HTMLInputElement>(null)
	const [dragging, setDragging] = useState(false)

	const { files, handleFileChange, fileErrors, fileLimitError } = useFileHandler({
		maxFiles,
		supportedFileTypes,
		maxFileSize: 100 * 1024 * 1024, // 100MB default (optional override)
	})

	const handleAreaClick = () => {
		if (disabled) return
		fileInputRef.current?.click()
	}

	const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (disabled) return
		if (e.target.files && e.target.files.length > 0) {
			const selectedFiles = Array.from(e.target.files)
			const validFiles = handleFileChange(selectedFiles)
			handleFiles(validFiles)
		}
	}

	const handleDrag = useCallback(
		(event: DragEvent<HTMLElement>) => {
			if (disabled) {
				event.preventDefault()
				event.stopPropagation()
				return
			}
			event.preventDefault()
			event.stopPropagation()
		},
		[disabled],
	)

	const handleDragEnter = () => {
		if (disabled) return
		setDragging(true)
	}
	const handleDragLeave = () => {
		if (disabled) return
		setDragging(false)
	}

	const handleDrop = (event: DragEvent<HTMLElement>) => {
		if (disabled) {
			event.preventDefault()
			event.stopPropagation()
			return
		}
		handleDrag(event)
		setDragging(false)
		const droppedFiles = event.dataTransfer.files
		if (droppedFiles && droppedFiles.length > 0) {
			const validFiles = handleFileChange(Array.from(droppedFiles))
			handleFiles(validFiles)
		}
	}

	return (
		<div
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDragOver={handleDrag}
			onDrop={handleDrop}
			className={cn(
				"p-8 border border-input rounded-lg text-center max-h-[80vh] overflow-y-auto w-full cursor-pointer",
				dragging && "border-primary bg-secondary",
				disabled && "opacity-50 cursor-not-allowed",
			)}
			onClick={handleAreaClick}
		>
			<Upload className="mx-auto mb-4 h-6 w-6 " />
			<h3 className="text-lg font-semibold">
				Drag and drop files here or click to browse.
				<label htmlFor="file-upload-button" className="cursor-pointer">
					<Input
						ref={fileInputRef}
						id="file-upload-button"
						type="file"
						className="hidden"
						onChange={onFileInputChange}
						multiple={maxFiles !== 1}
						disabled={disabled}
					/>
				</label>
			</h3>
			<p className="mt-2 text-sm text-muted-foreground">
				Supported file types: {supportedFileTypes.join(", ")}
			</p>
			{fileLimitError && <p className="mt-2 text-sm text-red-600">{fileLimitError}</p>}

			{fileErrors && fileErrors.length > 0 && (
				<div className="mt-2 text-sm text-red-600">
					{fileErrors.map((err) => (
						<p key={err}>{err}</p>
					))}
				</div>
			)}
		</div>
	)
}

export default DragAndDropFileArea
