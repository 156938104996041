import { Loader } from "@/components"
import { useParentContext } from "@/context/ParentContext"
import useDocumentInvaliditySummaryData from "@/features/charts/hooks/queries/useChartSummaryData"
import { cn } from "@/lib/utils"
import type { InvaliditySummaryRow } from "@/types"
import type React from "react"
import { useMemo } from "react"
import { useInView } from "react-intersection-observer"

// Props for one document column.
export interface ChartSummaryProps {
	chartId: string
	chartName: string
	summaryChartRowHeightCollapsed?: number
	isExpanded?: boolean
	handleSelect?: (chartId: string, claimLimitationId: string | null) => void
	style?: React.CSSProperties
}

const ChartSummary: React.FC<ChartSummaryProps> = ({
	chartId,
	chartName,
	summaryChartRowHeightCollapsed,
	isExpanded,
	handleSelect,
	style,
}) => {
	const { projectId } = useParentContext()

	const { ref, inView } = useInView({
		threshold: 0,
		triggerOnce: false,
	})

	const { invaliditySummaryData, isLoading, isError } = useDocumentInvaliditySummaryData(
		projectId,
		chartId,
		{ enabled: Boolean(inView) },
	)

	const columnDataByClaimId = useMemo(() => {
		if (!invaliditySummaryData) return {}

		const mapping: { [key: string]: InvaliditySummaryRow } = {}
		for (const row of invaliditySummaryData) {
			mapping[row.claimLimitationId] = row
		}
		return mapping
	}, [invaliditySummaryData])

	const renderCell = (row: InvaliditySummaryRow) => {
		const cellData = columnDataByClaimId[row.claimLimitationId]
		const cellColor = cellData?.chartColors[chartId].color
			? cellData.chartColors[chartId].color.toString()
			: "#D3D3D3"

		return (
			<div
				key={row.claimLimitationId}
				className="px-0.75 bg-background"
				style={{
					minWidth: "75px",
					flex: "1",
					margin: "0",
					padding: "0 2px",
				}}
			>
				<div
					className={cn(
						"w-full h-full flex items-center justify-center cursor-pointer transition-colors duration-200",
						isExpanded && "rounded m-1",
					)}
					style={{
						backgroundColor: cellColor,
						height: isExpanded ? "25px" : `${summaryChartRowHeightCollapsed}px`,
					}}
					onClick={() => handleSelect(chartId, isExpanded ? row.claimLimitationId : null)}
				/>
			</div>
		)
	}

	return (
		<div ref={ref} className="flex flex-col relative" style={style}>
			{/* Document column header row – sticky on the top during vertical scrolling */}
			<div
				className="sticky top-0 z-20 bg-background cursor-pointer"
				style={{ minWidth: "75px", flex: "1", padding: "0 2px" }}
				onClick={() => handleSelect(chartId, null)}
			>
				<div className="flex flex-col items-center pt-1 pb-2">
					<span className="text-xs truncate mr-2 flex-grow">{chartName}</span>
				</div>
			</div>

			{/* Render cells for each row */}
			{isLoading && <Loader />}
			{isError && <div>Error loading column data</div>}
			{!isLoading && !isError && invaliditySummaryData?.map(renderCell)}
		</div>
	)
}

export default ChartSummary
