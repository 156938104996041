/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Card } from "@/components/ui/card"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { ProjectType } from "@/types"

interface ProjectTypeCardProps {
	projectType: ProjectType
	showRadio?: boolean
	onTypeSelect?: (type: ProjectType) => void
}

/**
 * @description Project type cards for the create project flow
 * @param {Object} props
 * @param {ProjectType} props.projectType - The project type to display
 * @param {boolean} props.showRadio - Whether to show the radio button
 * @param {Function} props.onTypeSelect - The function to call when the project type is selected
 */
const projectTypes = [
	{
		type: ProjectType.INV,
		title: "Invalidity Project",
		description:
			"Analyze and challenge the validity of existing patents through prior art search, claim chart drafting, and technical analysis.",
	},
	{
		type: ProjectType.APP,
		title: "Patent Application Project",
		description:
			"Prepare and develop new patent applications, including technical specifications, claim drafting, and responding to office actions.",
		disabled: true,
		comingSoon: true,
	},
]

export const ProjectTypeCard = ({
	projectType,
	showRadio = false,
	onTypeSelect,
}: ProjectTypeCardProps) => {
	const { title, description, disabled, comingSoon } = projectTypes.find(
		(pt) => pt.type === projectType,
	)!

	return (
		<Card
			onClick={() => !disabled && onTypeSelect?.(projectType)}
			className={`${!onTypeSelect ? "cursor-default" : disabled ? "cursor-not-allowed opacity-60" : "cursor-pointer"}`}
		>
			<div className="flex justify-between items-start p-4">
				<div>
					<div className="flex items-center gap-2">
						<p className="text-sm font-semibold">{title}</p>
						{comingSoon && (
							<span className="px-2 py-1 text-xs font-medium bg-gray-100 text-gray-600 rounded-full">
								Coming Soon
							</span>
						)}
					</div>
					<p className="text-gray-600 text-sm mt-2">{description}</p>
				</div>
				{showRadio && <RadioGroupItem value={projectType} disabled={disabled} />}
			</div>
		</Card>
	)
}

export const ProjectTypeCards = ({
	selectedType,
	onTypeSelect,
}: {
	selectedType: ProjectType
	onTypeSelect: (type: ProjectType) => void
}) => {
	return (
		<RadioGroup value={selectedType} onValueChange={onTypeSelect}>
			<div className="grid grid-cols-2 gap-4">
				{projectTypes.map((pt) => (
					<ProjectTypeCard
						key={pt.type}
						projectType={pt.type}
						showRadio={true}
						onTypeSelect={onTypeSelect}
					/>
				))}
			</div>
		</RadioGroup>
	)
}
