import { api } from "@/api"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils/query/keys"
import { useMutation, useQueryClient } from "@tanstack/react-query"

/**
 * @description Hook for handling generic (type-agnostic) project operations
 */
const useUpdateProjectDetails = () => {
	const queryClient = useQueryClient()
	const { handleError } = useApi()

	const updateProjectDetailsMutation = useMutation({
		mutationKey: MUTATION_KEYS.project.updateDetails(),
		mutationFn: ({ projectId, options }: { projectId: string; options: any }) => {
			const payload: { [key: string]: any } = {
				project_id: projectId,
			}

			// Append additional options to payload if they exist
			for (const key of Object.keys(options)) {
				if (options[key]) {
					payload[key] = options[key]
				}
			}

			return api.updateProjectDetails(projectId, payload)
		},
		onSuccess: (_data, variables) => {
			queryClient.invalidateQueries({
				queryKey: QUERY_KEYS.project.metadata(variables.projectId),
			})
		},
		onError: (error) => {
			handleError(error, "Error updating project details")
		},
	})

	return {
		updateProjectDetails: updateProjectDetailsMutation.mutate,
	}
}

export default useUpdateProjectDetails
