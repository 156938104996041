/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
	NavigationMenu,
	NavigationMenuItem,
	NavigationMenuList,
} from "@/components/ui/navigation-menu"
import { useParentContext } from "@/context/ParentContext"
import { useProjectMetadata } from "@/hooks"
import { ProjectType } from "@/types"
import type React from "react"
import { memo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
// Enum for navigation keys
enum NavigationKey {
	PORTFOLIO = "portfolio",
	SUBJECT = "subject",
	CONTEXT = "context",
	REFERENCES = "references",
	CHARTS = "charts",
	SEARCH = "search",
	CHAT = "chat",
	DOCUMENTS = "documents",
	COVER = "cover",
}

// Ordered array of path segments to NavigationKeys
const pathToNavigationKey: Array<[string, NavigationKey]> = [
	["/cover", NavigationKey.COVER],
	["/subject", NavigationKey.SUBJECT],
	["/references", NavigationKey.REFERENCES],
	["/charts", NavigationKey.CHARTS],
	["/search", NavigationKey.SEARCH],
	["/chat", NavigationKey.CHAT],
	["/context", NavigationKey.CONTEXT],
	["/documents", NavigationKey.DOCUMENTS],
	["/portfolio", NavigationKey.PORTFOLIO],
]

type NavigationLabelMapping = { label: string; key: NavigationKey }

const NAV_ITEMS: Record<string, NavigationLabelMapping> = {
	PORTFOLIO: { label: "Portfolio", key: NavigationKey.PORTFOLIO },
	SUBJECT: { label: "Subject", key: NavigationKey.SUBJECT },
	CONTEXT: { label: "Context", key: NavigationKey.CONTEXT },
	REFERENCES: { label: "References", key: NavigationKey.REFERENCES },
	CHARTS: { label: "Charts", key: NavigationKey.CHARTS },
	SEARCH: { label: "Search", key: NavigationKey.SEARCH },
	CHAT: { label: "Chat", key: NavigationKey.CHAT },
	DOCUMENTS: { label: "Office Actions", key: NavigationKey.DOCUMENTS },
	COVER: { label: "Cover", key: NavigationKey.COVER },
}

const COMMON_NAV_ITEMS = [
	NAV_ITEMS.PORTFOLIO,
	NAV_ITEMS.SUBJECT,
	NAV_ITEMS.CONTEXT,
	NAV_ITEMS.REFERENCES,
	NAV_ITEMS.CHARTS,
	NAV_ITEMS.SEARCH,
	NAV_ITEMS.CHAT,
]

const APPLICATION_NAV_ITEMS = [
	NAV_ITEMS.PORTFOLIO,
	NAV_ITEMS.SUBJECT,
	NAV_ITEMS.CONTEXT,
	NAV_ITEMS.DOCUMENTS,
	NAV_ITEMS.REFERENCES,
	NAV_ITEMS.CHARTS,
	NAV_ITEMS.SEARCH,
	NAV_ITEMS.CHAT,
]

const NAV_ITEMS_WITH_COVER = [
	NAV_ITEMS.PORTFOLIO,
	NAV_ITEMS.SUBJECT,
	NAV_ITEMS.CONTEXT,
	NAV_ITEMS.REFERENCES,
	NAV_ITEMS.COVER,
	NAV_ITEMS.CHARTS,
	NAV_ITEMS.SEARCH,
	NAV_ITEMS.CHAT,
]

// Map project types to their respective navigation items
const navigationMapping: Record<ProjectType, NavigationLabelMapping[]> = {
	[ProjectType.PERSONAL]: COMMON_NAV_ITEMS,
	[ProjectType.INV]: NAV_ITEMS_WITH_COVER,
	[ProjectType.SEP]: COMMON_NAV_ITEMS,
	[ProjectType.APP]: APPLICATION_NAV_ITEMS,
}

/**
 * @description Component for navigation tabs in invalidity project
 */
const ProjectNavigation: React.FC = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const { projectId, portfolioId, inPortfolio } = useParentContext()
	const { projectType } = useProjectMetadata()

	const navItems = navigationMapping[projectType] || []

	const getTabValue = (pathname: string): NavigationKey | undefined => {
		for (const [pathSegment, navKey] of pathToNavigationKey) {
			if (pathname.includes(pathSegment)) {
				return navKey
			}
		}
	}

	const currentTabValue = getTabValue(location.pathname)

	// Handle nav item selection
	const handleTabChange = (
		_event: React.SyntheticEvent,
		newValue: NavigationKey,
	): void => {
		// ignore if clicking current tab
		if (newValue === currentTabValue) return

		let path = ""
		if (inPortfolio) {
			path = `/portfolio/${portfolioId}`
		}
		switch (newValue) {
			case NavigationKey.SUBJECT:
			case NavigationKey.REFERENCES:
			case NavigationKey.COVER:
			case NavigationKey.CHARTS:
			case NavigationKey.SEARCH:
			case NavigationKey.CHAT:
			case NavigationKey.CONTEXT:
			case NavigationKey.DOCUMENTS:
				navigate(`${path}/project/${projectId}/${newValue}`)
				break
			case NavigationKey.PORTFOLIO:
				navigate(`${path}/subjects`)
				break
			default:
				break
		}
	}

	// Render tab label
	const renderNavItem = (text: string, value: NavigationKey) => (
		<NavigationMenuItem
			key={value}
			className={`px-3 py-1 text-sm hover:cursor-pointer font-medium ${
				currentTabValue === value
					? "bg-accent text-accent-foreground rounded-full"
					: "text-muted-foreground"
			}`}
			onClick={(event) => handleTabChange(event, value)}
		>
			{text}
		</NavigationMenuItem>
	)

	return (
		<NavigationMenu>
			<NavigationMenuList>
				{navItems.map(
					({ label, key }) =>
						(key !== "portfolio" || inPortfolio) &&
						(key !== "cover" || !inPortfolio) &&
						renderNavItem(label, key),
				)}
			</NavigationMenuList>
		</NavigationMenu>
	)
}

export default memo(ProjectNavigation)
