import { ClaimCitationComponent } from "@/features/charts/components/ClaimCitation"
import useChartOptionsStore from "@/features/charts/hooks/chartOptionsStore"
import type { Claim, DocumentChunk } from "@/types"
import { CitationType } from "@/types/invalidity"
import { useRef } from "react"
import type React from "react"
import { useChartsContext } from "../../context/ChartsContext"
import DocumentChunkCitation from "../DocumentChunkCitation"
import { InvalidityTableCitation } from "../table-mode/InvalidityTableCitation"

interface PotentialCitationProps {
	documentId: string
	type: CitationType
	data: DocumentChunk | Claim
	onAddCitation: (data: DocumentChunk | Claim) => void
	showFigures?: boolean
	showTimestamp?: boolean
	showVerify?: boolean
	showRemove?: boolean
	showFeedback?: boolean
	showColor?: boolean
}

export const PotentialCitation: React.FC<PotentialCitationProps> = ({
	documentId,
	type,
	data,
	onAddCitation,
	showFigures = false,
	showVerify = true,
	showRemove = true,
	showFeedback = true,
	showColor = true,
}) => {
	const { activeLimitationId, activeLimitationInvalidityData } = useChartsContext()
	const containerRef = useRef<HTMLDivElement>(null)
	const { showFeedbackButtons } = useChartOptionsStore()

	// Look up if this item is already cited
	const citation = activeLimitationId
		? (
				activeLimitationInvalidityData?.[activeLimitationId]?.citations[documentId] || []
			).find(
				(c: any) =>
					(type === "chunk"
						? c.documentChunkId === data.id
						: c.claimCitationId === data.id) && !c.removed,
			)
		: undefined

	// Scroll into view if this citation is active
	if (
		citation &&
		activeLimitationId === citation.claimLimitationId &&
		containerRef.current
	) {
		containerRef.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		})
	}

	if (citation) {
		return (
			<InvalidityTableCitation
				citation={citation}
				showColor={showColor}
				showRemove={showRemove}
				showVerify={showVerify}
				showFeedback={showFeedback && showFeedbackButtons}
			/>
		)
	}

	if (type === CitationType.CHUNK) {
		return (
			<DocumentChunkCitation
				chunk={data as DocumentChunk}
				showFigures={showFigures}
				selectedLimitationId={activeLimitationId}
				onAddCitation={() => onAddCitation(data)}
			/>
		)
	}

	return (
		<ClaimCitationComponent
			citation={data as Claim}
			activeLimitationId={activeLimitationId}
			onAddCitation={() => onAddCitation(data)}
		/>
	)
}

export default PotentialCitation
