// services/frontend/src/features/create/CreateProjectContext.tsx

import useUnprocessedDocuments from "@/hooks/useUnprocessedDocuments"
import { CreateProjectStep } from "@/types"
import type React from "react"
import { createContext, useContext } from "react"
import useProjectCreation from "./hooks/useProjectCreation"
import useStepNavigation from "./hooks/useStepNavigation"

const CreateProjectContext = createContext<any>(undefined)

export const CreateProjectProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const documentManagement = useUnprocessedDocuments()

	const { unprocessedDocuments, unprocessedSubjectDocuments } = documentManagement

	const projectCreation = useProjectCreation({
		unprocessedDocuments,
		unprocessedSubjectDocuments,
	})

	const { projectName, projectType } = projectCreation

	const stepNavigation = useStepNavigation(
		projectName,
		projectType,
		unprocessedSubjectDocuments,
	)

	const { resetCreateProjectState } = projectCreation

	const resetProjectCreation = () => {
		resetCreateProjectState()
		documentManagement.resetAll()
		stepNavigation.setCurrentStep(CreateProjectStep.DETAILS)
	}

	// Combine all the states into a single context value
	const contextValue = {
		...documentManagement,
		...projectCreation,
		...stepNavigation,
		resetProjectCreation,
	}

	return (
		<CreateProjectContext.Provider value={contextValue}>
			{children}
		</CreateProjectContext.Provider>
	)
}

export const useCreateProjectContext = () => {
	const context = useContext(CreateProjectContext)
	if (!context) {
		throw new Error("useCreateProjectContext must be used within a CreateProjectProvider")
	}
	return context
}
