import { DateTimePicker } from "@/components/ui/datetime-picker"
import { Label } from "@/components/ui/label"

interface DateRangePickerProps {
    fromDate: Date | null
    toDate: Date | null
    onFromDateChange: (date: Date | null) => void
    onToDateChange: (date: Date | null) => void
    label?: string
    disabled?: boolean
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
    fromDate,
    toDate,
    onFromDateChange,
    onToDateChange,
    label = "Date Range",
    disabled = false,
}) => {
    return (
        <div className="space-y-2">
            <Label>{label}</Label>
            <div className="flex items-center gap-2">
                <DateTimePicker
                    value={fromDate}
                    granularity="day"
                    onChange={onFromDateChange}
                    disabled={disabled}
                    placeholder="From"
                />
                <span className="text-muted-foreground">-</span>
                <DateTimePicker
                    value={toDate}
                    granularity="day"
                    onChange={onToDateChange}
                    disabled={disabled}
                    placeholder="To"
                />
            </div>
        </div>
    )
}
