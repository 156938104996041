import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import { useApi } from "@/hooks"
import type { ChunkLocation, UpdateDocumentChunkRequest } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils"
import { invalidateDocumentChunkRelatedQueries } from "@/utils/query/invalidation"
import { revertOptimisticUpdates } from "@/utils/query/optimisticUpdates"
import { handleOptimisticUpdate } from "@/utils/query/optimisticUpdates"
import { useMutation, useQueryClient } from "@tanstack/react-query"

type UpdateChunkPayload = {
	documentId: string
	payload: UpdateDocumentChunkRequest
}

const useDocumentChunk = () => {
	const queryClient = useQueryClient()
	const { projectId, portfolioId } = useParentContext()
	const { handleError } = useApi()

	const performOptimisticChunkUpdate = async (
		documentId: string,
		updateFn: (oldData: any) => any,
	) => {
		// The query key can be defined based on your conventions.
		const queryKey = QUERY_KEYS.document.full(documentId) || ["document", documentId]
		return await handleOptimisticUpdate({
			queryClient,
			queryKeys: [queryKey],
			updateFn,
		})
	}

	const updateDocumentChunk = useMutation<
		any,
		Error,
		UpdateChunkPayload,
		{ snapshots: Map<string, any> }
	>({
		mutationKey: MUTATION_KEYS.document.chunk.update(),
		mutationFn: async ({ documentId, payload }: UpdateChunkPayload) => {
			return api.updateDocumentChunk({ updateDocumentChunkRequest: payload })
		},
		onMutate: async ({
			documentId,
			payload,
		}): Promise<{ snapshots: Map<string, any> }> => {
			return await performOptimisticChunkUpdate(documentId, (oldData: any) => {
				if (!oldData) {
					return oldData
				}

				// Extract the current document body where the chunks are stored.
				const oldChunks = oldData.document?.body

				// Helper function: merge only the provided fields from payload into a chunk.
				const updateFields = (chunk: any) => {
					const updated = {
						...chunk,
						...(payload.text !== undefined ? { text: payload.text } : {}),
						...(payload.figure_urls !== undefined ? { figureUrls: payload.figure_urls } : {}),
						...(payload.figure_rotation !== undefined
							? { figureRotation: payload.figure_rotation }
							: {}),
						...(payload.caption !== undefined ? { caption: payload.caption } : {}),
						...(payload.figure_number !== undefined
							? { figureNumber: payload.figure_number }
							: {}),
						...(payload.summary !== undefined ? { summary: payload.summary } : {}),
					}
					return updated
				}

				let updatedChunks
				if (Array.isArray(oldChunks)) {
					// Map through the array and update the matching chunk.
					updatedChunks = oldChunks.map((chunk: any) => {
						if (chunk.id === payload.document_chunk_id) {
							const updatedChunk = updateFields(chunk)
							return updatedChunk
						}
						return chunk
					})
				} else if (oldChunks && typeof oldChunks === "object") {
					// Handle the case where the chunk is stored as a single object.
					if (oldChunks.id === payload.document_chunk_id) {
						updatedChunks = updateFields(oldChunks)
					} else {
						updatedChunks = oldChunks
					}
				} else {
					// Fall back in case the structure is unexpected.
					updatedChunks = oldChunks
				}

				// Build the new state with the updated chunk(s)
				const newState = {
					...oldData,
					document: {
						...oldData.document,
						body: updatedChunks,
					},
				}
				return newState
			})
		},
		onError: (error, _variables, context) => {
			console.error("[onError] Error while updating document chunk:", error)
			if (context) {
				revertOptimisticUpdates(queryClient, context)
			}
			handleError(error, "Failed to update document chunk")
		},
		onSettled: (_, __, variables) => {
			const queryKey = QUERY_KEYS.document.full(variables.documentId) ?? [
				"document",
				variables.documentId,
			]
			queryClient.invalidateQueries({ queryKey })
		},
		onSuccess: (_, variables) => {
			invalidateDocumentChunkRelatedQueries(
				queryClient,
				variables.documentId,
				projectId,
				portfolioId,
			)
		},
	})

	const addDocumentChunk = useMutation({
		mutationKey: MUTATION_KEYS.document.chunk.add(),
		mutationFn: async ({
			documentId,
			text,
			location,
			figureUrls,
		}: {
			documentId: string
			text: string
			location: ChunkLocation
			figureUrls: string[]
		}) => {
			return api.addTextDocumentChunk(documentId, text, location, figureUrls)
		},
		onError: (error) => {
			handleError(error, "Failed to add document chunk")
		},
		onSuccess: (_, variables) => {
			invalidateDocumentChunkRelatedQueries(
				queryClient,
				variables.documentId,
				projectId,
				portfolioId,
			)
		},
	})

	const deleteDocumentChunk = useMutation({
		mutationKey: MUTATION_KEYS.document.chunk.delete(),
		mutationFn: async ({
			documentId,
			documentChunkId,
			isFigure,
		}: {
			documentId: string
			documentChunkId: string
			isFigure: boolean
		}) => {
			return api.deleteDocumentChunk(documentChunkId, isFigure)
		},
		onError: (error) => {
			handleError(error, "Failed to delete document chunk")
		},
		onSuccess: (_, variables) => {
			if (variables.isFigure) {
				queryClient.invalidateQueries({
					queryKey: QUERY_KEYS.document.figures(variables.documentId),
				})
			} else {
				invalidateDocumentChunkRelatedQueries(
					queryClient,
					variables.documentId,
					projectId,
					portfolioId,
				)
			}
		},
	})

	return {
		updateDocumentChunk: updateDocumentChunk.mutate,
		updateDocumentChunkIsLoading: updateDocumentChunk.isPending,
		addDocumentChunk: addDocumentChunk.mutate,
		addDocumentChunkIsLoading: addDocumentChunk.isPending,
		deleteDocumentChunk: deleteDocumentChunk.mutate,
		deleteDocumentChunkIsLoading: deleteDocumentChunk.isPending,
	}
}

export default useDocumentChunk
