import type {
	AddDocumentChunkRequest,
	ChunkLocation,
	CitationSearchResult,
	ClaimStrength,
	DeleteDocumentChunkRequest,
	DeleteDocumentsRequest,
	DocumentChunk,
	DocumentToProcess,
	GetClaimStrengthsRequest,
	GetDocumentFiguresRequest,
	GetOfficeActionsRequest,
	GetPatentDetailsRequest,
	PatentNumberOption,
	PortfolioDocument,
	ProcessDocumentsRequest,
	ProcessSettings,
	ProjectDocument,
	ReprocessDocumentsRequest,
	SearchMode,
	SemanticSearchDocumentsRequest,
	UpdateDocumentChunkRequest,
	UpdateDocumentMetadataRequest,
	UpdateDocumentRequest,
} from "@/types"
import { S3Operation } from "@/types"
import type { DocumentCoverSentence } from "@/types/documents"
import { apiClient } from "./client"

export const documentApi = {
	async deleteReferences(
		projectId: string | null,
		portfolioId: string | null,
		referenceIds: string[],
	) {
		const payload: DeleteDocumentsRequest = {
			project_id: projectId,
			portfolio_id: portfolioId,
			reference_ids: referenceIds,
		}

		return await apiClient.post("post_delete_documents", payload)
	},

	async semanticSearchDocuments(
		projectId: string,
		searchQuery: string,
		documentIds: string[],
		searchMode: SearchMode,
	): Promise<CitationSearchResult[]> {
		const params: SemanticSearchDocumentsRequest = {
			project_id: projectId,
			text: searchQuery,
			documents_ids: documentIds,
			results: 50,
			search_mode: searchMode,
		}

		return await apiClient.get("get_semantic_search_documents", params)
	},

	async getFullDocument(
		documentId: string,
		projectId?: string | null,
		portfolioId?: string | null,
	): Promise<ProjectDocument | PortfolioDocument> {
		if (portfolioId) {
			const params = {
				document_id: documentId,
				portfolio_id: portfolioId,
			}
			return await apiClient.get("get_full_portfolio_document", params)
		}
		const params = {
			document_id: documentId,
			project_id: projectId,
		}
		return await apiClient.get("get_full_document", params)
	},

	async getDocumentFigures(documentId: string): Promise<DocumentChunk[]> {
		const params: GetDocumentFiguresRequest = {
			document_id: documentId,
		}
		return await apiClient.get("get_document_figures", params)
	},

	async getClaimStrengths(projectId: string): Promise<ClaimStrength[]> {
		const params: GetClaimStrengthsRequest = {
			project_id: projectId,
		}
		return await apiClient.get("get_claim_strengths", params)
	},

	async processDocuments(
		portfolioId: string | null, // Add portfolioId for projects with a portfolio
		projectIds: string[] | null,
		documents: DocumentToProcess[],
		settings: ProcessSettings,
	) {
		const payload: ProcessDocumentsRequest = {
			portfolio_id: portfolioId,
			project_ids: projectIds,
			documents,
			settings,
		}
		return await apiClient.post("process", payload)
	},

	async reprocessDocuments(
		portfolioId: string | null,
		projectIds: string[] | null,
		documentIds: string[],
		settings: ProcessSettings,
	) {
		const payload: ReprocessDocumentsRequest = {
			portfolio_id: portfolioId,
			project_ids: projectIds,
			document_ids: documentIds,
			settings: {
				chart: settings.chart ?? true,
				reparse: settings.reparse ?? false,
				citation_policy: settings.citation_policy,
				claims_in_charting: settings.claims_in_charting ?? false,
				overwrite: settings.overwrite ?? false,
			},
		}
		return await apiClient.post("reprocess", payload)
	},

	async getPatentDetails(
		patentNumbers: string[],
		projectId: string,
		isEarlyApplication: boolean,
		projectMultiplier?: number | null,
	): Promise<PatentNumberOption[]> {
		const params: GetPatentDetailsRequest = {
			patent_numbers: patentNumbers,
			project_id: projectId,
			is_early_application: isEarlyApplication,
			project_multiplier: projectMultiplier,
		}
		return await apiClient.get("get_patent_details", params)
	},

	async updateDocuments(documentIds: string[], title: string | null = null) {
		const payload: UpdateDocumentRequest = {
			document_ids: documentIds,
			title,
		}
		return await apiClient.post("post_update_documents", payload)
	},

	async updateDocumentChunk({
		updateDocumentChunkRequest,
	}: {
		updateDocumentChunkRequest: UpdateDocumentChunkRequest
	}) {
		const payload: UpdateDocumentChunkRequest = {
			...updateDocumentChunkRequest,
		}
		return await apiClient.post("post_update_document_chunk", payload)
	},

	async addTextDocumentChunk(
		documentId: string,
		text: string,
		location: ChunkLocation,
		figureUrls: string[],
		// type: BlockType,
	) {
		const payload: AddDocumentChunkRequest = {
			document_id: documentId,
			text,
			location: location as Record<string, never>,
			figure_urls: figureUrls,
			type: "TEXT",
		}
		return await apiClient.post("post_add_document_chunk", payload)
	},

	async deleteDocumentChunk(documentChunkId: string, isFigure: boolean) {
		const payload: DeleteDocumentChunkRequest = {
			document_chunk_id: documentChunkId,
			is_figure: isFigure,
		}
		return await apiClient.post("post_delete_document_chunk", payload)
	},

	async updateDocumentMetadata(
		projectId: string | null,
		portfolioId: string | null,
		documentIds: string[],
		options: {
			notes?: string | null
			addTags?: string[] | null
			setTags?: string[] | null
			nickname?: string | null
			documentCover?: DocumentCoverSentence[] | null
		},
	) {
		const payload: UpdateDocumentMetadataRequest = {
			...(projectId && { project_id: projectId }),
			...(portfolioId && { portfolio_id: portfolioId }),
			document_ids: documentIds,
			...(options.notes && { notes: options.notes }),
			...(options.addTags && { add_tags: options.addTags }),
			...(options.setTags && { set_tags: options.setTags }),
			...(options.nickname && { nickname: options.nickname }),
			...(options.documentCover && {
				document_cover: options.documentCover.map((sentence) => ({
					sentence: sentence.sentence.map((part) => {
						if ("chunkId" in part) {
							return {
								text: part.text,
								chunk_id: part.chunkId,
								start_index: part.startIndex,
								end_index: part.endIndex,
							}
						}
						return part
					}),
				})),
			}),
		}
		return await apiClient.post("post_update_document_metadata", payload)
	},

	async getOfficeActions(projectId: string) {
		const params: GetOfficeActionsRequest = {
			project_id: projectId,
		}
		return await apiClient.get("get_office_actions", params)
	},

	async getSignedUrlForFile(
		s3Key: string,
		operation: S3Operation = S3Operation.GET,
		contentType = "application/pdf",
	) {
		const data = await apiClient.get("get_signed_url_for_file", {
			file_id: s3Key,
			operation: operation,
			content_type: contentType,
		})
		return data
	},

	async getPdfFile(url: string): Promise<{ data: Blob; status: number }> {
		return await apiClient.getBinary("get_pdf_file", { url })
	},
}
