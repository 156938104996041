/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import {
	NavigationMenu,
	NavigationMenuItem,
	NavigationMenuList,
} from "@/components/ui/navigation-menu"
import { useParentContext } from "@/context/ParentContext"
import { useNavigationState, usePortfolioMetadata } from "@/hooks"
import { ProjectType } from "@/types"
import type React from "react"
import { memo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
/**
 * @description Component for navigation tabs in invalidity project
 */
const PortfolioNavigationTabs: React.FC = () => {
	const navigate = useNavigate()
	const location = useLocation()

	// Global state from store
	const { isPortfolioPage } = useNavigationState()
	const { portfolioId } = useParentContext()
	const { portfolioType } = usePortfolioMetadata()

	const getTabValue = (pathname: string): string => {
		if (pathname.includes("/overview")) {
			return "overview"
		}
		if (pathname.includes("/subjects")) {
			return "subjects"
		}
		if (pathname.includes("/references")) {
			return "references"
		}
		if (pathname.includes("/cover")) {
			return "cover"
		}
	}

	const currentTabValue = getTabValue(location.pathname)

	// Handle nav item selection
	const handleTabChange = (_event: React.SyntheticEvent, newValue: string): void => {
		// ignore if clicking current tab
		if (newValue === currentTabValue) return

		let path = ""
		if (isPortfolioPage) {
			path = `/portfolio/${portfolioId}`
		}
		switch (newValue) {
			case "overview":
				navigate(`${path}/overview`)
				break
			case "subjects":
				navigate(`${path}/subjects`)
				break
			case "references":
				navigate(`${path}/references`)
				break
			case "cover":
				navigate(`${path}/cover`)
				break
			default:
				break
		}
	}

	// Render tab label
	const renderNavItem = (text: string, value: string) => (
		<NavigationMenuItem
			className={`px-3 py-1 text-sm hover:cursor-pointer font-medium ${
				currentTabValue === value
					? "bg-accent text-accent-foreground rounded-full"
					: "text-muted-foreground"
			}`}
			onClick={(event) => {
				if (portfolioId) {
					handleTabChange(event, value)
				}
			}}
		>
			{text}
		</NavigationMenuItem>
	)

	return (
		<NavigationMenu>
			<NavigationMenuList>
				{renderNavItem("Overview", "overview")}
				{renderNavItem(
					portfolioType === ProjectType.SEP ? "Standards" : "Subjects",
					"subjects",
				)}
				{renderNavItem("References", "references")}
				{renderNavItem("Cover", "cover")}
			</NavigationMenuList>
		</NavigationMenu>
	)
}

export default memo(PortfolioNavigationTabs)
