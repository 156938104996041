import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import type { PortfolioMatrixRow } from "@/types"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

export function usePortfolioMatrix() {
	const { portfolioId } = useParentContext()

	const portfolioMatrixQuery = useQuery({
		queryKey: ["portfolio", portfolioId, "matrix"],
		queryFn: () => api.getPortfolioMatrix(portfolioId),
		enabled: !!portfolioId,
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		portfolioMatrix: portfolioMatrixQuery.data ?? ([] as PortfolioMatrixRow[]),
		isLoading: portfolioMatrixQuery.isLoading,
		isFetching: portfolioMatrixQuery.isFetching,
		refetchMatrix: portfolioMatrixQuery.refetch,
	}
}

export default usePortfolioMatrix
