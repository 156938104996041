/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { Loader } from "@/components"
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import {
	AdminPageLayout,
	CurrentBillingDetailsCard,
	InvoicesTable,
	NoAdminAccessMessage,
	OverviewDetailsCard,
	PlanDetailsCard,
	UsersTable,
} from "@/features/admin/components"
import { DeleteOrganizationModal } from "@/features/admin/superadmin/components"
import { useUserStore } from "@/store"
import { RoleType } from "@/types"
import { PageName } from "@/types/pages"
import { useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAdminBilling } from "../hooks/useAdminBilling"
import { useAdminManagement } from "../hooks/useAdminManagement"
import { useAdminUsers } from "../hooks/useAdminUsers"

/**
 * @description Super admin page component
 */
function SuperAdminOrganizationDetailsPage() {
	const navigate = useNavigate()
	const { organizationId } = useParams()
	const { role } = useUserStore()
	const { adminOverview, isLoading: isLoadingOverview } =
		useAdminManagement(organizationId)
	const { adminUsers, isLoading: isLoadingUsers } = useAdminUsers(organizationId)
	const { adminBilling, isLoading: isLoadingBilling } = useAdminBilling(organizationId)

	const [_showEditOrgModal, setShowEditOrgModal] = useState(false)
	const [showRemoveOrgModal, setShowRemoveOrgModal] = useState(false)
	const [selectedMonth, setSelectedMonth] = useState<string | null>(null)
	const [selectedYear, setSelectedYear] = useState<string | null>(null)
	const [_showMonthlyOverview, setShowMonthlyOverview] = useState(false)

	const months = [
		{ value: "01", label: "January" },
		{ value: "02", label: "February" },
		{ value: "03", label: "March" },
		{ value: "04", label: "April" },
		{ value: "05", label: "May" },
		{ value: "06", label: "June" },
		{ value: "07", label: "July" },
		{ value: "08", label: "August" },
		{ value: "09", label: "September" },
		{ value: "10", label: "October" },
		{ value: "11", label: "November" },
		{ value: "12", label: "December" },
	]

	const currentYear = new Date().getFullYear()
	const years = Array.from({ length: currentYear - 2023 }, (_, i) => ({
		value: (2024 + i).toString(),
		label: (2024 + i).toString(),
	}))

	const handleConfirm = async () => {
		if (selectedMonth && selectedYear) {
			const _date = `${selectedYear}-${selectedMonth}`
			try {
				// TODO upser to use get overview
				// You can now use monthlyData to update your UI
				setShowMonthlyOverview(true)
			} catch (error) {
				console.error("Failed to fetch monthly overview:", error)
			}
		}
	}

	const handleReset = () => {
		setSelectedMonth(null)
		setSelectedYear(null)
		setShowMonthlyOverview(false)
	}

	const isSuperAdmin = useMemo(() => role === RoleType.OWNER, [role])

	const handleBackClick = () => {
		navigate("/superadmin/organizations")
	}

	return (
		<AdminPageLayout isSuperAdmin={true} page={PageName.SuperAdminOverview}>
			{isSuperAdmin ? (
				<div className="space-y-8 mt-2">
					{isLoadingOverview || isLoadingUsers || isLoadingBilling ? (
						<Loader />
					) : (
						<div className="space-y-4">
							<div className="flex justify-between">
								<Breadcrumb className="hidden md:flex">
									<BreadcrumbList>
										<BreadcrumbItem onClick={handleBackClick}>
											<BreadcrumbLink>Organizations</BreadcrumbLink>
										</BreadcrumbItem>
										<BreadcrumbSeparator />
										{/* <BreadcrumbItem>
                      {adminData?.organizations[0]?.name}
                    </BreadcrumbItem> */}
									</BreadcrumbList>
								</Breadcrumb>
								<div className="flex gap-2">
									<Popover>
										<PopoverTrigger asChild>
											<Button variant="outline">
												{selectedMonth
													? months.find((m) => m.value === selectedMonth)?.label
													: "Select Month"}
											</Button>
										</PopoverTrigger>
										<PopoverContent>
											<div className="grid grid-cols-3 gap-2">
												{months.map((month) => (
													<Button
														key={month.value}
														onClick={() => setSelectedMonth(month.value)}
														variant="ghost"
													>
														{month.label}
													</Button>
												))}
											</div>
										</PopoverContent>
									</Popover>
									<Popover>
										<PopoverTrigger asChild>
											<Button variant="outline">{selectedYear ?? "Select Year"}</Button>
										</PopoverTrigger>
										<PopoverContent>
											<div className="grid gap-2">
												{years.map((year) => (
													<Button
														key={year.value}
														onClick={() => setSelectedYear(year.value)}
														variant="ghost"
													>
														{year.label}
													</Button>
												))}
											</div>
										</PopoverContent>
									</Popover>

									<Button onClick={handleReset}>Reset</Button>
									<Button onClick={handleConfirm}>Confirm</Button>
									<Button
										variant="outline"
										onClick={() => setShowEditOrgModal(true)}
										disabled={isLoadingOverview.adminOverview}
									>
										Edit
									</Button>
									<Button
										variant="destructive"
										onClick={() => setShowRemoveOrgModal(true)}
										disabled={isLoadingOverview.adminOverview}
									>
										Delete
									</Button>
								</div>
							</div>

							<OverviewDetailsCard overview={adminOverview} />

							<div className="flex gap-3">
								<PlanDetailsCard plan={adminBilling?.plan} />
								<CurrentBillingDetailsCard cycleSummary={adminBilling?.cycleSummary} />
							</div>

							{adminBilling?.invoices?.length > 0 && (
								<InvoicesTable invoices={adminBilling.invoices} />
							)}
							{adminUsers.users && (
								<UsersTable orgName={""} orgId={organizationId} users={adminUsers.users} />
							)}
						</div>
					)}

					{/* <OrganizationModal
            open={showEditOrgModal}
            onClose={() => setShowEditOrgModal(false)}
            org={adminData?.plan}
            isEdit={true}
          /> */}
					<DeleteOrganizationModal
						open={showRemoveOrgModal}
						onClose={() => setShowRemoveOrgModal(false)}
						orgId={organizationId}
						orgName={""}
					/>
				</div>
			) : (
				<NoAdminAccessMessage />
			)}
		</AdminPageLayout>
	)
}

export default SuperAdminOrganizationDetailsPage
