/*
 * Copyright AndAI, Inc. 2025. All rights reserved.
 * This file contains proprietary information that is the property of AndAI, Inc.
 * and is protected as a trade secret.
 */

import { Loader } from "@/components"
import DocumentNamePill from "@/components/documents/DocumentNamePill"
import { Label } from "@/components/ui/label"
import { ResizableHandle, ResizablePanel } from "@/components/ui/resizable"
import { ResizablePanelGroup } from "@/components/ui/resizable"
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle"
import TooltipButton from "@/components/ui/tooltip-button"
import { H4 } from "@/components/ui/typography"
import { useParentContext } from "@/context/ParentContext"
import { useClaims, useProjectSubject } from "@/hooks"
import { usePriorArt } from "@/hooks/prior-art/usePriorArt"
import { cn } from "@/lib/utils"
import { useAreProcessesPending, useProjectStore } from "@/store"
import { ChartMode, conflictingChartProcesses } from "@/types"
import { DocumentType } from "@/types"
import { AlignVerticalSpaceAround, ChevronLeft } from "lucide-react"
import type React from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import ClaimSelect from "../../components/claims/ClaimMultiSelect"
import { PruneInvalidityColorModal } from "./components"
import ChartOptionsDropdown from "./components/ChartOptionsDropdown"
import ClaimCardsList from "./components/claim/ClaimCardsList"
import InvalidityDocumentMode from "./components/document-mode/InvalidityDocumentMode"
import EditCellBoilerplateModal from "./components/modals/EditCellBoilerplateModal"
import DocumentSectionSelect from "./components/selects/DocumentSectionSelect"
import InvalidityTableMode from "./components/table-mode/InvalidityTableMode"
import { useChartsContext } from "./context/ChartsContext"

/**
 * @description Renders the invalidity table page
 */
const InvalidityChartsPageContent: React.FC = () => {
	const navigate = useNavigate()
	const { projectSubject } = useProjectSubject()
	const { priorArtDocuments, isLoading: isPriorArtLoading } = usePriorArt()
	const { portfolioId, projectId, parentType } = useParentContext()
	const { setActiveLimitationId } = useChartsContext()
	const { projectClaims } = useClaims()
	const { showAssertedClaims } = useProjectStore()
	const isActionDisabled = useAreProcessesPending(
		conflictingChartProcesses,
		projectId || null,
		portfolioId || null,
	)

	// Retrieve the state passed via navigate
	const location = useLocation()
	const { claimLimitationIdFromSummary } =
		(location.state as {
			documentIdFromPortfolio?: string
			limitationIdFromPortfolio?: string
			claimLimitationIdFromSummary?: string
		}) || {}
	const [showPruneColorModal, setShowPruneColorModal] = useState(false)
	const [claimPanelSize, setClaimPanelSize] = useState<number>(40)
	const documentPanelSize = 100 - claimPanelSize
	const selectedClaimRef = useRef<HTMLDivElement | null>(null)
	const [showEditHeaderFooterModal, setShowEditHeaderFooterModal] = useState(false)

	useEffect(() => {
		if (claimLimitationIdFromSummary) {
			setActiveLimitationId(claimLimitationIdFromSummary)
		}
	}, [claimLimitationIdFromSummary, setActiveLimitationId])

	const {
		filterColors,
		filterLimitations,
		selectedChartMode,
		setFilterLimitations,
		setFilterColors,
		setSelectedChartMode,
		chartData,
		isCombination,
	} = useChartsContext()

	const handleColorSelect = useCallback(
		(colors: string[]) => {
			setFilterColors(colors)
		},
		[setFilterColors],
	)

	const handleClaimSelect = useCallback(
		(claimIds: string[]) => {
			setFilterLimitations(claimIds)
		},
		[setFilterLimitations],
	)

	// Auto-select asserted claims if 'showAssertedClaims' is true
	useEffect(() => {
		if (showAssertedClaims && projectClaims && projectClaims.length > 0) {
			setFilterLimitations(projectClaims.map((claim) => claim.id))
		} else if (showAssertedClaims) {
			setFilterLimitations([])
		}
	}, [showAssertedClaims, projectClaims, setFilterLimitations])

	// Conditional early returns
	if (priorArtDocuments.length === 0 && !isPriorArtLoading) {
		return (
			<div className="flex justify-center items-center flex-col mt-12">
				<H4>This {parentType.toLowerCase()} has no references.</H4>
			</div>
		)
	}

	if (!projectClaims) {
		return (
			<div className="flex justify-center items-center flex-col mt-12">
				<Loader message="Loading project data..." />
			</div>
		)
	}

	return (
		<div className="relative flex flex-col h-[calc(100vh-60px)]">
			<ResizablePanelGroup direction="horizontal" className="w-full p-0">
				{/* Claim Cards List Panel */}
				<ResizablePanel defaultSize={claimPanelSize} onResize={setClaimPanelSize}>
					<div className="sticky top-0 left-0 z-20 bg-background border-b h-12 flex items-center px-3 gap-2 justify-between">
						<div className="flex items-center gap-3">
							<TooltipButton
								icon={<ChevronLeft className="h-4 w-4" />}
								tooltipText="Back"
								onClick={() => navigate(-1)}
								variant="ghost"
								size="icon"
							/>
							<Label className="text-sm font-medium mb-0">
								{chartData?.name || "Invalidity Chart"}
							</Label>
						</div>

						<div className="flex items-center gap-2">
							<span className="whitespace-nowrap text-muted-foreground">
								{/* {getDocumentName(subjectDocumentId)} */}
								<DocumentNamePill id={projectSubject?.documentId} size="sm" />
							</span>

							<ClaimSelect
								claims={projectClaims}
								onClaimSelect={handleClaimSelect}
								className="h-7"
								filterLimitations={filterLimitations}
							/>
							<ChartOptionsDropdown isClaim={true} />
						</div>
					</div>
					<div className="flex-1 min-h-0 h-[calc(100% - 3rem)] overflow-y-auto">
						<ClaimCardsList
							projectId={projectId}
							documentIds={chartData?.documentIds}
							selectedClaimRef={selectedClaimRef}
						/>
					</div>
				</ResizablePanel>
				<ResizableHandle withHandle />
				<ResizablePanel defaultSize={documentPanelSize}>
					<div className="sticky top-0 left-0 z-20 bg-background border-b h-12 flex items-center px-3 gap-2 justify-between">
						<div className="flex items-center gap-2">
							{(!isCombination ||
								(isCombination && selectedChartMode === ChartMode.DOCUMENT)) && (
								<div className="flex gap-2">
									{chartData?.documentIds.map((documentId) => (
										<DocumentNamePill key={documentId} id={documentId} size="sm" />
									))}
								</div>
							)}
						</div>
						{/* <TooltipButton
							icon={<EllipsisVertical className="w-4 h-4" />}
							tooltipText="Options"
							onClick={() => setDropdownOpen(true)}
							variant="outline"
							size="xsIcon"
						/> */}

						<div className="flex items-center gap-2 ">
							{/* <ColorSelect
								colors={[ChartColor.GREEN, ChartColor.YELLOW, ChartColor.RED]}
								selectedColors={filterColors}
								setSelectedColors={handleColorSelect}
							/> */}
							{selectedChartMode === ChartMode.DOCUMENT && (
								<DocumentSectionSelect
									documentType={DocumentType.PATENT} // TODO: make dynamic
									disabled={isActionDisabled}
								/>
							)}

							{/* <Button
						variant="outline"
						size="sm"
						disabled={isActionDisabled}
						onClick={() => setShowPruneColorModal(true)}
					>
						Prune
					</Button> */}
							<div className="flex items-center gap-2">
								<ToggleGroup
									type="single"
									value={selectedChartMode}
									onValueChange={(value) => value && setSelectedChartMode(value as ChartMode)}
									className="h-8"
								>
									<ToggleGroupItem
										className={cn(
											"flex-1",
											selectedChartMode === ChartMode.TABLE
												? "bg-tertiary text-primary-foreground"
												: "text-muted-foreground",
										)}
										value={ChartMode.TABLE}
										size="sm"
									>
										Table
									</ToggleGroupItem>
									<ToggleGroupItem
										className={cn(
											"flex-1",
											selectedChartMode === ChartMode.DOCUMENT
												? "bg-tertiary text-primary-foreground"
												: "text-muted-foreground",
										)}
										value={ChartMode.DOCUMENT}
										size="sm"
									>
										Document
									</ToggleGroupItem>
								</ToggleGroup>
								<TooltipButton
									icon={<AlignVerticalSpaceAround className="h-4 w-4" />}
									tooltipText="Edit header/footer"
									onClick={() => setShowEditHeaderFooterModal(true)}
									disabled={isActionDisabled}
									srText="Edit header/footer"
									size="icon"
									variant="ghost"
								/>
								<ChartOptionsDropdown isClaim={false} />
							</div>
						</div>
					</div>
					{selectedChartMode === ChartMode.DOCUMENT ? (
						<InvalidityDocumentMode />
					) : (
						<InvalidityTableMode />
					)}
				</ResizablePanel>
			</ResizablePanelGroup>

			<PruneInvalidityColorModal
				open={showPruneColorModal}
				onOpenChange={setShowPruneColorModal}
				documents={priorArtDocuments}
			/>
			<EditCellBoilerplateModal
				isOpen={showEditHeaderFooterModal}
				onClose={() => setShowEditHeaderFooterModal(false)}
				applyToAllLimitations={true}
				cellHeader={""}
				cellFooter={""}
				cellMiddle={""}
			/>
		</div>
	)
}

export default InvalidityChartsPageContent
