// services/frontend/src/components/documents/PDFViewer.tsx

import { Loader } from "@/components"
import type React from "react"
import { useEffect, useMemo, useRef, useState } from "react"
import { Document, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { scrollToCenter } from "@/features/charts/components/scrollHelpers"
import { usePdf } from "@/hooks/usePdf"
import { type Bbox, type DocumentChunk, DocumentType } from "@/types"
import { ChevronLeftIcon, ChevronRightIcon, MinusIcon, PlusIcon } from "lucide-react"
import { Button } from "../ui/button"
import { Separator } from "../ui/separator"
import PdfPageWithBoundingBoxes from "./PdfPageWithBoundingBoxes"

// Set worker source before any PDF operations
if (typeof window !== "undefined") {
	try {
		pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js"
	} catch (error) {
		console.warn("Local PDF worker failed: ", error)
	}
}

// Move options outside component to prevent recreating on each render
const PDF_OPTIONS = {
	cMapUrl: "cmaps/",
	cMapPacked: true,
	standardFontDataUrl: "standard_fonts/",
	httpHeaders: {
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "application/pdf",
	},
} as const

interface PdfDisplayProps {
	documentId: string
	url: string
	documentChunks: DocumentChunk[]
	selectedChunkId?: string | null
	onChunkSelect?: (id: string) => void
	onHidePdf?: () => void
	documentType: DocumentType
}

const PdfDisplay: React.FC<PdfDisplayProps> = ({
	documentId,
	url,
	documentChunks,
	selectedChunkId,
	onChunkSelect,
	onHidePdf,
	documentType,
}) => {
	const [numPages, setNumPages] = useState<number>(0)
	const [scale, setScale] = useState<number>(1.0)
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [viewerError, setViewerError] = useState<boolean>(false)
	const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false)
	const [hoveredChunkId, setHoveredChunkId] = useState<string | null>(null)
	const pdfWrapperRef = useRef<HTMLDivElement>(null)

	const { pdfData, isLoading, error } = usePdf(url, documentId)

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages)
		setIsDocumentLoaded(true)
	}

	// Map pages to their bounding boxes; each chunk may have multiple bboxes now
	const pageToBBoxesMap = useMemo(() => {
		const map: { [page: number]: Array<DocumentChunk & { bboxes: Bbox[] }> } = {}

		documentChunks.forEach((chunk) => {
			const rawBboxes =
				typeof chunk.bboxes === "string" ? JSON.parse(chunk.bboxes) : chunk.bboxes
			const chunkBboxes = Array.isArray(rawBboxes) ? rawBboxes : [rawBboxes]

			chunkBboxes.forEach((bbox) => {
				if (bbox?.page) {
					if (!map[bbox.page]) {
						map[bbox.page] = []
					}

					// Check if chunk already exists for this page
					const existingChunkIndex = map[bbox.page].findIndex((c) => c.id === chunk.id)

					if (existingChunkIndex >= 0) {
						// Add bbox to existing chunk's bboxes array
						map[bbox.page][existingChunkIndex].bboxes.push(bbox as Bbox)
					} else {
						// Create new chunk entry with bboxes array
						map[bbox.page].push({ ...chunk, bboxes: [bbox as Bbox] })
					}
				}
			})
		})

		return map
	}, [documentChunks])

	// Scroll to the selected bounding box (first one if multiple exist)
	useEffect(() => {
		if (selectedChunkId && pdfWrapperRef.current) {
			const selectedChunk = documentChunks.find((chunk) => chunk.id === selectedChunkId)

			if (selectedChunk) {
				const rawBboxes = Array.isArray(selectedChunk.bboxes)
					? selectedChunk.bboxes.map((bbox) =>
							typeof bbox === "string" ? JSON.parse(bbox) : bbox,
						)
					: typeof selectedChunk.bboxes === "string"
						? [JSON.parse(selectedChunk.bboxes)]
						: [selectedChunk.bboxes]
				const chunkBboxes = Array.isArray(rawBboxes) ? rawBboxes : [rawBboxes]

				const firstBbox = chunkBboxes[0]

				if (firstBbox?.page) {
					setCurrentPage(firstBbox.page)

					// Wait for the page to render then scroll if needed
					setTimeout(() => {
						const chunkElement = pdfWrapperRef.current?.querySelector(
							`[data-chunk-id="${selectedChunkId}"]`,
						) as HTMLElement

						if (chunkElement) {
							// Use scrollToCenter helper instead of scrollIntoView for centering the element.
							scrollToCenter(chunkElement)
						}
					}, 100)
				}
			}
		}
	}, [selectedChunkId, documentChunks])

	const zoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.2, 2.5))
	const zoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.2, 0.5))
	const _resetZoom = () => setScale(1.0)

	// Add copy utility function
	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text)
	}

	// Function to handle page navigation
	const goToPage = (pageNum: number) => {
		const pageNumber = Math.min(Math.max(pageNum, 1), numPages)
		setCurrentPage(pageNumber)
		if (pdfWrapperRef.current) {
			const pageElements = pdfWrapperRef.current.querySelectorAll(".react-pdf__Page")
			const pageElement = pageElements[pageNumber - 1]
			if (pageElement) {
				pageElement.scrollIntoView({ behavior: "smooth", block: "start" })
			}
		}
	}

	if (viewerError || error) {
		if (documentType !== DocumentType.PATENT) {
			return (
				<iframe
					src={url}
					style={{ width: "100%", height: "100%", border: "none" }}
					title="PDF document"
				/>
			)
		}
		// For patents, trigger onHidePdf to switch to single panel mode
		onHidePdf?.()
		return null
	}

	if (isLoading) {
		return (
			<div>
				<Loader />
			</div>
		)
	}

	return (
		<div
			ref={pdfWrapperRef}
			style={{ height: "100%", overflow: "auto", position: "relative", width: "100%" }}
			className="flex flex-col"
		>
			{/* Zoom Controls */}
			<div
				className="bg-accent"
				style={{
					position: "sticky",
					top: 0,
					padding: "4px",
					zIndex: 10,
					display: "flex",
					gap: "10px",
					alignItems: "center",
					borderBottom: "1px solid #eee",
				}}
			>
				<div className="flex-1" />
				<div className="flex items-center justify-center gap-2">
					<div className="flex items-center gap-2">
						<Button variant="ghost" size="icon" onClick={zoomOut} title="Zoom out">
							<MinusIcon className="w-4 h-4" />
						</Button>
						<p className="text-sm ">{Math.round(scale * 100)}%</p>
						<Button variant="ghost" size="icon" onClick={zoomIn} title="Zoom in">
							<PlusIcon className="w-4 h-4" />
						</Button>
					</div>
					<Separator orientation="vertical" className="h-4" />

					{/* Page Selector */}
					<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
						<Button
							variant="ghost"
							size="icon"
							onClick={() => goToPage(currentPage - 1)}
							disabled={currentPage <= 1}
							title="Previous Page"
						>
							<ChevronLeftIcon className="w-4 h-4" />
						</Button>
						<span className="text-sm">
							{currentPage} / {numPages}
						</span>
						<Button
							variant="ghost"
							size="icon"
							onClick={() => goToPage(currentPage + 1)}
							disabled={currentPage >= numPages}
							title="Next Page"
						>
							<ChevronRightIcon className="w-4 h-4" />
						</Button>
					</div>
				</div>
				<div className="flex-1 flex justify-end">
					{documentType === DocumentType.PATENT && (
						<Button variant="outline" size="sm" onClick={onHidePdf} title="Hide PDF">
							Hide PDF
						</Button>
					)}
				</div>
			</div>

			<Document
				file={pdfData}
				onLoadSuccess={onDocumentLoadSuccess}
				onLoadError={(error) => {
					console.error(error)
					setViewerError(true)
				}}
				options={PDF_OPTIONS}
				loading={<Loader />}
				className="flex-1 flex flex-col items-start w-full"
			>
				{isDocumentLoaded &&
					Array.from(new Array(numPages), (_el, index) => {
						const pageNumber = index + 1
						const boundingBoxes = pageToBBoxesMap[pageNumber] || []

						return (
							<PdfPageWithBoundingBoxes
								key={`page_${pageNumber}`}
								pageNumber={pageNumber}
								scale={scale}
								documentChunks={boundingBoxes}
								onChunkSelect={onChunkSelect}
								selectedChunkId={selectedChunkId}
								copyToClipboard={copyToClipboard}
								width={pdfWrapperRef.current?.clientWidth ?? undefined}
								onChunkHover={setHoveredChunkId}
								hoveredChunkId={hoveredChunkId}
							/>
						)
					})}
			</Document>
		</div>
	)
}

export default PdfDisplay
