import type { ClaimCitation } from "@/types/invalidity"
import type React from "react"
import ClaimActions from "./ClaimActions"
import { ClaimComponent } from "./ClaimComponent"

interface ClaimCitationProps {
	citation: ClaimCitation
	activeLimitationId: string
	onAddCitation: (citation: ClaimCitation) => void
}

export const ClaimCitationComponent: React.FC<ClaimCitationProps> = ({
	citation,
	activeLimitationId,
	onAddCitation,
}) => {
	return (
		<ClaimActions
			claim={citation}
			selectedLimitationId={activeLimitationId}
			onAddCitation={() => onAddCitation(citation)}
		>
			<ClaimComponent claim={citation} />
		</ClaimActions>
	)
}

export default ClaimCitationComponent
