import { api } from "@/api"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useApi } from "@/hooks"
import type { CitationSearchResult, SearchMode } from "@/types"

const useSearchDocuments = () => {
	const { handleError } = useApi()

	/**
	 * @description Performs a semantic search for documents
	 * @param {string} projectId - The id of the project to perform the search for
	 * @param {string} searchQuery - The query to search for
	 * @param {array} documentsIds - The ids of the documents to search in
	 * @param {string} searchMode - The mode of the search (semantic or keyword)
	 * @returns Result object with success status and data or error message.
	 */
	const semanticSearchDocuments = async (
		projectId: string,
		searchQuery: string,
		documentsIds: string[],
		searchMode: SearchMode,
	): Promise<CitationSearchResult[]> => {
		try {
			const response = await api.semanticSearchDocuments(
				projectId,
				searchQuery,
				documentsIds,
				searchMode,
			)
			return response
		} catch (error) {
			return handleError(error, "Error fetching semantic search results")
		}
	}

	return {
		semanticSearchDocuments,
	}
}

export default useSearchDocuments
