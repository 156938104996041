import { TableCell, TableRow } from "@/components/ui/table"
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip"
import type { Claim, ClaimLimitation, CollapsedClaim } from "@/types"
import {
	getFlattenedLimitationsFromCollapsed,
	getFullClaimText,
} from "@/utils/claimUtils"
import { CheckCircledIcon } from "@radix-ui/react-icons"
import type React from "react"
import { CopyContextMenu } from "../menus/CopyContextMenu"
import ClaimLimitationComponent from "./ClaimLimitation"
export interface ClaimProps {
	claim: Claim | CollapsedClaim
	editingBool?: boolean
	isHighlighted?: boolean
	highlightedRef?: React.MutableRefObject<HTMLTableRowElement | null> | null
	isSubject: boolean
}

const ClaimRow: React.FC<ClaimProps> = ({
	claim,
	editingBool,
	isHighlighted,
	highlightedRef,
	isSubject,
}) => {
	// Common booleans
	const isIndependent = claim.isIndependent
	const isAsserted = claim.isAsserted

	function isRegularClaim(c: Claim | CollapsedClaim): c is Claim {
		return "limitations" in c
	}

	// If it's a regular claim, use its limitations;
	// if it's collapsed, flattten
	const limitations: ClaimLimitation[] = isRegularClaim(claim)
		? (claim.limitations ?? [])
		: getFlattenedLimitationsFromCollapsed(claim.collapsedLimitations ?? [])

	return (
		<TableRow
			ref={highlightedRef || null}
			className={`
        ${isHighlighted ? "bg-blue-100" : ""}
        ${isIndependent ? "border-t-2 border-gray-200" : ""}
          hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors

      `}
		>
			<TableCell className="py-4">
				<CopyContextMenu
					key={claim.id}
					getText={() => getFullClaimText(claim)}
					getCitation={() => `Claim ${claim.number}`}
				>
					{limitations.map((limitation) => (
						<ClaimLimitationComponent
							key={limitation.id}
							limitation={limitation}
							isClaimIndependent={isIndependent}
						/>
					))}
				</CopyContextMenu>
			</TableCell>

			{editingBool && (
				<TableCell className="w-[10px] align-top">
					{isSubject && isAsserted && (
						<Tooltip>
							<TooltipTrigger asChild>
								<CheckCircledIcon className="h-4 w-4 text-blue-500" />
							</TooltipTrigger>
							<TooltipContent>Asserted</TooltipContent>
						</Tooltip>
					)}
				</TableCell>
			)}
		</TableRow>
	)
}

export default ClaimRow
