import InfoPopover from "@/components/info/InfoPopover"
import type React from "react"
import { useMemo } from "react"

interface UsersHeatmapProps {
	heatmap: { [date: string]: string[] } // date: user emails active on that day
}

const UsersHeatmap: React.FC<UsersHeatmapProps> = ({ heatmap }) => {
	// Set up date parameters
	const today = useMemo(() => new Date(), [])
	const oneYearAgo = useMemo(() => {
		const d = new Date()
		d.setDate(d.getDate() - 365)
		return d
	}, [])

	// Adjust starting date to the previous Sunday for grid consistency
	const startDate = useMemo(() => {
		const d = new Date(oneYearAgo)
		d.setDate(d.getDate() - d.getDay())
		return d
	}, [oneYearAgo])

	// Generate array of weeks (each containing 7 Date objects)
	const weeks = useMemo(() => {
		const weeksArr: Date[][] = []
		const current = new Date(startDate)
		while (current <= today) {
			const week: Date[] = []
			for (let i = 0; i < 7; i++) {
				week.push(new Date(current))
				current.setDate(current.getDate() + 1)
			}
			weeksArr.push(week)
		}
		return weeksArr
	}, [startDate, today])

	// Compute the maximum user count on any day within the past year range.
	const maxCount = useMemo(() => {
		let max = 0
		for (const week of weeks) {
			for (const day of week) {
				if (day >= oneYearAgo && day <= today) {
					const key = day.toISOString().substring(0, 10)
					const count = heatmap[key]?.length || 0
					if (count > max) max = count
				}
			}
		}
		return max
	}, [weeks, heatmap, oneYearAgo, today])

	// Update the color mapping function to be continuous
	const getColorStyle = (count: number): { style: React.CSSProperties } => {
		if (count === 0) return { style: { backgroundColor: "hsl(142, 40%, 97%)" } }
		const ratio = maxCount > 0 ? count / maxCount : 0
		// Adjust saturation and lightness for a smooth green gradient
		const saturation = 60 + ratio * 40 // 60% to 100%
		const lightness = 90 - ratio * 70 // 90% to 20%
		return { style: { backgroundColor: `hsl(142, ${saturation}%, ${lightness}%)` } }
	}

	// Update legend items for continuous spectrum
	const getLegendItems = useMemo(() => {
		if (maxCount === 0) return []

		// Create 6 sample points for the legend with unique keys
		return [
			{ key: "0", label: "0", ratio: 0 },
			{ key: "20", label: `${Math.ceil(maxCount * 0.2)}`, ratio: 0.2 },
			{ key: "40", label: `${Math.ceil(maxCount * 0.4)}`, ratio: 0.4 },
			{ key: "60", label: `${Math.ceil(maxCount * 0.6)}`, ratio: 0.6 },
			{ key: "80", label: `${Math.ceil(maxCount * 0.8)}`, ratio: 0.8 },
			{ key: "100", label: `${maxCount}`, ratio: 1 },
		].map((item) => ({
			key: item.key,
			label: item.label,
			style: getColorStyle(maxCount * item.ratio).style,
		}))
	}, [maxCount])

	// Create month labels for each week column.
	const monthLabels = useMemo(() => {
		return weeks.map((week, index) => {
			// Use the week's starting day (Sunday) as reference.
			const date = week[0]
			if (index === 0 || date.getMonth() !== weeks[index - 1][0].getMonth()) {
				const monthAbbr = date.toLocaleString("default", { month: "short" })
				return { label: monthAbbr, weekIndex: index }
			}
			return { label: "", weekIndex: index }
		})
	}, [weeks])

	// Define static day-of-week labels (Sunday to Saturday).
	const dayLabels = useMemo(() => ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], [])

	return (
		<div className="bg-white shadow rounded-lg border border-gray-200 p-4">
			<div className="flex justify-between items-center mb-4">
				<h3 className="text-lg font-semibold">Daily User Activity</h3>
				{/* Legend with consistent circular style */}
				<div className="flex items-center space-x-2">
					<div className="flex items-center space-x-1">
						{getLegendItems.map((item) => (
							<div key={item.key} className="flex items-center">
								<div className="w-3 h-3 rounded-full" style={item.style} />
								<span className="text-xs text-gray-500 ml-1">{item.label}</span>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="flex">
				{/* Left side: Day-of-Week labels */}
				<div className="flex flex-col items-end pr-2 pt-6">
					{dayLabels.map((day) => (
						<div
							key={day}
							className="text-xs text-gray-500 h-4 mb-1"
							style={{ lineHeight: "1rem" }}
						>
							{day}
						</div>
					))}
				</div>
				<div className="w-full">
					{/* Top row: Month labels */}
					<div className="relative flex mb-1 h-6">
						{monthLabels.map(
							(month, i) =>
								month.label && (
									<div
										key={i}
										className="absolute text-xs text-gray-500"
										style={{
											left: `${(month.weekIndex * 100) / weeks.length}%`,
											width: "auto",
										}}
									>
										{month.label}
									</div>
								),
						)}
					</div>
					{/* Heatmap Grid */}
					<div className="flex w-full">
						{weeks.map((week) => (
							<div key={week[0].toISOString()} className="flex flex-col space-y-1 flex-1">
								{week.map((day) => {
									const key = day.toISOString().substring(0, 10)
									const isInRange = day >= oneYearAgo && day <= today
									const emails = isInRange ? (heatmap[key] ?? []) : []
									const count = emails.length
									const tooltipContent = isInRange ? (
										<div className="flex flex-col space-y-1">
											<div className="font-medium">{key}</div>
											<div>
												{count} active user{count !== 1 ? "s" : ""}
											</div>
											{emails.length > 0 && (
												<div className="flex flex-col text-sm">
													{emails.map((email, i) => (
														<span key={i}>{email}</span>
													))}
												</div>
											)}
										</div>
									) : (
										<div className="flex flex-col">
											<div className="font-medium">{key}</div>
											<div>No data</div>
										</div>
									)

									return (
										<div key={key} className="relative w-5 h-4">
											<div
												className="w-full h-full rounded-full"
												{...(isInRange
													? getColorStyle(count)
													: { style: { backgroundColor: "transparent" } })}
											/>
											<div className="absolute inset-0">
												<InfoPopover
													content={tooltipContent}
													className="opacity-0 hover:opacity-0"
												/>
											</div>
										</div>
									)
								})}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default UsersHeatmap
