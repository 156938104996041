/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { DragAndDropFileArea, ProcessSettings } from "@/components"
import MultiProjectSelect from "@/components/projects/MultiProjectSelect"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog"

import { useParentContext } from "@/context/ParentContext"
import {
	useFileHandler,
	usePatentDetails,
	useProcessDocuments,
	useUnprocessedDocuments,
} from "@/hooks"
import { useNavigationState, usePortfolioMetadata } from "@/hooks"
import { useAppStateStore } from "@/store"
import { DocumentRole, ProcessType, UnprocessedDocumentType } from "@/types"
import type { PatentNumberOption } from "@/types/documents"
import type React from "react"
import { useMemo, useState } from "react"
import AddDocumentFooter from "./AddDocumentFooter"
import UnprocessedDocumentsList from "./UnprocessedDocumentsList"

interface AddDocumentsModalProps {
	open: boolean
	handleClose: () => void
	title?: string
	buttonLabel?: string
	showProcessSettings?: boolean
	maxFiles?: number
	documentRole?: DocumentRole
	isProcessing?: boolean
}

/**
 * @description Upload files modal
 */
const AddDocumentsModal: React.FC<AddDocumentsModalProps> = ({
	open,
	handleClose,
	title = "Upload Files",
	buttonLabel = "Add",
	showProcessSettings = true,
	maxFiles = 10,
	documentRole = DocumentRole.PRIOR_ART,
	isProcessing,
}) => {
	const { projectId, portfolioId, inPortfolio } = useParentContext()
	const { processDocuments } = useProcessDocuments(projectId, portfolioId)
	const { handleFileChange, handleRemoveFile, resetAll, fileErrors, fileLimitError } =
		useFileHandler({ maxFiles })
	const {
		addUnprocessedDocuments,
		resetAll: resetUnprocessedDocuments,
		unprocessedDocuments,
		removeUnprocessedDocument,
		updateSelectedKindCode,
	} = useUnprocessedDocuments()

	const { addErrorMessage } = useAppStateStore()

	const { isDocumentsPage } = useNavigationState()
	const { portfolioProjects } = usePortfolioMetadata()

	// Local state for handling processing and confirmation
	const [fileAreaResetKey, setFileAreaResetKey] = useState<number>(0) // used to force remount of DragAndDropFileArea

	// Overbudget state similar to PatentNumberInput
	const [budgetExceededAmount, setBudgetExceededAmount] = useState<number | null>(null)
	const [_, setPendingPatentDetails] = useState<PatentNumberOption[]>([])

	// Get the on-demand patent details function (do not fetch automatically)
	const { getPatentDetails } = usePatentDetails()

	// Add project selection state
	const defaultProjectIds = projectId
		? [projectId]
		: portfolioProjects
			? portfolioProjects.map((p) => p.id)
			: []
	const [selectedProjectIds, setSelectedProjectIds] =
		useState<string[]>(defaultProjectIds)

	// State for the claims checkbox (process setting)
	const [checked, setChecked] = useState<boolean>(true)
	const { useClaimsCheckboxChecked, setUseClaimsCheckboxChecked } = (() => {
		return { useClaimsCheckboxChecked: checked, setUseClaimsCheckboxChecked: setChecked }
	})()

	// Close modal and reset state.
	const handleCloseClick = () => {
		resetAll()
		resetUnprocessedDocuments()
		handleClose()
		setSelectedProjectIds(defaultProjectIds)
		setBudgetExceededAmount(null)
		setPendingPatentDetails([])
	}

	/**
	 * Triggers when the user clicks the initial "Add" button. This function
	 * fetches dummy patent details (based on the unprocessed docs) and checks for budget issues.
	 * If any detail indicates a budget exceedance, the overbudget confirmation UI is shown.
	 * Otherwise, processing proceeds.
	 */
	const handleAddClick = async () => {
		if (isProcessing) return

		// Build dummy patent numbers for each uploaded document.
		const dummyPatentNumbers = unprocessedDocuments.map((_, index) => `DUMMY-${index}`)
		try {
			const response = await getPatentDetails(
				dummyPatentNumbers,
				portfolioId ?? projectId,
				false,
				selectedProjectIds.length,
			)

			const isOverBudget = response.some((detail) => detail.isBudgetExceeded)
			if (isOverBudget && budgetExceededAmount === null) {
				const totalOverBudget =
					response.find((detail) => detail.isBudgetExceeded)?.remainingBudget || 0
				setBudgetExceededAmount(totalOverBudget)
				setPendingPatentDetails(response.filter((item) => item.error === null))
				// Pause processing; confirmation is now needed.
				return
			}

			// If no budget issues or if already confirmed, process documents.
			handleClose()
			await processDocuments(
				ProcessType.ADD_PRIOR_ART,
				portfolioId,
				selectedProjectIds,
				unprocessedDocuments,
				!isDocumentsPage, // Don't chart if in user documents.
				useClaimsCheckboxChecked,
			)
			handleCloseClick()
		} catch (_error) {
			addErrorMessage("Error uploading documents")
		}
	}

	/**
	 * When the user confirms to proceed despite the budget being exceeded,
	 * this function continues with processing the documents.
	 */
	const handleOverBudgetConfirm = async () => {
		try {
			handleClose()
			await processDocuments(
				ProcessType.ADD_PRIOR_ART,
				portfolioId,
				selectedProjectIds,
				unprocessedDocuments,
				!isDocumentsPage,
				useClaimsCheckboxChecked,
			)
			handleCloseClick()
		} catch (_error) {
			addErrorMessage("Error uploading documents")
		} finally {
			setBudgetExceededAmount(null)
			setPendingPatentDetails([])
		}
	}

	const handleFileChangeInternal = (inputFiles: File[]) => {
		const validFiles = handleFileChange(inputFiles)
		addUnprocessedDocuments(documentRole, UnprocessedDocumentType.REFERENCE, {
			files: validFiles,
		})
	}

	const handleRemoveUploadedFile = (documentId: string) => {
		const document = unprocessedDocuments.find((doc) => doc.id === documentId)
		if (document) {
			removeUnprocessedDocument(documentId)
			handleRemoveFile(document.file)
		}
	}

	const isDragAndDropDisabled = useMemo(
		() => unprocessedDocuments.length >= maxFiles,
		[unprocessedDocuments.length, maxFiles],
	)

	const isButtonDisabled = useMemo(() => {
		return (
			unprocessedDocuments.length === 0 || isProcessing || budgetExceededAmount !== null
		)
	}, [unprocessedDocuments.length, isProcessing, budgetExceededAmount])

	const handleReset = () => {
		resetAll()
		resetUnprocessedDocuments()
		setFileAreaResetKey((prev) => prev + 1)
		setBudgetExceededAmount(null)
		setPendingPatentDetails([])
	}

	const effectiveButtonLabel = buttonLabel

	return (
		<Dialog open={open} onOpenChange={handleCloseClick}>
			<DialogContent className="w-[80vw] min-w-[300px] max-w-[1200px]">
				<div className="flex items-center">
					<DialogHeader>
						<DialogTitle>{title}</DialogTitle>
					</DialogHeader>
					{/* Show project selection for portfolio view */}
					{inPortfolio && (
						<div className="ml-4">
							<MultiProjectSelect
								selectedProjectIds={selectedProjectIds}
								onChange={setSelectedProjectIds}
							/>
						</div>
					)}
				</div>

				{/* If the action is over budget, display a styled alert with patent details and confirmation actions */}
				{budgetExceededAmount !== null ? (
					<div className="mt-4 space-y-4">
						<Alert variant="destructive">
							<AlertDescription>
								This action will exceed this project's budget by $
								{budgetExceededAmount.toLocaleString()}. Are you sure you want to continue?
							</AlertDescription>
						</Alert>
						{unprocessedDocuments.length > 0 && (
							<div className="mt-4 border rounded p-4">
								<h3 className="text-sm font-medium mb-2">Documents to be added:</h3>
								<div className="space-y-3">
									{unprocessedDocuments.map((doc) => (
										<div key={doc.id} className="text-sm">
											<div className="font-medium">{doc.file.name}</div>
										</div>
									))}
								</div>
							</div>
						)}
						<div className="flex justify-end gap-2">
							<Button
								variant="outline"
								onClick={() => {
									setBudgetExceededAmount(null)
									setPendingPatentDetails([])
								}}
							>
								Cancel
							</Button>
							<Button variant="destructive" onClick={handleOverBudgetConfirm}>
								Continue Anyway
							</Button>
						</div>
					</div>
				) : (
					<>
						{/* File Upload Section */}
						<div className="flex flex-col space-y-4 mt-4">
							<DragAndDropFileArea
								key={fileAreaResetKey}
								handleFiles={handleFileChangeInternal}
								supportedFileTypes={[".pdf", ".txt"]}
								maxFiles={maxFiles}
								disabled={isDragAndDropDisabled}
							/>
						</div>

						{/* Display Selected Files */}
						{unprocessedDocuments.length > 0 && (
							<div className="mt-4">
								<UnprocessedDocumentsList
									documents={unprocessedDocuments}
									onRemove={handleRemoveUploadedFile}
									updateSelectedKindCode={updateSelectedKindCode}
								/>
							</div>
						)}
					</>
				)}

				{/* Footer */}
				<DialogFooter className="mt-4">
					{budgetExceededAmount === null && (
						<div className="flex items-center justify-between w-full">
							<div className="flex flex-col gap-4">
								{showProcessSettings && (
									<ProcessSettings
										useClaimsCheckboxChecked={useClaimsCheckboxChecked}
										setUseClaimsCheckboxChecked={setUseClaimsCheckboxChecked}
										overwriteCheckboxChecked={false}
										setOverwriteCheckboxChecked={() => {}}
									/>
								)}
							</div>
							<AddDocumentFooter
								unprocessedDocuments={unprocessedDocuments}
								fileErrors={fileErrors}
								fileLimitError={fileLimitError}
								patentDetails={[]}
								handleReset={handleReset}
								handleAddClick={handleAddClick}
								isButtonDisabled={isButtonDisabled}
								isProcessing={isProcessing}
								buttonLabel={effectiveButtonLabel}
								handleAddAsPatentClick={() => {}}
								potentialPatents={[]}
							/>
						</div>
					)}
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default AddDocumentsModal
