import { ScrollArea } from "@/components/ui/scroll-area"
import { useParentContext } from "@/context/ParentContext"
import type React from "react"
import { useEffect, useMemo, useRef, useState } from "react"
import { useChartsContext } from "../../context/ChartsContext"
import { useChartSummaryData } from "../../hooks"

const VerticalClaimNavigation: React.FC = () => {
	const containerRef = useRef<HTMLDivElement>(null)
	const [isHovered, setIsHovered] = useState(false)
	const { filteredLimitations, activeLimitationId, setActiveLimitationId } =
		useChartsContext()
	const { projectId } = useParentContext()
	const { invalidityChartId } = useChartsContext()

	const { invaliditySummaryData } = useChartSummaryData(projectId, invalidityChartId)

	const limitationColors = useMemo(() => {
		if (!invaliditySummaryData) return {}

		const mapping: { [key: string]: string } = {}
		for (const row of invaliditySummaryData) {
			mapping[row.claimLimitationId] =
				row.chartColors[invalidityChartId].color?.toString() || "#D3D3D3"
		}
		return mapping
	}, [invaliditySummaryData, invalidityChartId])

	/**
	 * When the mouse is not over the component, arrow keys update the selected item.
	 * When the mouse is hovered, we let the keys scroll the navigation normally.
	 */
	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		// If the mouse pointer is over the component, do not intercept key presses.
		if (isHovered) return

		const isVerticalNav = event.key === "ArrowUp" || event.key === "ArrowDown"
		const isHorizontalNav = event.key === "ArrowLeft" || event.key === "ArrowRight"

		if (isVerticalNav || isHorizontalNav) {
			const currentIndex = filteredLimitations.findIndex(
				(lim) => lim.id === activeLimitationId,
			)

			// If no item is selected, pick one based on direction.
			if (currentIndex === -1 && filteredLimitations.length > 0) {
				if (event.key === "ArrowDown" || event.key === "ArrowRight") {
					setActiveLimitationId(filteredLimitations[0].id)
				} else {
					setActiveLimitationId(filteredLimitations[filteredLimitations.length - 1].id)
				}
				return
			}

			let newIndex = currentIndex
			if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
				newIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex
			} else if (event.key === "ArrowDown" || event.key === "ArrowRight") {
				newIndex =
					currentIndex < filteredLimitations.length - 1 ? currentIndex + 1 : currentIndex
			}

			if (newIndex !== currentIndex) {
				setActiveLimitationId(filteredLimitations[newIndex].id)
			}
		}
	}

	// Auto focus the container so that it can capture keyboard events.
	useEffect(() => {
		containerRef.current?.focus()
	}, [])

	// When the active limitation changes, scroll it into view.
	useEffect(() => {
		if (activeLimitationId && containerRef.current) {
			const activeElement = containerRef.current.querySelector(
				`[data-limitation-id="${activeLimitationId}"]`,
			)
			activeElement?.scrollIntoView({ behavior: "smooth", block: "nearest" })
		}
	}, [activeLimitationId])

	return (
		<ScrollArea className="h-full px-2">
			<nav
				ref={containerRef}
				aria-label="Claim navigation"
				onKeyDown={handleKeyDown}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				className="w-full outline-none"
			>
				<div className="flex flex-col gap-2 p-2">
					{filteredLimitations.map((limitation) => (
						<div
							key={limitation.id}
							data-limitation-id={limitation.id}
							onClick={() => setActiveLimitationId(limitation.id)}
							className="cursor-pointer text-sm p-1 h-7 text-center text-black rounded hover:bg-blue-100 dark:hover:bg-blue-900 transition-colors"
							style={{
								backgroundColor: limitationColors[limitation.id] || "",
								...(activeLimitationId === limitation.id && {
									outline: "2px solid",
									outlineColor: "rgb(59 130 246)", // blue-500
									outlineOffset: "-2px",
								}),
							}}
						>
							{limitation.label || limitation.id}
						</div>
					))}
				</div>
			</nav>
		</ScrollArea>
	)
}

export default VerticalClaimNavigation
