import { coverApi } from "@/api/coverApi"
import { useParentContext } from "@/context/ParentContext"
import type { DocumentChunk, DocumentCoverSentence } from "@/types"
import { MUTATION_KEYS, QUERY_KEYS } from "@/utils"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

/**
 * @description Hook for facilitating sentences editor operations
 */
const useSentencesEditor = (chunkIds: string[], documentId: string) => {
	const queryClient = useQueryClient()
	const { projectId, portfolioId } = useParentContext()

	const { data: documentChunks } = useQuery<DocumentChunk[]>({
		queryKey: QUERY_KEYS.cover.documentChunks(documentId),
		queryFn: () => coverApi.getDocumentChunksByIds(chunkIds),
		enabled: chunkIds.length > 0,
		...DEFAULT_QUERY_OPTIONS,
	})

	const invalidateSentencesEditorQueries = () => {
		queryClient.invalidateQueries({
			queryKey: QUERY_KEYS.cover.documentChunks(documentId),
		})
	}

	type UpdateDocumentSummaryPayload = {
		sentences: DocumentCoverSentence[]
	}

	const updateDocumentSummary = useMutation({
		mutationKey: MUTATION_KEYS.cover.update(),
		mutationFn: async ({ sentences }: UpdateDocumentSummaryPayload) => {
			return coverApi.updateDocumentSummary(
				projectId || "",
				portfolioId || "",
				documentId,
				sentences,
			)
		},
	})

	return {
		invalidateSentencesEditorQueries,
		documentChunks,
		updateDocumentSummary: updateDocumentSummary.mutate,
	}
}

export default useSentencesEditor
