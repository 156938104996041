import { DocumentChunkComponent } from "@/components/documents/sections/body/DocumentChunk"
import type { DocumentChunk } from "@/types"
import { BlockType } from "@/types"
import type React from "react"
import DocumentChunkActions from "./DocumentChunkActions"
import { FigureComponent } from "./FigureComponent"

export interface DocumentChunkCitationProps {
	chunk: DocumentChunk
	showFigures?: boolean
	selectedLimitationId?: string
	onAddCitation?: (chunk: DocumentChunk) => void
}

export const DocumentChunkCitation: React.FC<DocumentChunkCitationProps> = ({
	chunk,
	showFigures = false,
	selectedLimitationId,
	onAddCitation,
}) => {
	return (
		<DocumentChunkActions
			chunk={chunk}
			selectedLimitationId={selectedLimitationId}
			onAddCitation={onAddCitation}
		>
			{chunk.type === BlockType.FIGURE ? (
				<FigureComponent chunk={chunk} />
			) : (
				<DocumentChunkComponent chunk={chunk} showFigures={showFigures} />
			)}
		</DocumentChunkActions>
	)
}

export default DocumentChunkCitation
