import { api } from "@/api"
import { useParentContext } from "@/context/ParentContext"
import type { ProjectDocument } from "@/types"
import { QUERY_KEYS } from "@/utils/query/keys"
import { DEFAULT_QUERY_OPTIONS } from "@/utils/query/queryConfig"
import { useQuery } from "@tanstack/react-query"

export function useProjectSubject() {
	const { projectId } = useParentContext()

	const projectSubjectQuery = useQuery({
		queryKey: QUERY_KEYS.project.subject(projectId),
		queryFn: () => api.getProjectSubject(projectId),
		enabled: !!projectId,
		...DEFAULT_QUERY_OPTIONS,
	})

	return {
		projectSubject: projectSubjectQuery.data as ProjectDocument,
		isLoading: projectSubjectQuery.isLoading,
		isError: projectSubjectQuery.isError,
		refetchProjectSubject: projectSubjectQuery.refetch,
	}
}

export default useProjectSubject
