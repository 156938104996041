import { Avatar, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
/*
 * Copyright AndAI, Inc. 2025. All rights reserved. This file contains proprietary
 * information that is the property of AndAI, Inc. and is protected as a trade secret.
 */
import { useAuthInfo, useLogoutFunction } from "@propelauth/react"
import { ExitIcon } from "@radix-ui/react-icons"

function ProfileMenu() {
	const logout = useLogoutFunction()
	const authInfo = useAuthInfo()

	const firstName = authInfo.user?.firstName || authInfo.user?.email.split("@")[0] || ""

	const handleLogout = async () => {
		await logout(false) // Log out without redirecting
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="relative h-8 w-8 rounded-full">
					<Avatar className="h-8 w-8">
						<AvatarFallback>{firstName.charAt(0).toUpperCase()}</AvatarFallback>
					</Avatar>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56" align="end" forceMount>
				<DropdownMenuItem onClick={handleLogout}>
					<ExitIcon className="mr-2 h-4 w-4" />
					<span>Log out</span>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export default ProfileMenu
